import React, { useState } from "react";

import { Grid, Button, Typography, Grow } from "@mui/material";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { TextField, ToggleButtons } from "@aclymatepackages/atoms";

import RentalOrCompanyVehicleButtons from "../../atoms/buttons/RentalOrCompanyVehicleButtons";
import PlacesAutocomplete from "../autocomplete/PlacesAutocomplete";

import { buildInitialTransactionInputValueFromSchema } from "../../../helpers/components/inputs";
import { setAddress } from "../../../helpers/utils/geography";
import {
  fetchCarbonFromGallons,
  fetchFuelBillCarbon,
} from "../../../helpers/utils/apiCalls";

const editFuelGallons = async (gallons, fuelType, setTransactionCarbonTons) => {
  if (gallons && numbersRegExpTest(gallons)) {
    const tonsCo2 = await fetchCarbonFromGallons({ gallons, fuelType });
    return setTransactionCarbonTons(tonsCo2);
  }
  return setTransactionCarbonTons(0);
};

const FuelInputBlock = ({
  date,
  value,
  gasStation,
  editInputData,
  setCalcLoading,
  purchaseType,
  setPurchaseType,
  lessPrecise,
  setLessPrecise,
  billGallons,
  fuelCost,
  fuelType,
}) => {
  const onFuelTypeChange = async (fuelType) => {
    editInputData("fuelType")(fuelType);
    setCalcLoading(true);
    await editFuelGallons(billGallons, fuelType, editInputData("fuelTonsCo2e"));
    return setCalcLoading(false);
  };

  const onBillGallonsChange = async (value) => {
    editInputData("billGallons")(value);
    setCalcLoading(true);
    await editFuelGallons(value, fuelType, editInputData("fuelTonsCo2e"));
    return setCalcLoading(false);
  };

  const buildStationNameError = () => {
    if (!lessPrecise || !gasStation) {
      return "";
    }

    const { country } = gasStation || {};
    if (!country) {
      return "";
    }

    if (country.toLowerCase() === "us") {
      return "";
    }

    return "Gas station must be in the US";
  };

  const stationNameError = buildStationNameError();

  const onStationFuelChange = async ({ value, station }) => {
    const gasPrice = value || fuelCost;
    const gasStationForCalcs = station || gasStation;

    if (
      gasStationForCalcs &&
      gasPrice &&
      numbersRegExpTest(gasPrice) &&
      (!billGallons || !numbersRegExpTest(billGallons))
    ) {
      const tonsCo2 = await fetchFuelBillCarbon({
        billValue: Number(value) || Number(gasPrice),
        billDate: date || new Date(),
        gasStation: gasStationForCalcs,
      });
      return editInputData("fuelTonsCo2e")(tonsCo2);
    }

    return editInputData("fuelTonsCo2e")(0);
  };

  const editStation = async (station) => {
    setCalcLoading(true);
    editInputData("gasStation")(station);
    await onStationFuelChange({ station });
    return setCalcLoading(false);
  };

  const editFuelBill = async (value) => {
    setCalcLoading(true);
    editInputData("fuelCost")(value);
    await onStationFuelChange({ value });
    return setCalcLoading(false);
  };

  const autocompleteProps = {
    label: "City & State",
    place: gasStation || null,
    editPlace: setAddress((place) => editStation(place)),
    size: "small",
    helperText: stationNameError,
    error: !value && !!stationNameError,
    id: "fuel-location-input",
  };

  return (
    <Grid
      item
      container
      justifyContent="space-around"
      spacing={3}
      direction="column"
      wrap="nowrap"
    >
      <RentalOrCompanyVehicleButtons
        purchaseType={purchaseType}
        setPurchaseType={setPurchaseType}
      />
      {!lessPrecise ? (
        <Grid item container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h6">Gallons of Fuel Purchased</Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item>
              <ToggleButtons
                size="small"
                value={fuelType}
                onChange={onFuelTypeChange}
                buttons={[
                  {
                    value: "gas",
                    name: "Gasoline",
                    id: "gas",
                  },
                  {
                    value: "diesel",
                    name: "Diesel",
                    id: "diesel",
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Grid item>
            <TextField
              label="Gallons of Fuel"
              variant="outlined"
              fullWidth
              value={billGallons}
              setValue={onBillGallonsChange}
              helperText={
                !!billGallons &&
                (!numbersRegExpTest(billGallons) || billGallons >= 10000)
                  ? "This must be a number less than 10,000"
                  : ""
              }
              id="fuel-gallons-input"
            />
          </Grid>
          <Grid item>
            <Button
              onClick={() => {
                onBillGallonsChange(0);
                return setLessPrecise(true);
              }}
              variant="contained"
              color="primary"
              size="small"
            >
              Don't know how many gallons?
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grow in={lessPrecise} unmountOnExit>
          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">Location of Fuel Purchase</Typography>
              <Typography variant="body2" display="block">
                {value
                  ? "Please enter where you purchased the fuel."
                  : "Enter the location and cost of this fuel purchase"}
              </Typography>
            </Grid>
            {value ? (
              <Grid item>
                <PlacesAutocomplete {...autocompleteProps} />
              </Grid>
            ) : (
              <Grid item container spacing={2}>
                <Grid item sm={5}>
                  <TextField
                    label="Fuel Cost"
                    value={fuelCost}
                    setValue={editFuelBill}
                    id="fuel-cost-input"
                  />
                </Grid>
                <Grid item sm={7}>
                  <PlacesAutocomplete {...autocompleteProps} />
                </Grid>
              </Grid>
            )}
            <Grid item>
              <Button
                onClick={() => setLessPrecise(false)}
                size="small"
                variant="contained"
                color="primary"
              >
                Enter gallons instead
              </Button>
            </Grid>
          </Grid>
        </Grow>
      )}
    </Grid>
  );
};

const useFuelInput = ({ transaction = {}, onSave, setCalcLoading }) => {
  const { vehicle } = transaction;
  const { fuelType: vehicleFuelType } = vehicle || {};

  const inputSchema = [
    { field: "purchaseType", defaultValue: "company-vehicle" },
    { field: "fuelCost" },
    { field: "gasStation" },
    { field: "billGallons" },
    { field: "fuelType", defaultValue: "gas" },
  ];

  const [lessPrecise, setLessPrecise] = useState(false);
  const [inputData, setInputData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );
  const {
    purchaseType,
    fuelTonsCo2e,
    billGallons,
    fuelType: inputFuelType,
  } = inputData;

  const editInputData = (field) => (value) =>
    editObjectData(setInputData, field, value);

  const fuelType = vehicleFuelType || inputFuelType;

  const inputBlock = (
    <FuelInputBlock
      {...transaction}
      {...inputData}
      fuelType={fuelType}
      editInputData={editInputData}
      setCalcLoading={setCalcLoading}
      purchaseType={purchaseType}
      setPurchaseType={editInputData("purchaseType")}
      lessPrecise={lessPrecise}
      setLessPrecise={setLessPrecise}
    />
  );

  const onTransactionSave = () =>
    onSave({
      ...inputData,
      tonsCo2e: fuelTonsCo2e,
    });

  const saveEnabled = !!billGallons
    ? numbersRegExpTest(billGallons) && billGallons < 10000
    : !!fuelTonsCo2e;

  return {
    inputBlock,
    onTransactionSave,
    saveEnabled,
    tonsCo2e: fuelTonsCo2e,
  };
};
export default useFuelInput;
