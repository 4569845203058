import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";

import {SUPPORT_WEBSITE} from "@aclymatepackages/constants"

import { Box, Grid, Typography, Button } from "@mui/material";

import Link from "./mui/Link";

import { useAccountData, useAuth } from "../../helpers/firebase";
import { sendSupportEmail } from "../../helpers/utils/apiCalls";

const ErrorScreen = () => {
  const [{ name: companyName }, companyDataLoading] = useAccountData();
  const [user, userLoading] = useAuth();

  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {
    if (
      !userLoading &&
      !messageSent &&
      !companyDataLoading &&
      process.env.REACT_APP_ENVIRONMENT === "production"
    ) {
      const { displayName, email } = user || {};
      sendSupportEmail({
        name: displayName,
        companyName,
        email,
        subject: "Sentry Error Detected",
        message: `An error occurred at ${window.location.href}`,
      });
      setMessageSent(true);
    }
  }, [user, userLoading, companyName, messageSent, companyDataLoading]);

  return (
    <Box p={8} display="flex" alignItems="center" justifyContent="center">
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5" align="center">
            We're sorry, but unfortunately an error occurred.
          </Typography>
          <Typography variant="subtitle1" align="center" color="textSecondary">
            We've already been alerted, but you can send us an email if you want
            to provide more details
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" spacing={2}>
          <Grid item>
            <Link href={SUPPORT_WEBSITE}>
              <Button variant="contained" color="primary">
                Contact Support
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
