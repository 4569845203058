import React, { useState } from "react";

import { Box } from "@mui/material";

import EditableContentBlock from "./EditableContentBlock";
import ImageUploadDropzone from "./ImageUploadDropzone";

const EditableImageUploadDropzone = ({
  onSave,
  imageName,
  imageUrl,
  isLoading,
  backgroundSize = "cover",
  contentStyle = {},
  previewImageStyle = {},
  children,
  ...otherProps
}) => {
  const [imageFile, setImageFile] = useState(null);

  const isImageInFirebaseStorage = imageUrl?.includes("firebasestorage");

  const onImageSave = async () => {
    await onSave(imageFile);
    return setImageFile(null);
  };

  return (
    <EditableContentBlock
      {...otherProps}
      padding={0}
      elementName={imageName}
      contentLoading={isLoading}
      isEditableByDefault={!imageUrl}
      imageFile={imageFile}
      content={
        imageUrl ? (
          <Box
            style={{
              height: "100%",
              width: "100%",
              backgroundImage: isImageInFirebaseStorage
                ? `url(${imageUrl})`
                : `url('${imageUrl}?width=1920&format=jpeg&quality=50')`,
              backgroundSize,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              ...contentStyle,
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {children}
          </Box>
        ) : (
          <ImageUploadDropzone
            imageName={imageName}
            onImageUpload={onImageSave}
            previewImageStyle={previewImageStyle}
          />
        )
      }
      editingInterface={
        <ImageUploadDropzone
          setImageFile={setImageFile}
          imageName={imageName}
          previewImageStyle={previewImageStyle}
        />
      }
      onSave={onImageSave}
      saveDisabled={!imageFile}
    />
  );
};
export default EditableImageUploadDropzone;
