import React from "react";

import { Grid, Typography } from "@mui/material";

import { CategoriesAvatar, DefaultPaper } from "@aclymatepackages/atoms";
import { subcategories } from "@aclymatepackages/subcategories";
import { formatDecimal } from "@aclymatepackages/formatters";

const getEventPieChartTooltipData = (subcategory) => {
  const predefinedSubcategoryNames = {
    electricity: "Venue Electricity",
    gas: "Venue Gas",
    waste: "Attendee Emissions",
    shipping: "Employee Emissions",
  };

  const existingSubcategoryNames = subcategories.reduce(
    (acc, { subcategory, name }) => {
      if (!predefinedSubcategoryNames.hasOwnProperty(subcategory)) {
        return { ...acc, [subcategory]: name };
      }
      return acc;
    },
    {}
  );

  const allSubcategoryNames = {
    ...existingSubcategoryNames,
    ...predefinedSubcategoryNames,
  };

  return allSubcategoryNames[subcategory];
};

const EventPieChartTooltip = ({ payload }) => {
  const [payloadObj] = payload || [{}];

  const { payload: activePayload = {} } = payloadObj || [{}];

  const { payload: tooltipPayload = {} } = activePayload || {};

  const { emissionPercentage, subcategory, scope, color } = tooltipPayload;

  const name = getEventPieChartTooltipData(subcategory);

  return (
    <DefaultPaper>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <CategoriesAvatar
            scope={scope}
            subcategory={subcategory}
            color={color}
          />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" color="textPrimary">
            {name}
          </Typography>
          <Typography variant="caption" color="textSecondary">{`${formatDecimal(
            emissionPercentage
          )}% of total emissions`}</Typography>
        </Grid>
      </Grid>
    </DefaultPaper>
  );
};

export default EventPieChartTooltip;
