import React from "react";

import { Select } from "@aclymatepackages/atoms";

import {
  faSchool,
  faShoppingBasket,
  faUtensils,
  faHospital,
  faClinicMedical,
  faHotel,
  faCashRegister,
  faStore,
  faChairOffice,
  faUniversity,
  faPoliceBox,
  faPlaceOfWorship,
  faWrench,
  faWarehouseAlt,
  faIndustry,
} from "@fortawesome/pro-solid-svg-icons";

const buildingsSlugs = [
  { label: "Educational Facility", value: "education", icon: faSchool },
  { label: "Food Sales", value: "foodSales", icon: faShoppingBasket },
  { label: "Food Service", value: "foodService", icon: faUtensils },
  { label: "Healthcare- Inpatient", value: "inpatient", icon: faHospital },
  {
    label: "Healthcare- Outpatient",
    value: "outpatient",
    icon: faClinicMedical,
  },
  { label: "Lodging", value: "lodging", faHotel },
  { label: "Retail (other than malls)", value: "retail", faCashRegister },
  { label: "Mall (enclosed or strip)", value: "mall", faStore },
  { label: "Office", value: "office", faChairOffice },
  { label: "Public Assembly", value: "publicAssembly", faUniversity },
  { label: "Public Order and Safety", value: "publicOrder", faPoliceBox },
  { label: "Religious Worship", value: "religiousWorship", faPlaceOfWorship },
  { label: "Service", value: "service", faWrench },
  { label: "Warehouse and Storage", value: "warehouse", faWarehouseAlt },
  { label: "Other", value: "other", faIndustry },
];

const FacilityTypeSelect = ({
  facilityType = {},
  editFacilityType,
  label = "Facility Type",
}) => {
  const onFacilityTypeSelect = (facilityType) => {
    const { label, value } = buildingsSlugs.find(
      ({ value }) => value === facilityType
    );
    return editFacilityType({ label, value });
  };

  return (
    <Select
      size="small"
      options={buildingsSlugs}
      label={label}
      value={facilityType.value || ""}
      editValue={onFacilityTypeSelect}
    />
  );
};
export default FacilityTypeSelect;
