import React from "react";

import { Grid, Typography } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EmissionsDetailsWarning = ({ icon, emissionsDetailsTooltip, color }) => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    spacing={2}
    direction="column"
    wrap="nowrap"
  >
    <Grid item>
      <FontAwesomeIcon icon={icon} size="9x" style={{ color }} />
    </Grid>
    <Grid item xs={12}>
      <Typography align="center">{emissionsDetailsTooltip}</Typography>
    </Grid>
  </Grid>
);
export default EmissionsDetailsWarning;
