import React, { useState, useContext } from "react";

import {
  Box,
  Grid,
  Typography,
  Avatar,
  IconButton,
  useTheme,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faMoneyCheck,
  faTag,
} from "@fortawesome/free-solid-svg-icons";

import { tonsToLbs } from "@aclymatepackages/converters";
import { LoadingButton } from "@aclymatepackages/atoms";
import { ucFirstLetters } from "@aclymatepackages/formatters";

import PurchaseDialogLayout from "../../layouts/PurchaseDialogLayout";

import { analyticsTrack } from "../../../helpers/analytics";
import {
  generateDisclaimers,
  generateDisplayIcons,
  googlePurchaseTracking,
  fireConfetti,
} from "../../../helpers/components/purchase";
import { StripeCustomerContext } from "../../../helpers/contexts/stripeCustomer";
import { useCustomerCoupon } from "../../../helpers/hooks/stripe";
import { useAccountData } from "../../../helpers/firebase";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import usePurchasingContext from "../../../helpers/contexts/purchasing";
import { useGoldTier } from "../../../helpers/components/badges";

const PurchaseOffsetFooter = ({
  project,
  setPurchaseStep,
  setTransactionId,
  isCNaughtPurchase,
  paymentMethod,
}) => {
  const {
    purchaseTons,
    purchaseDollars,
    setPurchaseTons,
    nonDiamondYears,
    selectedScopeChips,
    selectedTab,
    selectedEvents,
  } = usePurchasingContext();
  const [{ stripeCustomerId: companyObjStripeCustomerId, billing }] =
    useAccountData();
  const { isEarned: isGoldEarned } = useGoldTier();

  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [purchaseError, setPurchaseError] = useState(null);
  const [purchaseErrorMessage, setPurchaseErrorMessage] = useState(null);

  const buildBadgeYears = () => {
    if (selectedTab !== "scopes") {
      return null;
    }

    const uniqueChipYears = [
      ...new Set(selectedScopeChips.map(({ year }) => year)),
    ];

    return uniqueChipYears.map((year) => {
      const isDiamond = !!selectedScopeChips.find(
        (chip) => chip.year === year && chip.type === "diamond"
      );

      return { year, badge: isDiamond ? "diamond" : "platinum" };
    });
  };
  const badgeYears = buildBadgeYears();
  const formattedSelectedEvents =
    Array.isArray(selectedEvents) && selectedEvents.length
      ? selectedEvents.map(({ id, name }) => ({ id, name }))
      : null;

  const purchaseOffset = () => {
    setPurchaseLoading(true);

    const accountId = window.sessionStorage.getItem("accountId");

    const { stripeCustomerId: billingObjStripeCustomerId } = billing || {};
    const { paymentMethodId, us_bank_account } = paymentMethod || {};
    const {
      images,
      name: projectName,
      state,
      country,
      distanceMi,
      slug,
      offsetProvider,
      description,
      typeSlug,
      type,
      coordinates = {},
      id,
      registry,
      registryUrl,
    } = project;

    const callAnalyticsTrackers = () => {
      if (badgeYears) {
        badgeYears.forEach(({ year, badge }) =>
          analyticsTrack(badge === "diamond" ? "Offset S3" : "Offset S1+2", {
            year,
          })
        );
      }

      const earnedBadgesEventCalls = () => {
        if (!isGoldEarned || !(badgeYears.length === nonDiamondYears.length)) {
          return;
        }

        const isEveryBadgeYearDiamond = badgeYears.every(
          ({ badge }) => badge === "diamond"
        );

        if (isEveryBadgeYearDiamond) {
          return analyticsTrack("Diamond Unlock");
        }

        return analyticsTrack("Platinum Unlock");
      };

      if (isCNaughtPurchase) {
        return analyticsTrack("CNaught Portfolio Purchased", {
          tons: purchaseTons,
          price: purchaseDollars,
        });
      }

      earnedBadgesEventCalls();
      googlePurchaseTracking("add_shipping_info", {
        project,
        purchaseDollars,
        purchaseTons,
      });
      return analyticsTrack("Offset Purchased", {
        tons: purchaseTons,
        project: slug,
        price: purchaseDollars,
      });
    };

    const purchaseOffsetCallback = async (data) => {
      if (data.error) {
        setPurchaseLoading(false);
        setPurchaseErrorMessage(data.message);
        return setPurchaseError(true);
      }
      const { invoiceId } = data;

      callAnalyticsTrackers();
      fireConfetti();

      setTransactionId(invoiceId);
      return setPurchaseStep("success");
    };

    try {
      return fetchOurApi({
        path: `/stripe/${
          us_bank_account
            ? "create-ach-offsets-payment"
            : "credit-card-offsets-purchase"
        }`,
        method: "POST",
        data: {
          accountId,
          projectSlug: slug,
          projectData: {
            state,
            projectName,
            offsetType: type,
            offsetTypeSlug: typeSlug,
            latLng: coordinates,
            description,
            images,
            id,
            registry,
            registryUrl,
          },
          country,
          offsetProvider,
          lbsToPurchase: tonsToLbs(purchaseTons),
          customerId: companyObjStripeCustomerId || billingObjStripeCustomerId,
          paymentMethodId,
          distanceMi,
          isCNaughtPurchase,
          badgeYears,
          events: formattedSelectedEvents,
        },
        callback: purchaseOffsetCallback,
      });
    } catch (e) {
      setPurchaseLoading(false);
      return setPurchaseError(true);
    }
  };

  return (
    <>
      {purchaseError && (
        <Grid item>
          <Typography variant="body2" color="error" align="center">
            {`An error occurred. Please try another project. ${purchaseErrorMessage}`}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        container
        justifyContent="center"
        spacing={2}
        alignItems="center"
      >
        <Grid item>
          <IconButton
            onClick={() => {
              setPurchaseStep("");
              setPurchaseTons(0);
            }}
            size="large"
          >
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <LoadingButton
            label="confirm this purchase"
            variant="contained"
            color="secondary"
            onClick={purchaseOffset}
            isLoading={purchaseLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};

const PurchaseConfirmation = ({
  project,
  setPurchaseStep,
  setTransactionId,
  isCNaughtPurchase,
}) => {
  const { palette } = useTheme();

  const { purchaseTons, purchaseDollars, selectedScopeChips, selectedTab } =
    usePurchasingContext();
  const { paymentMethod } = useContext(StripeCustomerContext);
  const { card, us_bank_account } = paymentMethod || {};
  const { last4: cardLast4, brand } = card || {};
  const { bank_name, last4: bankLast4 } = us_bank_account || {};

  const [offsetsCoupon] = useCustomerCoupon("offsets");
  const { percentOff, amountOff } = offsetsCoupon || {};

  const generateBadgeLevelYears = () => {
    if (selectedTab !== "scopes") {
      return {};
    }
    const diamondChips = selectedScopeChips.filter(
      ({ type }) => type === "diamond"
    );

    if (diamondChips.length) {
      return {
        badge: "diamond",
        years: diamondChips.length,
        badgeName: "Scope 3 Offsets",
      };
    }

    const platinumChips = selectedScopeChips.filter(
      ({ type }) => type === "platinum"
    );

    if (platinumChips.length) {
      return {
        badge: "platinum",
        years: platinumChips.length,
        badgeName: "Scope 1 + 2 Offsets",
      };
    }

    return {};
  };

  const { badge, years: badgeYears, badgeName } = generateBadgeLevelYears();

  const getDiscountPrice = () => {
    if (percentOff) {
      return purchaseDollars * ((100 - percentOff) / 100);
    }

    if (amountOff) {
      return purchaseDollars - amountOff;
    }

    return null;
  };

  const discountPrice = getDiscountPrice();

  const displayIcons = generateDisplayIcons({
    purchaseDollars,
    purchaseTons,
    discountPrice,
    badge,
    badgeYears,
    badgeName,
  });

  const createPaymentDisclaimerObj = () => {
    if (us_bank_account) {
      return {
        icon: faMoneyCheck,
        text: `We will bill your ${ucFirstLetters(
          bank_name
        )} bank account with an account number ending in ${bankLast4} for this transaction. It may take up to four business days to bill your bank account.`,
      };
    }

    return {
      icon: faCreditCard,
      text: `We will bill your ${ucFirstLetters(
        brand
      )} credit card ending in ${cardLast4} for this transaction.`,
    };
  };

  const createPurchaseDisclaimers = () => {
    if (offsetsCoupon) {
      return [
        {
          icon: faTag,
          text: `A ${
            percentOff ? `${percentOff}%` : `$${amountOff}`
          } discount will be applied to your purchase.`,
        },
        createPaymentDisclaimerObj(),
      ];
    }

    return [createPaymentDisclaimerObj()];
  };

  const disclaimers = generateDisclaimers(createPurchaseDisclaimers());

  return (
    <PurchaseDialogLayout
      title="Please confirm your purchase of offsets"
      subtitle="We need you to review the information and disclaimers about this purchase."
    >
      <Box p={2} position="relative">
        <Grid container direction="column" spacing={2}>
          <Grid item container spacing={1} alignItems="stretch">
            {displayIcons.map(({ icon, title, subtitle }, idx) => (
              <Grid
                item
                sm={badge ? 4 : 6}
                key={`purchase-display-icon-${idx}`}
              >
                <Box
                  p={1}
                  style={{
                    background: palette.backgroundGray.main,
                    height: "100%",
                  }}
                  display="flex"
                  alignItems="center"
                >
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    alignItems="center"
                  >
                    <Grid item>{icon}</Grid>
                    <Grid item>
                      <Typography
                        variant="h5"
                        align="center"
                        color="textPrimary"
                      >
                        {title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        align="center"
                        color="textSecondary"
                      >
                        {subtitle}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
          {disclaimers.map(({ avatar, icon, text }, idx) => (
            <Grid
              key={`purchase-disclaimer-${idx}`}
              item
              container
              spacing={2}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Avatar>
                  {icon ? <FontAwesomeIcon icon={icon} /> : avatar}
                </Avatar>
              </Grid>
              <Grid item>
                <Typography variant="body1">{text}</Typography>
              </Grid>
            </Grid>
          ))}
          <PurchaseOffsetFooter
            project={project}
            setPurchaseStep={setPurchaseStep}
            setTransactionId={setTransactionId}
            isCNaughtPurchase={isCNaughtPurchase}
            paymentMethod={paymentMethod}
          />
        </Grid>
      </Box>
    </PurchaseDialogLayout>
  );
};
export default PurchaseConfirmation;
