import React, { useState, useContext } from "react";

import {
  Grid,
  Button,
  Typography,
  InputAdornment,
  Avatar,
  Chip,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBadgePercent } from "@fortawesome/pro-solid-svg-icons";

import { TextField } from "@aclymatepackages/atoms";
import { formatDecimal } from "@aclymatepackages/formatters";
import { findYearsBaselinePledgePercentages } from "@aclymatepackages/accounting";

import SlideLayout from "../../layouts/SlideLayout";
import AccountingChart from "../../modules/charts/AccountingChart";

import useAccountingData from "../../../helpers/hooks/accountingData";
import { useCachedFirebaseCrud } from "../../../helpers/firebase";
import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";
import { useClimateWiseTiers } from "../../../helpers/components/badges";
import { analyticsTrack } from "../../../helpers/analytics";

const PledgeSlider = ({ setOpen, minimumPledgePercentage, title }) => {
  const { palette } = useTheme();
  const [{ accountingYears = [], baselineDate, baselineTonsCo2e }] =
    useAccountingData();
  const { displayUnitLabel, convertCarbonUnits } = useContext(
    PlatformLayoutContext
  );
  const { updateAccountData } = useCachedFirebaseCrud();
  const { isFinished: isClimateWiseFinished } = useClimateWiseTiers();

  const [pledgeReductionPercentage, setPledgeReductionPercentage] =
    useState("");
  const [pledgeYear, setPledgeYear] = useState("");
  const [confirmationStatement, setConfirmationStatement] = useState("");

  const {
    yearsWithPercentages,
    pledgeYears,
    reductionGoalTonsCo2e,
    pledgeTons,
    reductionRate,
  } = findYearsBaselinePledgePercentages({
    accountingYears,
    baselineTonsCo2e,
    pledgeYear,
    pledgeReductionPercentage,
  });

  const onSavePledge = () => {
    if (isClimateWiseFinished) {
      analyticsTrack(isNetZeroPledge ? "Net Zero Pledge" : "Gold Unlock");
    }

    updateAccountData({
      badges: {
        pledgeDate: new Date(pledgeYear, 0, 1),
        pledgeReductionPercentage: Number(pledgeReductionPercentage),
      },
    });
    return setOpen(false);
  };

  const isNetZeroPledge = pledgeReductionPercentage >= 50;

  const summaryList = [
    <>
      You're pledging to reduce your emissions by{" "}
      <strong>{pledgeReductionPercentage}%</strong> starting from the beginning
      of your pledge year of <strong>{pledgeYear}</strong>
    </>,
    <>
      This pledge means a reduction from{" "}
      <strong>
        {formatDecimal(convertCarbonUnits(pledgeTons))} {displayUnitLabel}/year
      </strong>{" "}
      down to{" "}
      <strong>
        {formatDecimal(convertCarbonUnits(reductionGoalTonsCo2e))}
        {displayUnitLabel}/year
      </strong>{" "}
      by the year <strong>2030</strong>
    </>,
    <>
      This pledge will require a continuous year over year reduction in your
      emissions of <strong>{formatDecimal(reductionRate * 100)}%</strong>.
    </>,
    <>
      By making this pledge today you'll earn your{" "}
      <strong>
        {isNetZeroPledge ? "Net Zero Pledge" : "Climate Leader Gold"}
      </strong>{" "}
      certification. You'll need to hit your reductions goals every year to
      maintain this badge.
    </>,
  ];

  return (
    <SlideLayout
      isSlideOpen
      setIsSlideOpen={setOpen}
      header={
        <Grid container spacing={2} alignItems="center" wrap="nowrap">
          <Grid item>
            <Avatar style={{ backgroundColor: palette.secondary.main }}>
              <FontAwesomeIcon
                icon={faBadgePercent}
                style={{ color: "white" }}
                size="lg"
              />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h4">{title}</Typography>
          </Grid>
        </Grid>
      }
      subtitle="Show the world you're serious about climate action by taking Aclymate's Net Zero Pledge."
      content={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              label="Choose a 2030 reduction percentage"
              value={pledgeReductionPercentage}
              setValue={setPledgeReductionPercentage}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="h6">% </Typography>
                  </InputAdornment>
                ),
              }}
              error={
                pledgeReductionPercentage &&
                pledgeReductionPercentage < minimumPledgePercentage
              }
              helperText={`You must pledge at least ${minimumPledgePercentage}% to earn this certification. Your pledge will be divided by the number of years left to 2030`}
            />
          </Grid>
          {pledgeReductionPercentage >= minimumPledgePercentage && (
            <Grid item>
              <Typography variant="body1" gutterBottom>
                Select the baseline year you’d like to start your reductions
                from
              </Typography>
              <Grid container spacing={1}>
                {accountingYears.map(({ year }) => (
                  <Grid item key={`year-chip-${year}`}>
                    <Chip
                      key={year}
                      label={year}
                      color="primary"
                      onClick={() => setPledgeYear(year)}
                      variant={year === pledgeYear ? "filled" : "outlined"}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
          {pledgeYear && (
            <>
              <Grid item>
                {summaryList.map((text, idx) => (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                    key={`disclaimer-list-item-${idx}`}
                  >
                    {text}
                  </Typography>
                ))}
              </Grid>
              <Grid item>
                <Typography variant="body1" gutterBottom>
                  If you agree to this reduction plan and want to pledge to
                  making these reductions starting today, enter "I pledge"
                  below.
                </Typography>
                <TextField
                  label="Confirm your pledge"
                  helperText={`Enter "I pledge" to confirm your pledge`}
                  value={confirmationStatement}
                  setValue={setConfirmationStatement}
                />
              </Grid>
              <Grid item container justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={confirmationStatement !== "I pledge"}
                    onClick={onSavePledge}
                  >
                    Take the pledge
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      }
      leftContent={
        pledgeYear &&
        pledgeReductionPercentage && (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <AccountingChart
                accountingYears={yearsWithPercentages}
                pledgeYear={pledgeYear}
                pledgeYears={pledgeYears}
                baselineDate={baselineDate}
                pledgeTons={pledgeTons}
                baselineTonsCo2e={baselineTonsCo2e}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="h6"
                align="center"
                style={{ color: "white" }}
              >
                Hover over the bars for more information
              </Typography>
            </Grid>
          </Grid>
        )
      }
    />
  );
};

const PledgeActionButton = ({
  disabled,
  ButtonComponent,
  minimumPledgePercentage,
  title,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const buttonProps = { onClick: () => setModalOpen(true), disabled };

  const button = ButtonComponent ? (
    <ButtonComponent {...buttonProps} />
  ) : (
    <Button variant="contained" color="secondary" {...buttonProps}>
      Take the Pledge
    </Button>
  );

  return (
    <>
      {modalOpen && (
        <PledgeSlider
          setOpen={setModalOpen}
          minimumPledgePercentage={minimumPledgePercentage}
          title={title}
        />
      )}
      {button}
    </>
  );
};
export default PledgeActionButton;
