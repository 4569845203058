import React from "react";
import dayjs from "dayjs";

import { ToggleButtons } from "@aclymatepackages/atoms";

import {
  useCachedFirebaseCrud,
  useAccountData,
} from "../../../helpers/firebase";

const EditStartDateButton = () => {
  const [{ startDate }] = useAccountData();

  const { updateAccountData } = useCachedFirebaseCrud();

  const onYearSelect = (year) =>
    updateAccountData({ startDate: new Date(year, 0, 1) });

  const yearsArray = [...new Array(5)].map(
    (_, idx) => dayjs().year() - (idx + 1)
  );

  return (
    <ToggleButtons
      value={startDate && dayjs(startDate).year()}
      buttons={yearsArray.map((year) => ({ value: year, name: year }))}
      onChange={onYearSelect}
    />
  );
};
export default EditStartDateButton;
