import React, { useContext, useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";

import { Box, Typography } from "@mui/material";

import { LoadingButton } from "@aclymatepackages/atoms";

import { useAuth, useAccountData } from "../../helpers/firebase";
import { analyticsTrack } from "../../helpers/analytics";
import { fetchOurApi } from "../../helpers/utils/apiCalls";
import { isObjectEmpty } from "../../helpers/otherHelpers";
import { googlePurchaseTracking } from "../../helpers/components/purchase";
import { StripeCustomerContext } from "../../helpers/contexts/stripeCustomer";

const StripeSubmitButton = ({
  stripeSubmit,
  stripeError,
  isStripeComplete,
  buttonText
}) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const handleSave = async () => {
    setSaveLoading(true);
    await stripeSubmit();

    return setSaveLoading(false);
  };

  return (
    <LoadingButton
      isLoading={saveLoading}
      label={buttonText || "Save Payment Info"}
      color="secondary"
      disabled={!isStripeComplete || !isObjectEmpty(stripeError)}
      onClick={() => handleSave()}
    />
  );
};

const CardInput = ({ options, setCardData }) => {
  const defaultOptions = {
    fields: {
      billingDetails: {
        name: "never",
        email: "never",
      },
    },
    wallets: {
      applePay: "never",
      googlePay: "never",
    },
    business: {
      name: "Aclymate",
    },
  };

  return (
    <PaymentElement
      options={options || defaultOptions}
      onChange={(e) => setCardData(e)}
    />
  );
};

const StripeInputForm = ({ style, options, error = {}, setCardData }) => {
  const { message } = error;
  const CardError = () => (
    <>
      {message && (
        <Typography variant="caption" color="error">
          {message}
        </Typography>
      )}
    </>
  );

  if (style) {
    return (
      <>
        <Box p={2} style={style}>
          <CardInput options={options} setCardData={setCardData} />
        </Box>
        <CardError />
      </>
    );
  }

  return (
    <>
      <CardInput options={options} setCardData={setCardData} />
      <CardError />
    </>
  );
};

const useStripeInput = (props = {}) => {
  const { onButtonClick, project, buttonText } = props;

  const stripe = useStripe();
  const elements = useElements();
  const [user] = useAuth();
  const { paymentMethod: customerPaymentMethod, refetchCustomerPaymentMethod } =
    useContext(StripeCustomerContext);

  const [companyData] = useAccountData();
  const {
    stripeCustomerId: companyObjStripeCustomerId,
    billing = {},
    name: companyName,
  } = companyData || {};
  const { stripeCustomerId: billingObjStripeCustomerId } = billing;

  const [paymentData, setPaymentData] = useState({});
  const { error: stripeError, complete: isStripeComplete } = paymentData;

  const createBillingDetailsFullName = () => {
    const { displayName } = user;
    const [firstCompanyWord] = companyName.split(" ");
    const [firstName, lastName] = displayName.split(" ");

    if (!lastName) {
      return `${firstName} ${firstCompanyWord}`;
    }

    return `${firstName} ${lastName}`;
  };

  const createStripePaymentMethod = async () => {
    const { email } = user;

    const billingDetailsFullName = createBillingDetailsFullName();

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `https://${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}/platform/company/settings`,
        payment_method_data: {
          billing_details: {
            name: billingDetailsFullName,
            email,
          },
        },
      },
      redirect: "if_required",
    });

    if (error) {
      setPaymentData((data) => ({ ...data, error }));
      return false;
    }

    const { payment_method, status } = setupIntent;

    const paymentMethod = await fetchOurApi({
      path: "/stripe/get-payment-method-data",
      method: "POST",
      data: {
        paymentMethodId: payment_method,
      },
      callback: (res) => res,
    });

    return {
      ...paymentMethod,
      status,
    };
  };

  const savePaymentMethod = async () => {
    const { paymentMethodId: existingPaymentMethodId } =
      customerPaymentMethod || {};

    if (existingPaymentMethodId && user) {
      await fetchOurApi({
        path: "/stripe/detach-payment-method",
        method: "POST",
        data: {
          paymentMethodId: existingPaymentMethodId,
        },
      });
    }

    if (onButtonClick) {
      onButtonClick();
    }

    return refetchCustomerPaymentMethod();
  };

  const stripeSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    if (project) {
      googlePurchaseTracking("add_payment_info", { project });
    }

    if (user) {
      analyticsTrack("Payment Method Added", {
        stripeCustomerId:
          companyObjStripeCustomerId || billingObjStripeCustomerId,
      });
    }

    await createStripePaymentMethod();

    return await savePaymentMethod();
  };

  return {
    input: <StripeInputForm setCardData={setPaymentData} error={stripeError} />,
    button: (
      <StripeSubmitButton
        stripeSubmit={stripeSubmit}
        stripeError={stripeError}
        isStripeComplete={isStripeComplete}
        buttonText={buttonText}
      />
    ),
  };
};
export default useStripeInput;
