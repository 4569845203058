import React from "react";

import { Box, Grid } from "@mui/material";

import StripeElements from "../modules/StripeElements";
import useStripeInput from "../hooks/stripeInput";

import CircularProgressWithLabel from "../atoms/loading/CircularProgressWithLabel";

const PaymentInputBox = () => {
  const { input, button } = useStripeInput({});

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item style={{ width: "100%" }}>
          {input}
        </Grid>
        <Grid item container justifyContent="flex-end">
          {button}
        </Grid>
      </Grid>
    </Box>
  );
};

const PaymentInput = () => (
  <StripeElements loadingComponent={<CircularProgressWithLabel />}>
    <PaymentInputBox />
  </StripeElements>
);
export default PaymentInput;
