import React from "react";

import InputBlock from "./InputBlock";
import DbAutocomplete from "../autocomplete/DbAutocomplete";

const EmployeesInput = ({
  employees,
  setEmployees,
  removeEmployee,
  title = "Tag employees for this transaction",
  ...otherProps
}) => (
  <InputBlock
    type="employees"
    title={title}
    cardData={employees}
    {...otherProps}
  >
    <DbAutocomplete
      collection="employees"
      setValue={setEmployees}
      value={employees || []}
      onDelete={removeEmployee}
      id="employees-tag-input"
    />
  </InputBlock>
);
export default EmployeesInput;
