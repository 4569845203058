import React, { createContext, useState, useContext } from "react";

import useAccountingData from "../hooks/accountingData";
import { useDisplayEvents } from "../components/events";

const PurchasingContext = createContext();

export const PurchasingContextProvider = ({ children }) => {
  const [{ accountingYears = [] }, accountingDataLoading] = useAccountingData();
  const [events, eventsLoading] = useDisplayEvents();

  const [selectedScopeChips, setSelectedScopeChips] = useState([]);
  const [purchaseTons, setPurchaseTons] = useState(0);
  const [purchaseDollars, setPurchaseDollars] = useState(0);
  const [selectedTab, setSelectedTab] = useState("volume");
  const [selectedEvents, setSelectedEvents] = useState([]);

  const nonDiamondYears = accountingYears.filter(
    ({ badge }) => badge !== "diamond"
  );
  const eventsThatCanBeOffset = events.filter(
    ({ status }) => status === "closed"
  );

  return (
    <PurchasingContext.Provider
      value={{
        selectedEvents,
        setSelectedEvents,
        selectedScopeChips,
        setSelectedScopeChips,
        purchaseTons,
        setPurchaseTons,
        purchaseDollars,
        setPurchaseDollars,
        selectedTab,
        setSelectedTab,
        nonDiamondYears,
        eventsThatCanBeOffset,
        dataLoading: accountingDataLoading || eventsLoading,
      }}
    >
      {children}
    </PurchasingContext.Provider>
  );
};

const usePurchasingContext = () => useContext(PurchasingContext);
export default usePurchasingContext;
