import React, { useState } from "react";
import * as Sentry from "@sentry/react";

import {
  Box,
  Grid,
  Typography,
  StyledEngineProvider,
  InputAdornment,
  ThemeProvider,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import { tonsToLbs } from "@aclymatepackages/converters";
import { TextField } from "@aclymatepackages/atoms/";
import { useLayoutHelpers } from "@aclymatepackages/themes";
import { numbersRegExpTest, emailRegExpTest } from "@aclymatepackages/reg-exp";
import { mergeDarkTheme, mainTheme } from "@aclymatepackages/themes";

import OffsetsPurchaseFlow from "./OffsetsPurchaseFlow";
import PurchaseInputSection from "./PurchaseInputSection";
import MyAclymateCta from "./MyAclymateCta";

// import { lastYearsFestivarianTravelDataSummary } from "../../helpers/components/events";
import { fetchOurApi } from "../../helpers/utils/apiCalls";
import { analyticsTrack } from "../../helpers/analytics";

const EmailInput = ({
  editServerData,
  individual,
  event,
  type,
  ...otherProps
}) => {
  const { id: eventId } = event;
  const { id: individualId } = individual;
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const onNextStep = async () => {
    const { error } = await fetchOurApi({
      path: "/events/update-partner-email",
      method: "POST",
      data: { email, partnerId: individualId, eventId, type },
      callback: (res) => res,
    });

    if (error) {
      return setEmailError("An error occurred with your email");
    }

    return editServerData("attendee")({ ...individual, email });
  };

  return (
    <PurchaseInputSection
      title="Tell us where to send your receipt"
      input={
        <TextField
          label="Enter your email address"
          value={email}
          setValue={setEmail}
          helperText={emailError}
          error={!!emailError}
        />
      }
      onNextStep={onNextStep}
      nextStepDisabled={!emailRegExpTest(email)}
      {...otherProps}
    />
  );
};

const ProjectVolumeSelection = ({
  purchaseDescription,
  displayCost,
  totalEventLbs,
  project,
  groupSize,
  purchaseDollars,
  lbsToPurchase,
  setLbsToPurchase,
  setPurchaseDollars,
  setTypeOfPurchase,
  typeOfPurchase,
  setStripeData,
  stripeChargeId,
  negativeFootprint,
  stripeCustomerId,
  ...otherProps
}) => {
  const { totalThousandLbsCost } = project;

  const [stripeDataLoading, setStripeDataLoading] = useState(false);

  //TODO: figure out a better way to do this
  // const { avgTotalEventTons } = lastYearsFestivarianTravelDataSummary();

  const setPaymentIntent = ({ lbsToPurchase, purchaseDollars, multiplier }) => {
    setStripeDataLoading(true);

    if (!stripeChargeId) {
      return fetchOurApi({
        path: "/stripe/create-payment-intent",
        method: "POST",
        data: {
          project,
          lbsToPurchase,
          multiplier,
          stripeCustomerId,
          description: purchaseDescription,
        },
        callback: ({ clientSecret, stripeChargeId }) => {
          setStripeDataLoading(false);
          return setStripeData({
            clientSecret,
            stripeChargeId,
            paymentIntentDollars: purchaseDollars,
          });
        },
      });
    }

    return fetchOurApi({
      path: "/stripe/update-payment-intent",
      method: "POST",
      data: { project, lbsToPurchase, multiplier, stripeChargeId },
      callback: () => setStripeDataLoading(false),
    });
  };

  const isNextStepDisabled = () =>
    stripeDataLoading ||
    Number(purchaseDollars) < 1 ||
    !numbersRegExpTest(purchaseDollars);

  const onNextStep = () => {
    const lbsToPurchase = (purchaseDollars / totalThousandLbsCost) * 1000;

    setLbsToPurchase(lbsToPurchase);
    return setPaymentIntent({ lbsToPurchase, purchaseDollars });
  };

  return (
    <PurchaseInputSection
      title="How much of an impact would you like to make?"
      input={
        <TextField
          label="Select how much you want to pay for offsets"
          value={purchaseDollars || ""}
          setValue={setPurchaseDollars}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}
          error={
            purchaseDollars &&
            (purchaseDollars < 1 || !numbersRegExpTest(purchaseDollars))
          }
          helperText={
            !!purchaseDollars &&
            purchaseDollars < 1 &&
            "You must purchase at least $1 of offsets"
          }
        />
      }
      nextStepDisabled={isNextStepDisabled()}
      onNextStep={onNextStep}
      {...otherProps}
    />
  );
};

const PurchaseThanks = ({ slug, thanksImage }) => {
  const { isMedium } = useLayoutHelpers();

  return (
    <Box style={{ height: "100%", overflowX: "hidden", overflowY: "auto" }}>
      <Grid
        container
        direction="column"
        spacing={4}
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item sm={6}>
          <img
            src={thanksImage || "/images/purchase-thanks.svg"}
            style={{ width: "100%" }}
            alt="Thanks for purchasing offsets from Aclymate"
          />
        </Grid>
        <Grid item>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={isMedium ? mainTheme : mergeDarkTheme}>
              <Typography variant="h4" align="center" color="textPrimary">
                The Planet thanks you for your contribution
              </Typography>
              <Typography
                variant="subtitle1"
                align="center"
                color="textSecondary"
              >
                You should have received an email to confirm that your offsets
                have been retired.
              </Typography>
            </ThemeProvider>
          </StyledEngineProvider>
        </Grid>
        <Grid item sm={11}>
          <MyAclymateCta />
        </Grid>
      </Grid>
    </Box>
  );
};

const EventOffsetPurchase = ({
  totalEventTonsCo2e,
  project,
  event,
  individual,
  groupSize,
  editServerData,
  negativeFootprint,
  surveyType,
}) => {
  const { name: eventName } = event || {};
  const { stripeCustomerId, name, email, isEmailOptIn } = individual || {};
  const { slug, displayCost, name: projectName } = project;

  const [stripeData, setStripeData] = useState({});
  const [lbsToPurchase, setLbsToPurchase] = useState(0);
  const [purchaseDollars, setPurchaseDollars] = useState(0);
  const [typeOfPurchase, setTypeOfPurchase] = useState("");
  const { stripeChargeId } = stripeData;

  const emailInputRow = email
    ? []
    : [
        {
          Component: EmailInput,
          props: { editServerData, individual, event, type: surveyType },
        },
      ];

  const buildDisplayCost = () => {
    if (negativeFootprint || displayCost < 1) {
      return 1;
    }

    return displayCost;
  };

  const purchaseRows = [
    {
      Component: ProjectVolumeSelection,
      props: {
        purchaseDescription: `${eventName}- ${projectName}`,
        totalEventLbs: tonsToLbs(totalEventTonsCo2e),
        displayCost: buildDisplayCost(),
        project,
        purchaseDollars,
        lbsToPurchase,
        setLbsToPurchase,
        setPurchaseDollars,
        setTypeOfPurchase,
        typeOfPurchase,
        setStripeData,
        groupSize,
        stripeChargeId,
        negativeFootprint,
        stripeCustomerId,
      },
    },
    ...emailInputRow,
  ];

  const groupOffsetPurchaseDataObj =
    negativeFootprint || surveyType !== "attendee"
      ? {}
      : {
          groupOffsetPurchaseData: {
            groupSize,
            typeOfPurchase,
          },
        };

  const purchaseCallback = async () => {
    const fetchData = {
      surveyType,
      lbsToPurchase,
      purchaseDollars,
      stripeChargeId,
      event,
      individual,
      project,
      ...groupOffsetPurchaseDataObj,
    };

    const callbackFunction = (res) => {
      const { success, error, offset } = res;
      if (error) {
        Sentry.setContext("Event Individual", individual);
        Sentry.setContext("Stripe Data", stripeData);
        Sentry.setContext("Purchase data", {
          projectSlug: slug,
          lbsToPurchase,
          purchaseDollars,
        });
        Sentry.captureException(error);
      }

      if (success) {
        editServerData("offsets")([offset]);
      }

      if (isEmailOptIn) {
        analyticsTrack("Event Offsets Purchase", {
          eventName,
          name,
          email,
          purchaseDollars,
        });
      }

      return res;
    };

    if (surveyType === "attendee") {
      return await fetchOurApi({
        path: "/individuals/event-offset-purchase",
        method: "POST",
        data: fetchData,
        callback: callbackFunction,
      });
    }

    return await fetchOurApi({
      path: "/events/partner-offset-purchase",
      method: "POST",
      data: fetchData,
      callback: callbackFunction,
    });
  };

  return (
    <OffsetsPurchaseFlow
      project={project}
      thanksStep={<PurchaseThanks {...event} />}
      dataEntryRows={purchaseRows}
      inputData={{ lbsToPurchase, purchaseDollars }}
      stripeData={stripeData}
      purchaseCallback={purchaseCallback}
      noFooter
      noVolume
    />
  );
};
export default EventOffsetPurchase;
