import React from "react";

import { Grid } from "@mui/material";

import GasInputForm from "../offices/GasInputForm";
import ElectricInputForm from "../offices/ElectricInputForm";

import {
  useElectricityInputProps,
  useGasInputProps,
} from "../../../helpers/components/inputs";

const CombinedUtilitiesInputBlock = ({
  fuelType,
  setFuelType,
  gasUnit,
  setGasUnit,
  electricKwh,
  setElectricKwh,
  gasUnitValue,
  setGasUnitValue,
  electricRenewablesAndIntensityInputFormProps,
}) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <ElectricInputForm
          electricKwh={electricKwh}
          setElectricKwh={setElectricKwh}
          {...electricRenewablesAndIntensityInputFormProps}
        />
      </Grid>
      <Grid item>
        <GasInputForm
          fuelType={fuelType}
          setFuelType={setFuelType}
          unitValue={gasUnitValue}
          setUnitValue={setGasUnitValue}
          gasUnit={gasUnit}
          setGasUnit={setGasUnit}
        />
      </Grid>
    </Grid>
  );
};

const useCombinedUtilitiesInput = ({ transaction = {}, onSave }) => {
  const {
    electricKwh,
    setElectricKwh,
    electricRenewablesAndIntensityInputFormProps,
    electricCarbonTons,
    saveEnabled: electricitySaveEnabled,
    saveObject: electricitySaveObject,
  } = useElectricityInputProps(transaction);

  const {
    gasCarbonTons,
    gasUnit,
    setGasUnit,
    gasUnitValue,
    setGasUnitValue,
    fuelType,
    setFuelType,
    saveEnabled: gasSaveEnabled,
    saveObject: gasSaveObject,
  } = useGasInputProps(transaction);

  const inputBlock = (
    <CombinedUtilitiesInputBlock
      fuelType={fuelType}
      setFuelType={setFuelType}
      gasUnit={gasUnit}
      setGasUnit={setGasUnit}
      gasUnitValue={gasUnitValue}
      setGasUnitValue={setGasUnitValue}
      electricKwh={electricKwh}
      setElectricKwh={setElectricKwh}
      electricRenewablesAndIntensityInputFormProps={
        electricRenewablesAndIntensityInputFormProps
      }
    />
  );

  const tonsCo2e = electricCarbonTons + gasCarbonTons;

  const onTransactionSave = () =>
    onSave({
      ...electricitySaveObject,
      ...gasSaveObject,
      electricCarbonTons,
      gasCarbonTons,
      tonsCo2e,
    });

  const saveEnabled = gasSaveEnabled && electricitySaveEnabled;

  return {
    inputBlock,
    onTransactionSave,
    saveEnabled,
    tonsCo2e,
  };
};
export default useCombinedUtilitiesInput;
