import React from "react";

import {
  Box,
  Grid,
  useMediaQuery,
  Button,
  Link,
  StyledEngineProvider,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import { mergeDarkTheme } from "@aclymatepackages/themes";

const PageHeader = ({ actionComponent, links, centerNav }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      py={3}
      px={4}
      sx={{
        backgroundColor: theme.palette.backgroundGray.main,
        boxShadow: "0 2px 4px #475467",
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item>
          <Grid container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item style={{ cursor: "pointer" }}>
              <Link href="https://aclymate.com">
                {isMobile ? (
                  <img
                    src="/images/aclymate-icon.png"
                    alt="RWT logo"
                    style={{ width: "40px" }}
                  />
                ) : (
                  <img
                    src="/images/aclymate-full-logo.png"
                    alt="RWT logo"
                    style={{ maxWidth: "200px" }}
                  />
                )}
              </Link>
            </Grid>
            {links &&
              links.map(({ label, href }, idx) => (
                <Grid item key={`header-link-${idx}`}>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={mergeDarkTheme}>
                      <Link href={href}>
                        <Button size="small">{label}</Button>
                      </Link>
                    </ThemeProvider>
                  </StyledEngineProvider>
                </Grid>
              ))}
          </Grid>
        </Grid>
        {centerNav && <Grid item>{centerNav}</Grid>}
        <Grid item color="inherit">
          {actionComponent}
        </Grid>
      </Grid>
    </Box>
  );
};
export default PageHeader;
