import React, { useState } from "react";

import { TextField } from "@aclymatepackages/atoms";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";

import CompanyDataListItem from "./CompanyDataListItem";
import { useCachedFirebaseCrud } from "../../../helpers/firebase";

const CompanyEmployeeCountRow = ({ companyData: { employeeCount } }) => {
  const { updateAccountData } = useCachedFirebaseCrud();

  const [modifyEmployeeCount, setModifyEmployeeCount] = useState(employeeCount);

  const isError =
    !modifyEmployeeCount || !numbersRegExpTest(modifyEmployeeCount);

  return (
    <CompanyDataListItem
      editingComponent={
        <TextField
          value={modifyEmployeeCount}
          setValue={setModifyEmployeeCount}
          variant="standard"
          label="Edit Employee Count"
          InputProps={{ style: { width: "250px" } }}
          error={isError}
        />
      }
      saveDisabled={isError}
      onCancel={() => setModifyEmployeeCount(employeeCount)}
      onSave={() => updateAccountData({ employeeCount: modifyEmployeeCount })}
      primaryText={employeeCount}
      secondaryText="Number of Employees"
    />
  );
};
export default CompanyEmployeeCountRow;
