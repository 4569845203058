const baseUrl = "https://api.imageresizer.io/v1";
const API_KEY = "8e5c587664f7c92e079581cef1e85cab712ff683";

export const uploadImageToImageResizerIo = async (image) => {
  const headers = {
    "Content-Type": "application/octet-stream",
  };

  try {
    const response = await fetch(`${baseUrl}/images?key=${API_KEY}`, {
      method: "POST",
      headers: headers,
      body: image,
    });

    if (!response.ok) {
      throw new Error("Error uploading image to ImageResizer.io");
    }
    const data = await response.json();
    const { success, response: responseData } = data;

    return {
      success,
      imageData: responseData,
    };
  } catch (error) {
    console.error("Error uploading image to ImageResizer.io", error);
    return { error: "Error uploading image to ImageResizer io" };
  }
};

export const getImageFromImageResizerIo = async (imageId) => {
  try {
    const response = await fetch(`${baseUrl}/images/${imageId}?key=${API_KEY}`);

    if (!response.ok) {
      throw new Error("Error fetching image from ImageResizer.io");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching image from ImageResizer.io", error);
    return { error: "Error fetching image from ImageResizer.io" };
  }
};
