import React from "react";

import Grid from "@mui/material/Grid";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const RowIconButton = ({ removeRow, id }) => (
  <IconButton onClick={() => removeRow()} id={id} size="large">
    <FontAwesomeIcon icon={faTimesCircle} size="1x" />
  </IconButton>
);

const RemovableRowIcon = ({ removeRow, table, id }) => {
  return (
    <>
      {table ? (
        <TableCell>
          <RowIconButton removeRow={removeRow} id={id} />
        </TableCell>
      ) : (
        <Grid item xs={1} container justifyContent="center">
          <Grid item>
            <RowIconButton removeRow={removeRow} id={id} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const RemovableRow = ({
  rowCount,
  removeRow,
  table,
  style,
  id,
  idx,
  leftIcon = false,
  children,
  ...otherProps
}) => (
  <>
    {table ? (
      <TableRow>
        {children}
        {rowCount > 1 && (
          <RemovableRowIcon
            removeRow={removeRow}
            table
            id={`Remove_${id}_${idx}`}
          />
        )}
      </TableRow>
    ) : (
      <Grid
        style={{ ...style, position: "relative" }}
        container
        item
        alignItems="center"
        spacing={2}
        justifyContent={leftIcon ? "flex-end" : "flex-start"}
        wrap="nowrap"
        {...otherProps}
      >
        {leftIcon && rowCount > 1 && (
          <RemovableRowIcon removeRow={removeRow} id={`Remove_${id}_${idx}`} />
        )}
        {children}
        {!leftIcon && rowCount > 1 && (
          <RemovableRowIcon removeRow={removeRow} id={`Remove_${id}_${idx}`} />
        )}
      </Grid>
    )}
  </>
);

export default RemovableRow;
