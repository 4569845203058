export const formatExternalCompanyData = ({
  companyId,
  companyName,
  employees,
  transactions,
}) => {
  const formattedEmployees = employees.map(
    ({ startDate, status, links, ...otherProps }) => ({
      ...otherProps,
      startDate,
      status,
      currentLinkedIds: [companyId],
      links: links.map((link) => ({
        ...link,
        id: companyId,
        name: companyName,
      })),
    })
  );

  const formattedTransactions = transactions.map(({ date, ...otherProps }) => ({
    date: new Date(date),
    ...otherProps,
  }));

  return {
    formattedEmployees,
    formattedTransactions,
  };
};
