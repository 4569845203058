import React, { useState } from "react";

import {
  Box,
  Grid,
  Avatar,
  IconButton,
  ListItemButton,
  ListItem as MuiListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Divider,
  Skeleton,
} from "@mui/material";

import Link from "./Link";

const AvatarIcon = ({
  isLoading,
  icon,
  avatarColor,
  avatar,
  avatarTooltip,
  avatarImg,
}) => {
  if ((icon || avatar) && isLoading) {
    return (
      <ListItemAvatar>
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
      </ListItemAvatar>
    );
  }

  if (icon) {
    return <Grid item>{icon}</Grid>;
  }

  const ListAvatar = () => (
    <ListItemAvatar>
      <Avatar style={{ backgroundColor: avatarColor }} src={avatarImg}>
        {avatar}
      </Avatar>
    </ListItemAvatar>
  );

  if (avatarTooltip) {
    return (
      <Grid item>
        <Tooltip title={avatarTooltip}>
          <span>
            <ListAvatar />
          </span>
        </Tooltip>
      </Grid>
    );
  }

  if (avatar || avatarImg) {
    return (
      <Grid item>
        <ListAvatar />
      </Grid>
    );
  }
  return null;
};

const ListAction = ({
  isLoading,
  iconButtonSize,
  icon,
  tooltip,
  href,
  onClick,
  ...otherProps
}) => {
  const ListActionIcon = ({ icon, ...props }) => (
    <IconButton size={iconButtonSize} {...props}>
      {isLoading ? <Skeleton /> : icon}
    </IconButton>
  );

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const fireOnClick = () => {
    if (onClick) {
      return onClick();
    }

    return () => null;
  };

  return (
    <Grid
      item
      onMouseEnter={() => setTooltipOpen(true)}
      onMouseLeave={() => setTooltipOpen(false)}
    >
      <Tooltip title={tooltip} open={tooltipOpen}>
        <span>
          {href ? (
            <Link
              href={href}
              target="_blank"
              rel="noopener"
              onClick={() => fireOnClick()}
            >
              <ListActionIcon icon={icon} {...otherProps} />
            </Link>
          ) : (
            <ListActionIcon
              icon={icon}
              onClick={() => {
                fireOnClick();
                setTooltipOpen(false);
              }}
              {...otherProps}
            />
          )}
        </span>
      </Tooltip>
    </Grid>
  );
};

const ListItemBlock = ({
  primaryText = "",
  secondaryText = "",
  onButtonClick,
  href,
  iconButtonSize = "small",
  actions,
  isLoading,
  primaryTypographyProps,
  listContent,
  ...avatarProps
}) => {
  const ListItemComponent = onButtonClick ? ListItemButton : MuiListItem;

  return (
    <>
      <ListItemComponent onClick={onButtonClick}>
        <Grid
          container
          wrap="nowrap"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center" wrap="nowrap" spacing={2}>
              <AvatarIcon isLoading={isLoading} {...avatarProps} />
              <Grid item>
                {isLoading ? (
                  <Box p={2}>
                    <Skeleton variant="text" width={200} />
                  </Box>
                ) : (
                  listContent || (
                    <ListItemText
                      primary={primaryText}
                      secondary={secondaryText}
                      primaryTypographyProps={{
                        color: "textPrimary",
                        ...primaryTypographyProps,
                      }}
                    />
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
          {Array.isArray(actions) ? (
            <Grid item>
              <Grid container wrap="nowrap">
                {actions.map(({ tooltip, ...otherProps }) => (
                  <ListAction
                    isLoading={isLoading}
                    iconButtonSize={iconButtonSize}
                    tooltip={tooltip}
                    {...otherProps}
                    key={`list-item-action-${tooltip}`}
                  />
                ))}
              </Grid>
            </Grid>
          ) : (
            actions
          )}
        </Grid>
      </ListItemComponent>
      <Divider />
    </>
  );
};

const ListItem = ({ href, onButtonClick, ...otherProps }) => {
  return (
    <>
      {!!href ? (
        <Link href={href} onClick={() => onButtonClick && onButtonClick()}>
          <ListItemBlock href={href} {...otherProps} />
        </Link>
      ) : (
        <ListItemBlock
          href={href}
          onButtonClick={onButtonClick}
          {...otherProps}
        />
      )}
    </>
  );
};
export default ListItem;
