import dayjs from "dayjs";

import { formatDate, letterSBoolean } from "@aclymatepackages/formatters";
import {
  findHighestRankedTier,
  areAccountingYearsWithinPledgePercentage,
  findEarnedCertificationTiers,
  buildAllCertificationTiersArray,
} from "@aclymatepackages/badges";
import buildBadgesArray from "@aclymatepackages/badges-display";

import ActionLinkButton from "../../components/atoms/buttons/ActionLinkButton";

import CloseAccountingButton from "../../components/atoms/accounting/CloseAccountingButton";
import PledgeActionButton from "../../components/platform-pages/badges/PledgeActionButton";

import { useSubscriptionType } from "../hooks/companyData";
import useAccountingData from "../hooks/accountingData";
import { useAccountData } from "../firebase";

const useEarnedCertificationTiers = () => {
  const [{ status: subscriptionStatus }, subscriptionLoading] =
    useSubscriptionType("saas");
  const [
    {
      accountingYears,
      unClosedYears = [],
      status: accountingStatus,
      pledgeReductionPercentage,
      pledgeTons,
    },
    accountingLoading,
  ] = useAccountingData();

  const earnedCertificationTiers = findEarnedCertificationTiers({
    subscriptionStatus,
    accountingStatus,
    unClosedYears,
    accountingYears,
    pledgeReductionPercentage,
    pledgeTons,
  });

  return [earnedCertificationTiers, accountingLoading || subscriptionLoading];
};

const useRankedCertificationTiers = () => {
  const [earnedCertifications, certificationTiersLoading] =
    useEarnedCertificationTiers();

  const allCertficationsArray =
    buildAllCertificationTiersArray(earnedCertifications);

  return [allCertficationsArray, certificationTiersLoading];
};

export const useHighestEarnedTier = () => {
  const [rankedCertificationTiers, rankedCertificationTiersLoading] =
    useRankedCertificationTiers();

  const highestEarnedTier = findHighestRankedTier(rankedCertificationTiers);

  return [highestEarnedTier, rankedCertificationTiersLoading];
};

const useSingleEarnedTier = (tier) => {
  const [rankedCertificationTiers, rankedTiersLoading] =
    useRankedCertificationTiers();

  const matchingTier = rankedCertificationTiers.find(
    ({ level }) => level === tier
  );

  return [matchingTier, rankedTiersLoading];
};

const useBronzeTier = () => {
  const [bronzeTier, bronzeTierLoading] = useSingleEarnedTier("bronze");

  const actions = [
    {
      href: "/platform/company/settings/products",
      text: "Sign up for an Aclymate subscription to start your Climate Journey",
    },
  ];

  return [
    {
      ...bronzeTier,
      actions,
    },
    bronzeTierLoading,
  ];
};

const useSilverTier = () => {
  const [{ startDate }, startDateLoading] = useAccountData();
  const [
    {
      baselineDate,
      unClosedYears = [],
      status: accountingStatus,
      openDate,
      accountingExpirationDate,
    },
    accountingLoading,
  ] = useAccountingData();
  const [silverTier, silverTierLoading] = useSingleEarnedTier("silver");
  const [{ isBronzeEarned }, earnedCertificationsLoading] =
    useEarnedCertificationTiers();

  const baselineActionProp = !baselineDate
    ? {
        action: (
          <CloseAccountingButton
            disabled={!isBronzeEarned || !startDate}
            type="baseline"
            ButtonComponent={(props) => (
              <ActionLinkButton {...props}>
                Establish a 1-year baseline including all scope emissions (1-3)
              </ActionLinkButton>
            )}
          />
        ),
      }
    : {
        text: `Congratulations! You can now track all of your emissions relative to your baseline.`,
      };

  const baselineAction = {
    isEarned: !!baselineDate,
    ...baselineActionProp,
  };

  const generatePostBaslineYearsCount = () => {
    if (unClosedYears.length) {
      return unClosedYears.length;
    }
    const currentYear = dayjs().year();
    const startDateYear = dayjs(startDate).year();

    return currentYear - startDateYear - 1;
  };

  const postBaselineYearsCount = generatePostBaslineYearsCount();

  const accountingActionText =
    accountingStatus === "open"
      ? `You need to close your accounting by ${formatDate(
          accountingExpirationDate
        )} to maintain this certification level.`
      : `You need to close your accounting for ${postBaselineYearsCount} year${letterSBoolean(
          postBaselineYearsCount
        )} to earn this certification level`;

  const accountingAction =
    postBaselineYearsCount > 0
      ? [
          accountingStatus === "closed"
            ? {
                isEarned: true,
                text: `Congratulations! Your accounting is closed and up to date. Make sure to come back on ${formatDate(
                  openDate
                )} to close your accounting.`,
              }
            : {
                isEarned: false,
                href: !!baselineDate && "/platform/company/accounting",
                text: accountingActionText,
              },
        ]
      : [];

  const actions = [
    { isEarned: isBronzeEarned, text: "Complete all Bronze Tier items" },
    baselineAction,
    ...accountingAction,
  ];

  return [
    {
      ...silverTier,
      actions,
    },
    earnedCertificationsLoading ||
      accountingLoading ||
      startDateLoading ||
      silverTierLoading,
  ];
};

export const useClimateWiseTiers = () => {
  const [bronzeTier, bronzeLoading] = useBronzeTier();
  const [silverTier, silverLoading] = useSilverTier();

  const tiers = [bronzeTier, silverTier];

  const isEarned = tiers.some(({ isEarned }) => isEarned);
  const isFinished = tiers.every(({ isEarned }) => isEarned);

  const certificationObj = {
    isAvailable: true,
    isEarned,
    isFinished,
    tiers,
    certification: "climate-wise",
    title: "Climate Wise",
    subtitle:
      "Earning the Climate Wise certification means that you’re getting smart about tackling climate for your business. You’ve taken some of the first steps on your climate journey, such as making the commitment to do so in the Bronze Tier, followed by measuring one year of emissions to earn the Silver Tier.",
  };

  return [certificationObj, bronzeLoading || silverLoading];
};

const usePledgeCertificationActions = ({
  minimumPledgePercentage,
  pledgeCta,
  isEarned,
}) => {
  const [{ accountingYears, pledgeReductionPercentage }, accountingLoading] =
    useAccountingData();
  const [{ isEarned: isSilverEarned }, silverLoading] = useSilverTier();

  const pledgeActionButtonProps = {
    disabled: !isSilverEarned,
    minimumPledgePercentage,
    title: pledgeCta,
  };

  const buildActionText = () => {
    if (!pledgeReductionPercentage) {
      return {
        action: (
          <PledgeActionButton
            ButtonComponent={(props) => (
              <ActionLinkButton {...props}>{pledgeCta}</ActionLinkButton>
            )}
            {...pledgeActionButtonProps}
          />
        ),
      };
    }

    if (pledgeReductionPercentage < minimumPledgePercentage) {
      return {
        action: (
          <PledgeActionButton
            {...pledgeActionButtonProps}
            ButtonComponent={(props) => (
              <ActionLinkButton {...props}>
                Upgrade your pledge to earn this certification tier.
              </ActionLinkButton>
            )}
          />
        ),
      };
    }

    if (!areAccountingYearsWithinPledgePercentage(accountingYears)) {
      return {
        text: "You didn't hit your reduction goal in the last year. Make sure to hit it this year, and every year to earn this certification level.",
      };
    }

    return {
      text: "Congratulations! Make sure you hit your reduction pledge goals every year to keep earning this certification.",
    };
  };

  const actions = [
    {
      isEarned: isSilverEarned,
      text: "Earn Your Climate Wise Silver Tier Certification",
    },
    { isEarned, ...buildActionText() },
  ];

  return [actions, silverLoading || accountingLoading];
};

export const useGoldTier = () => {
  const [goldTier, goldTierLoading] = useSingleEarnedTier("gold");
  const { isEarned } = goldTier;

  const [goldTierActions, goldTierActionsLoading] =
    usePledgeCertificationActions({
      minimumPledgePercentage: 5,
      pledgeCta: "Set a reduction goal- minimum 5% by 2030",
      isEarned,
    });

  return [
    {
      ...goldTier,
      actions: goldTierActions,
    },
    goldTierLoading || goldTierActionsLoading,
  ];
};

const buildScopeTiersActions = ({ type, isEarned, isPreviousEarned }) => {
  const scopeText = type === "diamond" ? "scope 3" : "scope 1 + 2";

  return [
    {
      isEarned: isPreviousEarned,
      text: `Complete all ${
        type === "platinum" ? "Gold" : "Platinum"
      } tier items`,
    },
    {
      isEarned,
      href: "/platform/company/purchase",
      text: `Offset your ${scopeText} emissions every year`,
    },
  ];
};

export const usePlatinumTier = () => {
  const [platinumTier, platinumTierLoading] = useSingleEarnedTier("platinum");
  const [
    { isGoldEarned, isEveryYearPlatinumOffset },
    earnedCertificationsLoading,
  ] = useEarnedCertificationTiers();

  return [
    {
      ...platinumTier,
      actions: buildScopeTiersActions({
        type: "platinum",
        isEarned: isEveryYearPlatinumOffset,
        isPreviousEarned: isGoldEarned,
      }),
    },
    earnedCertificationsLoading || platinumTierLoading,
  ];
};

export const useDiamondTier = () => {
  const [diamondTier, diamondTierLoading] = useSingleEarnedTier("diamond");

  const [
    { isPlatinumEarned, isEveryYearDiamondOffset },
    earnedCertificationsLoading,
  ] = useEarnedCertificationTiers();

  return [
    {
      ...diamondTier,
      actions: buildScopeTiersActions({
        type: "diamond",
        isEarned: isEveryYearDiamondOffset,
        isPreviousEarned: isPlatinumEarned,
      }),
    },
    earnedCertificationsLoading || diamondTierLoading,
  ];
};

export const useClimateLeaderTiers = () => {
  const [silverTier, silverLoading] = useSilverTier();
  const [goldTier, goldLoading] = useGoldTier();
  const [platinumTier, platinumLoading] = usePlatinumTier();
  const [diamondTier, diamondLoading] = useDiamondTier();

  const tiers = [goldTier, platinumTier, diamondTier];

  const { isEarned: isSilverEarned } = silverTier;
  const isEarned = tiers.every(({ isEarned }) => isEarned);

  const certificationObj = {
    isAvailable: isSilverEarned,
    isEarned,
    tiers,
    certification: "climate-leader-certified",
    title: "Climate Leader",
    subtitle:
      "Becoming a Climate Leader means your business is setting and meeting yearly emissions reduction goals and offsetting what cannot be reduced each year. This is a great step towards the Net Zero pathway when your business is ready.",
  };

  return [
    certificationObj,
    silverLoading || goldLoading || platinumLoading || diamondLoading,
  ];
};

export const useNetZeroPledgeTier = () => {
  const [netZeroPledgeTier, netZeroPledgeTierLoading] =
    useSingleEarnedTier("pledge");
  const { isEarned } = netZeroPledgeTier;

  const [netZeroPledgeActions, netZeroPledgeTierActionsLoading] =
    usePledgeCertificationActions({
      minimumPledgePercentage: 50,
      pledgeCta:
        "Sign the pledge in accordance with the Paris Agreement - minimum of 50% by 2030",
      isEarned,
    });

  return [
    {
      ...netZeroPledgeTier,
      actions: netZeroPledgeActions,
    },
    netZeroPledgeTierActionsLoading || netZeroPledgeTierLoading,
  ];
};

export const useNetZeroSuccessTier = () => {
  const [netZeroSuccessTier, netZeroSuccessTierLoading] =
    useSingleEarnedTier("success");
  const { isEarned } = netZeroSuccessTier;
  const [{ isNetZeroPledgeEarned }, earnedCertificationsLoading] =
    useEarnedCertificationTiers();

  const actions = [
    {
      isEarned: isNetZeroPledgeEarned,
      text: "Complete all Net Zero Pledge items",
    },
    { isEarned, text: "Meet your 2030 Pledge Goal" },
  ];

  return [
    {
      ...netZeroSuccessTier,
      isEarned,
      actions,
    },
    earnedCertificationsLoading || netZeroSuccessTierLoading,
  ];
};

export const useNetZeroTiers = () => {
  const [silverTier, silverLoading] = useSilverTier();
  const [pledgeTier, pledgeTierLoading] = useNetZeroPledgeTier();
  const [successTier, successTierLoading] = useNetZeroSuccessTier();

  const tiers = [pledgeTier, successTier];

  const { isEarned: isSilverEarned } = silverTier;
  const isEarned = tiers.every(({ isEarned }) => isEarned);

  const certificationObj = {
    isAvailable: isSilverEarned,
    isEarned,
    tiers,
    certification: "net-zero-pledge",
    title: "Net Zero",
    subtitle:
      "Take the Net Zero pledge to cement your commitment to slashing your business emissions in half by 2030, in alignment with the Paris Agreement. This will help your company get ahead of the curve and ahead of upcoming regulations from the government, vendors, investors and more. This is Aclymate’s most prestigious certification, and you’ll be able to proudly display your success upon reaching your goal in (or before!) 2030.",
  };

  return [
    certificationObj,
    silverLoading || pledgeTierLoading || successTierLoading,
  ];
};

export const useBadges = () => {
  const [
    {
      accountingYears,
      pledgeReductionPercentage,
      baselineDate,
      postBaselineYears,
    },
    accountingLoading,
  ] = useAccountingData();

  const badges = buildBadgesArray({
    accountingYears,
    postBaselineYears,
    pledgeReductionPercentage,
    baselineDate,
  });

  return [badges, accountingLoading];
};
