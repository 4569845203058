import React, { useEffect } from "react";

import { Box, Grid, Paper, Typography, ThemeProvider } from "@mui/material";

import { AnimatedLogo } from "@aclymatepackages/atoms";
import { mergeDarkTheme, useLayoutHelpers } from "@aclymatepackages/themes";

const PurchaseDialogLayout = ({
  title,
  subtitle,
  sideHeader,
  maxPurchaseStep,
  children,
  scrollableBoxRef,
  formBoxRef,
  onScroll,
  isLoading,
}) => {
  const { isMobile, theme } = useLayoutHelpers();

  useEffect(() => {
    if (maxPurchaseStep) {
      formBoxRef.current.lastElementChild.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [maxPurchaseStep, formBoxRef]);

  return (
    <Paper
      style={{ overflow: isMobile ? "auto" : "hidden", maxHeight: "100%" }}
    >
      <Box display="flex" flexDirection="column" style={{ maxHeight: "100%" }}>
        <Box
          p={isMobile ? 1 : 2}
          flexGrow={0}
          style={{ background: theme.palette.primary.main }}
        >
          <ThemeProvider theme={mergeDarkTheme}>
            <Grid
              container
              spacing={isMobile ? 2 : 8}
              alignItems="center"
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item>
                <Typography
                  variant={`${isMobile ? "h6" : "h4"}`}
                  color="textPrimary"
                >
                  {title}
                </Typography>
                {subtitle && (
                  <Typography
                    variant={`${isMobile ? "body2" : "subtitle2"}`}
                    color="textSecondary"
                  >
                    {subtitle}
                  </Typography>
                )}
              </Grid>
              {sideHeader && <Grid item>{sideHeader}</Grid>}
            </Grid>
          </ThemeProvider>
        </Box>
        <Box
          flexGrow={1}
          style={{ overflowY: "auto" }}
          onScroll={onScroll}
          ref={scrollableBoxRef}
        >
          <Box style={{ maxHeight: "100%" }} ref={formBoxRef}>
            {isLoading ? <AnimatedLogo /> : children}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
export default PurchaseDialogLayout;
