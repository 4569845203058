import dayjs from "dayjs";

import GroupIcon from "@mui/icons-material/Group";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SyncIcon from "@mui/icons-material/Sync";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ForestIcon from "@mui/icons-material/Forest";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

import {
  faAnalytics,
  faConciergeBell,
  faLightbulb,
} from "@fortawesome/pro-solid-svg-icons";

import { findProductPrice } from "@aclymatepackages/other-helpers";
import {
  UPDATED_SAAS_MONTHLY_BASE_PRICE,
  UPDATED_SAAS_MONTHLY_TIERED_PRICE,
  UPDATED_SAAS_YEARLY_BASE_PRICE,
  UPDATED_SAAS_YEARLY_TIERED_PRICE,
  UPDATED_CONCIERGE_MONTHLY_BASE_PRICE,
  UPDATED_CONCIERGE_MONTHLY_TIERED_PRICE,
  UPDATED_CONCIERGE_YEARLY_BASE_PRICE,
  UPDATED_CONCIERGE_YEARLY_TIERED_PRICE,
  STARTER_TIER_MONTHLY_PRICE,
  STARTER_TIER_YEARLY_PRICE,
  STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES,
} from "@aclymatepackages/constants";

import Billing from "../../components/platform-pages/settings/Billing";
import Company from "../../components/platform-pages/settings/Company";
import Integrations from "../../components/platform-pages/settings/Integrations";
import Products from "../../components/platform-pages/settings/Products";
import Users from "../../components/platform-pages/settings/Users";
import OffsetsPreferences from "../../components/platform-pages/settings/Offsets";
import Vehicles from "../../components/platform-pages/settings/Vehicles";

import { useSubscriptionType } from "../hooks/companyData";
import { useAccountData, useCachedDisplayData } from "../firebase";

export const settingsPages = [
  {
    label: "Company Data",
    href: "company",
    icon: <LocationCityIcon />,
    Component: Company,
  },
  {
    label: "Account Users",
    href: "users",
    icon: <GroupIcon />,
    Component: Users,
  },
  {
    label: "Billing/Invoices",
    href: "billing",
    icon: <CreditCardIcon />,
    Component: Billing,
  },
  {
    label: "Products/Services",
    href: "products",
    icon: <ShoppingCartIcon />,
    Component: Products,
  },
  {
    label: "Offset Settings",
    href: "offsets",
    icon: <ForestIcon />,
    Component: OffsetsPreferences,
  },
  {
    label: "Company Vehicles",
    href: "vehicles",
    icon: <DirectionsCarIcon />,
    Component: Vehicles,
  },
  {
    label: "Integrations",
    href: "integrations",
    icon: <SyncIcon />,
    Component: Integrations,
  },
];

export const useSubscriptionProducts = () => {
  const [employees, employeesLoading] = useCachedDisplayData("employees");
  const [{ employeeCount: dbEmployeeCount }, companyDataLoading] =
    useAccountData();
  const [{ type: subscriptionType, status }, subscriptionLoading] =
    useSubscriptionType("saas");

  const activeEmployees = employees.filter(
    ({ status }) => status !== "terminated"
  );
  const employeeCount = dbEmployeeCount || activeEmployees.length;

  const {
    REACT_APP_STRIPE_UPDATED_SAAS_MONTHLY,
    REACT_APP_STRIPE_UPDATED_SAAS_YEARLY,
    REACT_APP_STRIPE_UPDATED_CONCIERGE_MONTHLY,
    REACT_APP_STRIPE_UPDATED_CONCIERGE_YEARLY,
    REACT_APP_STRIPE_STARTER_TIER_MONTHLY,
    REACT_APP_STRIPE_STARTER_TIER_YEARLY,
  } = process.env;

  const starterTierProductObj =
    (subscriptionType !== "starter" && status === "active") ||
    employees.length > STARTER_TIER_MAXIMUM_NUMBER_EMPLOYEES
      ? []
      : [
          {
            product: "starter",
            icon: faLightbulb,
            borderColor: "primary",
            title: "Starter",
            image: "analytics-screenshot",
            bullets: [
              "For startups under 10 employees",
              "Measure and track your company's emissions",
              "View and export custom reports",
              "Access to help desk support",
            ],
            prices: [
              {
                interval: "month",
                stripePriceId: REACT_APP_STRIPE_STARTER_TIER_MONTHLY,
                value: STARTER_TIER_MONTHLY_PRICE,
              },
              {
                interval: "year",
                stripePriceId: REACT_APP_STRIPE_STARTER_TIER_YEARLY,
                value: STARTER_TIER_YEARLY_PRICE,
              },
            ],
          },
        ];

  const subscriptionProducts = [
    ...starterTierProductObj,
    {
      product: "integrations",
      icon: faAnalytics,
      borderColor: "primary",
      title: "Professional",
      image: "analytics-screenshot",
      bullets: [
        "Connect your accounting software to minimize data entry",
        "Get the integration for all major utility providers",
        "Podcast interview opportunity",
      ],
      prices: [
        {
          interval: "month",
          stripePriceId: REACT_APP_STRIPE_UPDATED_SAAS_MONTHLY,
          value: findProductPrice({
            base: UPDATED_SAAS_MONTHLY_BASE_PRICE,
            tiered: UPDATED_SAAS_MONTHLY_TIERED_PRICE,
            employeeCount,
          }),
        },
        {
          interval: "year",
          stripePriceId: REACT_APP_STRIPE_UPDATED_SAAS_YEARLY,
          value: findProductPrice({
            base: UPDATED_SAAS_YEARLY_BASE_PRICE,
            tiered: UPDATED_SAAS_YEARLY_TIERED_PRICE,
            employeeCount,
          }),
        },
      ],
    },
    {
      product: "concierge",
      title: "Turn Key Service",
      icon: faConciergeBell,
      borderColor: "primary",
      image: "concierge",
      bullets: [
        "Data-entry and management done for you",
        "Custom reports created for you to address complex reporting & compliance needs",
        "Designated Climate Navigator",
        "Assistance with supply chain emissions",
      ],
      prices: [
        {
          interval: "month",
          stripePriceId: REACT_APP_STRIPE_UPDATED_CONCIERGE_MONTHLY,
          value: findProductPrice({
            base: UPDATED_CONCIERGE_MONTHLY_BASE_PRICE,
            tiered: UPDATED_CONCIERGE_MONTHLY_TIERED_PRICE,
            employeeCount,
          }),
        },
        {
          interval: "year",
          stripePriceId: REACT_APP_STRIPE_UPDATED_CONCIERGE_YEARLY,
          value: findProductPrice({
            base: UPDATED_CONCIERGE_YEARLY_BASE_PRICE,
            tiered: UPDATED_CONCIERGE_YEARLY_TIERED_PRICE,
            employeeCount,
          }),
        },
      ],
    },
  ];

  const productsWithSubscriptionData = subscriptionProducts.map((productObj) =>
    productObj?.product === subscriptionType
      ? { ...productObj, status }
      : productObj
  );

  return [
    productsWithSubscriptionData,
    employeesLoading || companyDataLoading || subscriptionLoading,
  ];
};

export const calculateIsCardExpiringSoon = (expirationDate, expiration) => {
  if (!expirationDate && !expiration) {
    return false;
  }

  if (expirationDate) {
    return Math.abs(dayjs(new Date()).diff(dayjs(expirationDate), "d")) <= 60;
  }

  const [month, year] = expiration.split("/");
  const daysInMonth = dayjs(`${year}-${month}-01`).daysInMonth();
  const newExpirationDate = dayjs(`${month}-${daysInMonth}-${year}`);
  const daysLeft = Math.abs(
    dayjs(new Date()).diff(dayjs(newExpirationDate), "d")
  );

  return daysLeft <= 60;
};
