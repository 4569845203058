import React from "react";

import { Grid, Typography, Button, useTheme } from "@mui/material";

import { mergeDarkTheme } from "@aclymatepackages/themes";

import PageSectionPaper from "./PageSectionPaper";

const MyAclymateCta = () => {
  const { palette } = useTheme();

  return (
    <PageSectionPaper
      title="Offset Your Whole Life!"
      theme={mergeDarkTheme}
      backgroundColor={palette.secondary.dark}
    >
      <Grid container alignItems="stretch" spacing={2}>
        <Grid
          item
          sm={7}
          container
          direction="column"
          justifyContent="center"
          spacing={2}
        >
          <Grid item>
            <Typography variant="body1">
              Did you know you can also learn and offset your footprint for all
              of your personal emissions?
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">
              You can with myAclymate! You might think that one person can't
              make a difference, but in reality, individuals are responsible for
              over 20% of U.S. emissions!
            </Typography>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                href="/myaclymate-survey"
              >
                Take the survey
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={5} container justifyContent="center">
          <Grid item>
            <img
              alt="myaclymate promo"
              src="/images/events-myaclymate-cta.svg"
              style={{
                minWidth: "250px",
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </PageSectionPaper>
  );
};
export default MyAclymateCta;
