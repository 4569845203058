import React from "react";

import { TextField } from "@aclymatepackages/atoms";

import { electricRenewablesPercentageError } from "../../../helpers/components/offices";

const ElectricRenewablesPercentageInput = ({
  electricRenewablesPercentage,
  editElectricRenewablesPercentage,
  index = 0,
  ...otherProps
}) => (
  <TextField
    label="Percentage of electric utilities that come from renewables"
    value={electricRenewablesPercentage}
    setValue={editElectricRenewablesPercentage}
    error={electricRenewablesPercentageError(electricRenewablesPercentage)}
    helperText={
      electricRenewablesPercentageError(electricRenewablesPercentage)
        ? "You need to input a number for the percentage of electric utilities that come from renewables and the percentage cannot be greater than 100%"
        : ""
    }
    id={`NewOffice_${index}_ElectricRenewablesPercent`}
    {...otherProps}
  />
);
export default ElectricRenewablesPercentageInput;
