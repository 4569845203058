import React from "react";

import { Avatar } from "@mui/material";

import TagIcon from "./TagIcon";

import { findThisTagIconColor } from "../../../helpers/components/tags";

const TagAvatar = ({ tag }) => {
  const { color } = findThisTagIconColor(tag);

  return (
    <Avatar
      style={{
        backgroundColor: color,
      }}
    >
      <TagIcon type={tag} />
    </Avatar>
  );
};
export default TagAvatar;
