import React, { useState, useContext } from "react";

import { Box, Typography, ButtonBase, useTheme } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useLayoutHelpers } from "@aclymatepackages/themes";

import Link from "../../atoms/mui/Link";

import { ProjectsContext } from "../../../helpers/contexts/projects";

const CarouselIconButton = ({
  icon,
  name,
  isSelected,
  width,
  slug,
  displayPageUrl,
}) => {
  const { isMedium, isMobile } = useLayoutHelpers();

  const [hover, setHover] = useState(false);

  return (
    <ButtonBase
      onMouseEnter={() => !isMedium && setHover(true)}
      onMouseLeave={() => !isMedium && setHover(false)}
      style={{
        height: "60px",
        width,
      }}
    >
      <Link
        styleProps={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
        href={`${displayPageUrl}${slug ? `/${slug}` : ""}`}
      >
        {(isSelected || !hover) && (
          <div>
            <FontAwesomeIcon
              icon={icon}
              size={isMobile ? "xl" : "2x"}
              style={{
                "--fa-primary-color": "#efefef",
                "--fa-secondary-color": "white",
              }}
            />
          </div>
        )}
        {!isSelected && hover && (
          <Typography
            align="center"
            variant="caption"
            style={{ color: "white" }}
          >
            {name}
          </Typography>
        )}
        {!isSelected && (
          <Box
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: hover ? 0 : "100%",
              transition: "height 0.5s",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              cursor: "pointer",
            }}
          />
        )}
      </Link>
    </ButtonBase>
  );
};

const ProjectCategoriesBar = () => {
  const { palette } = useTheme();
  const {
    projectsLoading,
    displayCategories,
    selectedCategory,
    displayPageUrl,
  } = useContext(ProjectsContext);

  const { slug: selectedCategorySlug } = selectedCategory;

  if (projectsLoading) {
    return <></>;
  }

  return (
    <Box
      display="flex"
      style={{
        backgroundColor: palette.secondary.main,
        width: "100%",
      }}
    >
      {displayCategories.map(({ slug, all, ...otherProps }, idx) => (
        <CarouselIconButton
          key={`category-carousel-icon-${idx}`}
          width={`${100 / displayCategories.length}%`}
          isSelected={slug === selectedCategorySlug}
          displayPageUrl={displayPageUrl}
          slug={slug}
          {...otherProps}
        />
      ))}
    </Box>
  );
};
export default ProjectCategoriesBar;
