import { useCallback, useEffect, useState, useContext } from "react";

import { useSubscriptionType } from "./companyData";
import { useAccountData, useAuth } from "../firebase";
import { fetchOurApi } from "../utils/apiCalls";
import { analyticsTrack, triggerGoogleEvent } from "../analytics";
import { getAccountCollectionAndId } from "../otherHelpers";
import { StripeCustomerContext } from "../contexts/stripeCustomer";

export const useCustomerCoupon = (purchaseType) => {
  const [{ stripeCustomerId }, accountDataLoading] = useAccountData();

  const [coupon, setCoupon] = useState();
  const [isCouponLoading, setIsCouponLoading] = useState(true);

  useEffect(() => {
    const handleSetCoupon = (coupon) => {
      const { purchaseType: couponPurchaseType } = coupon;

      if (!purchaseType) {
        return setCoupon(coupon);
      }

      if (couponPurchaseType === purchaseType) {
        return setCoupon(coupon);
      }

      return null;
    };

    if (!accountDataLoading && isCouponLoading && !coupon) {
      fetchOurApi({
        path: "/stripe/get-customer-coupon",
        method: "POST",
        data: {
          customerId: stripeCustomerId,
        },
        callback: (coupon) => {
          if (!!coupon || !Object.keys(coupon).length) {
            handleSetCoupon(coupon);
          }

          return setIsCouponLoading(false);
        },
      });
    }
  }, [
    accountDataLoading,
    coupon,
    isCouponLoading,
    stripeCustomerId,
    purchaseType,
  ]);

  return [coupon, isCouponLoading];
};

export const useCustomerInvoices = () => {
  const [{ stripeCustomerId }, accountDataLoading] = useAccountData();

  const [invoices, setInvoices] = useState([]);
  const [isInvoicesLoading, setIsInvoicesLoading] = useState(true);

  useEffect(() => {
    if (!accountDataLoading && isInvoicesLoading && !invoices.length) {
      fetchOurApi({
        path: "/stripe/get-customer-invoices",
        method: "POST",
        data: {
          customerId: stripeCustomerId,
        },
        callback: (invoices) => {
          if (!!invoices && invoices.length) {
            const formattedDateInvoices = invoices.map(
              ({ date, ...otherFields }) => ({
                date: new Date(date),
                ...otherFields,
              })
            );

            setInvoices(formattedDateInvoices);
          }

          return setIsInvoicesLoading(false);
        },
      });
    }
  }, [accountDataLoading, invoices, isInvoicesLoading, stripeCustomerId]);

  return [invoices, isInvoicesLoading];
};

export const useCustomerPaymentMethod = (givenStripeCustomerId) => {
  const [{ stripeCustomerId }, accountDataLoading] = useAccountData();

  const [paymentMethod, setPaymentMethod] = useState({});
  const [isPaymentMethodLoading, setIsPaymentMethodLoading] = useState(true);

  const handleSetPaymentMethod = (paymentMethodObj) => {
    const { card } = paymentMethodObj;

    if (card) {
      const { expirationDate } = card;

      const cardPaymentMethodObj = {
        ...paymentMethodObj,
        card: {
          ...card,
          expirationDate: new Date(expirationDate),
        },
      };

      return setPaymentMethod(cardPaymentMethodObj);
    }

    return setPaymentMethod(paymentMethodObj);
  };

  const refetchCustomerPaymentMethod = useCallback(async () => {
    if (!stripeCustomerId) {
      return setIsPaymentMethodLoading(false);
    }

    return await fetchOurApi({
      path: "/stripe/get-customer-payment-method",
      method: "POST",
      data: {
        customerId: givenStripeCustomerId || stripeCustomerId,
      },
      callback: (paymentMethod) => {
        if (!!paymentMethod || !Object.keys(paymentMethod).length) {
          handleSetPaymentMethod(paymentMethod);
        }

        return setIsPaymentMethodLoading(false);
      },
    });
  }, [stripeCustomerId, givenStripeCustomerId]);

  useEffect(() => {
    if (
      !accountDataLoading &&
      isPaymentMethodLoading &&
      !Object.keys(paymentMethod).length
    ) {
      refetchCustomerPaymentMethod();
    }
  }, [
    accountDataLoading,
    isPaymentMethodLoading,
    paymentMethod,
    refetchCustomerPaymentMethod,
  ]);

  return {
    paymentMethod,
    isPaymentMethodLoading,
    refetchCustomerPaymentMethod,
  };
};

export const useCreateStripeSubscription = () => {
  const accountId = window.sessionStorage.getItem("accountId");

  const { refetchCustomerSubscriptions } = useContext(StripeCustomerContext);

  const [accountData, accountDataLoading] = useAccountData();

  const { stripeCustomerId: companyObjStripeCustomerId, billing = {} } =
    accountData;
  const { stripeCustomerId: billingObjStripeCustomerId } = billing;

  const createStripeSubscription = async ({
    stripePriceId,
    product,
    name,
    value,
    interval,
  }) =>
    await fetchOurApi({
      accountId,
      path: `/stripe/create-subscription-intent`,
      method: "POST",
      data: {
        accountId,
        customerId: billingObjStripeCustomerId || companyObjStripeCustomerId,
        subscriptionPriceId: stripePriceId,
        product,
        description: name,
      },
      callback: (response) => {
        const { id } = response;
        triggerGoogleEvent(
          "purchase",
          {
            item_id: stripePriceId,
            itemName: product,
            item_brand: "aclymate",
            item_category: "subscription",
            price: value,
            quantity: 1,
          },
          {
            transaction_id: id,
            value,
            currency: "dollars",
            subscriptionType: interval,
          }
        );

        return response;
      },
    });

  const stripeSubmit = async ({
    stripePriceId,
    interval,
    value,
    name,
    product,
  }) => {
    analyticsTrack("Subscription Created", {
      stripeCustomerId:
        companyObjStripeCustomerId || billingObjStripeCustomerId,
    });

    await createStripeSubscription({
      stripePriceId,
      product,
      name,
      value,
      interval,
    });

    return await refetchCustomerSubscriptions();
  };

  return { stripeSubmit, componentLoading: accountDataLoading, billing };
};

export const useUpdateStripeSubscription = (type = "saas") => {
  const { id: companyId } = getAccountCollectionAndId();
  const [user, userLoading] = useAuth();
  const { refetchCustomerSubscriptions } = useContext(StripeCustomerContext);

  const [subscription, subscriptionDataLoading] = useSubscriptionType(type);

  const { subscriptionId, status } = subscription;
  const { stripeSubmit, componentLoading } = useCreateStripeSubscription();

  const updateSubscription = async (stripeUpdateObj, dbUpdateObj = {}) => {
    const { price, ...otherProps } = stripeUpdateObj;
    const { product, interval } = dbUpdateObj;

    if (!subscriptionId || status === "expired") {
      return await stripeSubmit({
        subscriptionType: type,
        stripePriceId: price,
        interval,
        product,
      });
    }

    const priceUpdateObj = price && price !== subscriptionId ? { price } : {};

    await fetchOurApi({
      user,
      path: `/stripe/update-subscription`,
      method: "POST",
      data: {
        subscriptionId,
        companyId,
        product,
        ...otherProps,
        ...priceUpdateObj,
      },
    });

    return await refetchCustomerSubscriptions();
  };

  return [
    updateSubscription,
    userLoading || subscriptionDataLoading || componentLoading,
  ];
};
