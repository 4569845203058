import React from "react";

import EditableImageUploadDropzone from "../atoms/EditableImageUploadDropzone";
import { useAccountData, useCachedFirebaseCrud } from "../../helpers/firebase";
import { uploadImageToImageResizerIo } from "../../helpers/image-resizer";

const LogoUploadDropzone = (props) => {
  const [{ branding }, companyDataLoading] = useAccountData();
  const { logoUrl } = branding || {};

  const { updateAccountData } = useCachedFirebaseCrud();

  const saveLogo = async (image) => {
    const { error, success, imageData } = await uploadImageToImageResizerIo(
      image
    );

    if (error || !success || !imageData) {
      return;
    }

    const { id } = imageData;

    return await updateAccountData({
      "branding.logoUrl": `https://im.ages.io/${id}?width=1280&format=jpeg&quality=50`,
    });
  };

  return (
    <EditableImageUploadDropzone
      onSave={saveLogo}
      imageName="company logo"
      imageUrl={logoUrl}
      isLoading={companyDataLoading}
      backgroundSize="contain"
      previewImageStyle={{ backgroundSize: "contain" }}
      {...props}
    />
  );
};
export default LogoUploadDropzone;
