import React, { useEffect } from "react";
import { useParams } from "react-router";

import {
  Box,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";

import ErrorBoundary from "../../atoms/ErrorBoundary";
import Link from "../../atoms/mui/Link";

import { settingsPages } from "../../../helpers/components/settings";
import { analyticsPage } from "../../../helpers/analytics";

const SettingsNavIcon = ({ isActive, label, href, icon }) => {
  const { palette } = useTheme();

  return (
    <Grid item>
      <Tooltip title={label}>
        <span>
          <Link href={`/platform/company/settings/${href}`}>
            <IconButton
              style={{
                color: palette.text.primary,
                opacity: isActive ? 1 : 0.7,
              }}
              size="large"
            >
              {icon}
            </IconButton>
          </Link>
        </span>
      </Tooltip>
    </Grid>
  );
};

const Settings = () => {
  const { palette } = useTheme();

  const { view, action } = useParams();

  const { Component, label } = settingsPages.find(({ href }) => href === view);

  useEffect(() => {
    analyticsPage("settings");
  }, []);

  return (
    <ErrorBoundary>
      <Box display="flex" flexDirection="column" style={{ minHeight: "100%" }}>
        <Box
          p={2}
          style={{
            backgroundColor: palette.backgroundGray.dark,
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h2" color="inherit">
                {`Settings- ${label}`}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                {settingsPages.map((settingsPageObj, idx) => (
                  <SettingsNavIcon
                    key={`settings-nav-icon-${idx}`}
                    {...settingsPageObj}
                    isActive={view === settingsPageObj.href}
                  />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          flexGrow={1}
          p={2}
          style={{ backgroundColor: palette.backgroundGray.main }}
        >
          <Component action={action} />
        </Box>
      </Box>
    </ErrorBoundary>
  );
};
export default Settings;
