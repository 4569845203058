import React, { useContext, useState, useEffect } from "react";

import {
  Grid,
  Button,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";

import { mergeDarkTheme, mainTheme } from "@aclymatepackages/themes";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import FormErrorMessage from "./FormErrorMessage";
import FormInputRow from "./FormInputRow";

import { MultipartFormContext } from "../../../helpers/contexts/multiPartForm";

const RevealFormBlock = ({
  rows,
  isActiveForm,
  showNextForm,
  onAdvanceForm,
  formAdvanceErrorMessage = "",
  FormBottomInput,
  submitButtonText = "Next Step",
}) => {
  const { goToBottom } = useContext(MultipartFormContext) || {};

  const [formRow, setFormRow] = useState(isActiveForm ? 1 : rows.length);

  useEffect(() => {
    if (formRow > rows.length) {
      setFormRow(rows.length - 1);
    }

    if (isActiveForm && goToBottom) {
      goToBottom();
    }
  }, [rows, formRow, isActiveForm, goToBottom]);

  const cumulativeIsEnabled =
    rows &&
    rows
      .filter((_, idx) => idx < formRow)
      .reduce((acc, { error, value }) => acc && !error && !!value, true);

  const { onNextStep: rowNextStep } = rows[formRow - 1] || {};

  const onNextStep = () => {
    const advanceForm = () => {
      if (onAdvanceForm) {
        onAdvanceForm();
      }
      return showNextForm();
    };

    if (!rows || formRow === rows.length) {
      return advanceForm();
    }

    if (rowNextStep) {
      rowNextStep();
    }

    return setFormRow((currentStep) => currentStep + 1);
  };

  return (
    <>
      {[...new Array(formRow)].map((_, idx) => {
        if (FormBottomInput && idx === rows.length - 1) {
          return isActiveForm ? (
            <Grid item>
              <FormBottomInput showNextForm={showNextForm} />
            </Grid>
          ) : (
            <></>
          );
        }
        return (
          <FormInputRow
            key={`form-row-${idx}`}
            {...rows[idx]}
            isActiveRow={idx === formRow - 1}
          />
        );
      })}
      {formAdvanceErrorMessage && (
        <FormErrorMessage formAdvanceErrorMessage={formAdvanceErrorMessage} />
      )}
      {isActiveForm && !(formRow === rows.length && FormBottomInput) && (
        <Grid item container justifyContent="center">
          <Grid item>
            <StyledEngineProvider injectFirst>
              <ThemeProvider
                theme={cumulativeIsEnabled ? mainTheme : mergeDarkTheme}
              >
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon fontSize="large" />}
                  disabled={!cumulativeIsEnabled || formAdvanceErrorMessage}
                  onClick={onNextStep}
                >
                  {formRow === rows.length ? submitButtonText : "Next Step"}
                </Button>
              </ThemeProvider>
            </StyledEngineProvider>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default RevealFormBlock;
