import React, { useState, useRef } from "react";

import {
  Button,
  ButtonGroup,
  Paper,
  Popover,
  MenuItem,
  MenuList,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const ButtonMenu = ({ menuOptions = [], color, icon }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        color={color}
        style={{
          boxShadow: "-1px 2px 5px -1px rgba(35,31,32,1)",
          borderRadius: "0.5rem",
        }}
      >
        <Button
          onClick={() => menuOptions[0].onClick()}
          startIcon={icon}
          id={menuOptions[0].id}
        >
          {menuOptions[0].label}
        </Button>
        <Button
          size="small"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          id="button-menu-toggle"
        >
          <FontAwesomeIcon icon={faCaretDown} />
        </Button>
      </ButtonGroup>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper>
          <MenuList>
            {menuOptions.slice(1).map((option, idx) => (
              <MenuItem
                key={`menu-option-${idx}`}
                onClick={() => {
                  option.onClick();
                  return setAnchorEl(null);
                }}
                id={option.id}
              >
                {option.label}
              </MenuItem>
            ))}
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
};
export default ButtonMenu;
