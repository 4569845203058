import React from "react";

import { Box, useTheme } from "@mui/material";

import { hexToRgba } from "@aclymatepackages/converters";

import { AnimatedLogo } from "@aclymatepackages/atoms";

import { useLayoutHelpers } from "../../helpers/otherHelpers";

const LoadingCardLayoutBox = ({
  isLoading,
  backgroundColor,
  children,
  style = {},
  ...otherProps
}) => {
  const { palette } = useTheme();
  const { isMobile } = useLayoutHelpers();

  return (
    <Box
      id="card-layout-box"
      p={isMobile ? 1 : 3}
      style={{
        backgroundColor: backgroundColor
          ? hexToRgba(backgroundColor, 0.1)
          : palette.backgroundGray.light,
        ...style,
      }}
      {...otherProps}
    >
      {isLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ height: "100%", width: "100%" }}
        >
          <AnimatedLogo />
        </Box>
      ) : (
        children
      )}
    </Box>
  );
};
export default LoadingCardLayoutBox;
