import React, { useContext } from "react";

import { useTheme } from "@mui/material";

import findSubcategory from "@aclymatepackages/subcategories";
import { CategoriesAvatar } from "@aclymatepackages/atoms";

import ViewGraphCardLayout from "../../../../layouts/ViewGraphCard";
import ViewBarChart from "../../../../modules/charts/ViewBarChart";
import EmissionsCustomTooltip from "../../../../modules/charts/EmissionsCustomTooltip";

import { useOfficeStatuses } from "../../../../../helpers/components/offices";
import { PlatformLayoutContext } from "../../../../../helpers/contexts/platformLayout";

const OfficesBarChart = ({ dataArray, setSelectedObject }) => {
  const theme = useTheme();
  const { displayUnitLabel } = useContext(PlatformLayoutContext);

  const barAreasArray = [
    {
      ...findSubcategory("gas"),
      subcategory: "monthlyGasCarbonVolume",
      dataKey: "monthlyGasCarbonVolume",
      name: "Average Monthly Gas Emissions",
      color: theme.palette.gas.main,
      customAvatar: <CategoriesAvatar subcategory="gas" />,
    },
    {
      ...findSubcategory("electricity"),
      subcategory: "monthlyElectricCarbonVolume",
      dataKey: "monthlyElectricCarbonVolume",
      name: "Average Monthly Electricity Emissions",
      color: theme.palette.electricity.main,
      customAvatar: <CategoriesAvatar subcategory="electricity" />,
    },
    {
      ...findSubcategory("electricity"),
      subcategory: "monthlyElectricRenewablesCarbonVolume",
      dataKey: "monthlyElectricRenewablesCarbonVolume",
      name: "Average Monthly Renewable Electricity Emissions Avoided",
      color: theme.palette.secondary.main,
      customAvatar: (
        <CategoriesAvatar
          subcategory="electricity"
          color={theme.palette.secondary.main}
        />
      ),
    },
  ];

  return (
    <ViewBarChart
      setSelectedObject={setSelectedObject}
      barAreasArray={barAreasArray}
      data={dataArray}
      yLabel={`Monthly ${displayUnitLabel} of Carbon`}
      tooltipComponent={
        <EmissionsCustomTooltip
          categoriesArray={barAreasArray}
          labelAnnotation="(monthly average)"
          dataArray={dataArray}
        />
      }
    />
  );
};

const OfficesGraph = ({ dataArray, setSelectedObject }) => {
  const officeStatuses = useOfficeStatuses();

  const buildOfficeMonthlyElectricCarbonVolume = ({
    monthlyElectricRenewablesReplacementVolume,
    currentUtilities,
    monthlyElectricCarbonVolume,
    electricRenewablesPercentage = 0,
  }) => {
    if (monthlyElectricRenewablesReplacementVolume) {
      return monthlyElectricRenewablesReplacementVolume;
    }

    const { utilitiesInLease } = currentUtilities || {};
    if (utilitiesInLease) {
      return (
        monthlyElectricCarbonVolume *
        (electricRenewablesPercentage / (100 - electricRenewablesPercentage))
      );
    }

    return 0;
  };

  const buildStatusLabelWarningObj = ({ status }) => {
    if (status === "active") {
      return {};
    }

    const statusObj = officeStatuses[status];
    return { status: statusObj };
  };

  const formattedFilteredOffices = dataArray
    .filter(({ status }) => status !== "closed")
    .map(
      ({
        monthlyElectricCarbonVolume,
        monthlyGasCarbonVolume,
        totalMonthlyCarbonVolume,
        name,
        id,
        ...otherProps
      }) => ({
        monthlyElectricRenewablesCarbonVolume:
          buildOfficeMonthlyElectricCarbonVolume({
            monthlyElectricCarbonVolume,
            ...otherProps,
          }),
        monthlyElectricCarbonVolume,
        monthlyGasCarbonVolume,
        emissionsSumTons: totalMonthlyCarbonVolume,
        label: name,
        id,
        ...buildStatusLabelWarningObj(otherProps),
      })
    );

  const graphs = [
    {
      title: `Monthly Office emissions`,
      type: "bar",
      Graph: OfficesBarChart,
      graphProps: { setSelectedObject },
      sortable: true,
    },
  ];

  return (
    <ViewGraphCardLayout
      dataArray={formattedFilteredOffices}
      subtitle={`NOTE: Closed offices are not shown in the graph since they don't currently contribute to your emissions`}
      graphs={graphs}
      analyticsCta="to see how each of your offices contributes to your monthly emissions"
    />
  );
};
export default OfficesGraph;
