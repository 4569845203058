import React from "react";

import PurchaseDialogLayout from "../../layouts/PurchaseDialogLayout";
import PaymentInput from "../../modules/PaymentInput";

const PurchaseFlowPaymentInfoBox = ({
  dialogSubtitle = "We need your credit card or US bank account information before you can start buying offsets",
}) => (
  <PurchaseDialogLayout
    title="Enter your payment information"
    subtitle={dialogSubtitle}
  >
    <PaymentInput />
  </PurchaseDialogLayout>
);
export default PurchaseFlowPaymentInfoBox;
