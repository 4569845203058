import React, { useState, useEffect } from "react";

import OffsetDetailsPurchaseSlider from "../modules/projects/OffsetDetailsPurchaseSlider";

import offsetBuckets from "../../helpers/components/display-lists/offsetBuckets";

import { useAdminProjects } from "../../helpers/components/projects";

const SubscriptionDetailsSlider = ({
  selectedOffsetBucket,
  setSelectedOffsetBucket,
  startCarouselCycle,
  SubscriptionPurchaseFlow,
}) => {
  const [adminProjects] = useAdminProjects();

  const [displayProject, setDisplayProject] = useState(null);

  const { offsetCategories } = offsetBuckets[selectedOffsetBucket] || {};
  const projectsWithOffsetBucketCategories = adminProjects.filter(
    ({ categories = [] }) =>
      offsetCategories.some((categorySlug) =>
        categories.map(({ slug }) => slug).includes(categorySlug)
      )
  );
  const offsetsToDisplay = projectsWithOffsetBucketCategories.slice(0, 3);

  useEffect(() => {
    if (offsetsToDisplay.length > 1 && !displayProject) {
      setDisplayProject(offsetsToDisplay[0]);
    }
  }, [displayProject, offsetsToDisplay]);

  return displayProject ? (
    <OffsetDetailsPurchaseSlider
      project={displayProject}
      onSlideClose={() => {
        setSelectedOffsetBucket(null);

        if (startCarouselCycle) {
          return startCarouselCycle();
        }

        return null;
      }}
      ProjectPurchaseFlow={() => (
        <SubscriptionPurchaseFlow
          selectedOffsetBucket={selectedOffsetBucket}
          offsetsToDisplay={offsetsToDisplay}
          setDisplayProject={setDisplayProject}
        />
      )}
    />
  ) : (
    <></>
  );
};
export default SubscriptionDetailsSlider;
