import React from "react";

import { YesNoQuestion } from "@aclymatepackages/modules";

const ElectricIntensityYesNoQuestion = ({
  questionText,
  isElectricCarbonIntensityGiven,
  editIsElectricCarbonIntensityGiven,
}) => (
  <YesNoQuestion
    question={questionText}
    value={isElectricCarbonIntensityGiven}
    setValue={editIsElectricCarbonIntensityGiven}
  />
);
export default ElectricIntensityYesNoQuestion;
