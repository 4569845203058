import React from "react";

import { Box, Grid, Typography, Button, Divider } from "@mui/material";
import { useLayoutHelpers } from "@aclymatepackages/themes";

const PurchaseInputSection = ({
  setMaxPurchaseStep,
  input,
  title,
  onNextStep,
  nextStepDisabled,
  isMaxStep,
}) => {
  const { isMobile } = useLayoutHelpers();

  return (
    <>
      <Box p={isMobile ? 1 : 2} style={{ overflowX: "hidden" }}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant={isMobile ? "subtitle1" : "h6"}>
              {title}
            </Typography>
          </Grid>
          <Grid item>{input}</Grid>
          {isMaxStep && (
            <Grid item container justifyContent="center">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={nextStepDisabled}
                  onClick={() => {
                    if (onNextStep) {
                      onNextStep();
                    }

                    return setMaxPurchaseStep((currentStep) => currentStep + 1);
                  }}
                >
                  Next Step
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
      {!isMaxStep && <Divider flexItem style={{ height: "1px" }} />}
    </>
  );
};
export default PurchaseInputSection;
