import React, { useState, useEffect, useCallback } from "react";

import { TextField, Box, Typography, Paper } from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";

import { isObjectEmpty } from "../../../helpers/otherHelpers";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";

const MenuPaper = ({ children }) => (
  <Paper style={{ width: "300px" }}>{children}</Paper>
);

const AirportAutocomplete = ({
  label,
  airport,
  submitErrorMsg,
  editValue,
  isInternational,
  state,
  country,
  coordinates,
  id,
  smallFont,
  variant = "outlined",
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const buildAdditionalDataString = useCallback(() => {
    if (country && coordinates) {
      const { lat, lng } = coordinates;
      return `country:${country},lat:${lat},lng:${lng}`;
    }
    if (country) {
      return `country:${country}`;
    }
    if (state) {
      return `state:${state}`;
    }
    if (isInternational) {
      return "isInternational";
    }
    return "_";
  }, [coordinates, country, isInternational, state]);

  useEffect(() => {
    if (inputValue === "") {
      setOptions(airport && !isObjectEmpty(airport) ? [airport] : []);
      return undefined;
    }
    const controller = new AbortController();
    const { signal } = controller;

    const fetchMatchingAirports = async () => {
      if (inputValue.length >= 3) {
        try {
          await fetchOurApi({
            path: `/airports/autocomplete/${buildAdditionalDataString()}/${inputValue}`,
            method: "GET",
            callback: (res) => setOptions(res),
            signal,
          });
        } catch (err) {
          if (err.name === "AbortError") {
            console.log(
              "Places autocomplete was canceled via controller.abort"
            );
            return;
          }
        }
      }
    };
    fetchMatchingAirports();

    return () => {
      controller.abort();
    };
  }, [inputValue, airport, buildAdditionalDataString]);

  const styleObj = smallFont
    ? {
        fontSize: "0.875rem",
      }
    : {};

  return (
    <Autocomplete
      style={styleObj}
      id={id}
      PaperComponent={MenuPaper}
      options={options}
      getOptionLabel={(airport) =>
        typeof airport === "string" ? airport : airport?.airportName || ""
      }
      filterOptions={(x) => x}
      autoComplete
      openOnFocus={false}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          error={!!submitErrorMsg && !airport}
          size="small"
        />
      )}
      ListboxProps={{ style: { width: "300px" } }}
      renderOption={(props, airport) => (
        <li {...props}>
          <Box py={1}>
            <Typography display="inline" variant="subtitle1">
              {airport.iataCode && `${airport.iataCode}- `}
            </Typography>
            <Typography display="inline" variant="subtitle2">
              {airport.cityName}
            </Typography>
            <Typography variant="body2" display="block">
              {airport.airportName}
            </Typography>
          </Box>
        </li>
      )}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
      onChange={(event, newValue) => editValue(newValue)}
      value={airport || ""}
      isOptionEqualToValue={(option, value) =>
        value === "" || option.airportName === value.airportName
      }
    />
  );
};
export default AirportAutocomplete;
