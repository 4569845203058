import React, { useContext, useState } from "react";

import {
  Box,
  ButtonBase,
  Grid,
  Typography,
  Paper,
  Skeleton,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard, faMoneyCheck } from "@fortawesome/free-solid-svg-icons";
import {
  faCcMastercard,
  faCcVisa,
  faCcDiscover,
  faCcDinersClub,
  faCcAmex,
} from "@fortawesome/free-brands-svg-icons";

import { hexToRgba } from "@aclymatepackages/converters";

import { useAccountData } from "../../../helpers/firebase";
import { StripeCustomerContext } from "../../../helpers/contexts/stripeCustomer";

const BaseCard = ({ onClick, gradient, title, icon, subtitle, status }) => {
  const theme = useTheme();

  const [cardHover, setCardHover] = useState(false);

  return (
    <Grid item>
      <ButtonBase
        style={{ width: "100%", maxWidth: "350px" }}
        onMouseEnter={() => setCardHover(true)}
        onMouseLeave={() => setCardHover(false)}
        onClick={onClick && (() => onClick())}
        disabled={!onClick}
      >
        <Paper elevation={cardHover ? 3 : 0}>
          <Box
            p={2}
            style={{
              background: gradient,
              color: "white",
              borderRadius: theme.spacing(1),
              width: "100%",
            }}
            pt={6}
            boxShadow={cardHover ? 3 : 0}
          >
            <Grid container spacing={1} direction="column">
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={4}
              >
                <Grid item>
                  <Typography variant="subtitle1" color="inherit">
                    {title}
                  </Typography>
                </Grid>
                <Grid item>
                  <FontAwesomeIcon icon={icon} />
                </Grid>
              </Grid>
              <Grid item container spacing={4} justifyContent="space-between">
                <Grid item>
                  <Typography variant="subtitle2" color="inherit">
                    {subtitle}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={4} justifyContent="space-between">
                <Grid item>
                  <Typography variant="subtitle2" color="inherit">
                    {status}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </ButtonBase>
    </Grid>
  );
};

const BankDisplayCard = ({ bank_name, last4, ...otherProps }) => {
  const { palette } = useTheme();

  return (
    <BaseCard
      gradient={`linear-gradient(135deg, ${hexToRgba(
        palette.secondary.main,
        1
      )} 0%, rgba(0,0,0,1) 100%)`}
      title={bank_name}
      icon={faMoneyCheck}
      subtitle={`XXXXX${last4}`}
      status="This bank account is verified and ready to use."
      {...otherProps}
    />
  );
};

const CreditCardDisplayCard = ({
  companyName,
  last4,
  expiration,
  brand,
  ...otherProps
}) => {
  const { palette } = useTheme();

  const cardIcons = {
    visa: faCcVisa,
    amex: faCcAmex,
    mastercard: faCcMastercard,
    discover: faCcDiscover,
    diners: faCcDinersClub,
  };

  return (
    <BaseCard
      gradient={`linear-gradient(135deg, ${hexToRgba(
        palette.primary.main,
        1
      )} 0%, rgba(0,0,0,1) 100%)`}
      title={`${companyName}'s credit card`}
      icon={cardIcons[brand] || faCreditCard}
      subtitle={`XXXXXXXXXXXX${last4}`}
      status={expiration}
      {...otherProps}
    />
  );
};

const BillingMethodCardDisplay = ({ noCardComponent, onClick }) => {
  const [{ name }, companyDataLoading] = useAccountData();
  const { paymentMethod, isPaymentMethodLoading } = useContext(
    StripeCustomerContext
  );
  const { card, us_bank_account } = paymentMethod || {};

  if (companyDataLoading || isPaymentMethodLoading) {
    return (
      <Skeleton
        variant="rectangular"
        style={{ width: "100%", height: "150px" }}
      />
    );
  }

  if (card) {
    return (
      <CreditCardDisplayCard onClick={onClick} companyName={name} {...card} />
    );
  }

  if (us_bank_account) {
    return <BankDisplayCard onClick={onClick} {...us_bank_account} />;
  }

  return noCardComponent || <></>;
};
export default BillingMethodCardDisplay;
