import React from "react";

import { Grid } from "@mui/material";

import ScopeThreeCategorySelect from "./ScopeThreeCategorySelect";

import OpenAiVendorCategorization from "./OpenAiVendorCategorization";

const SpendBasedVendorInputs = ({
  editVendor,
  scopeThreeCategory,
  disabled,
  ...otherProps
}) => {
  return (
    <>
      <OpenAiVendorCategorization
        editVendor={editVendor}
        disabled={disabled}
        {...otherProps}
      />
      <Grid item>
        <ScopeThreeCategorySelect
          scopeThreeCategory={scopeThreeCategory}
          setScopeThreeCategory={editVendor("scopeThreeCategory")}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};
export default SpendBasedVendorInputs;
