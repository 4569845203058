import React, { useContext } from "react";

import { AccountingChart as AccountingChartModule } from "@aclymatepackages/modules";

import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";

const AccountingChart = (props) => {
  const { convertCarbonUnits, displayUnitLabel } = useContext(
    PlatformLayoutContext
  );

  return (
    <AccountingChartModule
      {...props}
      convertCarbonUnits={convertCarbonUnits}
      displayUnitLabel={displayUnitLabel}
    />
  );
};
export default AccountingChart;
