import React from "react";

import { Container } from "@mui/material";

import { Backdrop } from "@aclymatepackages/atoms";

import OnboardingImageLayout from "./OnboardingImageLayout";

const OnboardingImageLayoutBackdrop = ({
  backdropProps = {},
  ...otherProps
}) => (
  <Backdrop {...backdropProps}>
    <Container maxWidth="lg">
      <OnboardingImageLayout {...otherProps} />
    </Container>
  </Backdrop>
);
export default OnboardingImageLayoutBackdrop;
