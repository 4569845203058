import React from "react";

import {
  Grid,
  Container,
  Divider,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";

import { mergeDarkTheme } from "@aclymatepackages/themes";

const FormDivider = () => (
  <Grid item>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mergeDarkTheme}>
        <Container maxWidth="md">
          <Divider />
        </Container>
      </ThemeProvider>
    </StyledEngineProvider>
  </Grid>
);
export default FormDivider;
