import { createContext, useContext, useState, useEffect } from "react";

import { formatVendors, buildVendorAlerts } from "../components/vendors";
import {
  useFindCurrentEmployeeEmissions,
  useEmployeeStatuses,
} from "../components/employees";
// import {
//   useFormatDisplayOffices,
//   useOfficesWithCurrentChanges,
// } from "../components/offices";

import useEmissionsContext from "./emissions";

import {
  useCachedDisplayData,
  useCollectionDataListener,
  useAccountData,
} from "../firebase";
import { fetchOurApi } from "../utils/apiCalls";

const DashboardDataContext = createContext();

export const DashboardDataContextProvider = ({ children }) => {
  const {
    transactions,
    allEmissions,
    emissionsMonths,
    recurringEmissionsLoading,
  } = useEmissionsContext();
  const [{ employeeCount }, accountDataLoading] = useAccountData();

  const findCurrentEmployeeEmissions = useFindCurrentEmployeeEmissions();
  // const formatDisplayOffices = useFormatDisplayOffices();

  // const [dbOffices, dbOfficesLoading] = useOfficesWithCurrentChanges();
  const [dbEmployees, dbEmployeesLoading] = useEmployeeStatuses();
  const [unmatchedVendors, unmatchedVendorsLoading] =
    useCachedDisplayData("vendors");
  const [matchedVendors, matchVendorsLoading] =
    useCollectionDataListener("vendor-companies");

  const [duplicateTransactionsGroups, setDuplicateTransactionsGroups] =
    useState([]);
  const [
    duplicateTransactionsGroupsLoading,
    setDuplicateTransactionsGroupsLoading,
  ] = useState(true);

  useEffect(() => {
    if (
      !recurringEmissionsLoading &&
      allEmissions.length &&
      duplicateTransactionsGroupsLoading
    ) {
      fetchOurApi({
        path: "/transactions/find-duplicate-transactions-groups",
        method: "POST",
        data: { transactions: allEmissions },
        callback: ({ duplicateTransactionsGroups }) => {
          setDuplicateTransactionsGroups(duplicateTransactionsGroups);
          return setDuplicateTransactionsGroupsLoading(false);
        },
      }).catch((error) => {
        console.error("Error fetching duplicate transactions", error);
      });
    }
  }, [
    transactions,
    allEmissions,
    recurringEmissionsLoading,
    duplicateTransactionsGroupsLoading,
  ]);

  const vendorsLoading =
    unmatchedVendorsLoading || matchVendorsLoading || recurringEmissionsLoading;
  const employeesLoading =
    recurringEmissionsLoading || accountDataLoading || dbEmployeesLoading;
  // const officesLoading =
  //   dbOfficesLoading ||
  //   recurringEmissionsLoading ||
  //   accountDataLoading ||
  //   dbEmployeesLoading;

  const formatDbEmployees = () => {
    if (employeesLoading) {
      return [];
    }

    return findCurrentEmployeeEmissions({
      transactions,
      employees: dbEmployees,
      employeeCount,
      emissionsMonths,
    });
  };

  // const formatDbOffices = () => {
  //   if (officesLoading) {
  //     return [];
  //   }

  //   return formatDisplayOffices({
  //     offices: dbOffices,
  //     transactions,
  //     emissionsMonths,
  //     startDate,
  //     employees: dbEmployees,
  //   });
  // };

  const formatDbVendors = () => {
    if (vendorsLoading) {
      return { vendors: [], vendorAlerts: [] };
    }

    const vendors = formatVendors({
      transactions,
      matchedVendors,
      unmatchedVendors,
    });
    const vendorAlerts = buildVendorAlerts(vendors);

    return { vendors, vendorAlerts };
  };

  const employees = formatDbEmployees();
  // const offices = formatDbOffices(); //TODO: Offices currently isn't being used, because I was in a hurry and couldn't figure out how to make it work.

  const { vendors, vendorAlerts } = formatDbVendors();

  const dashboardDataLoading = vendorsLoading || employeesLoading;

  return (
    <DashboardDataContext.Provider
      value={{
        vendors,
        vendorAlerts,
        employees,
        // offices,
        dashboardDataLoading,
        duplicateTransactionsGroups,
      }}
    >
      {children}
    </DashboardDataContext.Provider>
  );
};
const useDashboardDataContext = () => useContext(DashboardDataContext);
export default useDashboardDataContext;
