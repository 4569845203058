import React, { useState } from "react";

import { Grid } from "@mui/material";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { Select, TextField, ToggleButtons } from "@aclymatepackages/atoms";

import UnitsSelect from "../UnitsSelect";

import {
  buildInitialTransactionInputValueFromSchema,
  isEmissionsInputSchemaEnabled,
} from "../../../helpers/components/inputs";
import { convertVolumeUnitsToTons } from "../../../helpers/otherHelpers";

const WasteInput = ({
  editWasteData,
  wasteUnit,
  wasteQuantity,
  wasteType,
  wasteCategory,
}) => {
  const generateWasteTypeButtonOptions = () => {
    const optionsByCategory = {
      recycle: [
        { label: "Mixed Recyclables", value: "mr" },
        { label: "Mixed Metals", value: "metals" },
        { label: "Mixed Papers", value: "papers" },
        { label: "Mixed Plastics", value: "plastics" },
        { label: "Cardboard Waste", value: "cardboard" },
        { label: "Glass", value: "glass" },
      ],
      landfill: [
        { label: "Mixed Stream Waste", value: "msv" },
        { label: "Mixed Recyclables", value: "mr" },
        { label: "Mixed Metals", value: "metals" },
        { label: "Mixed Papers", value: "papers" },
        { label: "Mixed Plastics", value: "plastics" },
        { label: "Cardboard Waste", value: "cardboard" },
        { label: "Food Waste", value: "food" },
        { label: "Glass", value: "glass" },
      ],
      combust: [
        { label: "Mixed Stream Waste", value: "msv" },
        { label: "Mixed Recyclables", value: "mr" },
        { label: "Mixed Metals", value: "metals" },
        { label: "Mixed Papers", value: "papers" },
        { label: "Mixed Plastics", value: "plastics" },
        { label: "Food Waste", value: "food" },
      ],
      compost: [{ label: "Food Waste", value: "food" }],
    };

    return optionsByCategory[wasteCategory];
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Grid container justifyContent="center">
          <Grid item>
            <ToggleButtons
              buttons={[
                { name: "Landfill", value: "landfill" },
                { name: "Recycle", value: "recycle" },
                { name: "Compost", value: "compost" },
                { name: "Combust", value: "combust" },
              ]}
              value={wasteCategory}
              onChange={editWasteData("wasteCategory")}
              size="small"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Select
          options={generateWasteTypeButtonOptions()}
          size="small"
          label="Waste Type"
          value={wasteType || null}
          editValue={editWasteData("wasteType")}
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Quantity"
            value={wasteQuantity}
            setValue={editWasteData("wasteQuantity")}
            size="small"
            error={wasteQuantity && !numbersRegExpTest(wasteQuantity)}
          />
        </Grid>
        <Grid item xs={6}>
          <UnitsSelect
            value={wasteUnit}
            setValue={editWasteData("wasteUnit")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const useWasteInput = ({ transaction, onSave }) => {
  const inputSchema = [
    { field: "wasteUnit", defaultValue: "lbs" },
    { field: "wasteCategory", defaultValue: "landfill" },
    { field: "wasteQuantity" },
    { field: "wasteType" },
  ];

  const [wasteData, setWasteData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );

  const { wasteQuantity, wasteType, wasteCategory, wasteUnit } = wasteData;

  const editWasteData = (field) => (value) =>
    editObjectData(setWasteData, field, value);

  const findTonsCo2e = () => {
    const constantFactorsForWasteTypes = {
      msv: { landfill: 0.52, combust: 0.43 },
      mr: { recycle: 0.09, landfill: 0.68, combust: 0.11 },
      metals: { recycle: 0.23, landfill: 0.02, combust: 0.01 },
      plastics: { recycle: 0.22, landfill: 0.02, combust: 2.34 },
      papers: { recycle: 0.07, landfill: 0.8, combust: 0.05 },
      food: { landfill: 0.58, combust: 0.05, compost: 0.15 },
      cardboard: { recycle: 0.11, landfill: 0.9 },
      glass: { recycle: 0.05, landfill: 0.02 },
    };

    if (!wasteType || !wasteUnit) {
      return 0;
    }

    const constantFactor =
      constantFactorsForWasteTypes[wasteType][wasteCategory];

    return constantFactor * convertVolumeUnitsToTons(wasteQuantity, wasteUnit);
  };

  const tonsCo2e = findTonsCo2e();

  const onTransactionSave = () => onSave({ ...wasteData, tonsCo2e });

  const saveEnabled = isEmissionsInputSchemaEnabled(wasteData, inputSchema);

  return {
    inputBlock: <WasteInput editWasteData={editWasteData} {...wasteData} />,
    saveEnabled,
    onTransactionSave,
    tonsCo2e,
  };
};
export default useWasteInput;
