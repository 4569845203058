import React, { useState } from "react";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

import { Box, Grid, Typography, Tabs, Tab, Divider } from "@mui/material";

import { useLayoutHelpers } from "@aclymatepackages/themes";
import {
  US_AVERAGE_PER_CAPITA_HOME_TONS_CO2E_PER_YEAR,
  US_AVERAGE_VEHICLE_TRAVEL_TONS_CO2E_PER_YEAR,
  US_AVERAGE_FLIGHTS_TONS_CO2E_PER_YEAR,
  US_AVERAGE_DIET_TONS_CO2E_PER_YEAR,
  US_AVERAGE_EXPENDITURES_PER_CAPITA_TONS_CO2E_PER_YEAR,
  WORLD_AVERAGE_PER_CAPITA_HOME_TONS_CO2E_PER_YEAR,
  WORLD_AVERAGE_VEHICLE_TRAVEL_TONS_CO2E_PER_YEAR,
  WORLD_AVERAGE_FLIGHTS_TONS_CO2E_PER_YEAR,
  WORLD_AVERAGE_DIET_TONS_CO2E_PER_YEAR,
  WORLD_AVERAGE_EXPENDITURES_PER_CAPITA_TONS_CO2E_PER_YEAR,
} from "@aclymatepackages/constants";
import { tonsToLbs } from "@aclymatepackages/converters";

import ReportContentCard from "./ReportCardContent";
import CarbonFoot from "./CarbonFoot";
import SlideLayout from "../layouts/SlideLayout";

const CarbonFootprintGraphs = ({
  selectedCategory,
  yearlyHomeTonsCo2e,
  yearlyTravelTonsCo2e,
  yearlyFlightsTonsCo2e,
  yearlyDietTonsCo2e,
  yearlySpendingTonsCo2e,
}) => {
  const { theme } = useLayoutHelpers();

  const emissionsData = {
    home: {
      label: "Home",
      subtitle:
        "These are the emissions from your gas/electric usage, pets emissions, and how they compare to the average household",
      barColor: theme.palette.offices.main,
      personalBarColor: theme.palette.offices.dark,
      barData: [
        {
          label: "US Avg",
          emissionValue: US_AVERAGE_PER_CAPITA_HOME_TONS_CO2E_PER_YEAR,
        },
        {
          label: "You",
          emissionValue: yearlyHomeTonsCo2e,
        },
        {
          label: "World Avg",
          emissionValue: WORLD_AVERAGE_PER_CAPITA_HOME_TONS_CO2E_PER_YEAR,
        },
      ],
    },
    travel: {
      label: "Travel",
      subtitle:
        "These are the emissions from traveling with your vehicles and public transportation",
      barColor: theme.palette.vehicles.main,
      personalBarColor: theme.palette.vehicles.dark,
      barData: [
        {
          label: "US Avg",
          emissionValue: US_AVERAGE_VEHICLE_TRAVEL_TONS_CO2E_PER_YEAR,
        },
        {
          label: "You",
          emissionValue: yearlyTravelTonsCo2e,
        },
        {
          label: "World Avg",
          emissionValue: WORLD_AVERAGE_VEHICLE_TRAVEL_TONS_CO2E_PER_YEAR,
        },
      ],
    },
    flights: {
      label: "Flights",
      subtitle:
        "These are the emissions from all of your flights and how it compares to the average",
      barColor: theme.palette["business-travel"].main,
      personalBarColor: theme.palette["business-travel"].dark,
      barData: [
        {
          label: "US Avg",
          emissionValue: US_AVERAGE_FLIGHTS_TONS_CO2E_PER_YEAR,
        },
        {
          label: "You",
          emissionValue: yearlyFlightsTonsCo2e,
        },
        {
          label: "World Avg",
          emissionValue: WORLD_AVERAGE_FLIGHTS_TONS_CO2E_PER_YEAR,
        },
      ],
    },
    diet: {
      label: "Diet",
      subtitle: "These are the emissions from what you eat",
      barColor: theme.palette.employees.main,
      personalBarColor: theme.palette.employees.dark,
      barData: [
        {
          label: "US Avg",
          emissionValue: US_AVERAGE_DIET_TONS_CO2E_PER_YEAR,
        },
        {
          label: "You",
          emissionValue: yearlyDietTonsCo2e,
        },
        {
          label: "World Avg",
          emissionValue: WORLD_AVERAGE_DIET_TONS_CO2E_PER_YEAR,
        },
      ],
    },
    costs: {
      label: "Expenses",
      subtitle: "These are the emissions from your lifestyle purchases",
      barColor: theme.palette.rides.main,
      personalBarColor: theme.palette.rides.dark,
      barData: [
        {
          label: "US Avg",
          emissionValue: US_AVERAGE_EXPENDITURES_PER_CAPITA_TONS_CO2E_PER_YEAR,
        },
        {
          label: "You",
          emissionValue: yearlySpendingTonsCo2e,
        },
        {
          label: "World Avg",
          emissionValue:
            WORLD_AVERAGE_EXPENDITURES_PER_CAPITA_TONS_CO2E_PER_YEAR,
        },
      ],
    },
  };

  const { label, barColor, personalBarColor, barData, subtitle } =
    emissionsData[selectedCategory];
  const barDataToLbs = barData.map(({ emissionValue, ...otherValues }) => ({
    emissionValue: tonsToLbs(emissionValue),
    ...otherValues,
  }));

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography variant="h5" align="center">
          {`${label} Emissions`}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" align="center">
          {subtitle}
        </Typography>
      </Grid>
      <Grid item>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={barDataToLbs}>
            <XAxis dataKey="label" />
            <YAxis
              width={65}
              label={{
                value: "Lbs CO2e",
                angle: -90,
                position: "insideLeft",
              }}
              tickFormatter={(v) => {
                if (v < 1000) {
                  return v;
                }

                return `${v / 1000}k`;
              }}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar dataKey="emissionValue">
              {barDataToLbs.map((entry, index) => (
                <Cell
                  key={index}
                  fill={index === 1 ? personalBarColor : barColor}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};

const CarbonFootprintGraphic = ({
  isDesktop,
  yearlyTonsCo2eObj,
  SlideLayoutLeftContent,
  numPeople,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const {
    yearlyHomeTonsCo2e,
    yearlyTravelTonsCo2e,
    yearlyFlightsTonsCo2e,
    yearlyDietTonsCo2e,
    yearlySpendingTonsCo2e,
    yearlyTotalTonsCo2e,
  } = yearlyTonsCo2eObj;

  const footprintDetails = [
    {
      category: "home",
      tonsCo2e: yearlyHomeTonsCo2e,
    },
    { category: "travel", tonsCo2e: yearlyTravelTonsCo2e },
    {
      category: "flights",
      tonsCo2e: yearlyFlightsTonsCo2e,
    },
    {
      category: "diet",
      tonsCo2e: yearlyDietTonsCo2e,
    },
    {
      category: "costs",
      tonsCo2e: yearlySpendingTonsCo2e,
    },
  ];

  const sortedFootprintDetails = footprintDetails.sort(
    (a, b) => b.tonsCo2e - a.tonsCo2e
  );

  const { category } =
    footprintDetails.find(({ category }) => category === selectedCategory) ||
    {};

  const leftContentObj = SlideLayoutLeftContent
    ? { leftContent: <SlideLayoutLeftContent /> }
    : {};

  return (
    <>
      {category && (
        <SlideLayout
          isSlideOpen
          setIsSlideOpen={() => setSelectedCategory(null)}
          header={<Typography variant="h6">Emissions Breakdown</Typography>}
          tagInputs={
            <>
              <Tabs
                value={selectedCategory}
                onChange={(_, value) => setSelectedCategory(value)}
                variant="scrollable"
                scrollButtons="on"
              >
                {sortedFootprintDetails.map(({ category }, idx) => (
                  <Tab
                    label={category}
                    value={category}
                    key={`object-details-view-tab-${idx}`}
                  />
                ))}
              </Tabs>
              <Divider />
            </>
          }
          content={
            <CarbonFootprintGraphs
              selectedCategory={category}
              {...yearlyTonsCo2eObj}
            />
          }
          {...leftContentObj}
        />
      )}
      <ReportContentCard
        title={`My ${
          numPeople > 1 ? "Household's " : ""
        }Annual Carbon Footprint`}
        subtitle="Click on the parts of your footprint to explore your emissions!"
        isDesktop={isDesktop}
      >
        <Box
          style={{ height: "100%" }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CarbonFoot
            totalFootprintTons={yearlyTotalTonsCo2e}
            footprintDetails={footprintDetails}
            onCategorySelect={setSelectedCategory}
            isDesktop={isDesktop}
          />
        </Box>
      </ReportContentCard>
    </>
  );
};
export default CarbonFootprintGraphic;
