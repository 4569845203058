import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import {
  Container,
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  useTheme,
  ThemeProvider,
} from "@mui/material";

import { AnimatedLogo, Link } from "@aclymatepackages/atoms";
import {
  mergeMainTheme,
  mergeDarkTheme,
  useLayoutHelpers,
} from "@aclymatepackages/themes";
import { hexToRgba, tonsToLbs } from "@aclymatepackages/converters";
import { formatDecimal, formatDollars } from "@aclymatepackages/formatters";
import { findProductPrice } from "@aclymatepackages/other-helpers";
import {
  convertFootprintToIce,
  footprintToHamburgers,
} from "@aclymatepackages/converters";
import {
  UPDATED_SAAS_MONTHLY_BASE_PRICE,
  UPDATED_SAAS_MONTHLY_TIERED_PRICE,
} from "@aclymatepackages/constants";

import Products from "../platform-pages/settings/Products";

import useEmissionsContext, {
  EmissionsContextProvider,
} from "../../helpers/contexts/emissions";
import { PlatformLayoutContextProvider } from "../../helpers/contexts/platformLayout";
import { useAccountData, signOut } from "../../helpers/firebase";
import { analyticsTrack } from "../../helpers/analytics";

const FootprintPricingSummary = ({
  employeeCount,
  primaryCta,
  carbonFootprintTons,
}) => {
  const theme = useTheme();
  const { isMobile } = useLayoutHelpers();

  const softwarePrice = findProductPrice({
    base: UPDATED_SAAS_MONTHLY_BASE_PRICE,
    tiered: UPDATED_SAAS_MONTHLY_TIERED_PRICE,
    employeeCount,
  });

  const footprintLbs = formatDecimal(tonsToLbs(carbonFootprintTons));
  const hamburgerFootprint = formatDecimal(
    footprintToHamburgers(carbonFootprintTons)
  );
  const polarBearFootprint = formatDecimal(
    convertFootprintToIce(carbonFootprintTons)
  );

  const LargeNumber = ({ value }) => (
    <Typography variant="body1" component="span" style={{ fontSize: "1.5em" }}>
      {value}
    </Typography>
  );

  const cardProperties = [
    {
      color: "mileage",
      imageUrl: "64b95ddc50f6878441e8ad6c_factory",
      string: (
        <>
          Your estimated monthly emissions are{" "}
          <LargeNumber value={footprintLbs} /> lbs
        </>
      ),
      size: 4,
    },
    {
      color: "secondary",
      imageUrl: "64b8458adb4e88d48db9852f_eating-hamburgers",
      string: (
        <>
          That is equivalent to eating{" "}
          <LargeNumber value={hamburgerFootprint} /> hamburgers
        </>
      ),
      size: 4,
    },
    {
      color: "primary",
      imageUrl: "64b845a4325c7e3eef9f4b10_melting-polar-bear",
      string: (
        <>
          That is enough to permanently melt{" "}
          <LargeNumber value={polarBearFootprint} /> square feet of Arctic sea
          ice
        </>
      ),
      size: 4,
    },
  ];

  return (
    <Grid container spacing={2}>
      {cardProperties.map(({ color, imageUrl, string, size }, idx) => (
        <Grid item xs={12} md={size} key={`footprint-card-${idx}`}>
          <Paper
            style={{
              color: "#fff",
              position: "relative",
              overflow: "hidden",
              display: "flex",
              height: "100%",
              backgroundColor: hexToRgba(theme.palette[color].light, 1),
            }}
          >
            <Typography
              fontWeight="bold"
              variant="body1"
              style={{
                width: "75%",
                alignSelf: "center",
                padding: theme.spacing(2),
              }}
            >
              {string}
            </Typography>
            <img
              src={`https://uploads-ssl.webflow.com/646be42f49d4d6d23104386c/${imageUrl}.svg`}
              alt="Card"
              style={{
                width: "90%",
                height: "90%",
                position: "absolute",
                bottom: "-15%",
                right: "-32%",
              }}
            />
          </Paper>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Paper
          style={{
            backgroundImage:
              "url('https://uploads-ssl.webflow.com/646be42f49d4d6d23104386c/64c058a11079283f4244e3c4_josh-pigford-RJdt6wGw4wk-unsplash-modified.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <Box p={6} style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}>
            <ThemeProvider theme={mergeDarkTheme}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography
                    variant={isMobile ? "body1" : "h5"}
                    textAlign="center"
                    color="textSecondary"
                    gutterBottom
                  >
                    The good news is that Aclymate makes it accessible to take
                    action on climate.
                  </Typography>
                  <Typography
                    variant={isMobile ? "body1" : "h4"}
                    textAlign="center"
                    color="textPrimary"
                  >
                    {`Start your climate journey today for only ${formatDollars(
                      softwarePrice,
                      0
                    )}`}
                  </Typography>
                </Grid>
                <Grid item container justifyContent="center">
                  <Grid item>{primaryCta}</Grid>
                </Grid>
              </Grid>
            </ThemeProvider>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

const ShowExpiredFootprintSummary = () => {
  const { palette } = useTheme();
  const { action } = useParams();

  const { averageMonthlyRecurringEmissionsTons } = useEmissionsContext();

  const [{ employeeCount, name }, accountDataLoading] = useAccountData();

  const [showProducts, setShowProducts] = useState(false);
  const [trackEventSent, setTrackEventSent] = useState(false);

  useEffect(() => {
    if (!accountDataLoading && !trackEventSent) {
      analyticsTrack("Trial Expired", { name, employeeCount });
      setTrackEventSent(true);
    }
  }, [name, accountDataLoading, employeeCount, trackEventSent]);

  return accountDataLoading ? (
    <Box p={4} display="flex" alignItems="center" justifyContent="center">
      <AnimatedLogo />
    </Box>
  ) : (
    <Box
      p={2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        height: "100vh",
        backgroundColor: palette.backgroundGray.main,
        boxSizing: "border-box",
      }}
    >
      <Container maxWidth="lg">
        {showProducts ? (
          <Products
            action={action}
            onBackButtonClick={() => setShowProducts(false)}
          />
        ) : (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography
                variant="h5"
                align="center"
              >{`${name}'s subscription to Aclymate has expired. Here's a summary of the carbon emissions we were able to calculate for you.`}</Typography>
            </Grid>
            <Grid item>
              <FootprintPricingSummary
                employeeCount={employeeCount}
                carbonFootprintTons={averageMonthlyRecurringEmissionsTons}
                primaryCta={
                  <ThemeProvider theme={mergeMainTheme}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => setShowProducts(true)}
                        >
                          Sign Up Now
                        </Button>
                      </Grid>
                      <Grid item>
                        <Link href="https://calendly.com/travis-kruse/meet-with-aclymate">
                          <Button variant="contained" color="primary">
                            Talk To Sales
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item>
                        <ThemeProvider theme={mergeDarkTheme}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              signOut().then(() => {
                                return window.sessionStorage.clear();
                              })
                            }
                          >
                            Log out
                          </Button>
                        </ThemeProvider>
                      </Grid>
                    </Grid>
                  </ThemeProvider>
                }
              />
            </Grid>
          </Grid>
        )}
      </Container>
    </Box>
  );
};

const ExpiredAccountFootprintSummary = () => (
  <PlatformLayoutContextProvider>
    <EmissionsContextProvider>
      <ShowExpiredFootprintSummary />
    </EmissionsContextProvider>
  </PlatformLayoutContextProvider>
);
export default ExpiredAccountFootprintSummary;
