import React, { useState, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Box, Typography, Grid } from "@mui/material";

import { nameToFirstName } from "@aclymatepackages/converters";

import ResendVerificationEmailButton from "./ResendVerificationEmailButton";
import AccountCreationInput from "./AccountCreationInput";

import Link from "../atoms/mui/Link";

import { AccountAccessContext } from "../../helpers/contexts/accountAccess";
import {
  onSignInWithEmailAndPassword,
  sendExistingCompanyAdminEmail,
  createUpdateMyAclymateAccount,
  onLoginSetSessionAndTracking,
  useReferralCookie,
  isEmailPersonal,
} from "../../helpers/components/accountAccess";
import {
  fetchOurApi,
  fetchUserWithEmail,
  queryForExistingCompany,
  deleteOauthUser,
  newAuthUser,
} from "../../helpers/utils/apiCalls";
import { useAuth } from "../../helpers/firebase";
import { analyticsTrack, triggerGoogleEvent } from "../../helpers/analytics";

const doesUserAlreadyExist = async (email) => {
  const existingUserData = await fetchUserWithEmail(email);
  return !!existingUserData;
};

const extractCompanyUrlFromEmail = (email) => {
  const [url] = email.split("@").reverse();
  return url;
};

const createNewAuthUser = async ({ user, callback }) => {
  analyticsTrack("User Created", user);
  return await newAuthUser(user, callback);
};

const AccountCreationForm = ({
  isNewCompanyAdmin,
  setError,
  companyId,
  referralPartner,
  setVerificationEmailSent,
  initialUserData,
  setEmail,
  setDisplayName,
}) => {
  const [currentUser] = useAuth();

  const { inviteId, email: storedEmail } = isNewCompanyAdmin || {};
  const { accountCreationType } = useParams();
  const isCompanyAccountCreation = accountCreationType === "company";

  const history = useHistory();

  const { referralPartner: partnerCookie } = useReferralCookie();
  const { setAccountAccessError } = useContext(AccountAccessContext) || {};
  const setErrorMsg = setAccountAccessError || setError;

  const [userLoading, setUserLoading] = useState(false);

  const { email: existingIndividualEmail = "", name: existingIndividualName } =
    initialUserData || {};

  const confirmNewCompanyAdmin = async ({
    loginFunction,
    user: passedUser,
  }) => {
    const fetchAdminUser = async () => {
      if (loginFunction) {
        return await loginFunction();
      }

      return currentUser;
    };

    const user = passedUser || (await fetchAdminUser());
    const { uid, email, displayName } = user || {};

    const loginNewAdmin = async () => {
      await user.getIdToken(true);
      onLoginSetSessionAndTracking(currentUser, `v2-companies-${companyId}`);
      history.push("/platform/company/dashboard");
      return window.location.reload();
    };

    return await fetchOurApi({
      path: `/users/confirm`,
      method: "POST",
      data: {
        companyId,
        uid,
        inviteId,
        email,
        name: displayName,
      },
      callback: loginNewAdmin,
    });
  };

  const showAccountAccessSnackbar = (message) => {
    setUserLoading(false);
    return setErrorMsg(message);
  };

  const processNewAdminUserInvite = async ({
    user,
    inputData,
    loginFunction,
  }) => {
    const { email, displayName, password } = inputData || user;

    if (isNewCompanyAdmin && email !== storedEmail) {
      return showAccountAccessSnackbar(
        "The email you've input doesn't match the email of your invitation"
      );
    }

    setUserLoading(true);

    const newUserProps = {
      email: email || existingIndividualEmail,
      displayName: displayName || existingIndividualName,
      password,
    };

    if (loginFunction) {
      return await createNewAuthUser({
        user: newUserProps,
        callback: () => confirmNewCompanyAdmin({ loginFunction }),
      });
    }

    return await confirmNewCompanyAdmin({ user });
  };

  const preCompanyAccountCreationChecks = async ({
    inputData,
    loginFunction,
    user,
  }) => {
    const {
      email,
      displayName,
      uid: existingUid,
      password,
    } = inputData || user;

    const joinOrphanCompany = async () => {
      const callLoginFunction = async () => {
        if (loginFunction) {
          return await loginFunction(inputData);
        }

        return;
      };

      const onSuccessfulJoin = async () => {
        await callLoginFunction();

        return setVerificationEmailSent(true);
      };

      return await fetchOurApi({
        path: `/onboarding/join-orphan-company`,
        method: "POST",
        data: {
          companyId,
          uid: existingUid,
          email,
          displayName,
          password,
        },
        callback: onSuccessfulJoin,
        setError: () =>
          showAccountAccessSnackbar("An error occurred joining your company."),
      });
    };

    if (accountCreationType === "personal") {
      return true;
    }

    if (isNewCompanyAdmin) {
      await processNewAdminUserInvite({
        loginFunction,
        inputData,
        user,
      });
      return false;
    }

    if (companyId && !isNewCompanyAdmin) {
      await joinOrphanCompany();

      return false;
    }

    const websiteUrl = extractCompanyUrlFromEmail(email);

    if (websiteUrl === "aclymate.com") {
      //This is important for being able to test flows internally
      return true;
    }

    const existingCompany = await queryForExistingCompany(
      "websiteUrl",
      websiteUrl
    );

    if (!existingCompany) {
      return true;
    }

    const createOrUseExistingUid = async () => {
      if (existingUid) {
        return existingUid;
      }

      return await createNewAuthUser({
        user: inputData,
        callback: ({ uid }) => uid,
      });
    };

    const uid = await createOrUseExistingUid();

    sendExistingCompanyAdminEmail(existingCompany, { uid, displayName, email });
    showAccountAccessSnackbar(
      "A company with this URL already exists. We've emailed your company administrators to let them know you want to join their account."
    );
    return false;
  };

  const passwordEmailSignIn = async (inputData, otherProps = {}) =>
    await onSignInWithEmailAndPassword({
      setErrorMsg: showAccountAccessSnackbar,
      callback: (user) => user,
      ...inputData,
      ...otherProps,
    });

  const companyAccountCallback = async ({ email }) => {
    await fetchOurApi({
      path: "/onboarding/company-account-creation",
      method: "POST",
      data: { email, referralPartner: partnerCookie || referralPartner },
    });

    triggerGoogleEvent("account_created");
    analyticsTrack("Company Created");
    return setVerificationEmailSent(true);
  };

  const myAccountCallback = async (email) => {
    await createUpdateMyAclymateAccount(email);
    await currentUser.getIdToken(true);
    return history.push("/platform/my/dashboard");
  };

  const completePasswordEmailAccountCreation = async (inputData) => {
    const { email, displayName } = inputData;

    setUserLoading(true);
    setEmail(email);
    setDisplayName(displayName);

    const userAlreadyExists = await doesUserAlreadyExist(email);

    if (userAlreadyExists) {
      return showAccountAccessSnackbar(
        "A user with this email already exists. Try logging in instead."
      );
    }

    const doesPassPreAccountCreationChecks =
      await preCompanyAccountCreationChecks({
        inputData,
        loginFunction: () => passwordEmailSignIn(inputData),
      });

    if (!doesPassPreAccountCreationChecks) {
      return;
    }

    await createNewAuthUser({ user: inputData });

    const accountCreationCallback = isCompanyAccountCreation
      ? () => companyAccountCallback(inputData)
      : myAccountCallback;

    return await passwordEmailSignIn(inputData, {
      callback: accountCreationCallback,
    });
  };

  const completeOauthAccountCreation = async (user) => {
    const { email } = user;

    if (isCompanyAccountCreation && isEmailPersonal(email)) {
      showAccountAccessSnackbar(
        "Please use a work email to sign up for Aclymate"
      );
      return deleteOauthUser(email);
    }

    setUserLoading(true);
    const doesPassPreAccountCreationChecks =
      await preCompanyAccountCreationChecks({ user });

    if (!doesPassPreAccountCreationChecks) {
      return;
    }

    if (isCompanyAccountCreation) {
      return await companyAccountCallback(user);
    }

    return myAccountCallback(email);
  };

  return (
    <AccountCreationInput
      isNewCompanyAdmin={isNewCompanyAdmin}
      setErrorMsg={setErrorMsg}
      completeOauthAccountCreation={completeOauthAccountCreation}
      initialUserData={initialUserData}
      isCompanyAccountCreation={isCompanyAccountCreation}
      userLoading={userLoading}
      completePasswordEmailAccountCreation={
        completePasswordEmailAccountCreation
      }
    />
  );
};

const EmailConfirmationBlock = ({ email, displayName = "" }) => (
  <Grid item>
    <Box py={4}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h4" color="textPrimary">
            Hi {nameToFirstName(displayName)}, Welcome to Aclymate.
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            We just sent you an email with a link to the next steps. Click the
            button below if you didn't receive it.
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item>
            <ResendVerificationEmailButton email={email} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Grid>
);

const AccountCreationFlow = ({ isNewCompanyAdmin, ...otherProps }) => {
  const { email: storedEmail } = isNewCompanyAdmin || {};

  const initialUserData = storedEmail ? { email: storedEmail } : {};
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");

  return (
    <>
      {verificationEmailSent ? (
        <EmailConfirmationBlock email={email} displayName={displayName} />
      ) : (
        <AccountCreationForm
          initialUserData={initialUserData}
          isNewCompanyAdmin={isNewCompanyAdmin}
          setVerificationEmailSent={setVerificationEmailSent}
          setEmail={setEmail}
          setDisplayName={setDisplayName}
          {...otherProps}
        />
      )}
      <Grid item container justifyContent="center">
        <Grid item>
          <Typography variant="body1" display="inline">
            Already have an account? <Link href="/">Login instead</Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default AccountCreationFlow;
