import React from "react";

import { Grid, Typography, Divider } from "@mui/material";

const OrDivider = () => (
  <Grid container spacing={1} justifyContent="center" alignItems="center">
    <Grid item xs={3}>
      <Divider style={{ borderBottomWidth: "2px" }} />
    </Grid>
    <Grid item>
      <Typography variant="h5">OR</Typography>
    </Grid>
    <Grid item xs={3}>
      <Divider style={{ borderBottomWidth: "2px" }} />
    </Grid>
  </Grid>
);
export default OrDivider;
