import React, { useState } from "react";

import { ThemeProvider, Button } from "@mui/material";
import PollIcon from "@mui/icons-material/Poll";

import { mergeDarkTheme } from "@aclymatepackages/themes";

import CollapsibleAlert from "../../atoms/notifications/CollapsibleAlert";

const OffsetsPageSurvey = ({ Survey, offsetPreferences, ...surveyProps }) => {
  const [showSurvey, setShowSurvey] = useState(false);

  if (offsetPreferences) {
    return <></>;
  }

  return (
    <>
      {showSurvey && <Survey setShowSurvey={setShowSurvey} {...surveyProps} />}
      <CollapsibleAlert
        variant="filled"
        title="Need help picking offsets?"
        subtitle="Take a quick quiz to find the best offset projects for your business"
        severity="success"
        onClick={() => setShowSurvey(true)}
        action={
          <ThemeProvider theme={mergeDarkTheme}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setShowSurvey(true)}
            >
              Take The Survey
            </Button>
          </ThemeProvider>
        }
        icon={<PollIcon style={{ fontSize: "2.5em" }} />}
      />
    </>
  );
};
export default OffsetsPageSurvey;
