import React from "react";

import {
  Avatar,
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";

import {
  formatDate,
  formatDollars,
  ucFirstLetters,
} from "@aclymatepackages/formatters";
import { Link } from "@aclymatepackages/atoms";

import SortableTable from "../../modules/tables/SortableTable";

const InvoiceTableRow = ({
  date,
  dollarValue,
  invoiceUrl,
  name,
  icon,
  type,
}) => (
  <TableRow>
    <TableCell>
      <Tooltip title={ucFirstLetters(type)}>
        <Avatar>
          <FontAwesomeIcon icon={icon} />
        </Avatar>
      </Tooltip>
    </TableCell>
    <TableCell>{formatDate(date)}</TableCell>
    <TableCell>{name}</TableCell>
    <TableCell>{formatDollars(dollarValue)}</TableCell>
    {invoiceUrl && (
      <TableCell>
        <Tooltip title="View invoice">
          <span>
            <Link href={invoiceUrl}>
              <IconButton size="large">
                <FontAwesomeIcon icon={faFileInvoice} />
              </IconButton>
            </Link>
          </span>
        </Tooltip>
      </TableCell>
    )}
  </TableRow>
);

const InvoicesTable = ({ formattedInvoices, columns }) =>
  formattedInvoices.length ? (
    <SortableTable
      rows={[...formattedInvoices].sort((a, b) => b.date - a.date)}
      rowComponent={(invoice) => (
        <InvoiceTableRow key={`invoice-row-${invoice.id}`} {...invoice} />
      )}
      columns={columns}
    />
  ) : (
    <Box p={2}>
      <Typography align="center" variant="subtitle1" color="textPrimary">
        You don't have any invoices yet. Invoices from your purchases of
        subscriptions or offsets will show up here.
      </Typography>
    </Box>
  );
export default InvoicesTable;
