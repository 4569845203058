import React from "react";

import InputBlock from "./InputBlock";
import DbAutocomplete from "../autocomplete/DbAutocomplete";
import { useCachedDisplayData } from "../../../helpers/firebase";

const EventsInput = ({ event, setEvent, ...otherProps }) => {
  const [events] = useCachedDisplayData("events");

  const nonClosedEvents = events.filter(({ isClosed }) => !isClosed);

  return (
    <InputBlock
      type="events"
      title="Tag an event for this transaction"
      cardData={event}
      {...otherProps}
    >
      <DbAutocomplete
        collection="events"
        setValue={setEvent}
        value={event || []}
        id="events-tag-input"
        availableOptions={nonClosedEvents}
      />
    </InputBlock>
  );
};
export default EventsInput;
