import React from "react";

import { IconButton, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const TitleHelperIcon = ({ tooltipText, href }) => (
  <>
    {href ? (
      <IconButton href={href} target="_blank" size="small">
        <Tooltip title={tooltipText}>
          <HelpOutlineIcon />
        </Tooltip>
      </IconButton>
    ) : (
      <Tooltip title={tooltipText}>
        <HelpOutlineIcon />
      </Tooltip>
    )}
  </>
);
export default TitleHelperIcon;
