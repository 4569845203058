import React, { useState } from "react";
import CompanyDataListItem from "./CompanyDataListItem";
import { useCachedFirebaseCrud } from "../../../helpers/firebase";
import { TextField } from "@aclymatepackages/atoms";

const CompanyNameRow = ({ companyData: { name: companyName }, isEditable }) => {
  const { updateAccountData } = useCachedFirebaseCrud();

  const [modifyName, setModifyName] = useState(companyName);
  const nameIsTooLong = modifyName?.length > 40;

  return (
    <CompanyDataListItem
      editingComponent={
        <TextField
          value={modifyName}
          setValue={setModifyName}
          variant="standard"
          label="Edit Company Name"
          InputProps={{ style: { width: "250px" } }}
          helperText={
            nameIsTooLong &&
            "Your company's name should be less than 40 characters long"
          }
          error={nameIsTooLong}
        />
      }
      saveDisabled={!modifyName || nameIsTooLong}
      onCancel={() => setModifyName(companyName)}
      onSave={() => updateAccountData({ name: modifyName })}
      primaryText={companyName}
      secondaryText="Company Name"
      isEditable={isEditable}
    />
  );
};

export default CompanyNameRow;
