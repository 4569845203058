import React from "react";

import { PlacesAutocomplete as PlacesAutocompleteModule } from "@aclymatepackages/modules";

import { useAccountData } from "../../../helpers/firebase";

const PlacesAutocomplete = (props) => {
  const [companyData] = useAccountData();
  const { coordinates } = companyData?.geography?.address || {};

  return <PlacesAutocompleteModule {...props} coordinates={coordinates} />;
};
export default PlacesAutocomplete;
