import React, { useState, createContext, useContext, useEffect } from "react";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";

import { fetchAdminApi } from "../utils/apiCalls";
import { unpackFirebaseObj, useAccountData } from "../firebase";
import { getAccountCollectionAndId } from "../otherHelpers";

import { PlatformLayoutContext } from "./platformLayout";
import { formatExternalCompanyData } from "./helpers";

export const SandboxDataContext = createContext();

const SandboxDataContextProvider = ({ children }) => {
  const { viewMode } = useContext(PlatformLayoutContext);
  const isSandboxMode = viewMode === "sandbox";

  const [companyData, companyDataLoading] = useAccountData();

  const [sandboxData, setSandboxData] = useState({
    companyData: {},
    transactions: [],
    offices: [],
    employees: [],
    vehicles: [],
    offsets: [],
  });
  const [sandboxDataLoading, setSandboxDataLoading] = useState(true);

  const editSandboxData = (field, value) =>
    editObjectData(setSandboxData, field, value);

  useEffect(() => {
    const { name: companyName } = companyData;
    const { id: companyId } = getAccountCollectionAndId();

    const fetchAndSetSandboxData = async () => {
      const {
        companyData = {},
        employees = [],
        offices = [],
        transactions = [],
        vehicles = [],
        offsets = [],
      } = await fetchAdminApi({
        url: "/aclymate/fetch-sandbox-data",
        data: { method: "GET" },
      });

      const { formattedEmployees, formattedTransactions } =
        formatExternalCompanyData({
          companyId,
          companyName,
          employees,
          transactions,
        });

      setSandboxData({
        companyData: unpackFirebaseObj(companyData),
        employees: formattedEmployees,
        offices,
        transactions: formattedTransactions,
        vehicles,
        offsets,
      });

      return setSandboxDataLoading(false);
    };

    if (isSandboxMode && sandboxDataLoading && !companyDataLoading) {
      fetchAndSetSandboxData();
    }
  }, [isSandboxMode, sandboxDataLoading, companyData, companyDataLoading]);

  return (
    <SandboxDataContext.Provider
      value={{
        sandboxData,
        sandboxDataLoading,
        editSandboxData,
      }}
    >
      {children}
    </SandboxDataContext.Provider>
  );
};
export default SandboxDataContextProvider;
