import React from "react";

import { Avatar } from "@mui/material";
import { AvatarGroup as MuiAvatarGroup } from "@mui/lab";

const AvatarGroup = ({
  max = 5,
  members = [],
  avatarColor,
  spacing = "small",
}) => (
  <MuiAvatarGroup max={max} spacing={spacing}>
    {members.map((member, idx) => (
      <Avatar
        key={`avatar-group-element-${idx}`}
        style={{ backgroundColor: avatarColor }}
      >
        {member}
      </Avatar>
    ))}
  </MuiAvatarGroup>
);
export default AvatarGroup;
