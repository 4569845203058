import React from "react";

import { Box, Grid, Skeleton } from "@mui/material";

const TableSkeleton = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Skeleton
          style={{
            maxWidth: "100%",
          }}
        >
          <Box p={10}></Box>
        </Skeleton>
      </Grid>
    </Grid>
  );
};
export default TableSkeleton;
