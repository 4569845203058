import React, { useState } from "react";

import { Grid, Typography } from "@mui/material";

import { Autocomplete } from "@aclymatepackages/atoms";
import { utilitiesProviders } from "@aclymatepackages/lists";

import {
  serviceClassDescription,
  useFetchMeters,
  useUtilityAuthStep,
} from "../../../helpers/components/utilitiesApi";
import { useCollectionDataListener } from "../../../helpers/firebase";

import CircularProgressWithLabel from "../../atoms/loading/CircularProgressWithLabel";

const UtilityAuthForm = ({ editOffice, utilityId, type }) => {
  const [selectedMeters, setSelectedMeters] = useState([]);

  const [utilityAuthStep] = useUtilityAuthStep({
    utilityId,
  });
  const [metersList, metersListLoading] = useFetchMeters({ utilityId });

  const { input } = utilityAuthStep || {};
  const metersOptions = metersList?.length
    ? metersList.map((meter) => ({
        name: `${meter?.meterName} (${
          meter?.serviceAddress
        }, ${serviceClassDescription(meter?.serviceClass)})`,
        ...meter,
      }))
    : [];

  const handleSetSelectedMeters = (newSelectedMeters) => {
    const linkedUtilityType =
      type === "electric" ? "linkedElectricUtility" : "linkedGasUtility";
    const linkedUtilityTypeId =
      type === "electric" ? "linkedElectricUtilityId" : "linkedGasUtilityId";

    if (!newSelectedMeters?.length) {
      editOffice(linkedUtilityType, null);
      editOffice(linkedUtilityTypeId, null);

      return setSelectedMeters([]);
    }

    const [{ expirationDate }] = metersList;
    const newLinkedUtilityObj = {
      name: utilitiesProviders.find(
        ({ utilityId: providerId }) => providerId === utilityId
      )?.name,
      utilityId,
      expirationDate,
      pairedMeters: newSelectedMeters.map(
        ({ meterId, serviceAddress, serviceClass }) => ({
          meterId,
          serviceAddress,
          serviceClass,
        })
      ),
    };

    editOffice(linkedUtilityType, newLinkedUtilityObj);
    editOffice(linkedUtilityTypeId, utilityId);

    return setSelectedMeters(newSelectedMeters);
  };

  return (
    <>
      {!metersList ? (
        input
      ) : (
        <Grid container justifyContent="center" spacing={2} direction="column">
          {metersListLoading ? (
            <Grid item container justifyContent="center">
              <CircularProgressWithLabel />
            </Grid>
          ) : (
            <>
              <Grid item>
                <Typography variant="body2">
                  {`Select the ${type} meters that belong to this office`}
                </Typography>
              </Grid>
              <Grid item>
                <Autocomplete
                  multiple
                  label="Select Meters"
                  id={`meters-autocomplete-${type}`}
                  availableOptions={metersOptions}
                  value={selectedMeters}
                  setValue={(selectedMeters) =>
                    handleSetSelectedMeters(selectedMeters)
                  }
                  onDelete={({ name: deletedMeterName }) => {
                    const filteredSelectedMeters = selectedMeters.filter(
                      ({ name }) => name !== deletedMeterName
                    );

                    return handleSetSelectedMeters(filteredSelectedMeters);
                  }}
                  disableCloseOnSelect
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </>
  );
};

const NewOfficeUtilityForm = ({ utilityProvidersList = [], editOffice }) => {
  const utilityIds = utilityProvidersList.map(({ utilityId }) => utilityId);
  const isSameUtilitiesInList = utilityIds.every(
    (val, idx, arr) => val === arr[0]
  );

  const [utilityApiIntegration] = useCollectionDataListener("integrations", [
    ["utilityId", "==", utilityIds[0] || "UNKNOWN"],
  ]);

  return (
    <Grid container spacing={2} justifyContent="center" direction="column">
      {utilityProvidersList.length === 2 &&
      isSameUtilitiesInList &&
      !utilityApiIntegration.length ? (
        <Grid item>
          <UtilityAuthForm
            editOffice={editOffice}
            {...utilityProvidersList[0]}
          />
        </Grid>
      ) : (
        utilityProvidersList.map((utilityProvider) => (
          <Grid item>
            <UtilityAuthForm editOffice={editOffice} {...utilityProvider} />
          </Grid>
        ))
      )}
    </Grid>
  );
};
export default NewOfficeUtilityForm;
