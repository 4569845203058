import React from "react";

import { YesNoQuestion } from "@aclymatepackages/modules";

const ElectricRenewablesYesNoQuestion = ({
  questionText,
  isElectricRenewablesPercentageGiven,
  editIsElectricRenewablesPercentageGiven,
}) => (
  <YesNoQuestion
    question={questionText}
    value={isElectricRenewablesPercentageGiven}
    setValue={editIsElectricRenewablesPercentageGiven}
  />
);
export default ElectricRenewablesYesNoQuestion;
