import React from "react";

import ElectricInputForm from "../offices/ElectricInputForm";

import { useElectricityInputProps } from "../../../helpers/components/inputs";

const useElectrictyInput = ({ transaction, onSave }) => {
  const {
    electricKwh,
    setElectricKwh,
    electricRenewablesAndIntensityInputFormProps,
    electricCarbonTons,
    saveEnabled,
    saveObject,
  } = useElectricityInputProps(transaction);

  const inputBlock = (
    <ElectricInputForm
      electricKwh={electricKwh}
      setElectricKwh={setElectricKwh}
      {...electricRenewablesAndIntensityInputFormProps}
    />
  );

  const onTransactionSave = () =>
    onSave({ ...saveObject, tonsCo2e: electricCarbonTons });

  return {
    inputBlock,
    onTransactionSave,
    saveEnabled,
    tonsCo2e: electricCarbonTons,
  };
};
export default useElectrictyInput;
