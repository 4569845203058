import React, { useState } from "react";

import { Box, Grid, Typography, Paper, Button, useTheme } from "@mui/material";

import {
  CompanyStatementBlock,
  EmissionsChartBlock,
  PurchasedOffsetBlock,
  AclymateFooter,
  BadgeDisplayBlock,
  AboutAclymateBlock,
} from "@aclymatepackages/net-zero";
import { DefaultPaper, TextField } from "@aclymatepackages/atoms";

import Link from "../../atoms/mui/Link";
import EditableImageUploadDropzone from "../../atoms/EditableImageUploadDropzone";
import EditableContentBlock from "../../atoms/EditableContentBlock";
import LogoUploadDropzone from "../../modules/LogoUploadDropzone";

import {
  useCachedFirebaseCrud,
  useAccountData,
  useCachedDisplayData,
} from "../../../helpers/firebase";
import useAccountingData from "../../../helpers/hooks/accountingData";
import {
  useHighestEarnedTier,
  useBadges,
} from "../../../helpers/components/badges";
import { uploadImageToImageResizerIo } from "../../../helpers/image-resizer";

const StatementEditor = ({
  defaultCompanyStatement,
  companyStatement = "",
}) => {
  const { updateAccountData } = useCachedFirebaseCrud();

  const [customStatement, setCustomStatement] = useState(companyStatement);

  const onStatementSave = async () =>
    await updateAccountData({
      "badges.companyStatement": customStatement,
    });

  return (
    <EditableContentBlock
      editingInterface={
        <TextField
          label="Custom Company Statement"
          multiline
          rows={5}
          value={customStatement}
          setValue={setCustomStatement}
        />
      }
      content={
        <Typography variant="body1">
          {companyStatement || defaultCompanyStatement}
        </Typography>
      }
      onSave={onStatementSave}
    />
  );
};

const EditableHeaderBlock = ({ headerImageUrl }) => {
  const theme = useTheme();
  const { borderRadius } = theme.shape;

  const { updateAccountData } = useCachedFirebaseCrud();

  const saveImage = async (image) => {
    const { error, success, imageData } = await uploadImageToImageResizerIo(
      image
    );

    if (error || !success || !imageData) {
      return;
    }

    const { id } = imageData;

    return await updateAccountData({
      "badges.headerImage": `https://im.ages.io/${id}?width=1280&format=jpeg&quality=50`,
    });
  };

  return (
    <EditableImageUploadDropzone
      onSave={saveImage}
      imageUrl={headerImageUrl}
      imageName="Header Image"
      contentStyle={{
        padding: theme.spacing(6),
        borderRadius: `${borderRadius}px ${borderRadius}px 0 0`,
      }}
      height="20rem"
    >
      <DefaultPaper>
        <Box style={{ height: "100px", width: "300px" }}>
          <LogoUploadBlock />
        </Box>
      </DefaultPaper>
    </EditableImageUploadDropzone>
  );
};

const EditableCompanyStatement = ({
  certificationId,
  name,
  certificationName,
  companyStatement,
}) => {
  const defaultCompanyStatement = `${name} is committed to a net zero future because we  take climate change seriously and want to ensure our business is doing its part. That's why we're excited to be working with Aclymate to track, reduce and offset our carbon footprint.`;
  return (
    <CompanyStatementBlock
      certificationId={certificationId}
      certificationName={certificationName}
      companyStatement={companyStatement}
      companyName={name}
      statementEditor={
        <StatementEditor
          companyStatement={companyStatement}
          defaultCompanyStatement={defaultCompanyStatement}
        />
      }
    />
  );
};

const NoOffsetsDisplay = () => {
  const { palette } = useTheme();

  return (
    <Box style={{ backgroundColor: palette.backgroundGray.main }} p={4}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5" align="center">
            Purchase offsets from our marketplace to display them on your
            certification page
          </Typography>
        </Grid>
        <Grid item container justifyContent="center">
          <Grid item>
            <Link href="/platform/company/purchase">
              <Button color="secondary" variant="contained">
                Browse Offsets
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const LogoUploadBlock = () => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <LogoUploadDropzone
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      elementName="logo"
    />
  );
};

const PageDisplayBlock = () => {
  const [accountingData] = useAccountingData();
  const [companyData] = useAccountData();
  const [offsets] = useCachedDisplayData("offsets");
  const [highestEarnedCertificationTier] = useHighestEarnedTier();
  const [badges] = useBadges();

  const {
    certification,
    level,
    certificationName,
    name: badgeLevelName,
  } = highestEarnedCertificationTier;
  const { name, badges: dbBadgeData } = companyData;
  const {
    companyStatement,
    headerImage = "/images/website/footprint-header.jpg",
  } = dbBadgeData || {};

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h2">Your Certification Page</Typography>
        <Typography variant="body1">
          This is a preview of your Certification page - feel free to customize
          it with your own company statement, logo, and header image. After
          you're done customizing, scroll to the bottom to get the link to your
          new page!
        </Typography>
      </Grid>
      <Grid item>
        <Paper style={{ backgroundColor: "white" }}>
          <EditableHeaderBlock headerImageUrl={headerImage} />
          <EditableCompanyStatement
            certificationId={`${certification}-${level}`}
            certificationName={`${certificationName}- ${badgeLevelName} Tier`}
            name={name}
            companyStatement={companyStatement}
          />
          <BadgeDisplayBlock
            earnedBadges={badges.filter(({ isEarned }) => isEarned)}
            companyName={name}
            noBadgeDisplay={
              <Typography variant="h4">
                Badges you earn on your Climate Journey will show up here.
              </Typography>
            }
          />
          <AboutAclymateBlock />
          <EmissionsChartBlock
            companyName={name}
            accountingData={accountingData}
            noChartBlock={
              <Typography variant="h4" align="center">
                Once you close your accounting for the year, your emissions
                chart will show up here.
              </Typography>
            }
          />
          <PurchasedOffsetBlock
            offsets={offsets}
            noOffsetsDisplay={<NoOffsetsDisplay />}
          />
          <AclymateFooter companyName={name} />
        </Paper>
      </Grid>
    </Grid>
  );
};
export default PageDisplayBlock;
