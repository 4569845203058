import React from "react";

import {
  Box,
  Grid,
  Chip,
  Tooltip,
  Badge,
  Card,
  CardHeader,
  ButtonBase,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";

import { darkTheme } from "@aclymatepackages/themes";
import { nameToInitials } from "@aclymatepackages/converters";
import { ucFirstLetters } from "@aclymatepackages/formatters";

import InputPopper from "./InputPopper";
import AvatarGroup from "../../atoms/mui/AvatarGroup";
import TagAvatar from "../../atoms/icons/TagAvatar";

import { isObjectEmpty } from "../../../helpers/otherHelpers";

const InputCardButton = ({
  type,
  activePopper,
  setActivePopper,
  setPopperAnchorEl,
  cardData,
  inputLength,
  disableInputCardResizing,
}) => {
  const isEmptyCardData = Array.isArray(cardData)
    ? !cardData.length
    : isObjectEmpty(cardData);

  const activeBool = !activePopper || type === activePopper;
  const borderRadiusObj =
    type === activePopper && !disableInputCardResizing
      ? { borderRadius: "4px 4px 0 0" }
      : {};

  const AvatarBadge = ({ children }) => {
    if (Array.isArray(cardData)) {
      return (
        <Tooltip title={cardData.map(({ name }) => name).join(", ")}>
          <Badge
            color="primary"
            badgeContent={cardData.length}
            overlap="circular"
          >
            {children}
          </Badge>
        </Tooltip>
      );
    }
    return (
      <Tooltip title={cardData.name}>
        <Badge color="primary" variant="dot" overlap="circular">
          {children}
        </Badge>
      </Tooltip>
    );
  };

  const CardDataDisplay = () => {
    const avatarGroupMax = () => {
      if (activePopper && activePopper !== type) {
        return 2;
      }
      if (activePopper === type) {
        return 8;
      }
      return 4;
    };

    if (isEmptyCardData || inputLength > 2) {
      return (
        <CardHeader
          avatar={
            !isEmptyCardData ? (
              <AvatarBadge>
                <TagAvatar tag={type} />
              </AvatarBadge>
            ) : (
              <TagAvatar tag={type} />
            )
          }
          title={
            inputLength < 4 &&
            (activeBool || disableInputCardResizing) &&
            ucFirstLetters(type)
          }
        />
      );
    }

    return (
      <Box p={2}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item>
            <TagAvatar tag={type} />
          </Grid>
          <Grid item>
            {Array.isArray(cardData) ? (
              <Tooltip title={cardData.map(({ name }) => name).join(", ")}>
                <div>
                  <AvatarGroup
                    max={avatarGroupMax()}
                    members={cardData.map(({ name }) => nameToInitials(name))}
                  />
                </div>
              </Tooltip>
            ) : (
              !!cardData && <Chip label={cardData?.name} />
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <ButtonBase
      style={{
        width: "100%",
        height: !activeBool && !disableInputCardResizing ? "72px" : "80px",
      }}
      onClick={(e) => {
        if (!!activePopper && activePopper === type) {
          setActivePopper("");
          return setPopperAnchorEl(null);
        }
        setPopperAnchorEl(e.currentTarget);
        setActivePopper(type);
      }}
    >
      <Card
        style={{
          textAlign: "left",
          width: "100%",
          height: "100%",
          ...borderRadiusObj,
        }}
      >
        <CardDataDisplay />
      </Card>
    </ButtonBase>
  );
};

const InputBlock = ({
  type,
  title,
  cardData,
  activePopper,
  setActivePopper,
  popperAnchorEl,
  setPopperAnchorEl,
  totalWidth,
  position,
  inputLength,
  children,
  icon,
  disableInputCardResizing,
}) => (
  <>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={darkTheme}>
        {!icon && (
          <InputCardButton
            type={type}
            activePopper={activePopper}
            setActivePopper={setActivePopper}
            setPopperAnchorEl={setPopperAnchorEl}
            cardData={cardData}
            inputLength={inputLength}
            disableInputCardResizing={disableInputCardResizing}
          />
        )}
        <InputPopper
          type={type}
          title={title}
          activePopper={activePopper}
          setActivePopper={setActivePopper}
          popperAnchorEl={popperAnchorEl}
          setPopperAnchorEl={setPopperAnchorEl}
          width={totalWidth}
          position={position}
        >
          {children}
        </InputPopper>
      </ThemeProvider>
    </StyledEngineProvider>
  </>
);
export default InputBlock;
