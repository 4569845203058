import React from "react";

import { Box, Button, Grid } from "@mui/material";

import PurchaseDialogLayout from "../layouts/PurchaseDialogLayout";

const SlideLayoutCreateAccountCTA = ({
  createAccountLink,
  doesAclymateAccountAlreadyExist,
}) => (
  <PurchaseDialogLayout
    title="Create an account today"
    subtitle="Get started on your climate journey today- it costs nothing to sign up."
  >
    <Box p={2} position="relative">
      <Grid item container justifyContent="center" direction="column">
        <Button variant="contained" color="primary" href={createAccountLink}>
          {doesAclymateAccountAlreadyExist ? "Login" : "Get Started"}
        </Button>
      </Grid>
    </Box>
  </PurchaseDialogLayout>
);
export default SlideLayoutCreateAccountCTA;
