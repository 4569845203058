import React, { createContext, useState } from "react";
import { useParams } from "react-router";

import { faGlobe } from "@fortawesome/pro-duotone-svg-icons";

import { useAdminProjects } from "../components/projects";
import { offsetCategories } from "../components/display-lists/projects";

import { useAccountData } from "../firebase";

export const ProjectsContext = createContext();

export const ProjectsContextProvider = ({
  GlobalHeaderComponent,
  globalHeaderProps = {},
  displayPageUrl,
  onProjectClick,
  children,
}) => {
  const [{ referralPartner }] = useAccountData();
  const [projects, projectsLoading] = useAdminProjects();

  const [splitView, setSplitView] = useState("cards");

  const { view: selectedCategorySlug } = useParams();

  const categoriesWithProjects = offsetCategories
    .map(({ offsetTypes, ...otherCategoryProps }) => {
      const categoryProjects = projects.filter((project) =>
        offsetTypes.includes(project.typeSlug)
      );

      if (!categoryProjects.length) {
        return { ...otherCategoryProps };
      }

      const projectsSortedByQuantityAvailable = categoryProjects.sort(
        (a, b) => b.availableCarbonInKg - a.availableCarbonInKg
      );

      return {
        projects: projectsSortedByQuantityAvailable,
        ...otherCategoryProps,
      };
    })
    .filter(({ projects }) => !!projects?.length);

  const categoriesSortedByProjectCount = [...categoriesWithProjects].sort(
    (a, b) => b.projects?.length - a.projects?.length
  );

  const allProjectsDisplayProps = GlobalHeaderComponent
    ? {
        header: (
          <GlobalHeaderComponent projects={projects} {...globalHeaderProps} />
        ),
      }
    : {
        name: "All Offset Projects",
        subtitle: `Carbon offsets are your way to invest in meaningful, measurable projects that either pull greenhouse gases out of the atmosphere or prevent them from ending up there. Browse through our ${projects.length} projects to find the perfect one that your customers, employees and other stakeholders can be proud of. `,
      };

  const allProjectsFilterObject = {
    name: "All Offsets Projects",
    image: "offsets-splash",
    all: true,
    icon: faGlobe,
    ...allProjectsDisplayProps,
  };

  const displayCategories = [
    allProjectsFilterObject,
    ...categoriesSortedByProjectCount,
  ];

  const selectedCategory = selectedCategorySlug
    ? categoriesWithProjects.find(({ slug }) => slug === selectedCategorySlug)
    : allProjectsFilterObject;
  const { image } = selectedCategory || {};

  const filteredProjects =
    referralPartner === "climeco"
      ? projects.filter(({ offsetProvider }) => offsetProvider === "climeco")
      : projects;

  return (
    <ProjectsContext.Provider
      value={{
        displayPageUrl,
        onProjectClick,
        projects: filteredProjects,
        projectsLoading,
        splitView,
        setSplitView,
        displayCategories,
        selectedCategory: {
          ...selectedCategory,
          image: `project-categories/${image}`,
        },
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
