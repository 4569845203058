import React from "react";
// import dayjs from "dayjs";

import {
  Box,
  Grid,
  Typography,
  StyledEngineProvider,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import {
  useLayoutHelpers,
  mergeDarkTheme,
  mainTheme,
  isColorDarkTheme,
} from "@aclymatepackages/themes";

import SocialMediaIcons from "./SocialMediaIcons";

import TopBarSupportIcon from "../atoms/buttons/TopBarSupportIcon";

const Footer = () => {
  const { palette } = useTheme();

  const { isMobile, isMedium } = useLayoutHelpers();

  return (
    <Box style={{ backgroundColor: palette.backgroundGray.main }} p={2}>
      <Grid container spacing={isMobile ? 2 : 4} alignItems="center">
        <Grid item lg="auto" xs={12}>
          <Grid
            container
            spacing={1}
            direction={isMedium && !isMobile ? "row" : "column"}
            alignItems="center"
          >
            <Grid item sm={6} lg={12}>
              <img
                src="/images/aclymate-full-logo.png"
                alt="aclymate logo"
                style={{ width: "100%", maxWidth: "282px" }}
              />
            </Grid>
            <Grid item sm={6} lg={12} container justifyContent="center">
              <Grid item>
                <SocialMediaIcons />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={9} xs={12}>
          <Typography
            variant={isMobile ? "h6" : "h5"}
            color="textPrimary"
            align={isMobile ? "center" : "left"}
          >
            Colorado-based Aclymate has a vision of a world where everyone is
            educated about and empowered to act for humanity’s best climate
            future and has built the carbon accounting technology to make it
            happen.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const EventLayout = ({
  name,
  branding,
  locationName,
  startDate,
  endDate,
  imageUrl,
  eventLogoUrl,
  backdropOpacity = 0.3,
  backdropColor = "0, 0, 0",
  children,
  otherContent,
  attendee,
  companyName,
}) => {
  const { theme, isMobile } = useLayoutHelpers();
  const { name: userName, email } = attendee || {};

  const { logoUrl: companyLogoUrl, primaryColor } = branding || {};
  // const { name: eventLocationName, description: eventLocationDescription } =
  //   locationName || {};

  // const headerDisplayDate = `${dayjs(startDate).format("MMMM DD")}-${dayjs(
  //   endDate
  // ).format("MMMM DD")}`;

  const eventBackgroundImageProps = {
    backgroundImage: `url('${
      imageUrl || "/images/website/event-survey-default.jpg"
    }')`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
  };

  const logoUrl = eventLogoUrl || companyLogoUrl;

  const imageBoxProps = isMobile ? {} : { ...eventBackgroundImageProps };

  const outerDivProps = isMobile
    ? {
        ...eventBackgroundImageProps,
        height: "100vh",
        overflowY: "auto",
        overflowX: "hidden",
      }
    : { overflow: "hidden" };

  return (
    <div style={outerDivProps}>
      <Box
        style={{ height: isMobile ? "auto" : "100vh", overflow: "hidden" }}
        display="flex"
        flexDirection="column"
      >
        <Box
          flexGrow={0}
          p={isMobile ? 1 : 2}
          style={{
            backgroundColor:
              primaryColor || theme.palette["business-travel"].dark,
          }}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider
              theme={
                isColorDarkTheme(primaryColor) ? mergeDarkTheme : mainTheme
              }
            >
              <Grid
                container
                justifyContent="space-between"
                spacing={isMobile ? 1 : 2}
                alignItems="center"
              >
                <Grid item xs="auto">
                  {logoUrl ? (
                    <img
                      alt="event-organizer-logo"
                      src={logoUrl}
                      style={{ height: "50px" }}
                    />
                  ) : (
                    <Typography variant="h1" color="texPrimary">
                      {name}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs="auto">
                  <Grid container alignItems="center">
                    {/* <Grid item>
                      {isMobile && (
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          align="right"
                        >
                          {name}
                        </Typography>
                      )}
                      <Typography
                        variant={isMobile ? "h6" : "h2"}
                        color={isMobile ? "textSecondary" : "textPrimary"}
                        align="right"
                      >
                        {`${headerDisplayDate}${
                          isMobile
                            ? `, ${
                                eventLocationName || eventLocationDescription
                              }`
                            : ""
                        }`}
                      </Typography>
                      {!isMobile && (
                        <Typography
                          variant="subtitle2"
                          color="textSecondary"
                          align={"right"}
                        >
                          {eventLocationName}
                        </Typography>
                      )}
                    </Grid> */}
                    <Grid item>
                      <TopBarSupportIcon
                        companyName={companyName}
                        email={email}
                        userName={userName}
                        showHelpCenter={false}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ThemeProvider>
          </StyledEngineProvider>
        </Box>
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          style={{ overflow: "hidden auto" }}
        >
          <Box
            position="relative"
            style={{
              ...imageBoxProps,
              overflowY: "auto",
              overflowX: "hidden",
            }}
            flexGrow={1}
            display="flex"
            flexDirection="column"
          >
            <Box
              style={{
                backgroundColor: `rgba(${backdropColor}, ${backdropOpacity})`,
              }}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              flexGrow={1}
              p={1}
            >
              {children}
            </Box>
            {!otherContent && <Footer />}
          </Box>
        </Box>
      </Box>
      {otherContent}
      {otherContent && <Footer />}
    </div>
  );
};
export default EventLayout;
