import React, { useState } from "react";

import {
  addDataRow,
  editRowData,
} from "@aclymatepackages/array-immutability-helpers";

import MultiPartForm from "./MultipartForm";
import {
  useCategoriesSteps,
  useLocationSteps,
  useMechanismsSteps,
  usePricingSteps,
  useUnSdgsSteps,
  useVerificationStatusSteps,
  useSubRankingPreferencesStep,
  useRankingPreferencesSteps,
} from "../../hooks/offsetSurvey";

const OffsetSurvey = ({
  setShowSurvey,
  saveOffsetPreferences,
  accountData,
  dbDataLoading,
  confirmClose,
  companyOffices,
}) => {
  const [offsetPreferences, setOffsetPreferences] = useState([
    { factor: "location", rank: 0, locations: [] },
  ]);

  const onSaveOffsetPreferences = (preferences) => {
    setShowSurvey(false);
    return saveOffsetPreferences(preferences);
  };

  const onAdvanceForm = (newPreferenceObj = {}) =>
    addDataRow(setOffsetPreferences, newPreferenceObj);

  const formatOffsetPreferencesForRanking = () => {
    const preferencesRanks = offsetPreferences.map(({ rank }) => rank);

    if (preferencesRanks.every((rank) => !rank)) {
      return onSaveOffsetPreferences(offsetPreferences);
    }

    return setOffsetPreferences((currentOffsetPreferences) => {
      const selectedPreferences = currentOffsetPreferences.filter(
        ({ rank }) => rank
      );
      const unselectedPreferences = currentOffsetPreferences.filter(
        ({ rank }) => !rank
      );

      return [
        ...selectedPreferences.map((preference, idx) => ({
          ...preference,
          rank: idx + 1,
        })),
        ...unselectedPreferences,
      ];
    });
  };

  const findPreference = (factorName) =>
    offsetPreferences.find(({ factor }) => factor === factorName);

  const locationQuestion = useLocationSteps({
    preference: findPreference("location"),
    setPreference: editRowData(0, setOffsetPreferences),
    onAdvanceForm: () => onAdvanceForm({ factor: "category" }),
    accountData,
    companyOffices,
  });

  const categoriesQuestion = useCategoriesSteps({
    preference: findPreference("category"),
    setPreference: editRowData(1, setOffsetPreferences),
    onAdvanceForm: () => onAdvanceForm({ factor: "unSdgs" }),
  });

  const unSdgsQuestion = useUnSdgsSteps({
    setPreference: editRowData(2, setOffsetPreferences),
    onAdvanceForm: () => onAdvanceForm({ factor: "mechanism" }),
  });

  const mechanismQuestion = useMechanismsSteps({
    setPreference: editRowData(3, setOffsetPreferences),
    onAdvanceForm,
  });

  const verificationStatusQuestion = useVerificationStatusSteps({
    setPreference: editRowData(4, setOffsetPreferences),
    onAdvanceForm,
  });

  const pricingQuestion = usePricingSteps({
    preference: findPreference("pricing"),
    setPreference: editRowData(5, setOffsetPreferences),
    accountData,
    dbDataLoading,
    onAdvanceForm: () => formatOffsetPreferencesForRanking(),
  });

  const preferenceRankingStep = useRankingPreferencesSteps({
    offsetPreferences,
    setOffsetPreferences,
    onAdvanceForm: () => initializeSubRankingStep(),
  });

  const { subRankingStep, initializeSubRankingStep } =
    useSubRankingPreferencesStep({
      offsetPreferences,
      saveOffsetPreferences: onSaveOffsetPreferences,
    });

  const forms = [
    locationQuestion,
    categoriesQuestion,
    unSdgsQuestion,
    mechanismQuestion,
    verificationStatusQuestion,
    pricingQuestion,
    preferenceRankingStep,
    subRankingStep,
  ];

  return (
    <MultiPartForm
      type="offsetSurvey"
      forms={forms}
      onClose={() => setShowSurvey(false)}
      showProgress
      confirmClose={confirmClose}
    />
  );
};
export default OffsetSurvey;
