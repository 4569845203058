import React, { useContext } from "react";

import {
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Bar,
  BarChart,
  Tooltip,
} from "recharts";

import { Box } from "@mui/material";

import { formatDecimal } from "@aclymatepackages/formatters";
import { useChartWarningLabels } from "@aclymatepackages/modules";

import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";

const ViewBarChart = ({
  data,
  setSelectedObject,
  yLabel = "Average Monthly Emissions",
  tooltipComponent,
  barAreasArray,
  dataType,
}) => {
  const { displayUnitLabel } = useContext(PlatformLayoutContext);

  const { warningLabels, labelSetter } = useChartWarningLabels({
    data,
    warningField: "status",
    barSumField: "emissionsSumTons",
  });

  const handleBarClick = ({ activePayload }) => {
    if (dataType && dataType === "events") {
      return setSelectedObject(activePayload[0].payload.name);
    }

    return setSelectedObject(activePayload[0].payload.id);
  };

  return (
    <Box position="relative">
      <ResponsiveContainer width="100%" aspect={4}>
        <BarChart
          width={500}
          height={300}
          data={data}
          style={{ cursor: "pointer" }}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          onClick={(event) => handleBarClick(event)}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis
            width={70}
            name={yLabel}
            tickFormatter={(tick) =>
              `${formatDecimal(tick)} ${displayUnitLabel}`
            }
          />
          <Tooltip wrapperStyle={{ zIndex: 99 }} content={tooltipComponent} />
          {barAreasArray.map(({ name, dataKey, color }, idx) => (
            <Bar
              key={`view-chart-bar-${idx}`}
              name={name}
              dataKey={dataKey}
              fill={color}
              stackId="a"
              isAnimationActive={false}
            >
              {labelSetter}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
      {warningLabels}
    </Box>
  );
};
export default ViewBarChart;
