import React, { useState } from "react";

import { Grid, Button, TableRow, TableCell } from "@mui/material";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { ucFirstLetters } from "@aclymatepackages/formatters";

import ErrorBoundary from "../../atoms/ErrorBoundary";
import Card from "../../atoms/mui/Card";

import SortableTable, {
  makeColumnObj,
} from "../../modules/tables/SortableTable";
import AddVehiclesInputs from "../../inputs/vehicles/AddVehiclesInputs";
import { useSingleVehicleForm } from "../../hooks/vehicles";

import SettingsObjectDetailsSlider from "../dashboard/SettingsObjectDetailsSlider";

import {
  useCachedDisplayData,
  useCachedFirebaseCrud,
} from "../../../helpers/firebase";

const VehicleDetailsSlider = ({ selectedVehicle, setSelectedVehicle }) => {
  const { id, employee } = selectedVehicle;

  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const [displayVehicle, setDisplayVehicle] = useState(selectedVehicle);
  console.log("display vehicle: ", displayVehicle);

  const setDisplayVehicleHandler = (field, value) =>
    editObjectData(setDisplayVehicle, field, value);
  const onNameSave = (name) => updateCollectionDoc("vehicles", id, { name });

  const { content: vehicleDetailsContent, footer: vehicleDetailsFooter } =
    useSingleVehicleForm({
      vehicle: displayVehicle,
      employee,
      setVehicle: setDisplayVehicleHandler,
      setDisplayVehicle,
    });

  const views = [
    {
      label: "Details",
      value: "details",
      content: vehicleDetailsContent,
      footer: vehicleDetailsFooter,
    },
  ];

  return (
    <SettingsObjectDetailsSlider
      type="vehicles"
      settingsObject={selectedVehicle}
      views={views}
      setSelectedObject={setSelectedVehicle}
      onNameSave={onNameSave}
    />
  );
};

const VehicleTableRow = ({ setSelectedVehicle, vehicle }) => {
  const { name, make, model, year, fuelType } = vehicle;
  const cellsArray = [name, make, model, year, fuelType];

  return (
    <TableRow hover onClick={() => setSelectedVehicle(vehicle)}>
      {cellsArray.map((value, idx) => (
        <TableCell key={`vehicle-row-table-cell-${idx}`}>
          {ucFirstLetters(value)}
        </TableCell>
      ))}
    </TableRow>
  );
};

const Vehicles = () => {
  const [companyVehicles, vehiclesLoading] = useCachedDisplayData("vehicles");

  const [newVehiclesOpen, setNewVehiclesOpen] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(false);

  const tableColumns = [
    makeColumnObj("NAME", "name", true),
    makeColumnObj("MAKE", "make", true),
    makeColumnObj("MODEL", "model", true),
    makeColumnObj("YEAR", "year", true),
    makeColumnObj("FUEL TYPE", "fuelType", true),
  ];

  return (
    <ErrorBoundary>
      {!!newVehiclesOpen && <AddVehiclesInputs setOpen={setNewVehiclesOpen} />}
      {!!selectedVehicle && (
        <VehicleDetailsSlider
          selectedVehicle={selectedVehicle}
          setSelectedVehicle={setSelectedVehicle}
        />
      )}
      <Grid container>
        <Card
          cardType="layout"
          gridProps={{ sm: 12 }}
          title="Company Vehicles"
          subtitle={
            !companyVehicles.length
              ? "It looks like your company doesn't have any vehicles yet. Click the button to set one up."
              : "Here are the company vehicles that you can use to track your company's driving emissions"
          }
          icon={<DirectionsCarIcon />}
          isLoading={vehiclesLoading}
          action={
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => setNewVehiclesOpen(true)}
            >
              Add A Vehicle
            </Button>
          }
        >
          <SortableTable
            rows={companyVehicles}
            rowComponent={(vehicle) => (
              <VehicleTableRow
                vehicle={vehicle}
                setSelectedVehicle={setSelectedVehicle}
              />
            )}
            columns={tableColumns}
          />
        </Card>
      </Grid>
    </ErrorBoundary>
  );
};
export default Vehicles;
