import React from "react";

import { Grid } from "@mui/material";

import { TextField, Select } from "@aclymatepackages/atoms";

import { electricCarbonIntensityError } from "../../../helpers/components/offices";

const electricCarbonIntensityUnitsList = [
  { label: "Tons CO2 / KWh", value: "tons kwh" },
  { label: "Tons CO2 / MWh", value: "tons mwh" },
  { label: "Lbs CO2 / KWh", value: "lbs kwh" },
  { label: "Lbs CO2 / MWh", value: "lbs mwh" },
];

const ElectricIntensityAndUnitsInput = ({
  electricCarbonIntensity,
  editElectricCarbonIntensity,
  electricCarbonIntensityUnits,
  editElectricCarbonIntensityUnits,
  index = 0,
}) => {
  const onUnitsSelect = (value) =>
    editElectricCarbonIntensityUnits(
      electricCarbonIntensityUnitsList.find((unit) => unit.value === value)
    );

  return (
    <>
      <Grid item xs={8}>
        <TextField
          label="Carbon intensity value"
          value={electricCarbonIntensity}
          setValue={editElectricCarbonIntensity}
          error={electricCarbonIntensityError(electricCarbonIntensity)}
          helperText={
            electricCarbonIntensityError(electricCarbonIntensity)
              ? "You need to input a number for the electric utilities carbon intensity value and/or the intensity value needs to be less than 1,000,000"
              : ""
          }
          id={`NewOffice_${index}_ElectricCarbonIntensity`}
        />
      </Grid>
      <Grid item xs={4}>
        <Select
          size="small"
          options={electricCarbonIntensityUnitsList}
          label="Intensity Units"
          value={electricCarbonIntensityUnits?.value || ""}
          editValue={onUnitsSelect}
        />
      </Grid>
    </>
  );
};
export default ElectricIntensityAndUnitsInput;
