import React, { useState } from "react";

import { Grid, Chip, Menu, MenuItem, Button, useTheme } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faChevronCircleDown, faSmog } from "@fortawesome/pro-solid-svg-icons";
import { ucFirstLetters } from "@aclymatepackages/formatters";

const FilterChips = ({
  chipsArray,
  selectedChips,
  setSelectedChips,
  type = "",
}) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const buildChipColorProps = (isSelected, color) =>
    isSelected
      ? {
          backgroundColor: color,
          color: "white",
          border: ` solid thin ${color}`,
        }
      : { color, border: ` solid thin ${color}`, backgroundColor: "white" };

  const formattedChips = chipsArray.map(({ id, color, ...otherProps }) => {
    const style = buildChipColorProps(selectedChips.includes(id), color);

    return { ...otherProps, id, style };
  });

  const onChipClick = (id) => {
    if (id === "all") {
      return setSelectedChips(["all"]);
    }

    return setSelectedChips((currentSelection) => {
      const allCategoriesButAll = currentSelection.filter(
        (subcategory) => subcategory !== "all"
      );

      if (!currentSelection.includes(id)) {
        return [...allCategoriesButAll, id];
      }

      const remainingCategories = allCategoriesButAll.filter(
        (subcategory) => subcategory !== id
      );

      if (!remainingCategories.length) {
        return ["all"];
      }

      return remainingCategories;
    });
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const clearAll = () => {
    setSelectedChips(["all"]);
    return handleMenuClose();
  };

  const isAllEmissionChipSelected = selectedChips.includes("all");

  const allChipsSelected = selectedChips.length === formattedChips.length;

  return (
    <Grid item>
      <Grid container alignItems="center" spacing={2}>
        {!allChipsSelected && (
          <Grid item>
            <Chip
              icon={
                <FontAwesomeIcon icon={faSmog} style={{ color: "inherit" }} />
              }
              clickable
              style={buildChipColorProps(
                selectedChips.includes("all"),
                palette.primary.main
              )}
              label={`${isAllEmissionChipSelected ? "All" : "Filtered"} ${
                type ? `${ucFirstLetters(type)} ` : ""
              }Emissions`}
              deleteIcon={
                <FontAwesomeIcon
                  icon={faChevronCircleDown}
                  style={{
                    color: isAllEmissionChipSelected
                      ? "white"
                      : palette.primary.main,
                    fontSize: "1rem",
                  }}
                />
              }
              onClick={(event) => {
                return handleMenuOpen(event);
              }}
              onDelete={(event) => {
                return handleMenuOpen(event);
              }}
            />
          </Grid>
        )}
        {!isAllEmissionChipSelected &&
          selectedChips.map((selectedChip) => {
            const { name, id, style, icon } =
              formattedChips.find((chip) => chip.id === selectedChip) || {};

            return (
              <Grid item key={`selected-chip-${id}`}>
                <Chip
                  icon={icon}
                  clickable
                  style={style}
                  label={name}
                  onDelete={() => onChipClick(id)}
                  sx={{
                    "& .MuiChip-deleteIcon": {
                      color: "white",
                      "&:hover": {
                        color: "white",
                      },
                    },
                  }}
                />
              </Grid>
            );
          })}
        {!isAllEmissionChipSelected && selectedChips.length > 0 && (
          <Grid item>
            <Button
              onClick={clearAll}
              variant="outlined"
              color="primary"
              size="small"
              sx={{
                paddingY: 0.5,
                paddingX: 1,
              }}
            >
              Clear All
            </Button>
          </Grid>
        )}
      </Grid>

      {!allChipsSelected && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {formattedChips
            .filter((chip) => !selectedChips.includes(chip.id))
            .map(({ name, id, style, icon }, idx) => (
              <MenuItem key={`filter-chip-${idx}`}>
                <Chip
                  icon={icon}
                  clickable
                  style={style}
                  label={name}
                  onClick={() => onChipClick(id)}
                />
              </MenuItem>
            ))}
        </Menu>
      )}
    </Grid>
  );
};
export default FilterChips;
