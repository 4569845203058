import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LogRocket from "logrocket";
import { LoadScript } from "@react-google-maps/api";
import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import { getAuth } from "firebase/auth";

import ErrorBoundary from "./components/atoms/ErrorBoundary";

import Login from "./components/account-access/Login";
import ForgotPassword from "./components/account-access/ForgotPassword";
import AccountCreation from "./components/account-access/AccountCreation";
import Platform from "./components/platformIndex";
import EmployeeSurvey from "./components/inputs/flows/EmployeeSurvey";
import PasswordReset from "./components/account-access/PasswordReset";
import ReferralPartner from "./components/account-access/Partners";
import Estimates from "./components/platform-pages/purchase/Estimates";
import MyAclymatePage from "./components/myaclymate/MyAclymatePage";
import EventPrimaryPage from "./components/events/EventPrimaryPage";
import EmailVerification from "./components/account-access/EmailVerification";
import Onboarding from "./components/account-access/Onboarding";
import UserVerification from "./components/account-access/UserVerification";
import OnboardingOffsets from "./components/account-access/OnboardingOffsets";
import VendorSurvey from "./components/vendors/VendorSurvey";

const growthbook = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV === "development",
});

const App = () => {
  window.dataLayer.push({ event: "pageview" });

  useEffect(() => {
    growthbook.loadFeatures({ autoRefresh: true });
  }, []);

  useEffect(() => {
    const user = getAuth().currentUser;
    const { uid, email, displayName } = user || {};

    if (
      user &&
      !email.includes("automateduser") &&
      process.env.NODE_ENV === "production"
    ) {
      LogRocket.init("zow6au/aclymate", {
        release: process.env.REACT_APP_ENVIRONMENT,
      });
      LogRocket.identify(uid, {
        name: displayName,
        email,
      });
    }
  }, []);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API}>
        <ErrorBoundary>
          <Router>
            <Switch>
              <Route path="/offsets/:view?" component={OnboardingOffsets} />
              <Route path="/onboarding" component={Onboarding} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/reset-password/:uid" component={PasswordReset} />
              <Route path="/user-verification" component={UserVerification} />
              <Route
                path="/platform/:accountType/:page/:view?/:action?"
                component={Platform}
              />
              <Route
                path="/account-creation/:accountCreationType/:companyDocId?"
                component={AccountCreation}
              />
              <Route
                path="/partners/:partnerSlug"
                component={ReferralPartner}
              />
              <Route
                path="/employee-survey/:companyId/:surveyType/:employeeId?"
                component={EmployeeSurvey}
              />
              <Route path="/vendor-survey" component={VendorSurvey} />
              <Route
                path="/estimates/:companyId/:estimateId"
                component={Estimates}
              />
              <Route
                path="/myaclymate-survey/:refresh?"
                component={MyAclymatePage}
              />
              <Route
                path="/ev/:eventSlug/:surveyType/:refresh?"
                component={EventPrimaryPage}
              />
              <Route path="/verification" component={EmailVerification} />
              <Route path="/:accountType?/:accountId?" component={Login} />
            </Switch>
          </Router>
        </ErrorBoundary>
      </LoadScript>
    </GrowthBookProvider>
  );
};

export default App;
