import React from "react";

import { Select } from "@aclymatepackages/atoms";

const VehicleYearSelect = ({
  error,
  year,
  editVehicle,
  noLabel = false,
  id,
  smallFont,
  variant,
}) => (
  <Select
    id={id}
    size="small"
    label={noLabel ? "" : "Vehicle Year"}
    value={year || ""}
    editValue={editVehicle}
    error={error}
    options={[...new Array(30)]
      .map((_, idx) => Number(new Date().getFullYear()) - idx)
      .map((option) => ({ label: option, value: option }))}
    smallFont={smallFont}
    variant={variant}
  />
);
export default VehicleYearSelect;
