import React from "react";

import {
  faLightbulb,
  faRoad,
  faTree,
  faElephant,
  faWindTurbine,
  faFarm,
  faChimney,
  faSeedling,
  faHeat,
} from "@fortawesome/pro-duotone-svg-icons";

import { Avatar } from "@mui/material";

import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import LanguageIcon from "@mui/icons-material/Language";
import ExploreIcon from "@mui/icons-material/Explore";
import BuildIcon from "@mui/icons-material/Build";
import VerificationIcon from "@mui/icons-material/VerifiedUser";
import CategoryIcon from "@mui/icons-material/Category";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

export const projectIcons = [
  {
    icon: <MonetizationOnIcon />,
    tags: ["totalThousandLbsCost", "pricing"],
  },
  {
    icon: <LanguageIcon />,
    tags: ["location"],
  },
  {
    avatar: <Avatar>lbs</Avatar>,
    tags: ["availableCarbonLbs"],
  },
  {
    icon: <ExploreIcon />,
    tags: ["distanceMi"],
  },
  {
    icon: <BuildIcon />,
    tags: ["mechanism"],
  },
  {
    avatar: <Avatar>UN</Avatar>,
    icon: <AccountBalanceIcon />,
    tags: ["unSdgs"],
  },
  {
    icon: <VerificationIcon />,
    tags: ["verificationStatus"],
  },
  {
    icon: <CategoryIcon />,
    tags: ["category"],
  },
];

export const offsetTypeImages = [
  "emissions-capture",
  "energy-efficiency",
  "afforestation-reforestation",
  "marine-efficiency",
  "fossil-fuel-substitution",
  "conservation",
  "reducing-deforestation-emissions",
  "reducing-emissions-from-deforestation",
  "ozone-depleting-substances",
  "ocean-ecosystems",
  "urban-forestry",
  "mineralization",
  "direct-air-capture",
  "agricultural-soil",
];

export const unSdgs = [
  "No Poverty",
  "Zero Hunger",
  "Good Health and Well-being",
  "Quality Education",
  "Gender Equality",
  "Clean Water and Sanitation",
  "Affordable and Clean Energy",
  "Decent Work and Economic Growth",
  "Industry, Innovation and Infrastructure",
  "Reduced Inequalities",
  "Sustainable Cities and Communities",
  "Responsible Consumption and Production",
  "Climate Action",
  "Life Below Water",
  "Life on Land",
  "Peace Justice and Strong Institutions",
  "Partnerships for the Goals",
];

export const offsetCategories = [
  {
    image: "energy-efficiency-main",
    icon: faLightbulb,
    name: "Energy Efficiency",
    slug: "energy-efficiency",
    description:
      "Using less energy means less emissions. By introducing new tech or tweaking consumption patterns, we can reduce greenhouse gas emissions!",
    offsetTypes: [
      "energy-efficiency",
      "cookstoves",
      "improved-cookstoves",
      "truckstop-electrification",
    ],
  },
  {
    image: "forestry-main",
    icon: faTree,
    name: "Forestry",
    slug: "forestry",
    description:
      "Forestry represents over $500 billion dollar global market. Forests also regulate our climate and capture carbon. Avoiding emissions in this sector is critical for climate success.",
    offsetTypes: [
      "afforestation",
      "reforestation",
      "afforestation-reforestation",
      "reforestation-or-deforestation",
      "conservation-avoided-deforestation",
      "avoided-deforestation",
      "reducing-deforestation-emissions",
      "reducing-emissions-from-deforestation",
      "improved-forest-management",
      "urban-forestry",
      "blue-carbon",
    ],
  },
  {
    image: "conservation-main",
    icon: faElephant,
    name: "Biodiversity Conservation",
    slug: "biodiversity",
    description:
      "Nature is a powerful ally in the fight against climate change. These projects protect and restore critical ecosystems that sequester carbon.",
    offsetTypes: [
      "conservation",
      "afforestation-reforestation",
      "reforestation-or-afforestation",
      "afforestation",
      "reforestation",
      "blue-carbon",
      "kelp-farming",
      "avoided-conversion",
      "avcon",
      "sustainable-grazing",
      "conservation-avoided-deforestation",
      "avoided-deforestation",
      "reducing-deforestation-emissions",
      "reducing-emissions-from-deforestation",
      "ocean-ecosystems",
      "urban-forestry",
    ],
  },
  {
    image: "fossil-fuel-substitution-main",
    icon: faWindTurbine,
    name: "Fossil Fuel Substitution",
    slug: "fossil-fuel-substitution",
    description:
      "Switching from fossil fuels to renewable energy rapidly reduce emissions.",
    offsetTypes: [
      "fossil-fuel-substitution",
      "renewables",
      "wind",
      "electric",
      "hydroelectric",
    ],
  },
  {
    image: "agriculture-main",
    icon: faFarm,
    name: "Agriculture",
    slug: "agriculture",
    description:
      "Meeting food security needs and reducing emissions go hand in hand! These projects aim to protect soil and drive agricultural innovation.",
    offsetTypes: [
      "agricultural-soil",
      "regenerative-agriculture",
      "livestock-gas-capture",
      "sustainable-grazing",
      "biochar",
      "soil",
      "soil-amendments",
      "soil-amendment",
      "avoided-conversion",
      "avcon",
      "bio-oil",
    ],
  },
  {
    image: "construction-main",
    icon: faChimney,
    name: "Construction",
    slug: "construction",
    description:
      "The construction industry is responsible for over 38% of global CO2 emissions. These projects drive innovation in construction to reduce emissions and capture carbon.",
    offsetTypes: ["mineralization", "wooden-building-elements"],
  },
  {
    image: "biomass-main",
    icon: faSeedling,
    name: "Biomass",
    slug: "biomass",
    description:
      "Carbon from biomass is constantly circulating. These projects harness this superpower to avoid releasing old emissions from fossil fuels.",
    offsetTypes: [
      "biomass",
      "kelp-farming",
      "biochar",
      "bio-oil",
      "soil-amendment",
      "soil-amendments",
    ],
  },
  {
    image: "infrastructure-main",
    icon: faRoad,
    name: "Infrastructure",
    slug: "infrastructure",
    description:
      "Adapting to climate change requires efficient shipping and resilient buildings. These projects reduce the footprint of these high emitting sectors.",
    offsetTypes: [
      "marine-efficiency",
      "truckstop-electrification",
      "biomass-burial",
    ],
  },
  {
    image: "emissions-capture-main",
    icon: faHeat,
    name: "Emissions Capture",
    slug: "emissions-capture",
    description:
      "Carbon Dioxide isn't the only harmful greenhouse gas. These target methane emissions from livestock, landfill and mining operations.",
    offsetTypes: [
      "landfill-gas-capture",
      "emissions-capture",
      "livestock-gas-capture",
      "industrial-emissions-capture",
    ],
  },
];
