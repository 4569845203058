import React, { useState } from "react";
import { useCookies } from "react-cookie";

import { Grid, Typography } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faTruck,
  faPlane,
  faBus,
  faCar,
} from "@fortawesome/free-solid-svg-icons";

import { PlusMinusNumericInput } from "@aclymatepackages/atoms";
import {
  useSharedFormLoading,
  MultiPartFormLayout,
} from "@aclymatepackages/multi-part-form";
import { ucFirstLetters } from "@aclymatepackages/formatters";
import {
  editObjectData,
  mergeObjectData,
} from "@aclymatepackages/array-immutability-helpers";
import { useLayoutHelpers, mainTheme } from "@aclymatepackages/themes";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";

import PlacesAutocomplete from "../inputs/autocomplete/PlacesAutocomplete";
import ButtonCards from "../atoms/buttons/ButtonCards";

import {
  generateFlyingTravelQuestions,
  editGroupAddress,
  calcTonsCo2eForVehicleType,
  calcFlyingEmissions,
} from "../../helpers/components/events";
import { fetchOurApi } from "../../helpers/utils/apiCalls";

const VehiclesTypesCountsInput = ({
  vehiclesTypesCounts = {},
  editVehiclesTypesCounts,
}) => {
  const vehicleTypes = [
    { type: "cars", icon: faCar },
    { type: "trucks", icon: faTruck },
    { type: "buses", icon: faBus },
  ];

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6" align="center" color="textPrimary">
          Tell us how many of each type of vehicle you'll be driving to the
          event.
        </Typography>
      </Grid>
      <Grid item container justifyContent="space-around" spacing={2}>
        {vehicleTypes.map(({ type, icon }, idx) => (
          <Grid item key={`vehicle-numeric-input-${idx}`}>
            <Grid container direction="column" spacing={1} alignItems="center">
              <Grid item>
                <FontAwesomeIcon icon={icon} size="3x" />
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  align="center"
                  color="textPrimary"
                >
                  {ucFirstLetters(type)}
                </Typography>
              </Grid>
              <Grid item>
                <PlusMinusNumericInput
                  value={vehiclesTypesCounts[type] || 0}
                  editValue={editVehiclesTypesCounts(type)}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

const buildTravelGroupForm = ({
  travelGroup = {},
  editTravelGroup,
  form,
  eventData,
  direction,
}) => {
  const { mainTransportationMethod, address, vehiclesTypesCounts } =
    travelGroup;
  const { description } = address || {};

  const isTravelingTo = direction === "to";

  const editVehiclesTypesCounts = (vehicleType) => (value) =>
    editTravelGroup("vehiclesTypesCounts")({
      ...vehiclesTypesCounts,
      [vehicleType]: value,
    });

  const transportationQuestions =
    mainTransportationMethod === "driving"
      ? [
          {
            input: (
              <VehiclesTypesCountsInput
                vehiclesTypesCounts={vehiclesTypesCounts}
                editVehiclesTypesCounts={editVehiclesTypesCounts}
              />
            ),
            value: true,
          },
        ]
      : generateFlyingTravelQuestions(
          eventData,
          travelGroup,
          editTravelGroup,
          direction
        );

  return [
    {
      NextStepInput: PlacesAutocomplete,
      nextStepInputProps: {
        variant: "standard",
        label: `Where is this group ${
          isTravelingTo ? "starting from" : "traveling to"
        }?`,
        size: "small",
        place: description || "",
        onInputSelect: editGroupAddress(editTravelGroup, eventData),
        inputSelectPropName: "editPlace",
      },
      value: description,
    },
    {
      NextStepInput: ButtonCards,
      nextStepInputProps: {
        question: `How is this group ${
          isTravelingTo ? "getting to" : "leaving"
        } the event?`,
        selectedOption: mainTransportationMethod || "",
        onInputSelect: editTravelGroup("mainTransportationMethod"),
        inputSelectPropName: "setOption",
        options: [
          {
            title: "Flying",
            value: "flying",
            fontAwesomeIcon: faPlane,
          },
          {
            title: "Driving",
            value: "driving",
            fontAwesomeIcon: faCar,
          },
        ],
      },
      value: mainTransportationMethod,
    },
    ...transportationQuestions,
  ].map((question) => ({ ...question, form }));
};

const PartnerSurvey = ({ eventData, setServerData }) => {
  const { name: eventName, id: eventId, branding } = eventData;
  const { secondaryColor } = branding || {};

  const { theme } = useLayoutHelpers();
  const [, setCookie] = useCookies();
  const { setFormLoading } = useSharedFormLoading();

  const [travelGroup, setTravelGroup] = useState({});
  const {
    name,
    travelToGroups = [],
    travelToGroupsCount,
    travelFromGroupsCount,
    travelFromGroups = [],
  } = travelGroup;

  const editTravel = (field) => (value) =>
    editObjectData(setTravelGroup, field, value);

  const editTravelGroups = (group, idx) => (field) => (value) =>
    setTravelGroup((currentTravelGroup) => {
      const travelGroups = currentTravelGroup[group] || [{}];
      const updatedGroup = { ...travelGroups[idx], [field]: value };

      const updatedTravelGroups = [...new Array(idx + 1)].map((_, groupIdx) =>
        groupIdx === idx ? updatedGroup : travelGroups[groupIdx]
      );

      return { ...currentTravelGroup, [group]: updatedTravelGroups };
    });

  const buildGroupFormsQuestions = (direction) => {
    const field = `travel${ucFirstLetters(direction)}Groups`;
    const count = travelGroup[`${field}Count`];
    const buildForm = (idx) => `${field}-form-${idx}`;

    if (!count || typeof count !== "number") {
      return { forms: [], questions: [] };
    }

    const emptyCountArray = [...new Array(Number(count || 1))];
    const travelDirectionGroup = travelGroup[field] || [{}];
    const questions = emptyCountArray
      .map((_, idx) =>
        buildTravelGroupForm({
          travelGroup: travelDirectionGroup[idx],
          editTravelGroup: editTravelGroups(field, idx),
          form: buildForm(idx),
          eventData,
          direction,
        })
      )
      .flat();

    const formAction = direction === "to" ? "traveling to" : "leaving";
    const forms = emptyCountArray.map((_, idx) => ({
      name: buildForm(idx),
      label: `Group ${idx + 1} ${formAction} ${eventName}`,
      title: `Tell us how group ${idx + 1} is ${formAction} ${eventName}`,
    }));

    return { forms, questions };
  };

  const { questions: travelToGroupsQuestions, forms: travelToGroupsForms } =
    buildGroupFormsQuestions("to");
  const { questions: travelFromGroupsQuestions, forms: travelFromGroupsForms } =
    buildGroupFormsQuestions("from");

  const onFormSubmit = async () => {
    const calcTravelGroupEmissions = async (travelGroup) => {
      const { mainTransportationMethod, mileageDistance, vehiclesTypesCounts } =
        travelGroup;

      if (mainTransportationMethod === "flying") {
        return await calcFlyingEmissions(travelGroup, eventData);
      }

      const TRUCK_CARBON_TONS_PER_MILE = 0.0004262787;
      const BUS_CARBON_TONS_PER_MILE = 0.0003;

      const { cars = 0, trucks = 0, buses = 0 } = vehiclesTypesCounts;
      const carsEmissionsTons =
        calcTonsCo2eForVehicleType("full-size-van", mileageDistance) *
        Number(cars);
      const trucksEmissionsTons =
        TRUCK_CARBON_TONS_PER_MILE * Number(trucks) * mileageDistance;
      const busEmissionsTons =
        BUS_CARBON_TONS_PER_MILE * Number(buses) * mileageDistance;

      return {
        cars,
        trucks,
        buses,
        carsEmissionsTons,
        trucksEmissionsTons,
        busEmissionsTons,
        mileageDistance,
        totalTonsCo2e:
          carsEmissionsTons + trucksEmissionsTons + busEmissionsTons,
      };
    };

    const travelToEmissions = await Promise.all(
      travelToGroups.map(async (group) => await calcTravelGroupEmissions(group))
    );
    const travelFromEmissions = await Promise.all(
      travelFromGroups.map(async (group) => calcTravelGroupEmissions(group))
    );
    const travelFromEmissionsTons = travelFromEmissions.reduce(
      (sum, { totalTonsCo2e }) => totalTonsCo2e + sum,
      0
    );
    const travelToEmissionsTons = travelToEmissions.reduce(
      (sum, { totalTonsCo2e }) => totalTonsCo2e + sum,
      0
    );

    const surveyData = {
      eventId,
      name,
      travel: {
        travelToEmissions,
        travelToEmissionsTons,
        travelFromEmissions,
        travelFromEmissionsTons,
        totalTonsCo2e: travelFromEmissionsTons + travelToEmissionsTons,
      },
      totalEventTonsCo2e: travelFromEmissionsTons + travelToEmissionsTons,
      confirmed: true,
    };

    const { partnerId, stripeCustomerId, slug } = await fetchOurApi({
      path: "/events/partners",
      data: surveyData,
      method: "POST",
      callback: (res) => res,
    });

    if (slug) {
      setCookie("aclymate-event-partner", slug);
    }

    mergeObjectData(setServerData, {
      attendee: { id: partnerId, stripeCustomerId, ...name },
      survey: surveyData,
    });
    return setFormLoading(false);
  };

  const rows = [
    {
      label: "Please enter your organization's name",
      value: name,
      editData: editTravel("name"),
      form: "selection",
    },
    {
      label: "How many groups are coming to the event together?",
      value: travelToGroupsCount,
      editData: editTravel("travelToGroupsCount"),
      error: travelToGroupsCount && !numbersRegExpTest(travelToGroupsCount),
      form: "travel-to",
    },
    ...travelToGroupsQuestions,
    {
      label: "How many groups will be leaving the event together?",
      value: travelFromGroupsCount,
      editData: editTravel("travelFromGroupsCount"),
      error: travelFromGroupsCount && !numbersRegExpTest(travelFromGroupsCount),
      form: "travel-from",
    },
    ...travelFromGroupsQuestions,
  ];

  const forms = [
    {
      name: "selection",
      label: "Enter your name",
      title: "Please tell us who you are",
    },
    {
      name: "travel-to",
      label: "How many groups are traveling to the event?",
      title:
        "Tell us about if you're breaking up into groups to get to the event. A group is defined as people traveling from the same location using the same method of transportation.",
    },
    ...travelToGroupsForms,
    {
      name: "travel-from",
      label: "How many groups are leaving the event?",
      title:
        "Tell us about if you're breaking up into groups to travel to your next destination. A group is defined as people traveling from the same location using the same method of transportation.",
    },
    ...travelFromGroupsForms,
  ];

  return (
    <MultiPartFormLayout
      color={secondaryColor || theme.palette.secondary.main}
      title={
        "We are committed to offsetting 100% of the emissions created by our events which includes artist, vendor, and/or sponsor travel to and from our events."
      }
      subtitle={
        "Please provide the following information for your entire team coming to the event in your estimate. This information will be used to purchase carbon credits to help neutralize the impact of our event. Thank you for being a part of the event and helping us with our mission!"
      }
      onFormSubmit={onFormSubmit}
      forms={forms}
      rows={rows}
      submitLoadingText="Please wait..."
      formSubmitText="See My Results"
      theme={mainTheme}
    />
  );
};
export default PartnerSurvey;
