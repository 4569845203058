import React, { useState, useEffect } from "react";

import { Typography, useTheme } from "@mui/material";

import { utilitiesProviders } from "@aclymatepackages/lists";

import { fetchOurApi } from "../utils/apiCalls";
import {
  useCachedFirebaseCrud,
  useCollectionDataListener,
  useAccountData,
} from "../firebase";
import TitleHelperIcon from "../../components/atoms/icons/TitleHelperIcon";

export const serviceClassDescription = (serviceClass) => {
  const serviceClassDescriptions = {
    res_electric: "Residential electric",
    comm_electric: "Commercial electric",
    electric: "Electric",
    res_gas: "Residential gas",
    comm_gas: "Commercial gas",
    gas: "Gas",
    res_water: "Residential water",
    comm_water: "Commercial water",
    water: "Water",
    other: "Other",
    unknown: "Unknown",
  };

  return serviceClassDescriptions[serviceClass.replace("-", "_")];
};

export const useCreateMeterTasks = () => {
  const { newCollectionDoc } = useCachedFirebaseCrud();

  const createMeterTasks = async (metersList) =>
    await Promise.all(
      metersList.map(
        async ({ meterId, pairedOffice: { name: officeName }, serviceClass }) =>
          await newCollectionDoc("tasks", {
            status: "running",
            type: "utilitiesApi",
            currentChunk: 0,
            totalChunks: 3,
            taskData: {
              meterId,
              officeName,
              serviceClass: serviceClassDescription(serviceClass),
            },
            startTime: new Date(),
          })
      )
    );

  return createMeterTasks;
};

export const useUtilityAuthStep = ({ utilityId }) => {
  const accountId = window.sessionStorage.getItem("accountId");

  const { palette } = useTheme();
  const { newCollectionDoc, updateAccountData } = useCachedFirebaseCrud();
  const [{ utilityApiAuthorizationIds = [] }] = useAccountData();
  const [[utilityAuthToken = {}]] = useCollectionDataListener("integrations", [
    ["utilityId", "==", utilityId || "UNKNOWN"],
  ]);

  const [isAuthSuccessful, setIsAuthSuccessful] = useState();

  const { authorizationId } = utilityAuthToken;

  useEffect(() => {
    const handleRedirectMsgEvent = async ({ data = {} }) => {
      if (data.referral) {
        const { authorizationId, pullDate, utilityId, expirationDate } =
          await fetchOurApi({
            accountId,
            path: "/integrations/utilityApi/fetch-authorization-details",
            method: "POST",
            data: { referralId: data.referral },
            callback: (res) => res,
          });

        const newUtilitiesIntegrationObj = {
          pullDate: new Date(pullDate),
          name: utilitiesProviders.find(
            ({ utilityId: providerId }) => providerId === utilityId
          ).name,
          utilityId,
          expirationDate: new Date(expirationDate),
          authorizationId,
          type: "utilitiesApi",
        };

        setIsAuthSuccessful(true);
        newCollectionDoc("integrations", newUtilitiesIntegrationObj);
        return updateAccountData({
          utilityApiAuthorizationIds: [
            ...utilityApiAuthorizationIds,
            authorizationId,
          ],
        });
      }

      return;
    };

    window.addEventListener("message", handleRedirectMsgEvent);

    return () => window.removeEventListener("message", handleRedirectMsgEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildAuthForm = () => {
    const sharedFormProps = {
      label: "Authorize Utility Access",
      title: "Authorize Utility Access",
      subtitle:
        "Please fill out the form below to grant Aclymate access to automatically pull in emissions data from your utility provider.",
      titleIcon: (
        <TitleHelperIcon
          tooltipText="Not your utility provider? Click to learn how you can add your utilities."
          href=""
        />
      ),
      buttonDisabled: !authorizationId,
    };

    if (!authorizationId && !isAuthSuccessful) {
      return [
        {
          ...sharedFormProps,
          input: (
            <iframe
              title="utility authorization"
              src={`https://utilityapi.com/authorize/aclymate?utility=${encodeURIComponent(
                utilityId
              )}`}
              width="100%"
              height="700px"
            />
          ),
        },
      ];
    }

    if (authorizationId && isAuthSuccessful) {
      return [
        {
          ...sharedFormProps,
          input: (
            <Typography
              variant="body2"
              style={{
                color: palette.secondary.main,
              }}
              align="center"
            >
              We have successfully connected to your utility provider.
            </Typography>
          ),
        },
      ];
    }

    return [];
  };

  return buildAuthForm();
};

export const useFetchMeters = ({ utilityId }) => {
  const accountId = window.sessionStorage.getItem("accountId");
  const [[utilityAuthToken = {}]] = useCollectionDataListener("integrations", [
    ["utilityId", "==", utilityId || "UNKNOWN"],
  ]);

  const [metersList, setMetersList] = useState();
  const [metersListLoading, setMetersListLoading] = useState(true);

  const { authorizationId } = utilityAuthToken;

  useEffect(() => {
    if (!metersList && authorizationId) {
      fetchOurApi({
        accountId,
        path: "/integrations/utilityApi/fetch-meters",
        method: "POST",
        data: { authorizationId },
        callback: ({ meters, expires, authorizationUid }) => {
          const formattedMeters = meters.map(
            (
              {
                base: { service_address, service_class },
                utility,
                uid,
                created,
              },
              idx
            ) => ({
              meterName: `Meter #${idx + 1}`,
              meterIdx: idx,
              meterId: uid,
              serviceAddress: service_address,
              serviceClass: service_class,
              utilityId: utility,
              expirationDate: new Date(expires),
              pullDate: new Date(created),
              authorizationUid,
            })
          );

          setMetersList(formattedMeters);
          setMetersListLoading(false);
        },
      });
    }
  }, [accountId, metersList, utilityId, authorizationId]);

  return [metersList, metersListLoading];
};
