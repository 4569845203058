import React from "react";

import {
  Grid,
  InputAdornment,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

import { TextField } from "@aclymatepackages/atoms";
import { mainTheme } from "@aclymatepackages/themes";

const FormInputRow = ({
  input,
  label,
  value,
  error,
  editData,
  isActiveRow,
  helperText,
}) => {
  return (
    <Grid
      item
      container
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item xs={12}>
        {input ||
          (label && (
            <TextField
              variant="standard"
              label={label}
              value={value}
              setValue={editData}
              error={!isActiveRow && !!error}
              helperText={helperText}
              InputProps={{
                endAdornment: !isActiveRow && (
                  <InputAdornment position="end">
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={mainTheme}>
                        {!error && value ? (
                          <DoneIcon
                            style={{ fontSize: "2rem" }}
                            color="secondary"
                          />
                        ) : (
                          <CloseIcon
                            style={{ fontSize: "2rem" }}
                            color="error"
                          />
                        )}
                      </ThemeProvider>
                    </StyledEngineProvider>
                  </InputAdornment>
                ),
              }}
            />
          ))}
      </Grid>
    </Grid>
  );
};
export default FormInputRow;
