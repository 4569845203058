import React from "react";

import { useTheme } from "@mui/material";

import { DefaultPaper } from "@aclymatepackages/atoms";

const GrayPaper = ({ shade = "main", style = {}, children }) => {
  const { palette } = useTheme();
  return (
    <DefaultPaper
      p={2}
      style={{ backgroundColor: palette.backgroundGray[shade], ...style }}
      elevation={0}
    >
      {children}
    </DefaultPaper>
  );
};
export default GrayPaper;
