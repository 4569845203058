import React from "react";

import { Select } from "@aclymatepackages/atoms";

import { scopeThreeSpendBasedCategories } from "../../../helpers/components/vendors";

const ScopeThreeCategorySelect = ({
  scopeThreeCategory = "",
  setScopeThreeCategory,
  ...otherProps
}) => (
  <Select
    label="Select Scope 3 Category"
    options={scopeThreeSpendBasedCategories}
    value={scopeThreeCategory}
    editValue={setScopeThreeCategory}
    size="small"
    {...otherProps}
    style={{ minWidth: "200px" }}
  />
);
export default ScopeThreeCategorySelect;
