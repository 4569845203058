import React from "react";

import { PdlCompanySelect as PdlCompanySelectModule } from "@aclymatepackages/modules";
import { companyMatchingFunction } from "../../helpers/utils/apiCalls";

const PdlCompanySelect = (props) => {
  return (
    <PdlCompanySelectModule
      matchingFunction={companyMatchingFunction}
      {...props}
    />
  );
};
export default PdlCompanySelect;
