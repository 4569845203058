import React, { useState, useContext } from "react";

import {
  Typography,
  Button,
  Tooltip,
  TableRow,
  TableCell,
  Grid,
  Avatar,
  Chip,
  useTheme,
} from "@mui/material";

import { faFire, faBuilding } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  letterSBoolean,
  formatDecimal,
  formatDate,
  ucFirstLetters,
} from "@aclymatepackages/formatters";
import { nameToInitials } from "@aclymatepackages/converters";
import { utilitiesProviders } from "@aclymatepackages/lists";
import { subcategories } from "@aclymatepackages/subcategories";

import OfficesGraph from "./OfficesGraph";
import OfficeDetailsSlider from "./OfficeDetailsSlider";
import EditRowTransactions from "../../EditRowTransactions";

import ErrorBoundary from "../../../../atoms/ErrorBoundary";
import AvatarGroup from "../../../../atoms/mui/AvatarGroup";
import SortableTable, {
  makeColumnObj,
} from "../../../../modules/tables/SortableTable";

import useStatusFilterChips from "../../../../hooks/statusFilterChips";

import AddOfficesForm from "../../../../inputs/offices/AddOfficesForm";
import LinkUtilitiesForm from "../../../../inputs/offices/LinkUtilitiesForm";

import DashboardViewLayout from "../../../../layouts/DashboardViewLayout";
import { MissingOffices } from "../../../../layouts/missingSettingsLayouts";

import {
  useOfficeStatuses,
  useOfficesData,
} from "../../../../../helpers/components/offices";

import { useAccountData } from "../../../../../helpers/firebase";
import { useCompletedOnboardingSteps } from "../../../../../helpers/hooks/companyData";
import { PlatformLayoutContext } from "../../../../../helpers/contexts/platformLayout";

const LinkedUtilityChip = ({ utilityId, icon, type }) => {
  const { palette } = useTheme();

  const chipColor = palette[type]?.main;
  const findUtilityName = (id) =>
    utilitiesProviders.find(({ utilityId }) => utilityId === id)?.name;

  const tooltipTitleType = () => {
    if (type === "electricity") {
      return "Electric utilities";
    }

    if (type === "gas") {
      return "Gas utilities";
    }

    return "Utilities";
  };

  return (
    <Grid item>
      <Tooltip
        title={`${tooltipTitleType()} are integrated with ${findUtilityName(
          utilityId
        )}`}
      >
        <span>
          <Chip
            icon={
              <Avatar
                style={{
                  fontSize: "1rem",
                  height: "18px",
                  width: "18px",
                  backgroundColor: chipColor,
                  color: "white",
                }}
              >
                {icon}
              </Avatar>
            }
            label={findUtilityName(utilityId)}
            style={{
              color: "white",
              backgroundColor: chipColor,
            }}
          />
        </span>
      </Tooltip>
    </Grid>
  );
};

const OfficeRow = ({
  endDate,
  name,
  address,
  sqFootage,
  currentCommuteEmployees,
  facilityType,
  status,
  totalMonthlyCarbonVolume,
  currentUtilities,
  linkedCombinedUtilityId,
  linkedElectricUtilityId,
  linkedGasUtilityId,
  onClick,
}) => {
  const {
    utilitiesInLease,
    combinedUtility,
    gasUtility,
    electricUtility,
    doesOfficeHaveGasUtility,
  } = currentUtilities || {};

  const { palette } = useTheme();
  const officeStatuses = useOfficeStatuses(endDate);
  const { icon, tooltip, color } = officeStatuses[status] || {};
  const { icon: electricIcon } = subcategories.find(
    ({ subcategory }) => subcategory === "electricity"
  );
  const { icon: gasIcon } = subcategories.find(
    ({ subcategory }) => subcategory === "gas"
  );
  const { icon: combinedIcon } = subcategories.find(
    ({ subcategory }) => subcategory === "utilities"
  );

  const employeeInitials = currentCommuteEmployees.map(({ name }) =>
    nameToInitials(name)
  );

  const displayUtilities = () => {
    if (utilitiesInLease) {
      return "Utilities in Lease";
    }

    if (combinedUtility) {
      return (
        <>
          {linkedCombinedUtilityId ? (
            <LinkedUtilityChip
              utilityId={linkedCombinedUtilityId}
              icon={combinedIcon}
              type="utilities"
            />
          ) : (
            ucFirstLetters(combinedUtility)
          )}
        </>
      );
    }

    const displayGasUtility = () => {
      if (doesOfficeHaveGasUtility === false) {
        return <></>;
      }

      if (linkedGasUtilityId) {
        return (
          <Grid item>
            <LinkedUtilityChip
              utilityId={linkedGasUtilityId}
              icon={gasIcon}
              type="gas"
            />
          </Grid>
        );
      }

      return (
        <Grid item>
          <strong>Gas: </strong> {gasUtility}
        </Grid>
      );
    };

    const displayElectricUtility = () => {
      if (linkedElectricUtilityId) {
        return (
          <Grid item>
            <LinkedUtilityChip
              utilityId={linkedElectricUtilityId}
              icon={electricIcon}
              type="electricity"
            />
          </Grid>
        );
      }

      if (doesOfficeHaveGasUtility === false) {
        return <Grid item>{`${electricUtility} (Electric Only)`}</Grid>;
      }

      return (
        <Grid item>
          <strong>Electric: </strong> {electricUtility}
        </Grid>
      );
    };

    return (
      <Grid container direction="column" spacing={1}>
        {displayGasUtility()}
        {displayElectricUtility()}
      </Grid>
    );
  };

  const rowDetailCells =
    status !== "closed" ? (
      <>
        <TableCell>
          <AvatarGroup
            max={3}
            members={employeeInitials}
            avatarColor={palette.employees.main}
          />
        </TableCell>
        <TableCell>
          {totalMonthlyCarbonVolume
            ? formatDecimal(totalMonthlyCarbonVolume)
            : "No emissions in last month"}
        </TableCell>
        <TableCell>{displayUtilities()}</TableCell>
      </>
    ) : (
      <TableCell colSpan={3}>
        <Typography
          variant="subtitle2"
          align="center"
          color="textSecondary"
        >{`This office is closed and hasn't contributed to your footprint since ${formatDate(
          endDate
        )}`}</Typography>
      </TableCell>
    );

  return (
    <TableRow hover onClick={onClick} style={{ cursor: "pointer" }}>
      <TableCell>
        <Tooltip title={tooltip}>
          <div>
            <FontAwesomeIcon icon={icon} size="2x" style={{ color }} />
          </div>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Typography variant="inherit" display="block">
          {name}
        </Typography>
        <Typography variant="caption" color="textSecondary" display="block">
          {address?.description || ""}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="inherit" display="block">{`${formatDecimal(
          sqFootage
        )} Sq. Ft.`}</Typography>
        <Typography variant="caption" color="textSecondary" display="block">
          {facilityType.label}
        </Typography>
      </TableCell>
      {rowDetailCells}
    </TableRow>
  );
};

const OfficesViewBlock = ({ transactions, viewLoading }) => {
  const { displayUnitLabel } = useContext(PlatformLayoutContext);

  const [{ officesComplete }] = useCompletedOnboardingSteps();
  const [{ isRemote }] = useAccountData();
  const {
    offices,
    selectedUtilityProviderFromBanner,
    setSelectedUtilityProviderFromBanner,
    officeAlerts,
  } = useOfficesData({ transactions });
  const officeStatuses = useOfficeStatuses();

  const { filterChips, filterByChips } = useStatusFilterChips(
    offices,
    officeStatuses
  );

  const [addOffices, setAddOffices] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [selectedUtilities, setSelectedUtilities] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);

  const buildSubtitle = () => {
    if (isRemote) {
      return "";
    }

    const openOffices = offices.filter(({ status }) => status !== "closed");

    const totalOfficeEmissions = openOffices.reduce(
      (sum, { totalMonthlyCarbonVolume }) =>
        (isNaN(totalMonthlyCarbonVolume) ? 0 : totalMonthlyCarbonVolume) + sum,
      0
    );

    return `You currently have ${openOffices.length} office${letterSBoolean(
      openOffices
    )} that emitted ${formatDecimal(
      totalOfficeEmissions
    )} ${displayUnitLabel} of CO2 last month.`;
  };

  const companyOfficesColumns = [
    makeColumnObj("STATUS", "status", true),
    makeColumnObj("NAME/ADDRESS", "name", true),
    makeColumnObj("OFFICE DETAILS", "sqFootage", true),
    makeColumnObj("COMMUTING EMPLOYEES"),
    makeColumnObj(
      `${displayUnitLabel.toUpperCase()} CO2 LAST MONTH`,
      "totalMonthlyCarbonVolume",
      true
    ),
    makeColumnObj("UTILITIES"),
  ];

  const findUtilityTypes = () => {
    const officesWithUtilsInLease = offices.filter(
      ({ utilities }) => utilities.utilitiesInLease
    );

    const officesWithCombinedUtils = offices.filter(
      ({ utilities }) => !!utilities.combinedUtility
    );

    const officesWithSeparateUtils = offices.filter(
      ({ utilities }) => utilities.gasUtility && utilities.electricUtility
    );

    const typesOfUtilities = [
      { type: officesWithUtilsInLease, caption: "Included in lease" },
      { type: officesWithCombinedUtils, caption: "Combined" },
      { type: officesWithSeparateUtils, caption: "Separate" },
    ];

    const utilitiesOptions = typesOfUtilities.filter(
      ({ type }) => type.length > 0
    );

    return utilitiesOptions.map((option) => ({
      name: option.caption,
    }));
  };

  const findFacilityTypes = () => {
    const officesWithFacilityTypes = offices.filter(
      ({ facilityType }) => facilityType
    );

    const facilitiesFromEntries = officesWithFacilityTypes.map(
      ({ facilityType }) => facilityType?.label
    );

    const facilitiesNoDups = !facilitiesFromEntries.length
      ? []
      : facilitiesFromEntries.filter(
          (value, index, displayOffices) =>
            displayOffices.indexOf(value) === index
        );

    return facilitiesNoDups.map((facility) => {
      return { name: ucFirstLetters(String(facility)) };
    });
  };

  const typeFilterFunction = (office) => {
    if (!selectedFacilities.length) {
      return true;
    }

    const lowerCaseFacilities = selectedFacilities.map(({ name }) =>
      name.toLowerCase()
    );

    return lowerCaseFacilities.includes(
      (office.facilityType?.label).toLowerCase()
    );
  };

  const applyFilters = () => {
    const filterByUtilities = ({ utilities }) => {
      if (!selectedUtilities.length) {
        return true;
      }

      const selectedUtilitiesNames = selectedUtilities.map(({ name }) => name);

      if (selectedUtilitiesNames.includes("Included in lease")) {
        return utilities.utilitiesInLease;
      }

      if (selectedUtilities.includes("Combined")) {
        return !!utilities.combinedUtility;
      }

      return utilities.electricUtility && utilities.gasUtility;
    };

    return offices.filter(
      (office) =>
        filterByChips(office) &&
        filterByUtilities(office) &&
        typeFilterFunction(office)
    );
  };

  const resetFilters = () => {
    setSelectedFacilities([]);
    return setSelectedUtilities([]);
  };

  const filterRows = [
    {
      icon: faFire,
      title: "Utility Type",
      type: "utilities",
      autocomplete: {
        availableOptions: findUtilityTypes(),
        value: selectedUtilities,
        setValue: setSelectedUtilities,
        label: "Utility Type",
      },
    },
    {
      icon: faBuilding,
      title: "Facility Type",
      type: "facilityType",
      autocomplete: {
        availableOptions: findFacilityTypes(),
        value: selectedFacilities,
        setValue: setSelectedFacilities,
        label: "Facility Type",
      },
    },
  ];

  const filteredOffices = applyFilters();

  const selectOfficeFromGraph = (id) =>
    setSelectedOffice(filteredOffices.find((office) => office.id === id));

  return (
    <>
      {!!selectedUtilityProviderFromBanner && (
        <LinkUtilitiesForm
          setOpen={setSelectedUtilityProviderFromBanner}
          utilitiesProvidersList={[selectedUtilityProviderFromBanner]}
        />
      )}
      {addOffices && (
        <AddOfficesForm open={addOffices} setOpen={setAddOffices} />
      )}
      {selectedOffice && (
        <OfficeDetailsSlider
          setSelectedOffice={setSelectedOffice}
          setSelectedTransaction={setSelectedTransaction}
          office={selectedOffice}
        />
      )}
      {selectedTransaction && (
        <EditRowTransactions
          transaction={selectedTransaction || {}}
          setIsSlideOpen={setSelectedTransaction}
          saveButtonText="Save Changes"
        />
      )}
      {viewLoading || (officesComplete && !isRemote) ? (
        <DashboardViewLayout
          type="offices"
          title="Offices"
          alerts={officeAlerts}
          subtitle={buildSubtitle()}
          viewLoading={viewLoading}
          isFiltered={selectedUtilities.length || selectedFacilities.length}
          filterPopperProps={{
            filterRows,
            resetFilters,
            title: "Filter Offices",
          }}
          chips={filterChips}
          primaryAction={
            <Button
              color="primary"
              variant="contained"
              onClick={() => setAddOffices(true)}
            >
              Add New Offices
            </Button>
          }
          graph={
            <OfficesGraph
              dataArray={[...filteredOffices].sort(
                (a, b) =>
                  a.totalMonthlyCarbonVolume - b.totalMonthlyCarbonVolume
              )}
              setSelectedObject={selectOfficeFromGraph}
            />
          }
          table={
            <SortableTable
              rows={[...filteredOffices].sort(
                (a, b) => b.severity - a.severity
              )}
              rowComponent={(office) => (
                <OfficeRow
                  key={`office-row-${office.id}`}
                  {...office}
                  onClick={() => setSelectedOffice(office)}
                />
              )}
              columns={companyOfficesColumns}
            />
          }
        />
      ) : (
        <MissingOffices />
      )}
    </>
  );
};

const OfficesView = (props) => (
  <ErrorBoundary>
    <OfficesViewBlock {...props} />
  </ErrorBoundary>
);
export default OfficesView;
