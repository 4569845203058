import React from "react";

import { Tooltip } from "@mui/material";

import {
  faSync,
  faFileUpload,
  faCalendarPlus,
  faICursor,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendarExclamation } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { formatDate, ucFirstLetters } from "@aclymatepackages/formatters";

const EmissionsSourcesIcon = ({ emission }) => {
  if (!emission) {
    return <></>;
  }

  const { importDate, source, sourceName, vendor } = emission;

  const buildAvatarProps = () => {
    switch (source) {
      case "accounting":
        return {
          icon: faSync,
          tooltip: `This transaction was imported from ${ucFirstLetters(
            sourceName
          )} on ${formatDate(importDate)}`,
        };
      case "utilityApi":
        return {
          icon: faWrench,
          tooltip: `This transaction was imported from utility provider ${vendor} on ${formatDate(
            importDate
          )}`,
        };
      case "csv-upload":
        return {
          icon: faFileUpload,
          tooltip: `This transaction was uploaded from a csv`,
        };
      case "recurring":
        return {
          icon: faCalendarPlus,
          tooltip:
            "This emission is a recurring emission that was calculated from your office usage and employees",
        };
      case "default-utilities":
        return {
          icon: faCalendarExclamation,
          tooltip:
            "This emission was created as a default emission since there is no utility emission for this month yet",
        };
      default:
        return {
          icon: faICursor,
          tooltip:
            "This emission was entered manually from Aclymate's dashboard",
        };
    }
  };

  const { icon: sourceIcon, tooltip: sourceTooltip } =
    buildAvatarProps(emission) || {};

  return (
    <Tooltip title={sourceTooltip}>
      <div>
        <FontAwesomeIcon icon={sourceIcon} size="lg" />
      </div>
    </Tooltip>
  );
};
export default EmissionsSourcesIcon;
