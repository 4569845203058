import React, { createContext, useRef } from "react";
import useInputType from "../components/display-lists/inputTypes";

export const MultipartFormContext = createContext();

export const MultipartFormContextProvider = ({ type, children }) => {
  const { color } = useInputType(type) || {};
  const formBottomRef = useRef();

  const goToBottom = () =>
    formBottomRef.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });

  return (
    <MultipartFormContext.Provider value={{ color, formBottomRef, goToBottom }}>
      {children}
    </MultipartFormContext.Provider>
  );
};
