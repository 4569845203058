import React, { useEffect, useContext } from "react";

import { useTheme } from "@mui/material";

import OffsetPurchaseFlow from "./OffsetPurchaseFlow";

import ErrorBoundary from "../../atoms/ErrorBoundary";
import ScrollingImageHeaderLayout from "../../layouts/ScrollingImageHeaderLayout";
import PlatformOffsetSurvey from "../../modules/projects/PlatformOffsetsSurvey";
import ProjectCategoriesBar from "../../modules/projects/ProjectCategoriesBar";
import OffsetsPageSurvey from "../../modules/projects/OffsetsPageSurvey";
import ProjectCategoriesCarousel from "../../modules/projects/ProjectCategoriesCarousel";
import ProjectsDisplayBlock from "../../modules/projects/ProjectsDisplayBlock";
import CNaughtPurchaseAction from "../../modules/projects/CNaughtPurchaseAction";

import {
  analyticsPage,
  analyticsTrack,
  triggerGoogleEvent,
} from "../../../helpers/analytics";
import { useCurrentCarbonBalanceTons } from "../../../helpers/hooks/companyData";
import { useAccountData } from "../../../helpers/firebase";
import { googlePurchaseTracking } from "../../../helpers/components/purchase";
import {
  ProjectsContext,
  ProjectsContextProvider,
} from "../../../helpers/contexts/projects";

const PurchasePageBlock = () => {
  const { palette } = useTheme();

  useEffect(() => {
    analyticsPage("purchase");
    triggerGoogleEvent("view_item_list");
  }, []);

  const [{ offsetPreferences }, companyDataLoading] = useAccountData();
  const { projectsLoading, selectedCategory, splitView } =
    useContext(ProjectsContext);
  const { image } = selectedCategory || {};

  const pageLoading = companyDataLoading || projectsLoading;

  const [currentCarbonBalanceTons, carbonBalanceLoading] =
    useCurrentCarbonBalanceTons();

  const showActions = () => {
    if (companyDataLoading) {
      return [];
    }
    if (offsetPreferences) {
      return [<CNaughtPurchaseAction />];
    }
    return [
      <OffsetsPageSurvey Survey={PlatformOffsetSurvey} confirmClose={false} />,
      <CNaughtPurchaseAction />,
    ];
  };

  return (
    <ScrollingImageHeaderLayout
      headerContent={<ProjectCategoriesCarousel titleFontSize="h2" />}
      headerImage={image}
      headerHeight={320}
      backgroundColor={palette.secondary.main}
      stickyContent={<ProjectCategoriesBar />}
      noShadow
      stickyBarHeight={60}
      isLoading={pageLoading}
      layoutBoxStyle={{
        height:
          splitView === "both"
            ? `calc(100vh - ${135 - (offsetPreferences ? 0 : 81)}px)` //135 is the height of the topbar plus the sticky categories bar. 81 is the height of the survey alert
            : "auto",
      }}
    >
      <ProjectsDisplayBlock
        ProjectPurchaseFlow={OffsetPurchaseFlow}
        currentCarbonBalanceTons={currentCarbonBalanceTons}
        carbonBalanceLoading={carbonBalanceLoading}
        projectsLoading={pageLoading}
        preferences={!!offsetPreferences}
        headerHeight={75}
        actions={showActions()}
      />
    </ScrollingImageHeaderLayout>
  );
};

const onProjectClick = (project) => {
  if (project) {
    const { slug } = project;
    analyticsTrack("Offset Project Browsed", { project: slug });
    return googlePurchaseTracking("select_item", { project });
  }
  return null;
};

const Purchase = () => (
  <ErrorBoundary>
    <ProjectsContextProvider
      displayPageUrl="/platform/company/purchase"
      onProjectClick={onProjectClick}
    >
      <PurchasePageBlock />
    </ProjectsContextProvider>
  </ErrorBoundary>
);

export default Purchase;
