import { useTheme } from "@mui/material";

import {
  faBox,
  faTachometerAlt,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";
import { faPodiumStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PeopleIcon from "@mui/icons-material/People";
import BusinessIcon from "@mui/icons-material/Business";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import BuildIcon from "@mui/icons-material/Build";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import EmailIcon from "@mui/icons-material/Email";
import SendIcon from "@mui/icons-material/Send";
import PaidIcon from "@mui/icons-material/Paid";

import { useAccountData, useCachedFirebaseCrud } from "../../firebase";
import { generateRandomPin } from "../../otherHelpers";

//Todo: let's take advantage of this opportunity to get rid of this
const useInputTypes = () => {
  const { palette } = useTheme();

  const [{ employeeSurveyPin }] = useAccountData();
  const { updateAccountData } = useCachedFirebaseCrud();

  return {
    offices: {
      color: palette.offices.light,
      title: "Add your company's workspaces",
      subtitle:
        "Add any company offices here. Home offices, coffee shops, and other personal workspaces will be added later.",
      primaryIcon: BusinessIcon,
    },
    officeMoving: {
      color: palette.offices.main,
      title: "Office Closing",
      customOptionsHeader: "How would you like to close your office?",
      options: [
        {
          value: "newOffice",
          IconComponent: BusinessIcon,
          title: "Create a new office",
          subtitle:
            "Close the current office, create a new office, and have the option of moving all employees in the current office to the new office",
        },
        {
          value: "moveOffice",
          IconComponent: BusinessIcon,
          title: "Move to an existing office",
          subtitle:
            "Close the current office and have the option of moving all employees in the current office to an existing office",
        },
      ],
      primaryIcon: BusinessIcon,
    },
    vendors: {
      title: "Add your company's vendors",
      subtitle:
        "Enter any company vendors here. Vendors will also be added automatically when you upload transactions from your accounting.",
      color: palette.vendors.light,
      primaryIcon: PaidIcon,
      options: [
        {
          value: "transactionsCsv",
          IconComponent: PlaylistAddCheckIcon,
          title: "Upload a CSV",
          subtitle:
            "Upload a CSV of financial transactions and we'll automatically sort out your vendors",
        },
        {
          value: "add",
          IconComponent: PaidIcon,
          title: "Add vendors manually",
          subtitle:
            "Add your vendors so that can start tracking emissions for them",
        },
      ],
    },
    vehicles: {
      title: "Add your company vehicles",
      subtitle:
        "Enter any company vehicles here. Employees can add personal vehicles later, so no need to worry about that now.",
      color: palette.vehicles.light,
      primaryIcon: DriveEtaIcon,
    },
    billing: {
      color: palette.primary.main,
      primaryIcon: CreditCardIcon,
    },
    transactions: {
      color: palette["business-travel"].main,
      primaryIcon: PlaylistAddCheckIcon,
      title: "Select the type of CSV you would like to upload",
      customOptionsHeader: "How would you like to add your emissions?",
      options: [
        {
          value: "transactions",
          IconComponent: PlaylistAddCheckIcon,
          title: "Transactions",
          subtitle: "Upload a CSV of financial transactions.",
        },
        {
          value: "mileage",
          IconComponent: DriveEtaIcon,
          title: "Mileage",
          subtitle: "Upload a CSV of mileage trips.",
        },
        {
          value: "flights",
          IconComponent: AirplanemodeActiveIcon,
          title: "Flights",
          subtitle: "Upload a CSV of flight trips.",
        },
      ],
    },
    employees: {
      title:
        "Invite your team to get a better picture of how they contribute to your company's overall emissions",
      color: palette.employees.light,
      primaryIcon: PeopleIcon,
      options: [
        {
          value: "email-link",
          IconComponent: EmailIcon,
          title: "Email your employees",
          subtitle: "Send your employees a survey link and pin",
          onClick: () => {
            if (!employeeSurveyPin) {
              return updateAccountData({
                employeeSurveyPin: generateRandomPin(),
              });
            }
          },
        },
        {
          value: "csvOrManualEmail",
          IconComponent: SendIcon,
          title: "We email your employees",
          subtitle: "Upload a csv file or input their email addresses manually",
        },
        {
          value: "add",
          IconComponent: KeyboardIcon,
          title: "Add employees manually",
          subtitle:
            "Enter employee information to calculate monthly carbon emissions",
        },
      ],
      subOptions: [
        {
          value: "emailManual",
          IconComponent: MailOutlineIcon,
          title: "Email employees manually",
          subtitle:
            "Email your employees the employee survey to track carbon emissions.",
        },
        {
          value: "csv",
          IconComponent: PeopleIcon,
          title: "Upload employee list",
          subtitle:
            "Upload a CSV file of your employees and we will send them the employee survey.",
        },
      ],
    },
    employeeSurvey: {
      title: "Employee Survey",
      color: palette.employees.main,
      customIcon: (
        <img src="/images/aclymate-icon.png" alt="RWT logo" width={100} />
      ),
    },
    personalInfoSurvey: {
      title: "Personal Info Survey",
      color: palette.employees.main,
      customIcon: (
        <img src="/images/aclymate-icon.png" alt="RWT logo" width={100} />
      ),
    },
    personalVehiclesSurvey: {
      title: "Personal Vehicles Survey",
      color: palette.employees.main,
      customIcon: (
        <img src="/images/aclymate-icon.png" alt="RWT logo" width={100} />
      ),
    },
    newCommuteSchedule: {
      title: "New Commute Schedule Survey",
      color: palette.employees.main,
      customIcon: (
        <img src="/images/aclymate-icon.png" alt="RWT logo" width={100} />
      ),
    },
    commuteEndpoints: {
      title: "Commute Endpoints Survey",
      color: palette.employees.main,
      customIcon: (
        <img src="/images/aclymate-icon.png" alt="RWT logo" width={100} />
      ),
    },
    mileage: {
      color: palette.vehicles.main,
      primaryIcon: faTachometerAlt,
    },
    trips: {
      color: palette["business-travel"].main,
      primaryIcon: CardTravelIcon,
    },
    shipping: {
      color: palette["business-travel"].light,
      primaryIcon: faBox,
    },
    offsetSurvey: {
      color: palette.secondary.main,
      title: "Offset Quiz",
      subtitle:
        "Select the qualities you are looking for in an offset project to find the best project options for your business to support. We'll use these offset preferences to display offset projects for you in the order in which they match these preferences.",
      customIcon: (
        <FontAwesomeIcon icon={faLeaf} size="5x" style={{ color: "green" }} />
      ),
      showIntro: true,
    },
    utilitiesApi: {
      title: "Connect Your Utilities Provider",
      subtitle:
        "In 3 easy steps, you can connect your utility provider and we will automatically add those transactions to your account on a rolling basis. No more manual entries needed!",
      color: palette.electricity.main,
      primaryIcon: BuildIcon,
    },
    events: {
      title: "Create a new event",
      customIcon: <FontAwesomeIcon icon={faPodiumStar} size="3x" />,
      color: palette.events.main,
    },
  };
};

const useInputType = (type) => {
  const inputTypes = useInputTypes();
  return inputTypes[type];
};
export default useInputType;
