import React, { useState } from "react";

import { Button, Grid } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import mainTheme from "@aclymatepackages/themes";

const PaginatedRowDisplay = ({
  rowList = [],
  RowComponent,
  rowComponentProps = {},
  rowKeyGenerator = () => {},
  maxDisplayedRowsPerPage = 4,
}) => {
  const [page, setPage] = useState(0);

  const listSliceStartingIdx = page * maxDisplayedRowsPerPage;

  const rowListToDisplay = rowList.slice(
    listSliceStartingIdx,
    listSliceStartingIdx + maxDisplayedRowsPerPage
  );

  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      {rowListToDisplay.map((row, idx) => (
        <RowComponent
          {...row}
          {...rowComponentProps}
          key={rowKeyGenerator(row) || page * maxDisplayedRowsPerPage + idx}
        />
      ))}
      {rowList.length > maxDisplayedRowsPerPage && (
        <ThemeProvider theme={mainTheme}>
          <Grid item container justifyContent="center" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                startIcon={<ChevronLeftIcon fontSize="large" />}
                onClick={() => setPage((currentPage) => currentPage - 1)}
                disabled={!page}
              >
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ChevronRightIcon fontSize="large" />}
                onClick={() => setPage((currentPage) => currentPage + 1)}
                disabled={
                  (page + 1) * maxDisplayedRowsPerPage >= rowList.length
                }
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </ThemeProvider>
      )}
    </Grid>
  );
};

export default PaginatedRowDisplay;
