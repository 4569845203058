import React, { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";

import {
  PieChart,
  Pie,
  Cell,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
} from "recharts";

import {
  Box,
  Chip,
  Grid,
  Paper,
  Slider,
  StyledEngineProvider,
  Tooltip,
  Typography,
  useTheme,
  ThemeProvider,
} from "@mui/material";
import styled from "@emotion/styled";

import {
  Autocomplete,
  DefaultPaper,
  ToggleButtons,
} from "@aclymatepackages/atoms";
import { formatDollars, ucFirstLetters } from "@aclymatepackages/formatters";
import { tonsToLbs } from "@aclymatepackages/converters";
import { mergeDarkTheme } from "@aclymatepackages/themes";
import { states, countryList } from "@aclymatepackages/lists";
import {
  YesNoQuestion,
  CustomTooltipDisplayRow,
} from "@aclymatepackages/modules";

import CircularProgressWithLabel from "../atoms/loading/CircularProgressWithLabel";
import TitleHelperIcon from "../atoms/icons/TitleHelperIcon";

import { createFactorText } from "../../helpers/components/offsetSurvey";
import { generateAutocompleteAnyValueSelectedList } from "../../helpers/components/inputs";
import { sortOptionsAlphabetically } from "../../helpers/otherHelpers";
import {
  offsetCategories,
  unSdgs,
  projectIcons,
} from "../../helpers/components/display-lists/projects";
import { useAdminProjects } from "../../helpers/components/projects";

const useStyles = styled((theme) => ({
  root: {
    width: "80%",
    maxWidth: "inherit",
  },
  mark: {
    height: 4,
    backgroundColor: "black",
    borderRadius: 0,
  },
  markLabel: ({ showYesNoQuestion }) => ({
    color: !showYesNoQuestion ? "black" : "white",
  }),
  track: {
    height: 4,
    background: theme.palette.vehicles.main,
  },
  rail: ({ valueOne }) => ({
    height: 4,
    background: `linear-gradient(90deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.main} ${valueOne}%, ${theme.palette.error.main} ${valueOne}%, ${theme.palette.error.main} 100%)`,
    opacity: 1,
  }),
  thumb: {
    backgroundColor: theme.palette.secondary.main,
    "&~&": {
      backgroundColor: theme.palette.vehicles.main,
    },
  },
}));

const ItemTypes = {
  RANKING_ITEM: "rankingItem",
};

const RankingItems = ({ preference }) => {
  const findRankItemIcon = (factor) =>
    projectIcons.find(({ tags }) => tags.includes(factor))?.icon;

  const { factor } = preference;
  const factorIcon = findRankItemIcon(factor);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.RANKING_ITEM,
    item: preference,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <Paper
      ref={drag}
      style={{
        cursor: isDragging ? "grabbing" : "grab",
        padding: "10px",
        opacity: 0.9,
      }}
      elevation={3}
    >
      <Grid
        container
        justifyContent={factorIcon ? "flex-start" : "center"}
        alignItems="center"
        direction="row"
        spacing={2}
      >
        {factorIcon && <Grid item>{factorIcon}</Grid>}
        <Grid item>
          <Typography
            variant="body2"
            align="center"
            noWrap
          >{`${createFactorText(factor)}`}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const RankingItemBox = ({ rankedZoneNum, setPreferences, children }) => {
  const moveRankedItem = ({ rank: droppedItemRank }, rankedZoneNum) =>
    setPreferences((currentPreferences) => {
      const rankedPreferenceItem = currentPreferences.find(
        ({ rank }) => rank === rankedZoneNum
      );
      const droppedPreferenceItem = currentPreferences.find(
        ({ rank }) => rank === droppedItemRank
      );

      const { factor: droppedPreferenceFactor } = droppedPreferenceItem;
      const { factor: rankedPreferenceFactor } = rankedPreferenceItem;
      const newPreferenceObj = {
        ...rankedPreferenceItem,
        rank: droppedItemRank,
      };
      const newDroppedPreferenceObj = {
        ...droppedPreferenceItem,
        rank: rankedZoneNum,
      };

      const filteredPreferences = currentPreferences.filter(
        ({ factor }) =>
          factor !== droppedPreferenceFactor &&
          factor !== rankedPreferenceFactor
      );

      const newPreferences = [
        ...filteredPreferences,
        newDroppedPreferenceObj,
        newPreferenceObj,
      ].sort((a, b) => a.rank - b.rank);

      return newPreferences;
    });

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: ItemTypes.RANKING_ITEM,
    drop: (droppedItem) => moveRankedItem(droppedItem, rankedZoneNum),
    canDrop: ({ rank: droppedItemRank }) => droppedItemRank !== rankedZoneNum,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  const styleObj = {
    opacity: isOver && canDrop ? 0.5 : 1,
    width: "100%",
  };

  return (
    <Box display="flex" alignItems="center">
      <Box px={1} flexGrow={0}>
        <Typography variant="h3">{rankedZoneNum}</Typography>
      </Box>
      <Box ref={drop} style={styleObj} p={1.5} flexGrow={1}>
        {children}
      </Box>
    </Box>
  );
};

const RankingBox = ({ preferences, setPreferences }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={mergeDarkTheme}>
      <Box style={{ minWidth: "50%" }}>
        {preferences.map((preference, idx) => (
          <RankingItemBox
            rankedZoneNum={idx + 1}
            setPreferences={setPreferences}
          >
            <RankingItems preference={preference} />
          </RankingItemBox>
        ))}
      </Box>
    </ThemeProvider>
  </StyledEngineProvider>
);

const IsPreferredQuestion = ({
  questionText,
  preference,
  setPreference,
  isPreferred,
  setIsPreferred,
  ...otherProps
}) => {
  const handleIsPreferred = (value) => {
    setPreference("rank", value ? 1 : 0);
    setPreference("factor", preference);
    return setIsPreferred(value);
  };

  return (
    <YesNoQuestion
      question={questionText}
      value={isPreferred}
      setValue={handleIsPreferred}
      {...otherProps}
    />
  );
};

const handleAutocompleteAnyValueSelect = ({
  rank,
  selectedValues,
  setAutocompleteValues,
  anyTypeKeyword,
  setPreference,
  setRankedPreference,
  inputType,
}) => {
  if (selectedValues.findIndex(({ name }) => name === anyTypeKeyword) > 0) {
    setPreference("rank", 0);
    setPreference(inputType, []);
    setRankedPreference([]);

    return setAutocompleteValues([{ name: anyTypeKeyword }]);
  }

  const notAnySelectedValues = selectedValues.filter(
    ({ name }) => name !== anyTypeKeyword
  );

  const rankedCategories = notAnySelectedValues.map(
    ({ name: subcategory }, idx) => ({
      factor: subcategory,
      rank: idx + 1,
    })
  );
  setPreference("rank", rank);
  setPreference(inputType, rankedCategories);
  setRankedPreference(rankedCategories);

  return setAutocompleteValues(notAnySelectedValues);
};

const handleSetLocationValue = ({
  newSelectedLocations,
  setSelectedLocations,
  handleChangeSelectedLocations,
  locationsType,
  anyTypeKeyword,
}) => {
  const newSelectedLocationsList = generateAutocompleteAnyValueSelectedList(
    newSelectedLocations,
    { name: anyTypeKeyword }
  );
  handleChangeSelectedLocations({ [locationsType]: newSelectedLocationsList });

  return setSelectedLocations(newSelectedLocationsList);
};

const OtherStatesInput = ({
  companyOffices = [],
  selectedStates,
  setSelectedStates,
  handleChangeSelectedLocations,
}) => {
  const officeStatesRow = !!companyOffices.length
    ? [{ name: "States I have offices in" }]
    : [];

  const filteredStates = states.filter(
    ({ country, name }) =>
      country === "united states" &&
      !selectedStates.find(
        (selectedState) => selectedState.name?.toLowerCase() === name
      )
  );

  const uniqueAmericanStates = [
    { name: "Any State" },
    ...officeStatesRow,
    ...sortOptionsAlphabetically(
      filteredStates.map(({ name }) => ({ name: ucFirstLetters(name) }))
    ),
  ];

  return (
    <Autocomplete
      multiple
      label="Select States"
      id="us-states-autocomplete"
      availableOptions={uniqueAmericanStates}
      value={selectedStates}
      setValue={(newSelectedStates) =>
        handleSetLocationValue({
          newSelectedLocations: newSelectedStates,
          setSelectedLocations: setSelectedStates,
          handleChangeSelectedLocations,
          locationsType: "newSelectedStates",
          anyTypeKeyword: "Any State",
        })
      }
      onDelete={({ name: deletedName }) => {
        const filteredSelectedStates = selectedStates.filter(
          ({ name: locationName }) => locationName !== deletedName
        );
        handleChangeSelectedLocations({
          newSelectedStates: filteredSelectedStates,
        });

        return setSelectedStates(filteredSelectedStates);
      }}
      disableCloseOnSelect
    />
  );
};

const OtherCountriesInput = ({
  companyOffices = [],
  selectedCountries,
  setSelectedCountries,
  handleChangeSelectedLocations,
}) => {
  const officesLocationRow = !!companyOffices.length
    ? [{ name: "Countries I have offices in" }]
    : [];

  const filteredCountries = Object.values(countryList).filter(
    (country) => !selectedCountries.find(({ name }) => name === country)
  );

  const uniqueCountries = [
    { name: "Any Country" },
    ...officesLocationRow,
    ...filteredCountries.map((name) => ({ name })),
  ];

  return (
    <Autocomplete
      multiple
      label="Select Countries"
      id="countries-autocomplete"
      availableOptions={uniqueCountries}
      value={selectedCountries}
      setValue={(selectedCountries) => {
        handleSetLocationValue({
          newSelectedLocations: selectedCountries,
          setSelectedLocations: setSelectedCountries,
          handleChangeSelectedLocations,
          locationsType: "newSelectedCountries",
          anyTypeKeyword: "Any Country",
        });
      }}
      onDelete={({ name: deletedName }) => {
        const filteredSelectedCountries = selectedCountries.filter(
          ({ name }) => name !== deletedName
        );
        handleChangeSelectedLocations({
          newSelectedCountries: filteredSelectedCountries,
        });

        return setSelectedCountries(filteredSelectedCountries);
      }}
      disableCloseOnSelect
    />
  );
};

export const useLocationSteps = ({
  preference,
  setPreference,
  onAdvanceForm,
  showRanking,
  accountData = {},
  companyOffices = [],
}) => {
  const { isRemote, geography } = accountData;
  const { address } = geography || {};
  const { state: primaryAddressState, country: primaryAddressCountry } =
    address || {};

  const {
    locations: preferenceLocations = [],
    selectedStates: preferenceSelectedStates = [{ name: "Any State" }],
    selectedCountries: preferenceSelectedCountries = [{ name: "Any Country" }],
  } = preference || {};

  const [selectedStates, setSelectedStates] = useState(
    preferenceSelectedStates
  );
  const [selectedCountries, setSelectedCountries] = useState(
    preferenceSelectedCountries
  );

  const [selectedRankedLocations, setSelectedRankedLocations] =
    useState(preferenceLocations);

  const doesCountriesIncludesUS = selectedCountries.find(
    ({ name }) => name === "United States of America"
  );

  const generateMyStatesPreference = () => {
    const findStateName = (stateAbbreviation) =>
      states.find(
        ({ abbreviation }) => abbreviation === stateAbbreviation.toLowerCase()
      ).name;

    if (isRemote) {
      return [findStateName(primaryAddressState)];
    }

    const officesStates = companyOffices.map(({ address: { state } }) =>
      findStateName(state)
    );

    return [...new Set(officesStates)];
  };

  const generateMyCountriesPreference = () => {
    if (isRemote) {
      return [countryList[primaryAddressCountry]];
    }

    const officesCountries = companyOffices.map(({ address: { country } }) =>
      countryList[country].toLowerCase()
    );

    return [...new Set(officesCountries)];
  };

  const generateSelectedStatesList = (selectedStates) => {
    const formattedStates = selectedStates.map(({ name }) =>
      name.toLowerCase()
    );

    if (formattedStates.includes("any state")) {
      return [];
    }

    if (formattedStates.includes("states i have offices in")) {
      const filteredStates = formattedStates.filter(
        (name) => name !== "states i have offices in"
      );
      const statesWithOffices = generateMyStatesPreference();

      return [...new Set([...statesWithOffices, ...filteredStates])];
    }

    return formattedStates;
  };

  const generateSelectedCountriesList = (selectedCountries) => {
    const formattedCountries = selectedCountries.map(({ name }) =>
      name.toLowerCase()
    );

    if (formattedCountries.includes("any country")) {
      return [];
    }

    if (formattedCountries.includes("countries i have offices in")) {
      const filteredStates = formattedCountries.filter(
        (name) => name !== "countries i have offices in"
      );
      const statesWithOffices = generateMyCountriesPreference();

      return [...new Set([...statesWithOffices, ...filteredStates])];
    }

    return formattedCountries;
  };

  const handleChangeSelectedLocations = ({
    newSelectedStates,
    newSelectedCountries,
  }) => {
    const newSelectedStatesList = newSelectedStates || selectedStates;
    const newSelectedCountriesList = newSelectedCountries || selectedCountries;

    const selectedStatesList = generateSelectedStatesList(
      newSelectedStatesList
    );
    const selectedCountriesList = generateSelectedCountriesList(
      newSelectedCountriesList
    );

    const newLocationsList = [...selectedStatesList, ...selectedCountriesList];
    const newRankedLocationsList = newLocationsList.map((location, idx) => ({
      factor: location,
      rank: idx + 1,
    }));

    setSelectedRankedLocations(newRankedLocationsList);
    setPreference("locations", newRankedLocationsList);
    setPreference("selectedStates", newSelectedStatesList);
    setPreference("selectedCountries", newSelectedCountriesList);
    return setPreference("rank", Number(!!newRankedLocationsList?.length));
  };

  return {
    label: "Location",
    title: "Location",
    subtitle: "Select the locations that you would prefer to buy offsets from.",
    input: (
      <Grid container justifyContent="center" spacing={2} direction="column">
        <Grid item container xs={12} justifyContent="space-between" spacing={2}>
          <Grid item xs={doesCountriesIncludesUS ? 6 : 12}>
            <OtherCountriesInput
              companyOffices={companyOffices}
              selectedCountries={selectedCountries}
              setSelectedCountries={setSelectedCountries}
              handleChangeSelectedLocations={handleChangeSelectedLocations}
            />
          </Grid>
          {doesCountriesIncludesUS && (
            <Grid item xs={6}>
              <OtherStatesInput
                companyOffices={companyOffices}
                selectedStates={selectedStates}
                setSelectedStates={setSelectedStates}
                handleChangeSelectedLocations={handleChangeSelectedLocations}
              />
            </Grid>
          )}
        </Grid>
        {showRanking && (
          <Grid item container spacing={2} justifyContent="center">
            <Grid item>
              <Typography variant="h6" align="center">
                Rank your locations below from most to least important
              </Typography>
            </Grid>
            <Grid item container justifyContent="center">
              <RankingBox
                preferences={selectedRankedLocations}
                setPreferences={setSelectedRankedLocations}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    ),
    buttonDisabled: !selectedStates?.length || !selectedCountries?.length,
    buttonText: "Done selecting locations",
    onAdvanceForm,
    helperText: "Select the locations you want offset projects to be from",
    rankedPreferences: selectedRankedLocations,
  };
};

export const useCategoriesSteps = ({
  preference,
  setPreference,
  onAdvanceForm,
  showRanking,
}) => {
  const { subcategories = [], rank = 1 } = preference || {};

  const [preferredCategories, setPreferredCategories] = useState(
    subcategories.length
      ? subcategories.map(({ factor }) => ({ name: factor }))
      : [{ name: "Any Category" }]
  );
  const [rankedCategories, setRankedCategories] = useState(subcategories);

  const handleDelete = ({ name }) => {
    const filteredCategories = preferredCategories.filter(
      ({ name: categoryName }) => categoryName !== name
    );
    const rankedCategories = filteredCategories.map(({ name }, idx) => ({
      factor: name,
      rank: idx + 1,
    }));
    setPreference("subcategories", rankedCategories);
    setRankedCategories(rankedCategories);

    return setPreferredCategories(filteredCategories);
  };

  return {
    label: "Categories",
    title: "Categories",
    subtitle: "Please select your preferred offset project categories.",
    input: (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            label="Select Project Categories"
            id="categories-autocomplete"
            availableOptions={[
              { name: "Any Category" },
              ...sortOptionsAlphabetically(offsetCategories),
            ]}
            value={preferredCategories}
            setValue={(selectedCategories) =>
              handleAutocompleteAnyValueSelect({
                rank,
                selectedValues: selectedCategories,
                anyTypeKeyword: "Any Category",
                setAutocompleteValues: setPreferredCategories,
                setPreference,
                setRankedPreference: setRankedCategories,
                inputType: "subcategories",
              })
            }
            onDelete={handleDelete}
            disableCloseOnSelect
          />
        </Grid>
        {showRanking && (
          <Grid item container spacing={2} direction="column">
            <Grid item>
              <Typography variant="h6" align="center">
                Rank your categories below from most to least important
              </Typography>
            </Grid>
            <Grid item container justifyContent="center">
              <RankingBox
                preferences={rankedCategories}
                setPreferences={setRankedCategories}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    ),
    buttonDisabled: !preferredCategories.length,
    buttonText: "Done selecting categories",
    onAdvanceForm,
    helperText: "Select the categories you want in an offset project",
    rankedPreferences: rankedCategories,
  };
};

export const useUnSdgsSteps = ({
  preference,
  setPreference,
  onAdvanceForm,
  showRanking,
}) => {
  const { unSdgs: preferenceUnSdgs = [], rank = 1 } = preference || {};
  const [preferredUnSdgs, setPreferredUnSdgs] = useState(
    preferenceUnSdgs.length
      ? preferenceUnSdgs.map(({ factor }) => ({ name: factor }))
      : [{ name: "Any UN SDG" }]
  );
  const [rankedUnSdgs, setRankedUnSdgs] = useState(preferenceUnSdgs);

  const sdgOptions = [
    { name: "Any UN SDG" },
    ...unSdgs.map((sdg, idx) => ({
      name: `${idx + 1}- ${sdg}`,
    })),
  ];

  const handleDeleteUnSdgs = ({ name }) => {
    const filteredUnSdgs = preferredUnSdgs.filter(
      ({ name: unSdg }) => unSdg !== name
    );
    const rankedFilteredUnSdgs = filteredUnSdgs.map(({ name }, idx) => ({
      factor: name,
      rank: idx + 1,
    }));
    setPreference("unSdgs", rankedFilteredUnSdgs);
    setRankedUnSdgs(rankedFilteredUnSdgs);
    return setPreferredUnSdgs(filteredUnSdgs);
  };

  return {
    label: "UN SDGs",
    title: "United Nations Sustainable Development Goals (UN SDGs)",
    subtitle: "Please select your preferred UN SDGs.",
    titleIcon: (
      <TitleHelperIcon
        tooltipText="UN Sustainable Development Goals SDGs are a collection of 17 goals set by the UN to improve the planet and human lives. Click to learn more about the UN SDGs."
        href="https://sdgs.un.org/goals"
      />
    ),
    titleAlign: "center",
    size: "md",
    input: (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={8}>
          <Autocomplete
            multiple
            label="Select Goals"
            id="un-sdgs-autocomplete"
            availableOptions={sdgOptions}
            value={preferredUnSdgs}
            setValue={(selectedSdgs) =>
              handleAutocompleteAnyValueSelect({
                rank,
                selectedValues: selectedSdgs,
                setAutocompleteValues: setPreferredUnSdgs,
                anyTypeKeyword: "Any UN SDG",
                setPreference,
                setRankedPreference: setRankedUnSdgs,
                inputType: "unSdgs",
              })
            }
            onDelete={handleDeleteUnSdgs}
            disableCloseOnSelect
          />
        </Grid>
        {showRanking && (
          <Grid item container spacing={2} direction="column">
            <Grid item>
              <Typography variant="h6" align="center">
                Rank your UN SDGs below from most to least important
              </Typography>
            </Grid>
            <Grid item container justifyContent="center">
              <RankingBox
                preferences={rankedUnSdgs}
                setPreferences={setRankedUnSdgs}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    ),
    buttonDisabled: !preferredUnSdgs.length,
    buttonText: "Done selecting goals",
    helperText: "Select the UN SDGs that you want in an offset project",
    onAdvanceForm,
    rankedPreferences: rankedUnSdgs,
  };
};

export const useMechanismsSteps = ({
  preference,
  setPreference,
  onAdvanceForm,
}) => {
  const { mechanisms = [], rank = 1 } = preference || {};

  const generateInitialValue = () => {
    if (preference) {
      return mechanisms.length > 1 ? "all" : mechanisms[0];
    }

    return "all";
  };

  const [selectedMechanism, setSelectedMechanism] = useState(
    generateInitialValue()
  );

  return {
    label: "Offset Mechanisms",
    title: "Offset Mechanisms",
    subtitle: "Please select your preferred offsetting mechanism",
    titleIcon: (
      <TitleHelperIcon tooltipText="Are you interested in projects that remove the carbon from our atmosphere that has already been emitted, projects that prevent carbon from being emitted in the first place, or both?" />
    ),
    input: (
      <Grid container justifyContent="center" spacing={2}>
        <Grid item>
          <ToggleButtons
            value={selectedMechanism}
            onChange={(mechanism) => {
              setPreference("rank", mechanism === "all" ? 0 : rank);
              setPreference(
                "mechanisms",
                mechanism === "all" ? ["avoidance", "removal"] : [mechanism]
              );

              return setSelectedMechanism(mechanism);
            }}
            buttons={[
              { value: "all", name: "Both" },
              { value: "avoidance", name: "Carbon Avoidance" },
              { value: "removal", name: "Carbon Removal" },
            ]}
          />
        </Grid>
      </Grid>
    ),
    buttonDisabled: !selectedMechanism,
    buttonText: "Done selecting mechanisms",
    helperText: "Select your preferred offset mechanism",
    onAdvanceForm,
  };
};

export const useVerificationStatusSteps = ({
  preference,
  setPreference,
  onAdvanceForm,
}) => {
  const [isVerificationPreferred, setIsVerificationPreferred] = useState(
    !!preference ? true : undefined
  );

  return {
    label: "Verification Status",
    title: "Verification Status",
    titleIcon: (
      <TitleHelperIcon tooltipText="Nearly all of the projects that we sell on Aclymate are verified through reliable offset registries, but we don't have all of the verification documents available for every project. " />
    ),
    input: (
      <IsPreferredQuestion
        questionText="Is it important to your company that an offset project have verification documents available?"
        preference="verificationStatus"
        setPreference={setPreference}
        isPreferred={isVerificationPreferred}
        setIsPreferred={setIsVerificationPreferred}
      />
    ),
    buttonDisabled: isVerificationPreferred === undefined,
    onAdvanceForm,
  };
};

const PriceChartLabelTooltipChip = ({ labelOne, labelTwo, color }) => (
  <Grid container justifyContent="center" spacing={2}>
    <Grid item xs={12}>
      <Chip
        label={labelOne}
        style={{ backgroundColor: color, color: "white" }}
      />
    </Grid>
    {labelTwo && (
      <Grid item xs={12}>
        <Chip
          label={labelTwo}
          style={{ backgroundColor: color, color: "white" }}
        />
      </Grid>
    )}
  </Grid>
);

const PriceChartCustomTooltip = ({ payload }) => (
  <DefaultPaper zIndex={999}>
    <CustomTooltipDisplayRow
      name={`${payload[0]?.value} projects`}
      subtitle={`${payload[0]?.name}`}
      color={payload[0]?.payload?.color}
      customAvatar={<PriceChartLabelTooltipChip {...payload[0]?.payload} />}
    />
  </DefaultPaper>
);

const PriceSliderValueLabel = ({ value, open, children }) => (
  <Tooltip open={open} title={value} placement="top">
    {children}
  </Tooltip>
);

export const usePricingSteps = ({
  showYesNoQuestion = true,
  preference,
  setPreference,
  onAdvanceForm,
  dbDataLoading,
  accountData,
}) => {
  const { currentCarbonBalanceTons, averageMonthlyRecurringEmissionsTons } =
    accountData || {};

  const { palette } = useTheme();
  const [projects, projectsLoading] = useAdminProjects();

  const [isPricingPreferred, setIsPricingPreferred] = useState(
    showYesNoQuestion ? undefined : true
  );
  const [sliderValue, setSliderValue] = useState([0, 0]);

  const valuesLoading = projectsLoading || dbDataLoading;
  const pricingMarks = projects
    .sort((a, b) => a.totalThousandLbsCost - b.totalThousandLbsCost)
    .map(({ totalThousandLbsCost }) => Number(totalThousandLbsCost.toFixed(2)));
  const deduplicatedPricingMarks = [...new Set(pricingMarks)];
  const deduplicatedLabeledPricingMarks = deduplicatedPricingMarks.map(
    (totalThousandLbsCost, idx) => {
      const value = Number(totalThousandLbsCost);
      const labelObj =
        idx === 0 || idx === deduplicatedPricingMarks.length - 1
          ? {
              label: `$${value} per 1000 lbs.`,
            }
          : {};

      return {
        value: idx + 1,
        priceValue: value,
        ...labelObj,
      };
    }
  );

  const sliderMin = Math.min(
    ...deduplicatedLabeledPricingMarks.map(({ value }) => value)
  );
  const sliderMax = Math.max(
    ...deduplicatedLabeledPricingMarks.map(({ value }) => value)
  );
  const { idealPrice, maxPrice } = preference || {};

  const classes = useStyles({
    showYesNoQuestion,
    valueOne: (sliderValue[0] / sliderMax) * 100,
  });

  useEffect(() => {
    if (!projectsLoading && !sliderValue[0]) {
      const midpointIndex = Math.floor(
        deduplicatedLabeledPricingMarks?.length / 2
      );
      const secondValueIndex = Math.floor(
        deduplicatedLabeledPricingMarks?.length * 0.7
      );
      const { value: medianValue = 0 } =
        deduplicatedLabeledPricingMarks[midpointIndex] || {};
      const { value: secondInitialValue = 0 } =
        deduplicatedLabeledPricingMarks[secondValueIndex] || {};

      setSliderValue([medianValue, secondInitialValue]);
    }
  }, [
    sliderValue,
    projectsLoading,
    deduplicatedLabeledPricingMarks,
    setPreference,
  ]);

  const { priceValue: idealPriceValue = 0 } =
    deduplicatedLabeledPricingMarks.find(
      ({ value }) => value === sliderValue[0]
    ) || {};
  const { priceValue: maxPriceValue = 0 } =
    deduplicatedLabeledPricingMarks.find(
      ({ value }) => value === sliderValue[1]
    ) || {};

  const calculateCost = (emissionsTons, priceValue) =>
    (tonsToLbs(emissionsTons) / 1000) * priceValue;

  const idealMonthlyCost = calculateCost(
    averageMonthlyRecurringEmissionsTons,
    idealPriceValue
  );
  const idealFootprintCost =
    calculateCost(currentCarbonBalanceTons, idealPriceValue) * -1;
  const maxMonthlyCost = calculateCost(
    averageMonthlyRecurringEmissionsTons,
    maxPriceValue
  );
  const maxFootprintCost =
    calculateCost(currentCarbonBalanceTons, maxPriceValue) * -1;

  const numProjectsWithinIdealRange = projects.filter(
    ({ totalThousandLbsCost }) =>
      Number(totalThousandLbsCost.toFixed(2)) <= idealPriceValue
  ).length;
  const numProjectsWithinMaxRange = projects.filter(
    ({ totalThousandLbsCost }) =>
      Number(totalThousandLbsCost.toFixed(2)) >= idealPriceValue &&
      Number(totalThousandLbsCost.toFixed(2)) <= maxPriceValue
  ).length;

  const pieData = [
    {
      value: numProjectsWithinIdealRange,
      name: "Below ideal price",
      color: palette.success.main,
      labelOne: `<= ${formatDollars(idealPriceValue)}`,
    },
    {
      value: numProjectsWithinMaxRange,
      name: "Between ideal & max price",
      color: palette.vehicles.main,
      labelOne: `>= ${formatDollars(idealPriceValue)}`,
      labelTwo: `<= ${formatDollars(maxPriceValue)}`,
    },
    {
      value:
        projects.length -
        (numProjectsWithinIdealRange + numProjectsWithinMaxRange),
      name: "Above max price",
      color: palette.error.main,
      labelOne: `>= ${formatDollars(maxPriceValue)}`,
    },
  ];

  const handleSliderChange = (e, newValue) => {
    const [idealPrice, maxPrice] = newValue;
    const minValue = deduplicatedLabeledPricingMarks[1].value;
    const maxValue =
      deduplicatedLabeledPricingMarks[
        deduplicatedLabeledPricingMarks.length - 2
      ].value;

    if (
      idealPrice < minValue ||
      maxPrice > maxValue ||
      idealPrice === maxPrice
    ) {
      return;
    }

    const { priceValue: idealPriceValue } =
      deduplicatedLabeledPricingMarks.find(({ value }) => value === idealPrice);
    const { priceValue: maxPriceValue } = deduplicatedLabeledPricingMarks.find(
      ({ value }) => value === maxPrice
    );

    setPreference("idealPrice", idealPriceValue);
    setPreference("maxPrice", maxPriceValue);

    return setSliderValue(newValue);
  };

  const DisplayNumber = ({
    number,
    caption,
    align = "left",
    color = "secondary",
  }) => (
    <Grid item>
      <Typography variant={!showYesNoQuestion ? "h5" : "h3"} align={align}>
        {formatDollars(number)}
      </Typography>
      <Typography
        variant={!showYesNoQuestion ? "caption" : "subtitle2"}
        align={align}
        style={{
          color: palette[color].main,
          display: "block",
        }}
      >
        {caption}
      </Typography>
    </Grid>
  );

  return {
    label: "Pricing",
    title: "Pricing",
    subtitle:
      "Use the slider to select the ideal price and the maximum price that you would be willing to pay for offsets",
    titleIcon: (
      <TitleHelperIcon tooltipText="Ideally we'll be able to match you to an offset project that falls within your ideal project range, but if we find one that matches all of your other criterion, then we'll match rank that project for you as long as it falls below your maximum price. We won't rank project for your above your maximum price." />
    ),
    input: (
      <>
        {!valuesLoading ? (
          <>
            {showYesNoQuestion && (
              <Grid container justifyContent="center">
                <Grid item xs={5}>
                  <IsPreferredQuestion
                    questionText="Do you care about the pricing of an offset?"
                    preference="pricing"
                    setPreference={setPreference}
                    isPreferred={isPricingPreferred}
                    setIsPreferred={setIsPricingPreferred}
                    typographyProps={{ noWrap: true }}
                  />
                </Grid>
              </Grid>
            )}
            {isPricingPreferred && (
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                  <Box p={1}>
                    <Typography variant="h6" align="center">
                      Select the price range you would consider for your ideal
                      offsets.
                    </Typography>
                  </Box>
                </Grid>
                <Grid container item justifyContent="center">
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={mergeDarkTheme}>
                      <Slider
                        value={sliderValue}
                        onChange={handleSliderChange}
                        marks={deduplicatedLabeledPricingMarks}
                        valueLabelDisplay="on"
                        valueLabelFormat={(selectedValue) =>
                          `${formatDollars(
                            deduplicatedLabeledPricingMarks.find(
                              ({ value }) => value === selectedValue
                            )?.priceValue || 0
                          )}`
                        }
                        components={{
                          ValueLabel: PriceSliderValueLabel,
                        }}
                        step={null}
                        min={sliderMin}
                        max={sliderMax}
                        classes={{
                          root: classes.root,
                          thumb: classes.thumb,
                          rail: classes.rail,
                          track: classes.track,
                          mark: classes.mark,
                          markLabel: classes.markLabel,
                        }}
                      />
                    </ThemeProvider>
                  </StyledEngineProvider>
                </Grid>
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                  wrap="nowrap"
                >
                  {accountData && (
                    <Grid
                      item
                      container
                      justifyContent="flex-end"
                      spacing={2}
                      direction="column"
                    >
                      <DisplayNumber
                        number={idealMonthlyCost}
                        caption="Ideal Monthly Cost"
                        align="right"
                      />
                      <DisplayNumber
                        number={idealFootprintCost}
                        caption="Ideal current footprint cost"
                        align="right"
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <ResponsiveContainer width={200} aspect={1}>
                      <PieChart>
                        <Pie
                          data={pieData}
                          outerRadius="80%"
                          fill="#8884d8"
                          dataKey="value"
                          cx="50%"
                          cy="50%"
                        >
                          {pieData.map(({ name, color }, index) => (
                            <Cell
                              key={`cell-${index}`}
                              name={name}
                              fill={color}
                            />
                          ))}
                        </Pie>
                        <ChartTooltip content={<PriceChartCustomTooltip />} />
                      </PieChart>
                    </ResponsiveContainer>
                  </Grid>
                  {accountData && (
                    <Grid item container xs={4} spacing={2}>
                      <DisplayNumber
                        number={maxMonthlyCost}
                        caption="Maximum monthly cost"
                        color="vehicles"
                      />
                      <DisplayNumber
                        number={maxFootprintCost}
                        caption="Maximum current footprint cost"
                        color="vehicles"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Grid container justifyContent="center">
            <Grid item>
              <CircularProgressWithLabel />
            </Grid>
          </Grid>
        )}
      </>
    ),
    buttonDisabled:
      isPricingPreferred === undefined ||
      (!!isPricingPreferred && !idealPrice && !maxPrice),
    buttonText: "Done selecting pricing",
    size: isPricingPreferred ? "lg" : "md",
    onAdvanceForm,
  };
};

export const useRankingPreferencesSteps = ({
  offsetPreferences,
  setOffsetPreferences,
  onAdvanceForm,
}) => {
  return {
    label: "Offset Preference Ranking",
    title:
      "Drag and drop the following preferences in order of most important to least important.",
    titleAlign: "center",
    input: (
      <Grid item container justifyContent="center">
        <RankingBox
          preferences={offsetPreferences.filter(({ rank }) => rank)}
          setPreferences={setOffsetPreferences}
        />
      </Grid>
    ),
    buttonText: "Done ranking preferences",
    value: true,
    onAdvanceForm,
  };
};

export const useSubRankingPreferencesStep = ({
  offsetPreferences = [],
  saveOffsetPreferences,
}) => {
  const [locationsList, setLocationsList] = useState();
  const [subcategoriesList, setSubcategoriesList] = useState();
  const [unSdgsList, setUnSdgsList] = useState();

  const initializeSubRankingStep = () => {
    const { locations = [] } =
      offsetPreferences.find(({ factor }) => factor === "location") || {};
    const { subcategories = [] } =
      offsetPreferences.find(({ factor }) => factor === "category") || {};
    const { unSdgs = [] } =
      offsetPreferences.find(({ factor }) => factor === "unSdgs") || {};

    if (!locations.length && !subcategories.length && !unSdgs.length) {
      return saveOffsetPreferences(offsetPreferences);
    }

    setLocationsList(locations);
    setSubcategoriesList(subcategories);
    return setUnSdgsList(unSdgs);
  };

  const saveSubRankingChanges = () => {
    const offsetPreferencesWithSubRankings = offsetPreferences.map(
      (offsetPreference) => {
        const { factor } = offsetPreference;

        if (factor === "location") {
          return {
            ...offsetPreference,
            locations: locationsList,
          };
        }

        if (factor === "unSdgs") {
          return {
            ...offsetPreference,
            unSdgs: unSdgsList,
          };
        }

        if (factor === "category") {
          return {
            ...offsetPreference,
            subcategories: subcategoriesList,
          };
        }

        return offsetPreference;
      }
    );

    return saveOffsetPreferences(offsetPreferencesWithSubRankings);
  };

  const subRankingLists = [
    {
      title: "Locations",
      list: locationsList,
      setList: setLocationsList,
    },
    {
      title: "Categories",
      list: subcategoriesList,
      setList: setSubcategoriesList,
    },
    {
      title: "UN SDGs",
      list: unSdgsList,
      setList: setUnSdgsList,
    },
  ];

  return {
    subRankingStep: {
      label: "Preference Ranking",
      title: "Rank the preferences below in the order you prefer",
      input: (
        <Grid container direction="row" justifyContent="center" spacing={2}>
          {subRankingLists
            .filter(({ list = [] }) => list.length > 1)
            .map(
              ({ title, list, setList }, idx) =>
                list &&
                list.length && (
                  <Grid
                    item
                    container
                    xs={4}
                    justifyContent="flex-start"
                    direction="column"
                    spacing={2}
                    key={`sub-ranking-item-${idx}`}
                  >
                    <Grid item>
                      <Typography variant="body2" align="center">
                        {title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <RankingBox preferences={list} setPreferences={setList} />
                    </Grid>
                  </Grid>
                )
            )}
        </Grid>
      ),
      size: "lg",
      titleAlign: "center",
      onAdvanceForm: saveSubRankingChanges,
      buttonText: "save preferences",
    },
    initializeSubRankingStep,
  };
};
