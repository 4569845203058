import React from "react";

import InputBlock from "./InputBlock";
import DbAutocomplete from "../autocomplete/DbAutocomplete";

import { useOfficesWithCurrentChanges } from "../../../helpers/components/offices";

const OfficeInput = ({
  office,
  setOffice,
  transactionSubcategory,
  availableOffices,
  title = "Tag an office for this transaction",
  ...otherProps
}) => {
  const [offices] = useOfficesWithCurrentChanges();

  const filterOffices = () => {
    if (!offices.length) {
      return [];
    }

    if (transactionSubcategory === "utilities") {
      return offices.filter(
        ({ utilities, endDate }) => utilities?.combinedUtility && !endDate
      );
    }

    return offices.filter(
      ({ utilities, endDate }) =>
        utilities?.electricUtility && utilities?.gasUtility && !endDate
    );
  };

  const filteredOffices = filterOffices();

  return (
    <>
      {!!filteredOffices.length && (
        <InputBlock
          type="offices"
          title={title}
          cardData={office}
          {...otherProps}
        >
          <DbAutocomplete
            collection="offices"
            setValue={setOffice}
            value={office}
            queries={[["type", "==", "companyOffice"]]}
            availableOptions={availableOffices || filteredOffices}
            id="office-tag-input"
          />
        </InputBlock>
      )}
    </>
  );
};
export default OfficeInput;
