/*global rewardful  */
import React, { useState, useContext } from "react";

import { useHistory } from "react-router-dom";

import { Button, Grid, Typography } from "@mui/material";

import { LoadingButton } from "@aclymatepackages/atoms";
import { formatDate, ucFirstLetters } from "@aclymatepackages/formatters";

import Link from "../atoms/mui/Link";

import { PlatformLayoutContext } from "../../helpers/contexts/platformLayout";
import {
  useCreateStripeSubscription,
  useUpdateStripeSubscription,
} from "../../helpers/hooks/stripe";
import { useSubscriptionType } from "../../helpers/hooks/companyData";
import { getAccountCollectionAndId } from "../../helpers/otherHelpers";
import { StripeCustomerContext } from "../../helpers/contexts/stripeCustomer";
import { useAuth } from "../../helpers/firebase";
import { analyticsTrack } from "../../helpers/analytics";
import { fetchOurApi } from "../../helpers/utils/apiCalls";

const generateExpiredCardErrorObj = (paymentMethod) => {
  const { card } = paymentMethod || {};
  const { expirationDate } = card || {};

  if (expirationDate < new Date()) {
    return {
      disabled: true,
      errorMessage:
        "Your card has expired. Enter a new payment method before subscribing.",
    };
  }

  return {};
};

const SignupFormLoadingActionButtonLayout = ({
  componentLoading,
  onClick,
  label,
  caption,
  href,
  snackbarProps,
  disabled,
  redirectUrl = "/platform/company/dashboard",
  setLeftContentTitle,
  errorMessage,
}) => {
  const history = useHistory();
  const { activateSnackbar } = useContext(PlatformLayoutContext) || {};

  const [saveLoading, setSaveLoading] = useState(false);

  const { collection } = getAccountCollectionAndId();

  const onButtonClick = () => {
    setSaveLoading(true);
    onClick();

    if (snackbarProps && activateSnackbar) {
      activateSnackbar(snackbarProps);
    }

    if (collection === "individuals") {
      return null;
    }

    return history.push(redirectUrl);
  };

  return (
    <Grid container spacing={1} direction="column" alignItems="center">
      {errorMessage && (
        <Grid item>
          <Typography color="error" align="center" variant="caption">
            {errorMessage}
          </Typography>
        </Grid>
      )}
      <Grid item>
        {href ? (
          <Link href={href}>
            <Button variant="contained" color="primary">
              {label}
            </Button>
          </Link>
        ) : (
          <LoadingButton
            isLoading={saveLoading}
            label={label}
            color="secondary"
            disabled={componentLoading || disabled}
            onClick={onButtonClick}
          />
        )}
      </Grid>
      <Grid item>
        <Typography
          color="textSecondary"
          align="center"
          variant="caption"
          component="div"
        >
          {caption}
        </Typography>
      </Grid>
    </Grid>
  );
};

const RestartSubscriptionButton = ({ subscriptionType, redirectUrl }) => {
  const [updateSubscription, subscriptionUpdateLoading] =
    useUpdateStripeSubscription();
  const { paymentMethod, isPaymentMethodLoading } = useContext(
    StripeCustomerContext
  );

  const expiredCardObj = generateExpiredCardErrorObj(paymentMethod);

  return (
    <SignupFormLoadingActionButtonLayout
      componentLoading={subscriptionUpdateLoading || isPaymentMethodLoading}
      label="Restart Subscription"
      redirectUrl={redirectUrl}
      onClick={() =>
        updateSubscription(
          { cancel_at_period_end: false },
          { status: "active" }
        )
      }
      caption={`By clicking this button you agree to immediately restart your subscription for our ${subscriptionType} plan. You will be automatically billed on the interval that you selected when you originally created the ${subscriptionType} subscription until you cancel.`}
      {...expiredCardObj}
    />
  );
};

const ExpiredSubscriptionButton = ({
  subscriptionType,
  stripeId,
  interval,
  redirectUrl,
  product,
}) => {
  const { stripeSubmit, componentLoading, billing } =
    useCreateStripeSubscription();
  const { paymentMethod, isPaymentMethodLoading } = useContext(
    StripeCustomerContext
  );

  const expiredCardObj = generateExpiredCardErrorObj(paymentMethod);

  const removeExpiredSubscription = async () => {
    const { subscriptions = [] } = billing;
    const activeSubscriptions = subscriptions.filter(
      ({ status }) => status !== "canceled"
    );
    const updatedBillingObj = {
      ...billing,
      subscriptions: activeSubscriptions,
    };

    return stripeSubmit({
      subscriptionType: "saas",
      stripePriceId: stripeId,
      interval,
      product,
      updatedBillingObj,
    });
  };

  return (
    <SignupFormLoadingActionButtonLayout
      snackbarProps={{
        message: `You've turned automatic billing for your ${subscriptionType} subscription back on.`,
        alert: "success",
      }}
      componentLoading={componentLoading || isPaymentMethodLoading}
      label="Subscribe"
      onClick={removeExpiredSubscription}
      caption={`By clicking this button you agree to immediately create a subscription for our ${subscriptionType} plan. You will be automatically billed on the interval that you've selected until you cancel. Your payment method will immediately be billed the amount shown above.`}
      redirectUrl={redirectUrl}
      {...expiredCardObj}
    />
  );
};

const SubscribeButton = ({
  stripeId,
  interval,
  name,
  onPostSubscriptionSubmit,
  product,
  redirectUrl,
  setLeftContentTitle,
}) => {
  const [user] = useAuth();
  const { email } = user || {};

  const [{ status }, subscriptionLoading] = useSubscriptionType("saas");
  const [updateSubscription, updateSubscriptionLoading] =
    useUpdateStripeSubscription();
  const { paymentMethod, isPaymentMethodLoading } = useContext(
    StripeCustomerContext
  );

  const expiredCardObj = generateExpiredCardErrorObj(paymentMethod);

  const onSubscribeClick = async () => {
    const { paymentMethodId } = paymentMethod;

    const trialingUpdateObj =
      status === "trialing" || status === "expired"
        ? { default_payment_method: paymentMethodId, trial_end: "now" }
        : {};

    await updateSubscription(
      { ...trialingUpdateObj, price: stripeId },
      { interval, product, status: "active" }
    );

    rewardful("convert", { email });
    if (onPostSubscriptionSubmit) {
      return onPostSubscriptionSubmit();
    }

    analyticsTrack("Bronze Unlock");
    return null;
  };

  return (
    <SignupFormLoadingActionButtonLayout
      snackbarProps={{
        message: `You've subscribed to our ${name} plan.`,
        alert: "success",
      }}
      componentLoading={
        subscriptionLoading ||
        isPaymentMethodLoading ||
        updateSubscriptionLoading
      }
      label="Subscribe"
      onClick={onSubscribeClick}
      caption={`By clicking this button you agree to immediately create a subscription for our ${name} plan. You will be automatically billed on the interval that you've selected until you cancel. Your payment method will immediately be billed the amount shown above.`}
      redirectUrl={redirectUrl}
      setLeftContentTitle={setLeftContentTitle}
      {...expiredCardObj}
    />
  );
};

const CancelSubscriptionButton = ({ subscriptionType, redirectUrl }) => {
  const { refetchCustomerSubscriptions } = useContext(StripeCustomerContext);
  const [{ upcomingInvoice, subscriptionId }, subscriptionLoading] =
    useSubscriptionType("saas");

  const { dueDate } = upcomingInvoice || {};

  return (
    <SignupFormLoadingActionButtonLayout
      snackbarProps={{
        message: `You've canceled your ${subscriptionType} subscription.`,
        alert: "success",
      }}
      componentLoading={subscriptionLoading}
      label="Cancel Subscription"
      onClick={() =>
        fetchOurApi({
          path: `/stripe/cancel-subscription`,
          method: "POST",
          data: {
            subscriptionId,
          },
          callback: () => refetchCustomerSubscriptions(),
        })
      }
      caption={`By clicking this button you agree to cancel your subscription for our ${subscriptionType} plan. You can continue using the features of this plan until ${formatDate(
        dueDate
      )}, at which point your subscription won't automatically renew.`}
      disabled={subscriptionLoading}
      redirectUrl={redirectUrl}
    />
  );
};

const SubscriptionActionBlock = ({
  status,
  subscriptionType,
  title,
  stripeId,
  interval,
  value,
  product,
  redirectUrl,
}) => {
  const sharedProps = { subscriptionType, redirectUrl };

  if (status === "active" || status === "incomplete") {
    return <CancelSubscriptionButton {...sharedProps} />;
  }

  if (status === "canceled") {
    return <RestartSubscriptionButton {...sharedProps} />;
  }

  if (status === "expired") {
    return (
      <ExpiredSubscriptionButton
        {...sharedProps}
        stripeId={stripeId}
        interval={interval}
        product={product}
      />
    );
  }

  return (
    <SubscribeButton
      {...sharedProps}
      stripeId={stripeId}
      interval={interval}
      value={value}
      product={product}
      name={`${ucFirstLetters(interval)}ly ${title}`}
    />
  );
};
export default SubscriptionActionBlock;
