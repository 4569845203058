import React from "react";

import { Grid, Typography } from "@mui/material";

import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { TextField } from "@aclymatepackages/atoms";

import ElectricIntensityAndUnitsInput from "./ElectricIntensityAndUnitsInput";
import ElectricIntensityYesNoQuestion from "./ElectricIntensityYesNoQuestion";
import ElectricRenewablesPercentageInput from "./ElectricRenewablesPercentageInput";
import ElectricRenewablesYesNoQuestion from "./ElectricRenewablesYesNoQuestion";

const ElectricInputForm = ({
  electricKwh,
  setElectricKwh,
  isElectricRenewablesPercentageGiven,
  onIsElectricRenewablesPercentageGiven,
  electricRenewablesPercentage,
  setElectricRenewablesPercentage,
  isElectricCarbonIntensityGiven,
  onIsElectricCarbonIntensityGiven,
  electricCarbonIntensity,
  setElectricCarbonIntensity,
  electricCarbonIntensityUnits,
  setElectricCarbonIntensityUnits,
}) => (
  <Grid container spacing={2} direction="column" wrap="nowrap">
    <Grid item>
      <Typography variant="h6">Electric Utility</Typography>
    </Grid>
    <Grid item xs={12}>
      <TextField
        fullWidth
        variant="outlined"
        label="Enter kWh consumed"
        value={electricKwh}
        setValue={(value) => setElectricKwh(value)}
        helperText={
          electricKwh &&
          (!numbersRegExpTest(electricKwh) || electricKwh >= 3000000) &&
          "This must be a number and be less than 3,000,000"
        }
        id="electricity-utility-input"
      />
    </Grid>
    <Grid item>
      <ElectricRenewablesYesNoQuestion
        questionText="Did part of this electric utility come from renewable energy sources?"
        isElectricRenewablesPercentageGiven={
          isElectricRenewablesPercentageGiven
        }
        editIsElectricRenewablesPercentageGiven={(value) =>
          onIsElectricRenewablesPercentageGiven(value)
        }
      />
    </Grid>
    {isElectricRenewablesPercentageGiven && (
      <Grid item>
        <ElectricRenewablesPercentageInput
          electricRenewablesPercentage={electricRenewablesPercentage}
          editElectricRenewablesPercentage={setElectricRenewablesPercentage}
        />
      </Grid>
    )}
    {electricRenewablesPercentage !== "100" && (
      <>
        <Grid item>
          <ElectricIntensityYesNoQuestion
            questionText="Do you know the carbon intensity of this electric utility that didn't come from renewables?"
            isElectricCarbonIntensityGiven={isElectricCarbonIntensityGiven}
            editIsElectricCarbonIntensityGiven={
              onIsElectricCarbonIntensityGiven
            }
          />
        </Grid>
        {isElectricCarbonIntensityGiven && (
          <Grid container item direction="row" spacing={2}>
            <ElectricIntensityAndUnitsInput
              electricCarbonIntensity={electricCarbonIntensity}
              editElectricCarbonIntensity={setElectricCarbonIntensity}
              electricCarbonIntensityUnits={electricCarbonIntensityUnits}
              editElectricCarbonIntensityUnits={setElectricCarbonIntensityUnits}
            />
          </Grid>
        )}
      </>
    )}
  </Grid>
);
export default ElectricInputForm;
