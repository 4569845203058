import React from "react";

import { Select } from "@aclymatepackages/atoms";

const GasUnitSelect = ({ label = "Select Units", gasUnit, editGasUnit }) => {
  return (
    <Select
      options={[
        { label: "Therms", value: "therms" },
        { label: "Mcf", value: "mcf" },
        { label: "Ccf", value: "ccf" },
      ]}
      size="small"
      label={label}
      value={gasUnit}
      editValue={editGasUnit}
    />
  );
};
export default GasUnitSelect;
