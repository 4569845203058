import { Button } from "@mui/material";

import {
  faEdit,
  faCheck,
  faHourglassStart,
  faLock,
} from "@fortawesome/free-solid-svg-icons";

import { letterSBoolean } from "@aclymatepackages/formatters";

import { scopeThreeSpendBasedCategories } from "./vendors";

import useEmissionsContext from "../contexts/emissions";
import useDashboardDataContext from "../contexts/dashboardData";

export const usePrimaryViewAlerts = (setShowDuplicateTransactions) => {
  const { allEmissions } = useEmissionsContext();
  const { duplicateTransactionsGroups = [] } = useDashboardDataContext();

  const unconfirmedTransactions = allEmissions.filter(
    ({ currentStatus }) => currentStatus === "unconfirmed"
  );

  const unconfirmedTransactionsAlert = unconfirmedTransactions.length
    ? [
        {
          title: `You have ${
            unconfirmedTransactions.length
          } transaction${letterSBoolean(
            unconfirmedTransactions
          )} that need more information.`,
          subtitle:
            "These transactions have been added to your balance, but we need more information to increase their accuracy. Scroll down to the transactions table to view and edit the transactions that need more data input from you.",
        },
      ]
    : [];

  const duplicateTransactionsAlert = duplicateTransactionsGroups.length
    ? [
        {
          title: `You have ${
            duplicateTransactionsGroups.length
          } group${letterSBoolean(
            duplicateTransactionsGroups
          )} of possibly duplicate transactions.`,
          subtitle: "Click to review and clean up these transactions",
          action: (
            <Button
              color="inherit"
              onClick={() => setShowDuplicateTransactions(true)}
            >
              View Duplicates
            </Button>
          ),
        },
      ]
    : [];

  return [...unconfirmedTransactionsAlert, ...duplicateTransactionsAlert];
};

export const emissionStatuses = {
  confirmed: {
    icon: faCheck,
    color: "secondary",
    tooltip: "This emission is confirmed and added to your balance",
    severity: 1,
  },
  locked: {
    icon: faLock,
    color: "backgroundGray",
    tooltip:
      "This emission is locked because you've closed the accounting period for it",
    severity: 0,
  },
  unconfirmed: {
    icon: faEdit,
    color: "error",
    tooltip:
      "This emission has been added to your balance but is missing information to make it more accurate",
    severity: 3,
  },
  cleared: {
    icon: faHourglassStart,
    color: "travel",
    tooltip:
      "This emission hasn't been added to your balance yet because it hasn't happened yet",
    severity: 2,
  },
};

export const extractScopeThreeEmissions = (emissions) =>
  emissions.map((emission = {}) => {
    const { subcategory, scopeThreeCategory } = emission;
    if (subcategory !== "spend-based" || scopeThreeCategory < 0) {
      return emission;
    }

    const { subcategory: scopeThreeSubcategory } =
      scopeThreeSpendBasedCategories.find(
        ({ value }) => value === scopeThreeCategory
      ) || {};

    if (!scopeThreeSubcategory) {
      return emission;
    }

    return { ...emission, subcategory: scopeThreeSubcategory };
  });
