import React, { useEffect, useState, useCallback } from "react";

import { TextField as MuiTextField } from "@mui/material"; //TODO: Why is this not our texfield atom
import Autocomplete from "@mui/lab/Autocomplete";

import { ucFirstLetters } from "@aclymatepackages/formatters";
import fetchAclymateApi from "@aclymatepackages/fetch-aclymate-api";

const VehicleMakeModelSelect = ({
  vehicleValue,
  type,
  editValue,
  make,
  showError,
  noLabel = false,
  id,
  variant = "outlined",
  handleSetShowHeavyVehicleForm,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const fetchAndSetOptions = useCallback(() => {
    if (type === "models" ? !!make : true) {
      fetchAclymateApi({
        path: `/cars/${type}/${inputValue || "*"}${
          type === "models" ? `/${make}` : ""
        }`,
        method: "GET",
        callback: (res) => setOptions(res.matchingOptions),
      });
    }
  }, [inputValue, make, type]);

  useEffect(() => {
    fetchAndSetOptions();
    setOptions(vehicleValue ? [vehicleValue] : []);
  }, [vehicleValue, fetchAndSetOptions]);

  const buildDontSeeYourVehicleOption = handleSetShowHeavyVehicleForm
    ? ["don't see your vehicle?"]
    : [];

  return (
    <Autocomplete
      onFocus={() => fetchAndSetOptions()}
      onBlur={() => setOptions([])}
      id={`${type}-autocomplete-${id}`}
      options={[...options, ...buildDontSeeYourVehicleOption]}
      getOptionLabel={(res) => res}
      filterOptions={(x) => x}
      autoComplete
      openOnFocus={false}
      renderInput={(params) => (
        <MuiTextField
          {...params}
          label={
            noLabel ? "" : type === "makes" ? "Vehicle Make" : "Vehicle Model"
          }
          variant={variant}
          size="small"
          error={showError && !vehicleValue}
        />
      )}
      renderOption={(props, res) => (
        <li {...props}>
          {typeof res === "string" ? ucFirstLetters(res) : res}
        </li>
      )}
      inputValue={inputValue}
      onInputChange={(e, newInputValue) => {
        setInputValue(ucFirstLetters(newInputValue));
        if (
          options.includes(inputValue?.toLowerCase()) &&
          (type === "models" ? !!make : true)
        ) {
          editValue(ucFirstLetters(inputValue || ""));
        }
      }}
      onChange={(event, newValue) => {
        if (newValue === "don't see your vehicle?") {
          return handleSetShowHeavyVehicleForm("");
        }

        return editValue(ucFirstLetters(newValue));
      }}
      value={vehicleValue || ""}
      getOptionSelected={(option, value) =>
        value === "" ||
        option?.toString()?.toLowerCase() === value?.toString()?.toLowerCase()
      }
    />
  );
};
export default VehicleMakeModelSelect;
