import React from "react";

import { TextField } from "@aclymatepackages/atoms";
import { formatDecimal } from "@aclymatepackages/formatters";

const NumberInput = ({ value, setValue, ...otherProps }) => {
  return (
    <TextField
      value={formatDecimal(value)}
      setValue={(value) => {
        const formattedNumber = Number(value.replaceAll(",", ""));
        if (isNaN(formattedNumber)) {
          return;
        }

        return setValue(Number(value.replaceAll(",", "")));
      }}
      {...otherProps}
    />
  );
};
export default NumberInput;
