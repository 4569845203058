import React, { useContext } from "react";

import {
  Box,
  Grid,
  Typography,
  Snackbar,
  Alert,
  ThemeProvider,
} from "@mui/material";

import { AnimatedLogo, Link } from "@aclymatepackages/atoms";
import { mergeDarkTheme } from "@aclymatepackages/themes";

import { useLayoutHelpers } from "../../helpers/otherHelpers";
import {
  AccountAccessContext,
  AccountAccessContextProvider,
} from "../../helpers/contexts/accountAccess";

const AccountCreationLayout = ({
  formTitle,
  formSubtitle,
  imagePath,
  bulletPoints = [],
  formDataLoading,
  children,
}) => {
  const { theme } = useLayoutHelpers();
  const { borderRadius } = theme.shape;
  const HEADER_BOX_PADDING = theme.spacing(2);

  return (
    <>
      <Box position="relative">
        <Box style={{ position: "absolute", inset: 0, zIndex: 0 }}>
          <Grid container justifyContent="flex-end" style={{ height: "100%" }}>
            <Grid
              item
              xs={6}
              style={{
                height: "100%",
                backgroundImage: `url('${
                  imagePath || "/images/platform/account-access-header.jpg"
                }')`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            />
          </Grid>
        </Box>
        <Box
          position="relative"
          sx={{
            height: "30vh",
            boxSizing: "border-box",
            width: `calc(50% + ${HEADER_BOX_PADDING})`,
            padding: HEADER_BOX_PADDING,
            paddingX: 4,
            backgroundColor: theme.palette.secondary.main,
            borderRadius: `0 ${borderRadius}px ${borderRadius}px 0`,
          }}
          display="flex"
          alignItems="center"
        >
          <ThemeProvider theme={mergeDarkTheme}>
            <Typography variant="h1" color="textPrimary">
              Start Your Net Zero Journey Today!
            </Typography>
          </ThemeProvider>
        </Box>
      </Box>
      <Box p={2} pt={4}>
        <Grid container>
          <Grid
            item
            md={6}
            xs={12}
            style={{
              borderRight: `solid thin ${theme.palette.secondary.main}`,
            }}
          >
            {formDataLoading ? (
              <Box
                style={{ height: "100%" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AnimatedLogo />
              </Box>
            ) : (
              <Box px={6}>
                <Grid container direction="column" gap={3}>
                  <Grid item>
                    <Typography variant="h3">{formTitle}</Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      {formSubtitle}
                    </Typography>
                  </Grid>
                  {children}
                </Grid>
              </Box>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            <Box px={4} height="100%">
              <Grid container direction="column" height="100%">
                <Grid item>
                  <Typography variant="h3">
                    The first step to net zero is{" "}
                    <span style={{ color: theme.palette.secondary.main }}>
                      measure.
                    </span>
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{ fontStyle: "italic" }}
                    color="textSecondary"
                  >
                    Here's how to get started in minutes:
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  gap={2}
                  direction="column"
                  justifyContent="center"
                  flex={1}
                  width="75%"
                  xs={9}
                >
                  {bulletPoints.map(({ title, subtitle }, idx) => (
                    <Grid
                      item
                      container
                      key={`bullet-point-${idx}`}
                      gap={2}
                      wrap="nowrap"
                    >
                      <Grid item>
                        <img
                          alt={`Step ${idx + 1}`}
                          style={{ width: "50px" }}
                          src={`/images/platform/icons/account-access-${
                            idx + 1
                          }.svg`}
                        />
                      </Grid>
                      <Grid item mt={1}>
                        <Typography variant="h4">{title}</Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          mt={1}
                        >
                          {subtitle}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const AccountLoginLayout = ({ children, formTitle, formSubtitle }) => {
  const { theme } = useLayoutHelpers();

  const HEADER_BOX_HEIGHT = "10vh";

  return (
    <>
      <Box
        sx={{
          bgcolor: theme.palette.backgroundGray.main,
          height: HEADER_BOX_HEIGHT,
          display: "flex",
          alignItems: "center",
          px: 4,
        }}
      >
        <Link href="https://aclymate.com">
          <img
            src="/images/aclymate-full-logo.png"
            style={{ maxWidth: "250px" }}
            alt="full-aclymate-logo"
          />
        </Link>
      </Box>
      <Grid
        container
        gap={4}
        wrap="nowrap"
        sx={{
          height: `calc(100vh - ${HEADER_BOX_HEIGHT})`,
        }}
      >
        <Grid item xs={12} md={6}>
          <Box
            height="100%"
            px={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid container direction="column" gap={3}>
              <Grid item>
                <Typography variant="h3">{formTitle}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {formSubtitle}
                </Typography>
              </Grid>
              {children}
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            style={{
              height: "100%",
              backgroundImage: `url('/images/platform/account-access-header.jpg')`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          ></Box>
        </Grid>
      </Grid>
    </>
  );
};

//Comment to cause the preview to update
const AccountAccessLayoutBlock = ({
  formTitle,
  formSubtitle,
  imagePath,
  bulletPoints = [],
  formDataLoading,
  layoutType,
  children,
}) => {
  const { accountAccessError, setAccountAccessError } =
    useContext(AccountAccessContext);

  const onSnackbarClose = () => setAccountAccessError("");

  const layoutContents = [
    {
      type: "account-creation",
      Component: (
        <AccountCreationLayout
          formTitle={formTitle}
          formSubtitle={formSubtitle}
          imagePath={imagePath}
          bulletPoints={bulletPoints}
          formDataLoading={formDataLoading}
        >
          {children}
        </AccountCreationLayout>
      ),
    },
    {
      type: "login",
      Component: (
        <AccountLoginLayout formTitle={formTitle} formSubtitle={formSubtitle}>
          {children}
        </AccountLoginLayout>
      ),
    },
  ];

  const layoutContent = layoutContents.find(
    (content) => content.type === layoutType
  );

  const { Component } = layoutContent;

  return (
    <>
      {!!accountAccessError && (
        <Snackbar
          open={!!accountAccessError}
          autoHideDuration={6000}
          onClose={onSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert severity="error" onClose={onSnackbarClose}>
            {accountAccessError}
          </Alert>
        </Snackbar>
      )}
      {Component}
    </>
  );
};

const AccountAccessLayout = ({ children, ...otherProps }) => (
  <AccountAccessContextProvider>
    <AccountAccessLayoutBlock {...otherProps}>
      {children}
    </AccountAccessLayoutBlock>
  </AccountAccessContextProvider>
);

export default AccountAccessLayout;
