import { faTree, faBalanceScale } from "@fortawesome/free-solid-svg-icons";
import { faWindTurbine } from "@fortawesome/pro-solid-svg-icons";

const offsetBuckets = {
  naturalAndWorkingLands: {
    title: "Natural & Working Lands",
    icon: faTree,
    tagline:
      "Leveraging the power of the natural world to capture and store carbon.",
    descriptor: `Through the magic of photosynthesis, nature has managed the climate for millions of years by capturing and storing 
        carbon emissions in plants and soil. By investing in this category, you'll be helping to preserve landscapes 
        under threat, regenerate those that have been degraded, and engaging Earth's superpower.`,
    offsetCategories: ["forestry", "agriculture"],
    stripePriceId:
      process.env.REACT_APP_STRIPE_NATURAL_AND_WORKING_LANDS_MONTHLY_PRICE,
  },
  cleanTransition: {
    title: "Clean Transition",
    icon: faWindTurbine,
    tagline: "Helping to fund the expansion of carbon reducing technology.",
    descriptor: `To get to our climate future, we will need to change the way we all do things, but often the right thing doesn't
        make financial sense. Investing in projects in the clean transition will change the economics - making renewable energy and
        energy efficiency projects more viable in the short-term and improve their ability to compete with dirtier alternatives
        in the long term.`,
    offsetCategories: [
      "fossil-fuel-substitution",
      "construction",
      "infrastructure",
    ],
    stripePriceId: process.env.REACT_APP_STRIPE_CLEAN_TRANSITION_MONTHLY_PRICE,
  },
  climateJustice: {
    title: "Climate Justice",
    icon: faBalanceScale,
    tagline: "Focusing on marginalized communities and the Global South.",
    descriptor: `The Global South and marginalized communities bear a disproportionate share of the negative effects of climate change
        and the economic system causing it. By investing in these projects, you will be helping to fund restorative solutions for these
        communities that empowers them to both improve quality of life and help control their own climate path.`,
    offsetCategories: ["energy-efficiency", "biodiversity"],
    stripePriceId: process.env.REACT_APP_STRIPE_CLIMATE_JUSTICE_MONTHLY_PRICE,
  },
};
export default offsetBuckets;
