import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Box, Button, Container, Grid, Typography } from "@mui/material";

import { AnimatedLogo } from "@aclymatepackages/atoms";

import { fetchOurApi } from "../../helpers/utils/apiCalls";

const EmailVerification = () => {
  const history = useHistory();

  const emailVerificationToken = new URLSearchParams(
    window.location.search
  ).get("token");
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  useEffect(() => {
    const checkEmailVerification = async () => {
      setIsEmailVerified(true);
      const { success } = await fetchOurApi({
        path: "/users/email-verification",
        method: "POST",
        data: { token: emailVerificationToken },
        callback: (res) => res,
      });
      setIsEmailVerified(false);

      if (success) {
        setIsEmailVerified(success);
        return history.push("/");
      }

      return setIsEmailVerified(success);
    };

    if (emailVerificationToken) {
      checkEmailVerification();
    }
  }, [emailVerificationToken, setVerificationLoading, history]);

  if (verificationLoading) {
    return <AnimatedLogo />;
  }

  return (
    <Box>
      {!isEmailVerified && !verificationLoading && emailVerificationToken && (
        <Container maxWidth="md">
          <Grid container direction="column">
            <Grid item>
              <Typography>: "Try re-send your email.</Typography>
            </Grid>
            <Grid item>
              <Button> Back to Login page</Button>
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
};

export default EmailVerification;
