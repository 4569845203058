import { useCallback } from "react";
import { useParams } from "react-router";

import { useTheme } from "@mui/material";

import {
  faTruckPickup,
  faShuttleVan,
  faSubway,
  faBiking,
  faBus,
} from "@fortawesome/free-solid-svg-icons";
import { faCarGarage, faTruck } from "@fortawesome/pro-solid-svg-icons";

import {
  BUS_TONS_CO2E_PER_MILE,
  CARPOOL_TONS_CO2E_PER_MILE,
} from "@aclymatepackages/constants";
import { rentalCarCategories } from "@aclymatepackages/lists";
import { trainTransportationCarbon } from "@aclymatepackages/calcs/travel";

import { fetchOurApi } from "../utils/apiCalls";
import { getAccountCollectionAndId } from "../otherHelpers";

export const ALT_VEHICLE_TYPES = [
  ...rentalCarCategories.map(({ name, type }) => ({
    label: name,
    value: type,
  })),
  { label: "Long Haul", value: "longHaul" },
  { label: "Short Haul", value: "shortHaul" },
  { label: "Medium Duty Urban", value: "mediumDutyUrban" },
  { label: "Transit Bus", value: "transitBus" },
  { label: "Other Bus", value: "otherBus" },
  { label: "Heavy Duty Pickup", value: "heavyDutyPickup" },
  { label: "Medium Duty Vocational", value: "mediumDutyVocational" },
  { label: "Heavy Duty Vocational", value: "heavyDutyVocational" },
];

export const ALT_VEHICLE_FUEL_TYPES = [
  { label: "Gas", value: "gas" },
  { label: "Diesel", value: "diesel" },
];

export const alternateVehicles = [
  {
    name: "Walking/Biking",
    type: "walkBike",
    id: "walkBike",
    tonsCo2ePerMile: 0,
  },
  {
    name: "Light Rail/Subway",
    type: "lightRail",
    id: "lightRail",
    tonsCo2ePerMile: trainTransportationCarbon("lightRail", 1),
  },
  {
    name: "Regional Rail",
    type: "regionalRail",
    id: "regionalRail",
    tonsCo2ePerMile: trainTransportationCarbon("regionalRail", 1),
  },
  {
    name: "Intercity Rail",
    type: "intercityRail",
    id: "intercityRail",
    tonsCo2ePerMile: trainTransportationCarbon("intercityRail", 1),
  },
  {
    name: "Carpooling",
    type: "carpool",
    id: "carpool",
    tonsCo2ePerMile: CARPOOL_TONS_CO2E_PER_MILE,
  },
  {
    name: "Bus",
    type: "bus",
    id: "bus",
    tonsCo2ePerMile: BUS_TONS_CO2E_PER_MILE,
  },
];

export const useAllVehicleTypes = () => {
  const { palette } = useTheme();
  const alternateColor = palette.vehicles.light;

  return [
    {
      type: "personal",
      icon: faCarGarage,
      color: palette.vehicles.dark,
      tooltip: "Personal Vehicle",
      isVehicle: true,
    },
    {
      type: "company",
      icon: faTruckPickup,
      color: palette.vehicles.main,
      tooltip: "Company Vehicle",
      isVehicle: true,
    },
    {
      type: "heavy",
      icon: faTruck,
      color: palette.vehicles.light,
      tooltip: "Heavy Vehicle",
      isVehicle: true,
    },
    {
      type: "carpool",
      icon: faShuttleVan,
      color: alternateColor,
      tooltip: "Carpooling with another person",
    },
    {
      type: "lightRail",
      icon: faSubway,
      color: alternateColor,
      tooltip:
        "A rail service within an urban center, such as subways, elevated railways, and tramways",
    },
    {
      type: "regionalRail",
      icon: faSubway,
      color: alternateColor,
      tooltip: "A rail service between a central city and adjacent suburbs",
    },
    {
      type: "intercityRail",
      icon: faSubway,
      color: alternateColor,
      tooltip: "Long-distance rail between major cities",
    },
    {
      type: "bus",
      icon: faBus,
      color: alternateColor,
      tooltip: "Public transportation bus service",
    },
    {
      type: "walkBike",
      icon: faBiking,
      color: alternateColor,
      tooltip: "Walking or biking",
    },
  ];
};

export const useVehicleTypeProperties = (type) => {
  const allVehicleTypes = useAllVehicleTypes();

  return allVehicleTypes.find((typeObj) => typeObj.type === type);
};

export const useFetchVehicleFuelTypes = () => {
  const paramsCompanyId = useParams()?.companyId;
  const { id } = getAccountCollectionAndId();
  const companyId = paramsCompanyId || id;

  const fetchAvailableFuelTypes = useCallback(
    (make, model, year, editVehicle, setAvailableFuelTypes) => {
      const fuelTypesFetchCallback = (fuelTypesArray) => {
        if (fuelTypesArray.length === 1) {
          const [fuelType] = fuelTypesArray;
          editVehicle("fuelType", fuelType);
        }
        return setAvailableFuelTypes(fuelTypesArray);
      };
      if (make && model && year && editVehicle && setAvailableFuelTypes) {
        fetchOurApi({
          accountId: `v2-companies-${companyId}`,
          path: "/cars/available-fuel-types",
          method: "POST",
          data: { make, model, year },
          callback: fuelTypesFetchCallback,
        });
      }

      return null;
    },
    [companyId]
  );

  return fetchAvailableFuelTypes;
};
