import React, { useState } from "react";

import MultiPartForm from "../flows/MultipartForm";
import { useNewVehiclesForm } from "../../hooks/vehicles";

import {
  useAccountData,
  useCachedFirebaseCrud,
} from "../../../helpers/firebase";
import { analyticsTrack } from "../../../helpers/analytics";

const AddVehiclesInputs = ({ setOpen, onFormSubmit, isSliderLeftContent }) => {
  const { newCollectionDoc, updateAccountData } = useCachedFirebaseCrud();
  const [{ name: companyName, hasNoCompanyVehicles }] = useAccountData();

  const [newVehicles, setNewVehicles] = useState([{}]);

  const onNewVehicleSubmit = async () => {
    analyticsTrack("Vehicles Added", { numberOfVehicles: newVehicles.length });

    return await Promise.all(
      newVehicles.map(
        async (vehicle) =>
          await newCollectionDoc("vehicles", {
            ...vehicle,
            archived: false,
            type: "company",
          })
      )
    );
  };

  const onSubmit = async () => {
    if (onFormSubmit) {
      onFormSubmit();
    }
    if (hasNoCompanyVehicles) {
      updateAccountData({ hasNoCompanyVehicles: false });
    }
    await onNewVehicleSubmit();
    if (setOpen) {
      setOpen(false);
    }
  };

  const newVehiclesForm = useNewVehiclesForm({
    newVehicles,
    setNewVehicles,
    onNewVehicleSubmit: onSubmit,
    companyName,
  });

  return (
    <MultiPartForm
      type="vehicles"
      forms={[...newVehiclesForm]}
      onClose={!isSliderLeftContent && (() => setOpen(false))}
      submitLoadingText="Please wait while we load your new vehicles form..."
      style={isSliderLeftContent && { right: "40%" }}
    />
  );
};
export default AddVehiclesInputs;
