import React from "react";
import { Link as ReactLink } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";

const Link = ({
  href,
  variant,
  underline = "none",
  color,
  children,
  styleProps,
  onClick,
  newTab,
}) => {
  const isExternalLink = href?.includes("http");

  const styledLinkProps = {
    variant,
    underline,
    color,
  };

  const onClickObj = {
    onClick: (e) => {
      e.stopPropagation();
      if (onClick) {
        onClick();
      }
    },
  };

  if (!href) {
    return (
      <div {...styledLinkProps} {...styleProps} {...onClickObj}>
        {children}
      </div>
    );
  }

  if (isExternalLink) {
    return (
      <MuiLink
        {...styledLinkProps}
        {...styleProps}
        {...onClickObj}
        rel="noopener"
        target="_blank"
        href={href}
      >
        {children}
      </MuiLink>
    );
  }

  const newTabProps = newTab ? { target: "_blank", rel: "noopener" } : {};

  return (
    <ReactLink
      to={href}
      style={{ textDecoration: "none", ...styleProps }}
      {...newTabProps}
      {...onClickObj}
    >
      <MuiLink {...styledLinkProps} component="span">
        {children}
      </MuiLink>
    </ReactLink>
  );
};
export default Link;
