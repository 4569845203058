import React, { useState, useEffect } from "react";

import { IconButton, Tooltip } from "@mui/material";

import FileCopy from "@mui/icons-material/FileCopy";

const CopyToClipboardIcon = ({ text, color }) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, [isCopied]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text);
    return setIsCopied(true);
  };

  return (
    <Tooltip title={isCopied ? "Copied!" : "Copy"}>
      <IconButton onClick={() => copyToClipboard()} style={{ color }}>
        <FileCopy />
      </IconButton>
    </Tooltip>
  );
};
export default CopyToClipboardIcon;
