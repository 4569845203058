import React, { useState } from "react";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";

import CompanyDataListItem from "./CompanyDataListItem";
import PlacesAutocomplete from "../../inputs/autocomplete/PlacesAutocomplete";
import DbAutocomplete from "../../inputs/autocomplete/DbAutocomplete";
import {
  useCachedFirebaseCrud,
  useCachedDisplayData,
} from "../../../helpers/firebase";
import { setAddressUpdateAirport } from "../../../helpers/utils/geography";

const CompanyAddressRow = ({
  companyData: { geography = {}, isRemote, primaryOffice },
  isEditable,
}) => {
  const { updateAccountData } = useCachedFirebaseCrud();

  const [modifyData, setModifyData] = useState(geography);
  const { address: modifyAddress, name } = modifyData || {};
  const { description: addressDescription } = modifyAddress || {};

  const [offices] = useCachedDisplayData("offices");

  const editModifyData = (field) => (value) =>
    editObjectData(setModifyData, field, value);

  const updateAddress = async (modifyData) => {
    if (!isRemote) {
      const { address } = offices.filter((office) => office.name === name);
      return updateAccountData({
        geography: { address },
        primaryOffice: modifyData,
      });
    }
    return updateAccountData({ geography: modifyData });
  };

  return (
    <CompanyDataListItem
      editingComponent={
        isRemote && !primaryOffice ? (
          <PlacesAutocomplete
            place={modifyAddress}
            size={"small"}
            editPlace={setAddressUpdateAirport(editModifyData)}
            label="Choose a new address"
            style={{ width: "250px" }}
            variant="standard"
          />
        ) : (
          <DbAutocomplete
            collection="offices"
            value={modifyData || ""}
            setValue={setModifyData}
            queries={[["type", "==", "companyOffice"]]}
            size="small"
            style={{ width: "250px" }}
            label="Choose a new primary office"
            variant="standard"
          />
        )
      }
      saveDisabled={!modifyData}
      onCancel={() => setModifyData(geography)}
      onSave={() => {
        updateAddress(modifyData);
      }}
      primaryText={addressDescription || "Address not set"}
      secondaryText={"Company Address"}
      primaryTypographyProps={
        isRemote && !modifyData?.address?.description && { color: "error" }
      }
      isEditable={isEditable}
    />
  );
};

export default CompanyAddressRow;
