import React, { useState, useEffect } from "react";

import InputBlock from "./InputBlock";
import { Autocomplete } from "@aclymatepackages/atoms";

import { useCachedDisplayData } from "../../../helpers/firebase";

const VehicleInput = ({
  vehicle,
  setVehicle,
  title = "Select a Vehicle",
  ...otherProps
}) => {
  const [employees, employeesLoading] = useCachedDisplayData("employees");
  const [companyVehicles, companyVehiclesLoading] = useCachedDisplayData(
    "vehicles",
    [["type", "==", "company"]]
  );
  const [displayVehicles, setDisplayVehicles] = useState([]);

  const arraysEqual = (arr1, arr2) =>
    arr1.length === arr2.length &&
    arr1.every(
      (obj, index) => JSON.stringify(obj) === JSON.stringify(arr2[index])
    );

  useEffect(() => {
    if (
      !employeesLoading &&
      !companyVehiclesLoading &&
      (employees.length || companyVehicles.length)
    ) {
      const personalVehicles = employees
        .flatMap(({ vehicles }) => vehicles)
        .filter((vehicle) => vehicle)
        .map(({ name, description, ...otherProps }) => ({
          name: name || description,
          ...otherProps,
        }));
      const combinedVehicles = [...companyVehicles, ...personalVehicles].filter(
        ({ archived }) => !archived
      );

      if (!arraysEqual(combinedVehicles, displayVehicles)) {
        setDisplayVehicles(combinedVehicles);
      }
    }
  }, [
    employeesLoading,
    companyVehiclesLoading,
    companyVehicles,
    employees,
    displayVehicles,
  ]);

  return (
    <InputBlock
      type="vehicles"
      title={title}
      cardData={vehicle}
      {...otherProps}
    >
      <Autocomplete
        collection="vehicles"
        setValue={setVehicle}
        value={vehicle}
        id="vehicle-tag-input"
        availableOptions={displayVehicles}
      />
    </InputBlock>
  );
};
export default VehicleInput;
