import React from "react";

import { Grid, Typography } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { ucFirstLetters } from "@aclymatepackages/formatters";
import DownloadButton from "../../atoms/buttons/DownloadButton";
import GrayPaper from "../../atoms/GrayPaper";

const LogoDownloadButton = ({ type, logo, path }) => {
  const buildFile = async () => {
    const image = await fetch(path);
    return await image.blob();
  };

  return (
    <Grid item>
      <DownloadButton
        tooltip={`Download .${type}`}
        fileName={`${logo}.${type}`}
        buildFile={buildFile}
      >
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <FontAwesomeIcon icon={faDownload} size="xs" />
          </Grid>
          <Grid item>
            <Typography variant="caption" display="block" align="center">
              .{type}
            </Typography>
          </Grid>
        </Grid>
      </DownloadButton>
    </Grid>
  );
};

const LogoDownloadColumn = ({ logo, certificationName, annotation, color }) => {
  const buildPath = (ending = "png") =>
    `/images/certifications/png/${logo}.${ending}`;

  const buttonTypes = ["svg", "png"];

  return (
    <Grid
      item
      style={{ maxWidth: "250px" }}
      container
      spacing={2}
      direction="column"
    >
      <Grid item>
        <GrayPaper shade="dark">
          <img alt={logo} src={buildPath()} style={{ width: "100%" }} />
        </GrayPaper>
      </Grid>
      <Grid item>
        <Typography
          variant="h5"
          align="center"
        >{`${certificationName} ${annotation}`}</Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
        >{`${ucFirstLetters(color)} Option`}</Typography>
      </Grid>
      <Grid item container justifyContent="center" spacing={4}>
        {buttonTypes.map((type) => (
          <LogoDownloadButton
            key={type}
            type={type}
            logo={logo}
            path={buildPath(type)}
          />
        ))}
      </Grid>
    </Grid>
  );
};

const DownloadBadges = ({ certificationName, certification, level }) => {
  const blackWhiteLogoAnnotation = certification.includes("net-zero")
    ? ""
    : "Certified";

  const logosArray = [
    {
      logo: `${certification}-${level}`,
      annotation: `${ucFirstLetters(level)} Tier`,
      color: "full color",
    },
    {
      logo: `${certification}-black`,
      annotation: blackWhiteLogoAnnotation,
      color: "black",
    },
    {
      logo: `${certification}-white`,
      annotation: blackWhiteLogoAnnotation,
      color: "white",
    },
  ];

  return (
    <Grid container spacing={2} justifyContent="space-around">
      {logosArray.map((logo, idx) => (
        <LogoDownloadColumn
          key={`logo-download-column-${idx}`}
          certificationName={certificationName}
          {...logo}
        />
      ))}
    </Grid>
  );
};
export default DownloadBadges;
