import React from "react";

import { ThemeProvider } from "@mui/material/styles";
import { Button, Grid, Typography, StyledEngineProvider } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { mainTheme } from "@aclymatepackages/themes";

const AddNewFormQuestion = ({
  showNextForm,
  onAddNewForm,
  onFormSubmit,
  label,
}) => {
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={mainTheme}>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <Typography variant="h6">Add another {label}?</Typography>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => {
                    onAddNewForm();
                    showNextForm();
                  }}
                  variant="contained"
                  endIcon={<ChevronRightIcon fontSize="large" />}
                >
                  Yes
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="secondary"
                  onClick={() => {
                    onFormSubmit();
                    showNextForm();
                  }}
                  variant="contained"
                  endIcon={<ChevronRightIcon fontSize="large" />}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};
export default AddNewFormQuestion;
