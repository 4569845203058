import React from "react";

import { Grid } from "@mui/material";
import { Popper } from "@aclymatepackages/atoms";

export const InputPopper = ({
  type,
  title,
  activePopper,
  setActivePopper,
  popperAnchorEl,
  setPopperAnchorEl,
  width,
  position,
  popperPlacement = null,
  children,
}) => {
  const positionStyles = {
    left: { placement: "bottom-start", borderRadius: "0 4px 4px 4px" },
    center: { placement: "bottom", borderRadius: "inherit" },
    right: { placement: "bottom-end", borderRadius: "4px 0 4px 4px" },
  };

  return (
    <Popper
      isOpen={activePopper === type}
      anchorEl={popperAnchorEl}
      placement={popperPlacement || positionStyles[position].placement}
      style={{ width: `${width}px` }}
      title={title}
      onClose={() => {
        setActivePopper("");
        setPopperAnchorEl(null);
      }}
      content={
        <Grid item sm={12}>
          {children}
        </Grid>
      }
    />
  );
};
export default InputPopper;
