import React from "react";

import { Box, Tooltip, useTheme } from "@mui/material";

import { subcategories } from "@aclymatepackages/subcategories";
import { CategoriesAvatar } from "@aclymatepackages/atoms";

import TagIcon from "./TagIcon";

import { isObjectEmpty } from "../../../helpers/otherHelpers";
import { findThisTagIconColor } from "../../../helpers/components/tags";

const TagRowDisplay = ({
  employees,
  knownVendor,
  trip,
  office,
  subcategory,
}) => {
  const tags = [
    !!employees && "employees",
    !!knownVendor && "vendors",
    !!office && "offices",
  ].filter((item) => item);

  const { palette } = useTheme();

  const { color: employeesColor } = findThisTagIconColor("employees");
  const { color: vendorsColor } = findThisTagIconColor("vendors");
  const { color: officesColor } = findThisTagIconColor("offices");

  const iconColor = (tag) => {
    if (tag === "employees" && employees?.length) {
      return employeesColor;
    }
    if (tag === "vendors" && !isObjectEmpty(knownVendor)) {
      return vendorsColor;
    }
    if (tag === "offices" && !isObjectEmpty(office)) {
      return officesColor;
    }
    return null;
  };

  const iconPadding = (idx) => {
    if (tags.length === 2 && idx === 0) {
      return { p: 1 };
    }
    if (tags.length === 2 && idx === 1) {
      return { pr: 2 };
    }
    if (tags.length === 3 && idx === 1) {
      return { p: 0 };
    }
    return { p: 1 };
  };

  const buildTooltipTitle = () => {
    const { name } = subcategories.find(
      (category) => subcategory === category.subcategory
    ) || { category: {} };

    if (!name) {
      return "Unclassified transaction";
    }

    if (!employees && !knownVendor && !trip) {
      return name;
    }

    const tooltipEmployees =
      employees && employees.map(({ name }) => name).join(", ");
    const tooltipVendor = knownVendor?.name;

    return `${name}. ${[tooltipEmployees, tooltipVendor]
      .filter((item) => item)
      .join(", ")}`;
  };

  return (
    <Tooltip title={buildTooltipTitle()}>
      <Box
        alignItems="center"
        justifyContent="flex-start"
        style={{
          display: "inline-flex",
          backgroundColor: tags?.length ? palette.backgroundGray.main : "white",
          color: "#303030",
          borderRadius: 20,
          height: "35px",
        }}
      >
        <CategoriesAvatar subcategory={subcategory} />
        {tags &&
          tags.map((tag, idx) => (
            <Box key={tag} {...iconPadding(idx)}>
              <TagIcon
                type={tag}
                fontSize="small"
                style={{ color: iconColor(tag), transform: "translateY(3px)" }}
              />
            </Box>
          ))}
      </Box>
    </Tooltip>
  );
};
export default TagRowDisplay;
