import React from "react";

import { Grid, Typography, Avatar, Skeleton } from "@mui/material";

import TagAvatar from "../atoms/icons/TagAvatar";

const ViewTitleActionLayout = ({
  viewLoading,
  avatar,
  type,
  title,
  secondaryAction,
  primaryAction,
  subtitle,
}) => (
  <Grid item container wrap="nowrap" spacing={2} alignItems="center">
    <Grid item>
      {viewLoading ? (
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
      ) : (
        avatar || <TagAvatar tag={type} />
      )}
    </Grid>
    <Grid item style={{ flexGrow: 1 }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          {viewLoading ? (
            <Skeleton width={500} height={60} />
          ) : (
            <>
              <Typography variant="h2" noWrap>
                {title}
              </Typography>
            </>
          )}
        </Grid>
        {!viewLoading && secondaryAction && <Grid item>{secondaryAction}</Grid>}
        {!viewLoading && <Grid item>{primaryAction}</Grid>}
      </Grid>
      <Typography
        variant="subtitle2"
        color="textSecondary"
        noWrap={!secondaryAction}
      >
        {!viewLoading && subtitle}
      </Typography>
    </Grid>
  </Grid>
);
export default ViewTitleActionLayout;
