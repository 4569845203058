import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import dayjs from "dayjs";

import {
  Grid,
  Box,
  Container,
  Typography,
  Avatar,
  Button,
  Link,
  useTheme,
} from "@mui/material";

import { faClock, faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  AnimatedLogo,
  DefaultPaper,
  LoadingButton,
  TextField,
} from "@aclymatepackages/atoms";
import { emailRegExpTest } from "@aclymatepackages/reg-exp";

import OffsetDetailsPurchaseSlider from "../../modules/projects/OffsetDetailsPurchaseSlider";
import PurchaseDialogLayout from "../../layouts/PurchaseDialogLayout";
import SignInForm from "../../account-access/SignInForm";
import {
  generateDisclaimers,
  generateDisplayIcons,
} from "../../../helpers/components/purchase";
import { useAuth } from "../../../helpers/firebase";
import { handleLoggedInUser } from "../../../helpers/components/accountAccess";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import { formatProject } from "../../../helpers/components/projects";

const Layout = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        backgroundImage:
          "url('/images/platform/employee-survey-background.jpg')",
        backgroundSize: "cover",
      }}
    >
      <Grid
        container
        direction="column"
        style={{
          height: "100vh",
          boxSizing: "border-box",
          overflow: "hidden",
        }}
        wrap="nowrap"
      >
        <Grid
          item
          style={{
            flexGrow: 1,
            overflowY: "auto",
            position: "relative",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            style={{ minHeight: "100%", padding: theme.spacing(2) }}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const UnaccessibleEstimateDialog = ({
  status,
  purchaseErrorMessage,
  isExpired,
}) => {
  const generateHeaderText = () => {
    if (isExpired) {
      return "This estimate has expired";
    }

    if (status === "rejected") {
      return "This estimate has been rejected";
    }

    if (status === "failed") {
      return "There was a problem purchasing this estimate";
    }

    return "This estimate has been payed";
  };

  const generateSubtitleText = () => {
    if (isExpired) {
      return "After 30 days, estimates expire and can no longer be accessed. If you would like to create another estimate, go into your company's Aclymate account and create another estimate in the offsets purchase page.";
    }

    if (status === "rejected") {
      return "One of the users who had access to this estimate rejected it. This estimate can no longer be accessed or paid.";
    }

    if (status === "failed") {
      return `${purchaseErrorMessage} Contact Aclymate for more information regarding this error.`;
    }

    return "One of the users who had access to this estimate payed for the estimate. This offset purchase can be seen in your company's Aclymate account.";
  };

  return (
    <Container maxWidth="sm">
      <DefaultPaper>
        <Typography variant="h5" align="center" gutterBottom>
          {generateHeaderText()}
        </Typography>
        <Typography variant="body1" align="center">
          {generateSubtitleText()}
        </Typography>
      </DefaultPaper>
    </Container>
  );
};

const EstimateAuthentication = ({
  email,
  setEmail,
  passwordPin,
  setPasswordPin,
  checkEmailAndPasswordLoading,
  checkEmailAndPin,
  loginError,
}) => {
  return (
    <Container maxWidth="sm">
      <DefaultPaper>
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item style={{ marginBottom: "50px", paddingTop: "20px" }}>
            <img
              src="/images/aclymate-vertical-logo.png"
              alt="RWT logo"
              width={250}
            />
          </Grid>
          <Grid item>
            <Typography variant="h5" align="center">
              View an estimate
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <TextField
              label="Enter your email address"
              value={email}
              setValue={setEmail}
            />
          </Grid>
          <Grid item container xs={12}>
            <TextField
              label="Enter your password pin"
              value={passwordPin}
              setValue={(value) => setPasswordPin(value)}
            />
          </Grid>
          {loginError && (
            <Grid item>
              <Typography align="center" variant="subtitle2" color="error">
                {loginError}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <LoadingButton
              label="Login"
              variant="contained"
              color="primary"
              disabled={!emailRegExpTest(email) || !passwordPin}
              isLoading={checkEmailAndPasswordLoading}
              onClick={() => checkEmailAndPin()}
            />
          </Grid>
        </Grid>
      </DefaultPaper>
    </Container>
  );
};

const CountdownTimer = ({ startTime, endTime }) => {
  const startTimeDayJs = dayjs(startTime);
  const endTimeDayJs = dayjs(endTime);

  const minutesRemaining = endTimeDayJs.diff(startTimeDayJs, "minute");
  const secondsRemaining = endTimeDayJs.diff(startTimeDayJs, "second") % 60;
  const [[mins, secs], setTime] = useState([
    minutesRemaining,
    secondsRemaining,
  ]);

  const tick = () => {
    if (secs === 0) {
      return setTime([mins - 1, 59]);
    }

    return setTime([mins, secs - 1]);
  };

  useEffect(() => {
    setTimeout(() => {
      if (!(mins === 0 && secs === 0)) {
        tick();
      }
    }, 1000);
  });

  return (
    <Typography align="center">{`Time remaining: ${mins}:${
      secs.toString().length < 2 ? `0${secs}` : secs
    }`}</Typography>
  );
};

const EstimateAppLogin = ({ setDialogOpen }) => {
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accountAccessError, setAccountAccessError] = useState("");

  const oAuthCallback = (user) =>
    handleLoggedInUser({
      user,
      setDialogOpen,
    });

  return (
    <PurchaseDialogLayout
      title="Login to the Aclymate app"
      subtitle="Once you are logged into your company's Aclymate account, you can use the saved payment info in the account to pay for this estimate."
    >
      <Box
        p={2}
        position="relative"
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid
          container
          direction="column"
          spacing={2}
          style={{ width: "800px" }}
        >
          <SignInForm
            userEmail={userEmail}
            setUserEmail={setUserEmail}
            password={password}
            setPassword={setPassword}
            setDialogOpen={setDialogOpen}
            setAccountAccessError={setAccountAccessError}
            oAuthCallback={oAuthCallback}
          />
          <Grid item container justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => setDialogOpen(false)}
            >
              Go back
            </Button>
          </Grid>
          <Grid item>
            <Typography align="center" color="error">
              {accountAccessError}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </PurchaseDialogLayout>
  );
};

const EstimateDialog = ({
  purchaseDollars,
  lbsToPurchase,
  certificationDate,
  certificationLevel,
  companyId,
  estimateDocId,
  hostedInvoiceUrl,
  expirationDate,
  couponPercentOff,
  couponAmountOff,
  showInvoice,
  setShowInvoice,
  setEstimateData,
}) => {
  const [loggedInUser] = useAuth();
  const { palette } = useTheme();

  const [estimateLoading, setEstimateLoading] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState("");
  const [estimateCompletedMessage, setEstimateCompletedMessage] =
    useState(false);
  const [appLoginDialogOpen, setAppLoginDialogOpen] = useState(false);

  const displayIcons = generateDisplayIcons({
    certificationDate,
    certificationLevel,
    purchaseDollars,
    lbsToPurchase,
  });

  const createLimitedTimeDisclaimer = () => ({
    icon: faClock,
    text: "Once you approve this estimate, you will have 30 minutes to pay before it expires. You may pay via a link to an invoice we will provide or use saved payment information from your company's Aclymate account.",
  });

  const createEstimateDisclaimers = () => {
    if (couponPercentOff || couponAmountOff) {
      return [
        {
          icon: faTag,
          text: `A ${
            couponPercentOff ? `${couponPercentOff}%` : `$${couponAmountOff}`
          } discount has been applied to your estimate.`,
        },
        createLimitedTimeDisclaimer(),
      ];
    }

    return [createLimitedTimeDisclaimer()];
  };

  const disclaimers = generateDisclaimers(
    certificationDate,
    createEstimateDisclaimers()
  );

  const acceptEstimate = async () => {
    setEstimateLoading(true);
    return await fetchOurApi({
      companyId,
      path: `/estimates/approve-estimate`,
      method: "POST",
      data: {
        companyId,
        estimateDocId,
      },
      callback: (estimateDocData) => {
        setEstimateData(estimateDocData);
        setShowInvoice(true);
        return setEstimateLoading(false);
      },
    }).catch((e) => {
      console.log(e);
      return setApiErrorMessage(e.message);
    });
  };

  const payOrRejectEstimate = async (endpoint, completedMessage) => {
    setEstimateLoading(true);
    return await fetchOurApi({
      companyId,
      path: `/estimates/${endpoint}`,
      method: "POST",
      data: {
        companyId,
        estimateDocId,
      },
      callback: ({ error, message }) => {
        if (error) {
          setApiErrorMessage(message);
          return setEstimateLoading(false);
        }

        setEstimateCompletedMessage(completedMessage);
        return setEstimateLoading(false);
      },
    }).catch((e) => {
      console.log(e);
      return setApiErrorMessage(e.message);
    });
  };

  return appLoginDialogOpen ? (
    <EstimateAppLogin setDialogOpen={setAppLoginDialogOpen} />
  ) : (
    <PurchaseDialogLayout
      title="Please review this estimate of an offset purchase"
      subtitle="We need you to review the information and disclaimers about this estimate."
    >
      <Box p={2} position="relative">
        <Grid container direction="column" spacing={2}>
          <Grid item container spacing={1} alignItems="stretch">
            {displayIcons.map(({ icon, title, subtitle }, idx) => (
              <Grid
                item
                sm={certificationDate ? 4 : 6}
                key={`purchase-display-icon-${idx}`}
              >
                <Box
                  p={2}
                  style={{
                    background: palette.backgroundGray.main,
                  }}
                >
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                    alignItems="center"
                  >
                    <Grid item>{icon}</Grid>
                    <Grid item>
                      <Typography
                        variant="h5"
                        align="center"
                        color="textPrimary"
                      >
                        {title}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle2"
                        align="center"
                        color="textSecondary"
                      >
                        {subtitle}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
          {disclaimers.map(({ avatar, icon, text }, idx) => (
            <Grid
              key={`purchase-disclaimer-${idx}`}
              item
              container
              spacing={2}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Avatar>
                  {icon ? <FontAwesomeIcon icon={icon} /> : avatar}
                </Avatar>
              </Grid>
              <Grid item container style={{ maxWidth: "900px" }}>
                <Typography variant="body1">{text}</Typography>
              </Grid>
            </Grid>
          ))}
          {showInvoice && !estimateCompletedMessage && (
            <Grid
              item
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <CountdownTimer
                  startTime={new Date()}
                  endTime={expirationDate}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            item
            container
            justifyContent="center"
            spacing={2}
            alignItems="center"
          >
            {estimateCompletedMessage ? (
              <Grid item>
                <Typography>{estimateCompletedMessage}</Typography>
              </Grid>
            ) : !showInvoice ? (
              <>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      payOrRejectEstimate(
                        "reject-estimate",
                        "This estimate has been rejected. You may now close this window."
                      )
                    }
                  >
                    Reject
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => acceptEstimate()}
                  >
                    Approve Estimate
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  {loggedInUser?.uid ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        payOrRejectEstimate(
                          "pay-with-app",
                          "Your estimate was successfully purchased. You may now close this window."
                        )
                      }
                    >
                      Pay with account info
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setAppLoginDialogOpen(true)}
                    >
                      Login to Aclymate
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <Link
                    href={hostedInvoiceUrl}
                    target="_blank"
                    rel="noopener"
                    underline="none"
                  >
                    <Button variant="contained" color="secondary">
                      Pay via invoice
                    </Button>
                  </Link>
                </Grid>
              </>
            )}
          </Grid>
          {apiErrorMessage && (
            <Grid item container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography align="center" color="error">
                  {apiErrorMessage}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        {estimateLoading && (
          <Box
            position="absolute"
            style={{ inset: 0, backgroundColor: "rgba(255, 255, 255, 0.85)" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AnimatedLogo />
          </Box>
        )}
      </Box>
    </PurchaseDialogLayout>
  );
};

const Estimates = () => {
  const { companyId, estimateId } = useParams();

  const [checkEmailAndPasswordLoading, setCheckEmailAndPasswordLoading] =
    useState(false);
  const [email, setEmail] = useState("");
  const [passwordPin, setPasswordPin] = useState("");
  const [estimateData, setEstimateData] = useState(null);
  const [showInvoice, setShowInvoice] = useState(false);
  const [loginError, setLoginError] = useState("");

  const {
    projectData,
    totalPrice,
    lbsToPurchase,
    certificationDate,
    certificationLevel,
    hostedInvoiceUrl,
    status: estimateDataStatus,
    expirationDate,
    error: purchaseErrorMessage,
    couponPercentOff,
    couponAmountOff,
    type,
  } = estimateData || {};
  const isExpired = new Date() > expirationDate;

  const estimateLoginCallback = ({ success, message }) => {
    if (!success) {
      setCheckEmailAndPasswordLoading(false);
      return setLoginError(message);
    }

    return fetchOurApi({
      companyId,
      path: "/estimates/fetch-estimate-data",
      method: "POST",
      data: { companyId, estimateDocId: estimateId },
      callback: (response) => {
        setCheckEmailAndPasswordLoading(false);
        setShowInvoice(response.status === "approved");
        return setEstimateData(response);
      },
    });
  };

  const checkEmailAndPin = async () => {
    setCheckEmailAndPasswordLoading(true);
    await fetchOurApi({
      companyId,
      path: `/estimates/check-valid-user`,
      method: "POST",
      data: {
        companyId,
        estimateDocId: estimateId,
        email,
        passwordPin,
      },
      callback: estimateLoginCallback,
    }).catch((e) => console.log(e));
  };

  const displayEstimate = () => {
    if (!estimateData) {
      return (
        <EstimateAuthentication
          email={email}
          setEmail={setEmail}
          passwordPin={passwordPin}
          setPasswordPin={setPasswordPin}
          checkEmailAndPasswordLoading={checkEmailAndPasswordLoading}
          loginError={loginError}
          checkEmailAndPin={checkEmailAndPin}
        />
      );
    }

    if (
      (estimateDataStatus !== "approved" &&
        estimateDataStatus !== "awaiting-approval") ||
      isExpired
    ) {
      return (
        <UnaccessibleEstimateDialog
          status={estimateDataStatus}
          purchaseErrorMessage={purchaseErrorMessage}
          isExpired={isExpired}
        />
      );
    }

    return (
      <OffsetDetailsPurchaseSlider
        isCNaughtPurchase={type === "cNaught"}
        project={type !== "cNaught" && formatProject(projectData)}
        ProjectPurchaseFlow={() => (
          <EstimateDialog
            purchaseDollars={totalPrice}
            lbsToPurchase={lbsToPurchase}
            certificationDate={certificationDate}
            certificationLevel={certificationLevel}
            companyId={companyId}
            estimateDocId={estimateId}
            hostedInvoiceUrl={hostedInvoiceUrl}
            expirationDate={expirationDate}
            couponPercentOff={couponPercentOff}
            couponAmountOff={couponAmountOff}
            showInvoice={showInvoice}
            setShowInvoice={setShowInvoice}
            setEstimateData={setEstimateData}
          />
        )}
      />
    );
  };

  return (
    <Layout>
      <Grid item style={{ maxHeight: "100%" }}>
        {displayEstimate()}
      </Grid>
    </Layout>
  );
};
export default Estimates;
