import React, { useContext } from "react";

import { Box, Grid, Typography, Button, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { letterSBoolean } from "@aclymatepackages/formatters";
import { useLayoutHelpers } from "@aclymatepackages/themes";

import Link from "../../atoms/mui/Link";
import { ProjectsContext } from "../../../helpers/contexts/projects";

const CarouselArrowContainer = ({ side, href }) => {
  return (
    <Box
      style={{ [side]: 0, zIndex: 99 }}
      position="absolute"
      top={0}
      bottom={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Link href={href}>
        <IconButton style={{ color: "white" }}>
          {side === "left" ? (
            <ChevronLeftIcon sx={{ fontSize: "1.5em" }} />
          ) : (
            <ChevronRightIcon sx={{ fontSize: "1.5em" }} />
          )}
        </IconButton>
      </Link>
    </Box>
  );
};

const ProjectCategoriesCarousel = ({ titleFontSize }) => {
  const { isMobile } = useLayoutHelpers();

  const {
    projectsLoading,
    selectedCategory,
    displayCategories,
    displayPageUrl,
  } = useContext(ProjectsContext);

  const {
    name: categoryName,
    description,
    projects = [],
    subtitle,
    header,
    slug,
  } = selectedCategory || {};
  const [firstProject = {}, ...otherProjects] = projects;
  const { slug: projectSlug } = firstProject;

  const buildSubtitleString = () => {
    const { name: projectName } = firstProject;

    const baseProjectString = `Check out the ${projectName} project`;
    if (!otherProjects.length) {
      return `${description} ${baseProjectString}.`;
    }

    return `${description} ${baseProjectString} or ${
      otherProjects.length
    } other project${letterSBoolean(otherProjects)} like it.`;
  };

  const selectedCategoryIdx = displayCategories.findIndex(
    (category) => category?.slug === slug
  );

  const buildArrowUrl = (idx) => {
    const { slug } = displayCategories[idx];

    return `${displayPageUrl}${slug ? `/${slug}` : ""}`;
  };

  if (projectsLoading) {
    return <></>;
  }

  return (
    <>
      {!!selectedCategoryIdx && (
        <CarouselArrowContainer
          side="left"
          href={buildArrowUrl(selectedCategoryIdx - 1)}
        />
      )}
      {selectedCategoryIdx + 1 !== displayCategories.length && (
        <CarouselArrowContainer
          side="right"
          href={buildArrowUrl(selectedCategoryIdx + 1)}
        />
      )}
      {header || (
        <Box
          style={{ height: "100%", color: "white" }}
          position="relative"
          p={4}
        >
          <Grid container direction="column" spacing={2}>
            <Grid item sm={8}>
              <Typography
                variant={titleFontSize || (isMobile ? "h3" : "h2")}
                component="h1"
                color="inherit"
                style={{ fontWeight: 500 }}
                align={isMobile ? "center" : "left"}
              >
                {categoryName}
              </Typography>
              <Typography
                variant="subtitle1"
                color="inherit"
                align={isMobile ? "center" : "left"}
              >
                {subtitle || buildSubtitleString()}
              </Typography>
            </Grid>
            {projectSlug && (
              <Grid item sm={8} container justifyContent="center" spacing={2}>
                <Grid item>
                  <Link href={`${displayPageUrl}/${projectSlug}`}>
                    <Button variant="contained" color="secondary">
                      Learn more about this project
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};
export default ProjectCategoriesCarousel;
