import React, { useState } from "react";

import { Grid, Typography } from "@mui/material";
import { Rating } from "@mui/lab";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { TextField } from "@aclymatepackages/atoms";

import PlacesAutocomplete from "../autocomplete/PlacesAutocomplete";

import { findGoogleAddressStringDetails } from "../../../helpers/utils/geography";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import { buildInitialTransactionInputValueFromSchema } from "../../../helpers/components/inputs";

const HotelInput = ({
  editHotelStay,
  hotel,
  starRating,
  numberOfNights,
  setCalcLoading,
}) => {
  const [starRatingError, setStarRatingError] = useState(false);

  const fetchHotelCarbon = async (hotel, starRating) =>
    await fetchOurApi({
      path: "/calcs/travel/fetch-hotels-carbon",
      method: "POST",
      data: { ...hotel, starRating },
      callback: ({ tonsCo2e }) => editHotelStay("tonsCo2ePerNight")(tonsCo2e),
    });

  const onHotelSelect = async (value) => {
    if (!value) {
      setStarRatingError(false);
      editHotelStay("tonsCo2ePerNight")(0);
      return editHotelStay("starRating")(null);
    }

    setCalcLoading(true);
    const { place_id: placeId, description } = value;
    const address = await findGoogleAddressStringDetails(placeId);
    const hotel = { ...address, description, placeId };
    editHotelStay("hotel")(hotel);

    const { starRating, error } = await fetchOurApi({
      path: "/calcs/travel/fetch-hotel-star-rating",
      method: "POST",
      data: hotel,
      callback: (res) => res,
    });

    if (error) {
      setCalcLoading(false);
      return setStarRatingError(true);
    }

    editHotelStay("starRating")(starRating);
    await fetchHotelCarbon(hotel, starRating);

    return setCalcLoading(false);
  };

  const onStarSelect = async (e, value) => {
    setCalcLoading(true);
    editHotelStay("starRating")(value);

    await fetchHotelCarbon(hotel, value);

    return setCalcLoading(false);
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <PlacesAutocomplete
          place={hotel}
          editPlace={onHotelSelect}
          label="Hotel Name and Address"
          size="small"
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          label="Number of Nights"
          value={numberOfNights}
          setValue={editHotelStay("numberOfNights")}
          error={numberOfNights && !numbersRegExpTest(numberOfNights)}
        />
      </Grid>
      {starRatingError && (
        <Grid
          item
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          wrap="nowrap"
        >
          <Grid item>
            <Typography variant="body1">
              We couldn't find a star rating for that hotel. Please enter one.
            </Typography>
          </Grid>
          <Grid item>
            <Rating value={starRating} onChange={onStarSelect} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const useHotelInput = ({ transaction, onSave, setCalcLoading }) => {
  const inputSchema = [
    { field: "hotel" },
    { field: "numberOfNights" },
    { field: "tonsCo2ePerNight" },
  ];

  const [hotelStay, setHotelStay] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );

  const { numberOfNights, tonsCo2ePerNight } = hotelStay;

  const tonsCo2e =
    tonsCo2ePerNight &&
    numbersRegExpTest(numberOfNights) &&
    tonsCo2ePerNight * Number(numberOfNights);

  const editHotelStay = (field) => (value) =>
    editObjectData(setHotelStay, field, value);

  const onTransactionSave = () => onSave({ ...hotelStay, tonsCo2e });

  return {
    inputBlock: (
      <HotelInput
        setCalcLoading={setCalcLoading}
        editHotelStay={editHotelStay}
        {...hotelStay}
      />
    ),
    saveEnabled: tonsCo2e,
    onTransactionSave,
    tonsCo2e,
  };
};
export default useHotelInput;
