import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Grid,
  Button,
  Typography,
  Divider,
  IconButton,
  useTheme,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";

import { LoadingButton, TextField } from "@aclymatepackages/atoms";
import {
  EmissionsPieChart,
  PlacesAutocomplete,
} from "@aclymatepackages/modules";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { subcategories } from "@aclymatepackages/subcategories";
import { ucFirstLetters } from "@aclymatepackages/formatters";

import StatePercentagesInterface from "./StatePercentagesInterface";

import EmissionsDetailsTableAccordion from "../../modules/tables/EmissionsDetailsTableAccordion";
import SettingsObjectDetailsSlider from "../dashboard/SettingsObjectDetailsSlider";
import DatePicker from "../../atoms/mui/DatePicker";

import {
  createBasicDataForEventEmissionsPieChart,
  isAttendeeStateBreakdownPercentageError,
} from "../../../helpers/components/events";
import { setAddress } from "../../../helpers/utils/geography";
import {
  useAccountData,
  useCachedFirebaseCrud,
} from "../../../helpers/firebase";
import useEmissionsContext from "../../../helpers/contexts/emissions";
import WarningPopup from "../../atoms/notifications/WarningPopup";

import EventPieChartTooltip from "./EventPieChartTooltip";

const EventDetailsContent = ({ modifyEvent, setModifyEvent }) => {
  const {
    name,
    address,
    sqFootage,
    attendeeCount,
    startDate,
    endDate,
    isClosed,
  } = modifyEvent;

  const [{ startDate: companyStartDate }] = useAccountData();

  const editModifyEvent = (field) => (value) =>
    editObjectData(setModifyEvent, field, value);

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <TextField
          label="Name"
          value={name}
          setValue={editModifyEvent("name")}
          disabled={isClosed}
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Venue Sq. Footage"
            value={sqFootage}
            setValue={editModifyEvent("sqFootage")}
            disabled={isClosed}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Total Attendees"
            value={attendeeCount}
            setValue={editModifyEvent("attendeeCount")}
            disabled={isClosed}
          />
        </Grid>
      </Grid>
      <Grid item>
        <PlacesAutocomplete
          label="Venue Address"
          place={address || null}
          editPlace={setAddress(editModifyEvent("address"))}
          size="small"
          disabled={isClosed}
        />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            label="Start Date"
            date={startDate}
            minDate={companyStartDate}
            maxDate={endDate}
            editDate={(date) => editModifyEvent("startDate")(new Date(date))}
            disabled={isClosed}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="End Date"
            date={endDate}
            minDate={startDate}
            editDate={(date) => editModifyEvent("endDate")(new Date(date))}
            disabled={isClosed}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const EventDetailsFooter = ({ modifyEvent }) => {
  const { name, address, sqFootage, startDate, endDate, id } = modifyEvent;

  const { updateCollectionDoc } = useCachedFirebaseCrud();

  //TODO: I left out a check for attendeeCount because it's going to be moved from here
  const isSaveDisabled =
    !name ||
    !address ||
    !sqFootage ||
    !numbersRegExpTest(sqFootage) ||
    !startDate ||
    !endDate;

  return (
    <Grid container justifyContent="flex-end">
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          disabled={isSaveDisabled}
          onClick={() => updateCollectionDoc("events", id, modifyEvent)}
        >
          Save Event
        </Button>
      </Grid>
    </Grid>
  );
};

const useEventDetailsTab = (event) => {
  const [modifyEvent, setModifyEvent] = useState(event);

  return {
    content: (
      <EventDetailsContent
        modifyEvent={modifyEvent}
        setModifyEvent={setModifyEvent}
      />
    ),
    footer: <EventDetailsFooter modifyEvent={modifyEvent} event={event} />,
  };
};

const useEventAttendeesTab = ({ selectedEvent }) => {
  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const { attendeesStateBreakdown = [], id: eventId, isClosed } = selectedEvent;

  const [editStateBreakdown, setEditStateBreakdown] = useState(false);
  const [stateBreakdown, setStateBreakdown] = useState(attendeesStateBreakdown);

  const onIconButtonClick = () =>
    setEditStateBreakdown((isEditing) => {
      if (isEditing) {
        updateCollectionDoc("events", eventId, {
          attendeesStateBreakdown: stateBreakdown,
        });
      }

      return !isEditing;
    });

  return {
    content: (
      <Grid container direction="column" spacing={2}>
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4">
              Breakdown of Attendees by State
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={onIconButtonClick}
              disabled={
                isClosed ||
                (editStateBreakdown &&
                  isAttendeeStateBreakdownPercentageError(stateBreakdown))
              }
            >
              {editStateBreakdown ? <SaveIcon /> : <EditIcon />}
            </IconButton>
          </Grid>
        </Grid>
        <Grid item>
          {editStateBreakdown ? (
            <StatePercentagesInterface
              stateBreakdown={stateBreakdown}
              setStateBreakdown={setStateBreakdown}
            />
          ) : (
            <Grid container direction="column" spacing={2}>
              {stateBreakdown.map(({ state, percentage }, idx) => (
                <React.Fragment key={`state-breakdown-row-${idx}`}>
                  {!!state && !!percentage && (
                    <>
                      <Grid
                        item
                        container
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <Grid item>
                          <Typography variant="h5">
                            {ucFirstLetters(state)}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h5">{percentage}%</Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  )}
                </React.Fragment>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    ),
  };
};

const useEventEmissionsTab = ({
  selectedEvent,
  setSelectedTransaction,
  closeSelectedEvent,
}) => {
  const { palette } = useTheme();
  const history = useHistory();
  const { transactions } = useEmissionsContext();
  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const [showCharts, setShowCharts] = useState(true);
  const [isShowConfirmCloseEvent, setIsShowConfirmCloseEvent] = useState(false);
  const [isClosingEvent, setIsClosingEvent] = useState(false);

  const onAccordionClick = () => setShowCharts((currentState) => !currentState);

  const eventTransactions = transactions?.filter(
    ({ event = {} }) => event.id === selectedEvent.id
  );

  const { endDate, isClosed, id } = selectedEvent;

  const basicEventEmissionsPieChartData =
    createBasicDataForEventEmissionsPieChart(selectedEvent);

  const combinedData = [
    ...eventTransactions,
    ...basicEventEmissionsPieChartData,
  ];

  return {
    content: (
      <Grid
        container
        rowGap={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item width="100%">
          <EmissionsPieChart
            dataArray={combinedData}
            CustomTooltip={EventPieChartTooltip}
          />
        </Grid>
        <Grid item width="100%">
          <EmissionsDetailsTableAccordion
            type="events"
            emissions={eventTransactions}
            isExpanded={!showCharts}
            onChange={onAccordionClick}
            setSelectedTransaction={setSelectedTransaction}
            closeSelectedObjectSlider={closeSelectedEvent}
          />
        </Grid>
        {isClosed && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/platform/company/purchase")}
            >
              Buy offsets
            </Button>
          </Grid>
        )}
        {endDate < new Date() && !isClosed && (
          <Grid item>
            <WarningPopup
              backgroundColor={palette.mileage.main}
              warningTitle="This event has ended and can be closed. Once an event is closed you can no longer edit it, no transactions can be tagged to it, and offsets become purchasable for the event."
              warningInput={
                <Grid
                  item
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {isShowConfirmCloseEvent ? (
                    <LoadingButton
                      label="Confirm closing this event"
                      onClick={async () => {
                        setIsClosingEvent(true);

                        await updateCollectionDoc("events", id, {
                          isClosed: true,
                        });

                        return closeSelectedEvent();
                      }}
                      isLoading={isClosingEvent}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setIsShowConfirmCloseEvent(true)}
                    >
                      Close this event
                    </Button>
                  )}
                </Grid>
              }
            />
          </Grid>
        )}
      </Grid>
    ),
  };
};

const EventDetailsSlider = ({
  selectedEvent,
  setSelectedEvent,
  setSelectedTransaction,
  closeSelectedEvent,
}) => {
  const { id, isClosed } = selectedEvent;

  const { updateCollectionDoc } = useCachedFirebaseCrud();

  const { content: detailsContent, footer } = useEventDetailsTab(selectedEvent);
  const { content: attendeesContent } = useEventAttendeesTab({
    selectedEvent,
  });
  const { content: emissionsContent } = useEventEmissionsTab({
    selectedEvent,
    setSelectedTransaction,
    closeSelectedEvent,
  });

  const onNameSave = (name) => updateCollectionDoc("events", id, { name });

  return (
    <SettingsObjectDetailsSlider
      type="events"
      setSelectedObject={setSelectedEvent}
      settingsObject={selectedEvent}
      footerInputSubcategories={subcategories
        .filter(({ tags = [] }) => tags.includes("events"))
        .map(({ subcategory }) => ({
          subcategory,
        }))}
      onNameSave={!isClosed && onNameSave}
      views={[
        {
          label: "Emissions",
          value: "emissions",
          content: emissionsContent,
          contentPosition: "flex-start",
        },
        {
          label: "Details",
          value: "details",
          content: detailsContent,
          footer,
        },
        {
          label: "Attendees",
          value: "attendees",
          content: attendeesContent,
        },
        // {
        //   label: "Reports",
        //   value: "reports",
        //   content: <div>Reports</div>,
        // },
      ]}
      noFooter={isClosed}
    />
  );
};
export default EventDetailsSlider;
