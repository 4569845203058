import React, { useState } from "react";

import {
  Grid,
  TableSortLabel,
  Tooltip,
  Switch,
  FormControlLabel,
  IconButton,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Card from "../atoms/mui/Card";

const ViewGraphCard = ({
  analyticsCta,
  subtitle,
  graphs,
  color,
  dataArray,
  icon,
  toggles,
  ...otherProps
}) => {
  const { palette } = useTheme();

  const [{ type: initialType }] = graphs;

  const [selectedGraphType, setSelectedGraphType] = useState(initialType);
  const [isAsc, setIsAsc] = useState(true);

  const handleOrderChange = () => setIsAsc((isAsc) => !isAsc);

  const {
    Graph,
    graphProps = {},
    title,
    sortable,
  } = graphs?.find((graphObj) => graphObj.type === selectedGraphType) || {};

  const sortedDataArray = isAsc ? dataArray : [...dataArray].reverse();

  return (
    <Card
      style={{ overflow: "visible" }}
      cardType="layout"
      icon={icon}
      colorType="secondary"
      title={title}
      subtitle={subtitle}
      action={
        <Grid container alignItems="center" spacing={1}>
          {toggles &&
            toggles.map(({ value, setValue, label }, idx) => (
              <Grid item key={`graph-toggle-btn-${idx}`}>
                <FormControlLabel
                  value={label}
                  label={label}
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => setValue(e.target.checked)}
                    />
                  }
                  labelPlacement="start"
                />
              </Grid>
            ))}
          {graphs.length > 1 &&
            graphs.map(({ type, icon }, idx) => (
              <Grid item key={`graph-toggle-btn-${idx}`}>
                <IconButton onClick={() => setSelectedGraphType(type)}>
                  <FontAwesomeIcon
                    style={{
                      color:
                        type === selectedGraphType
                          ? palette[color].main
                          : "inherit",
                    }}
                    icon={icon}
                  />
                </IconButton>
              </Grid>
            ))}
          {selectedGraphType === "bar" && sortable && (
            <Grid item>
              <Tooltip title="Change sort order">
                <span>
                  <IconButton
                    onClick={handleOrderChange}
                    style={{ height: "50px" }}
                  >
                    <TableSortLabel
                      active={true}
                      direction={isAsc ? "asc" : "desc"}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      }
      content={<Graph {...graphProps} dataArray={sortedDataArray} />}
      {...otherProps}
    />
  );
};
export default ViewGraphCard;
