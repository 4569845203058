import React, { useState } from "react";

import { Grid, Typography } from "@mui/material";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { Select, TextField, ToggleButtons } from "@aclymatepackages/atoms";
import { ucFirstLetters } from "@aclymatepackages/formatters";

import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import {
  isEmissionsInputSchemaEnabled,
  buildInitialTransactionInputValueFromSchema,
} from "../../../helpers/components/inputs";

const equipmentTypes = [
  { label: "Ships and Boats", value: "boats" },
  { label: "Locomotives", value: "locomotives" },
  {
    label: "Agricultural Equipment A",
    value: "agricultural-equipment-a",
  },
  {
    label: "Agricultural Offroad Trucks",
    value: "agricultural-offroad-trucks",
  },
  {
    label: "Construction/Mining Equipment B",
    value: "construction-equipment-b",
  },
  {
    label: "Construction/Mining Offroad Trucks",
    value: "construction-offroad-trucks",
  },
  { label: "Lawn and Garden Equipment", value: "lawn-garden" },
  {
    label: "Industrial/Commercial Equipment",
    value: "industrial",
  },
  { label: "Logging Equipment", value: "logging" },
  { label: "Railroad Equipment", value: "railroad" },
  { label: "Recreational Equipment", value: "recreational" },
];

const fuelTypes = [
  {
    value: "gas",
    name: "Gasoline",
    id: "gas",
  },
  {
    value: "diesel",
    name: "Diesel",
    id: "diesel",
  },
  {
    value: "lpg",
    name: "LPG",
    id: "lgp",
  },
];

const EquipmentFuelInputBlock = ({
  setCalcLoading,
  editEquipmentFuelData,
  equipmentType,
  billGallons,
  fuelType,
}) => {
  const getAvailableFuelTypesForEquipment = () => {
    const fuelOptionsForEquipments = {
      boats: ["gas", "diesel"],
      locomotives: ["diesel"],
      industrial: ["gas", "diesel", "lpg"],
      logging: ["gas", "diesel"],
      railroad: ["gas", "diesel", "lpg"],
      recreational: ["gas", "diesel", "lpg"],
      "lawn-garden": ["gas", "diesel", "lpg"],
      "agricultural-equipment-a": ["gas", "diesel", "lpg"],
      "agricultural-offroad-trucks": ["gas", "diesel"],
      "construction-equipment-b": ["gas", "diesel", "lpg"],
      "construction-offroad-trucks": ["gas", "diesel"],
    };

    if (equipmentType) {
      const types = fuelOptionsForEquipments[equipmentType];

      return types.map((type) => ({
        value: type,
        name: ucFirstLetters(type),
        id: type,
      }));
    }

    return fuelTypes;
  };

  const availableFuelTypes = getAvailableFuelTypesForEquipment();

  const onInputChange = async (gallons, equipmentType, fuelType) => {
    if (
      !gallons ||
      !equipmentType ||
      !fuelType ||
      !availableFuelTypes.find(({ id }) => id === fuelType)
    ) {
      return editEquipmentFuelData("tonsCo2e")(0);
    }

    setCalcLoading(true);

    const { tonsCo2e } = await fetchOurApi({
      path: "/calcs/equipment/carbon-from-gallons",
      method: "POST",
      data: {
        gallons,
        equipmentType,
        fuelType,
      },
      callback: (res) => res,
    });

    editEquipmentFuelData("tonsCo2e")(tonsCo2e);
    return setCalcLoading(false);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6" gutterBottom>
          What kind of equipment is this?
        </Typography>
        <Select
          options={equipmentTypes}
          size="small"
          label="Select Equipment"
          value={equipmentType}
          editValue={(equipment) => {
            editEquipmentFuelData("equipmentType")(equipment);
            return onInputChange(billGallons, equipment, fuelType);
          }}
        />
      </Grid>
      <Grid item>
        <Typography variant="h6" gutterBottom>
          Gallons of Fuel Purchased
        </Typography>
        <TextField
          label="Gallons of Fuel"
          variant="outlined"
          fullWidth
          value={billGallons}
          setValue={(gallons) => {
            editEquipmentFuelData("billGallons")(gallons);
            return onInputChange(gallons, equipmentType, fuelType);
          }}
          helperText={
            !!billGallons &&
            (!numbersRegExpTest(billGallons) || billGallons >= 10000)
              ? "This must be a number and be less than 10,000"
              : ""
          }
          id="fuel-gallons-input"
        />
      </Grid>
      <Grid item style={{ alignSelf: "center" }}>
        <ToggleButtons
          size="small"
          value={fuelType}
          onChange={(type) => {
            editEquipmentFuelData("fuelType")(type);
            return onInputChange(billGallons, equipmentType, type);
          }}
          buttons={getAvailableFuelTypesForEquipment()}
          style={{ margin: "auto" }}
        />
      </Grid>
    </Grid>
  );
};

const useEquipmentFuelInput = ({ transaction, onSave, setCalcLoading }) => {
  const inputSchema = [
    { field: "fuelType", defaultValue: "gas" },
    { field: "equipmentType" },
    { field: "billGallons" },
  ];

  const [equipmentFuelData, setEquipmentFuelData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );

  const { tonsCo2e } = equipmentFuelData;

  const editEquipmentFuelData = (field) => (value) =>
    editObjectData(setEquipmentFuelData, field, value);

  const onTransactionSave = () => onSave({ ...equipmentFuelData, tonsCo2e });

  const saveEnabled = isEmissionsInputSchemaEnabled(
    equipmentFuelData,
    inputSchema
  );

  return {
    inputBlock: (
      <EquipmentFuelInputBlock
        setCalcLoading={setCalcLoading}
        editEquipmentFuelData={editEquipmentFuelData}
        {...equipmentFuelData}
      />
    ),
    saveEnabled,
    onTransactionSave,
    tonsCo2e,
  };
};
export default useEquipmentFuelInput;
