import React, { useState } from "react";
import dayjs from "dayjs";

import { Grid, Button } from "@mui/material";

import { Popper } from "@aclymatepackages/atoms";

import DatePicker from "../../../../atoms/mui/DatePicker";

import useAccountingData from "../../../../../helpers/hooks/accountingData";

const DatePopper = ({
  employee,
  anchorEl,
  setAnchorEl,
  selectedScheduleProps = {},
  open,
  onClick,
  title,
  buttonText,
}) => {
  const [{ earliestNewEmissionDate }] = useAccountingData();

  const { adjSchedule, prevSchedule, endDate } = selectedScheduleProps || {};

  const [date, setDate] = useState(dayjs(endDate) || dayjs(new Date()));

  const { startDate } = employee || {};

  return (
    <Popper
      isOpen={open}
      anchorEl={anchorEl}
      style={{ zIndex: 2002, width: "450px" }}
      title={title}
      content={
        <Grid
          item
          container
          direction="column"
          spacing={1}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <DatePicker
              minDate={
                earliestNewEmissionDate || prevSchedule?.startDate || startDate
              }
              maxDate={adjSchedule?.endDate || dayjs(new Date())}
              date={date}
              editDate={(date) => setDate(date)}
            />
          </Grid>
          <Grid item container justifyContent="space-between">
            <Grid item>
              <Button onClick={() => setAnchorEl(null)}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onClick(date)}
              >
                {buttonText || "Save"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};
export default DatePopper;
