import React, { useState } from "react";

import { Tooltip } from "@mui/material";

import { LoadingButton } from "@aclymatepackages/atoms";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { sendEmployeeSurvey } from "../../../helpers/components/employees";

const SendEmployeeSurveyButton = ({ employee }) => {
  const [sendSurveyLoading, setSendSurveyLoading] = useState(false);
  const [surveySent, setSurveySent] = useState(false);

  return (
    <Tooltip
      title={
        surveySent ? "This survey has been sent!" : "Send employee survey link"
      }
    >
      <span>
        <LoadingButton
          variant="contained"
          color="primary"
          isLoading={sendSurveyLoading}
          startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
          disabled={!sendSurveyLoading && surveySent}
          onClick={() => {
            setSurveySent(true);
            return sendEmployeeSurvey({ employee, setSendSurveyLoading });
          }}
          label="Send Survey"
        />
      </span>
    </Tooltip>
  );
};
export default SendEmployeeSurveyButton;
