import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import OAuthButtons from "../account-access/OAuthButtons";

import { LoadingButton } from "@aclymatepackages/atoms";
import { FREE_TRIAL_DAYS } from "@aclymatepackages/constants";

import PasswordInput from "../account-access/PasswordInput";
import PurchaseDialogLayout from "../layouts/PurchaseDialogLayout";

import { isObjectEmpty } from "../../helpers/otherHelpers";
import {
  fetchOurApi,
  setUserCustomClaims,
  updateAccountData,
} from "../../helpers/utils/apiCalls";
import { onSignInWithEmailAndPassword } from "../../helpers/components/accountAccess";

const SubscriptionAccountCreation = ({
  individualData,
  setIndividualData,
  setPaymentFlowStep,
}) => {
  const [password, setPassword] = useState("");
  const [newUserLoading, setNewUserLoading] = useState(false);
  const [existingAccountAuthData, setExistingAccountAuthData] = useState({});
  const [existingAccountPassword, setExistingAccountPassword] = useState("");
  const [isExistingAccountCheckLoading, setIsExistingAccountCheckLoading] =
    useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const {
    email: individualEmail,
    name: individualName,
    id: individualId,
  } = individualData;

  useEffect(() => {
    const checkForExistingAccount = async () => {
      const existingUserData = await fetchOurApi({
        path: "/onboarding/check-email",
        method: "POST",
        data: { email: individualEmail },
        callback: ({ success, data }) => {
          if (success) {
            return data;
          }
          return false;
        },
      });

      if (existingUserData) {
        const { displayName, email, uid } = existingUserData;

        setExistingAccountAuthData({
          displayName,
          email,
          uid,
        });
      }

      return setIsExistingAccountCheckLoading(false);
    };

    if (isExistingAccountCheckLoading) {
      checkForExistingAccount();
    }
  }, [individualEmail, isExistingAccountCheckLoading]);

  const generateIndividualData = ({
    uid,
    displayName,
    userEmail,
    stripeCustomerId,
  }) => ({
    dateCreated: new Date(),
    trialDate: dayjs().add(FREE_TRIAL_DAYS, "days").toDate(),
    uids: [uid],
    users: [{ uid, name: displayName, email: userEmail }],
    stripeCustomerId,
    billing: {},
    name: displayName,
  });

  const updateAccountAndSetClaims = async ({ uid, individualUpdateObj }) => {
    await updateAccountData("individuals", individualId, individualUpdateObj);

    await setUserCustomClaims({
      uid,
      accountId: individualId,
      accountCreationType: "individual",
    });

    return window.sessionStorage.setItem(
      "accountId",
      `individuals-${individualId}`
    );
  };

  const completePasswordSetup = async () => {
    setNewUserLoading(true);

    const getAccountData = async () => {
      if (isObjectEmpty(existingAccountAuthData)) {
        return await fetchOurApi({
          path: "/onboarding/new-auth-user",
          method: "POST",
          data: {
            email: individualEmail,
            password,
            displayName: individualName,
          },
          callback: (res) => res,
        });
      }

      return existingAccountAuthData;
    };

    const { uid, displayName, email: userEmail } = await getAccountData();

    const stripeCustomerId = await fetchOurApi({
      path: "/onboarding/create-stripe-customer",
      method: "POST",
      data: {
        name: displayName,
        metadata: { representative: displayName },
        email: userEmail,
      },
      callback: ({ id }) => id,
    });

    const individualUpdateObj = generateIndividualData({
      uid,
      displayName,
      userEmail,
      stripeCustomerId,
    });

    await updateAccountAndSetClaims({ uid, individualUpdateObj });

    const user = await onSignInWithEmailAndPassword({
      email: userEmail,
      password: !isObjectEmpty(existingAccountAuthData)
        ? existingAccountPassword
        : password,
      callback: (res) => res,
      setUserLoading: setNewUserLoading,
      setErrorMsg,
    });

    await user.getIdToken(true);

    setIndividualData({
      ...individualData,
      ...individualUpdateObj,
    });

    return setPaymentFlowStep("payment-information");
  };

  const completeOAuthSetup = async (user) => {
    const { uid, displayName, email: userEmail } = user;

    const stripeCustomerId = await fetchOurApi({
      path: "/onboarding/create-stripe-customer",
      method: "POST",
      data: {
        name: displayName,
        metadata: { representative: displayName },
        email: userEmail,
      },
      callback: ({ id }) => id,
    });

    const individualUpdateObj = generateIndividualData({
      uid,
      displayName,
      userEmail,
      stripeCustomerId,
    });

    await updateAccountAndSetClaims({ uid, individualUpdateObj });

    setIndividualData({
      ...individualData,
      ...individualUpdateObj,
    });

    return setPaymentFlowStep("payment-information");
  };

  return (
    <PurchaseDialogLayout
      title="Enter a password for your account"
      subtitle="We need a password for your myAclymate account before we can continue"
    >
      <Box p={2}>
        <Grid container spacing={2} alignItems="center" direction="column">
          {isExistingAccountCheckLoading ? (
            <Grid item>
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {!isObjectEmpty(existingAccountAuthData) ? (
                <Grid item>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography variant="body1" align="center">
                        Looks like we found an Aclymate account already
                        associated with your email address. You can use the same
                        password to continue.
                      </Typography>
                    </Grid>
                    <Grid item>
                      <OAuthButtons callback={completeOAuthSetup} />
                    </Grid>
                    <Grid item>
                      <PasswordInput
                        password={existingAccountPassword}
                        setPassword={setExistingAccountPassword}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item>
                  <PasswordInput
                    password={password}
                    setPassword={setPassword}
                    helperText
                  />
                </Grid>
              )}
              <Grid item>
                <LoadingButton
                  id="new-user-submit"
                  color="primary"
                  label={
                    !isObjectEmpty(existingAccountAuthData) ? "Login" : "Next"
                  }
                  disabled={
                    isObjectEmpty(existingAccountAuthData) &&
                    password.length < 12
                  }
                  onClick={() => completePasswordSetup()}
                  isLoading={newUserLoading}
                />
              </Grid>
              {errorMsg && (
                <Grid item>
                  <Typography variant="body2" color="error">
                    {errorMsg}
                  </Typography>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </PurchaseDialogLayout>
  );
};
export default SubscriptionAccountCreation;
