import React from "react";

import { Popper, Autocomplete } from "@aclymatepackages/atoms";

import { Grid, Button } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FilterRow = ({ icon, filterComponent, autocomplete }) => {
  const { setValue, ...otherAutocompleteProps } = autocomplete || {};

  return (
    <Grid
      item
      container
      spacing={2}
      alignItems="center"
      justifyContent="space-evenly"
      wrap="nowrap"
    >
      <Grid item>
        <FontAwesomeIcon icon={icon} size="2x" />
      </Grid>
      <Grid item xs={10}>
        {autocomplete ? (
          <Autocomplete
            multiple
            onDelete={({ name }) =>
              setValue((currentValue) =>
                currentValue.filter((filterObj) => name !== filterObj.name)
              )
            }
            setValue={(newValue) => setValue(newValue)}
            {...otherAutocompleteProps}
          />
        ) : (
          filterComponent
        )}
      </Grid>
    </Grid>
  );
};

const FilterPopper = ({
  open,
  anchorEl,
  setAnchorEl,
  filterPopperProps = {},
}) => {
  const { filterRows = [], resetFilters, title } = filterPopperProps;

  return (
    <Popper
      isOpen={open}
      onClose={() => setAnchorEl(null)}
      anchorEl={anchorEl}
      placement="bottom-end"
      title={title}
      style={{ maxWidth: "600px" }}
      content={
        <>
          {filterRows.map((filter, idx) => (
            <FilterRow {...filter} key={`filter-row-${idx}`} />
          ))}
          {resetFilters && (
            <Grid item container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => resetFilters()}
                >
                  Reset Filters
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      }
    />
  );
};
export default FilterPopper;
