import React from "react";

import { Grid, Typography, Avatar, useTheme } from "@mui/material";

const SliderHeader = ({ avatarColor = "secondary", icon, title }) => {
  const { palette } = useTheme();

  return (
    <Grid container spacing={2} wrap="nowrap" alignItems="center">
      <Grid item>
        <Avatar style={{ backgroundColor: palette[avatarColor].main }}>
          {icon}
        </Avatar>
      </Grid>
      <Grid item>
        <Typography variant="h3">{title}</Typography>
      </Grid>
    </Grid>
  );
};
export default SliderHeader;
