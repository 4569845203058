import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import { AnimatedLogo } from "@aclymatepackages/atoms";
import Link from "../atoms/mui/Link";
import { fetchOurApi } from "../../helpers/utils/apiCalls";

const UserVerification = () => {
  const [isAccessGranted, setIsAccessGranted] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    const adminResponse = new URLSearchParams(window.location.search).get(
      "value"
    );
    const userUid = new URLSearchParams(window.location.search).get("id");
    const companyId = new URLSearchParams(window.location.search).get(
      "companyId"
    );
    const userEmail = new URLSearchParams(window.location.search).get("email");

    const sendAcceptanceEmailToUser = async () => {
      return await fetchOurApi({
        path: "/sendgrid/send-acceptance-email",
        method: "POST",
        data: { userEmail, companyId },
      });
    };

    const addUser = async () => {
      return await fetchOurApi({
        path: "/users/add-user-by-admin",
        method: "POST",
        data: { companyId, userUid },
      });
    };

    const handleUserVerification = async () => {
      if (!adminResponse) {
        return setIsAccessGranted(false);
      }
      setIsAccessGranted(true);
      await addUser();
      return await sendAcceptanceEmailToUser();
    };

    if (adminResponse || adminResponse === false) {
      setDataLoading(true);
      handleUserVerification();
      setDataLoading(false);
    }
  }, []);

  if (dataLoading) {
    return <AnimatedLogo />;
  }

  return (
    <Container maxWidth="md" style={{ height: "100vh" }}>
      <Grid
        container
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%" }}
      >
        <Grid item>
          <Typography variant="h5">
            {!isAccessGranted
              ? "You denied this user access to your account"
              : "This user has been granted access to your company account"}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6" color="textSecondary">
            You can close the window
          </Typography>
        </Grid>
        <Grid item>
          <Link href="https://aclymate.app/login">
            <Button variant="contained">Login</Button>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UserVerification;
