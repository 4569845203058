import React, { useState, useEffect, useContext } from "react";

import { Box, Grid, Typography, CircularProgress, Button } from "@mui/material";

import { DefaultPaper, LoadingButton } from "@aclymatepackages/atoms";

import PurchaseFlowPaymentInfoBox from "./PurchaseFlowPaymentInfoBox";
import SubscriptionAccountCreation from "./SubscriptionAccountCreation";

import PurchaseDialogLayout from "../layouts/PurchaseDialogLayout";
import OffsetProjectCard from "../modules/projects/OffsetProjectCard";

import { fetchOurApi } from "../../helpers/utils/apiCalls";
import { analyticsTrack } from "../../helpers/analytics";
import offsetBuckets from "../../helpers/components/display-lists/offsetBuckets";
import {
  useCustomerPaymentMethod,
  useCreateStripeSubscription,
} from "../../helpers/hooks/stripe";
import { StripeCustomerContext } from "../../helpers/contexts/stripeCustomer";
import { useAuth } from "../../helpers/firebase";
import { getAccountCollectionAndId } from "../../helpers/otherHelpers";

const SubscriptionSignupBlock = ({
  selectedOffsetBucket,
  individualData,
  stripePriceId,
}) => {
  const { email } = individualData;

  const { stripeSubmit } = useCreateStripeSubscription();

  const [saveLoading, setSaveLoading] = useState(false);

  const { title } = offsetBuckets[selectedOffsetBucket];

  return (
    <Grid container spacing={1} direction="column" alignItems="center">
      <Grid item>
        <LoadingButton
          isLoading={saveLoading}
          label="Subscribe"
          color="secondary"
          onClick={async () => {
            setSaveLoading(true);

            await stripeSubmit({
              subscriptionType: selectedOffsetBucket,
              stripePriceId,
              interval: "month",
              product: selectedOffsetBucket,
            });

            return analyticsTrack(
              "myAclymate Offset Subscription Purchase (Impact Survey)",
              {
                email,
                title,
              }
            );
          }}
        />
      </Grid>
      <Grid item>
        <Typography
          color="textSecondary"
          align="center"
          variant="caption"
          component="div"
        >
          {`By clicking this button you agree to immediately create a subscription for our ${title} plan. You will be automatically billed on the interval that you've selected until you cancel. Your payment method will immediately be billed the amount shown above.`}
        </Typography>
      </Grid>
    </Grid>
  );
};

const SubscriptionPurchaseBox = ({
  selectedOffsetBucket,
  individualData,
  paymentMethodId,
  offsetsToDisplay,
  setDisplayProject,
  handleSubscribeOnClick,
}) => {
  const { title, tagline, descriptor, stripePriceId } =
    offsetBuckets[selectedOffsetBucket];

  return (
    <PurchaseDialogLayout title={title} subtitle={tagline}>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="body1">{descriptor}</Typography>
          </Grid>
          <Grid item>
            {paymentMethodId ? (
              <SubscriptionSignupBlock
                selectedOffsetBucket={selectedOffsetBucket}
                individualData={individualData}
                stripePriceId={stripePriceId}
              />
            ) : (
              <Grid item container justifyContent="center">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSubscribeOnClick}
                >
                  Subscribe
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Typography variant="body1" align="center">
              Here are just some of the projects you can support
            </Typography>
          </Grid>
          <Grid item container>
            <Grid container spacing={2} justifyContent="center">
              {offsetsToDisplay.map(({ totalThousandLbsCost, location, ...otherProjectProps }, idx) => (
                <OffsetProjectCard
                  key={idx}
                  project={otherProjectProps}
                  onSelectProject={setDisplayProject}
                  isFullScreen
                  noIcons
                  disableHref
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </PurchaseDialogLayout>
  );
};

const MyAclymateSurveyOffsetSubscriptionPurchase = ({
  individualId,
  selectedOffsetBucket,
  offsetsToDisplay,
  setDisplayProject,
}) => {
  const [user, userLoading] = useAuth();

  const [individualData, setIndividualData] = useState({});
  const [paymentFlowStep, setPaymentFlowStep] = useState("");

  const { uids, stripeCustomerId } = individualData;

  const { paymentMethod, isPaymentMethodLoading } =
    useCustomerPaymentMethod(stripeCustomerId);

  const {
    subscriptions,
    isSubscriptionsLoading,
    refetchCustomerSubscriptions,
  } = useContext(StripeCustomerContext);
  const { paymentMethodId } = paymentMethod || {};

  const findSubscriptionByType = (subscriptionType) =>
    subscriptions.find(({ type }) => type === subscriptionType);

  const { type: currentOffsetSubscriptionType, status: currentOffsetSubscriptionStatus } =
    findSubscriptionByType("naturalAndWorkingLands") ||
    findSubscriptionByType("cleanTransition") ||
    findSubscriptionByType("climateJustice") ||
    {};
  const { title: currentOffsetSubscriptionTypeTitle } =
    offsetBuckets[currentOffsetSubscriptionType] || {};

  const { title } = offsetBuckets[selectedOffsetBucket];

  useEffect(() => {
    if (individualId && !Object.keys(individualData).length) {
      fetchOurApi({
        path: `/individuals/${individualId}`,
        method: "GET",
        callback: (res) => {
          setIndividualData(res);

          const { stripeCustomerId } = res || {};

          return refetchCustomerSubscriptions(stripeCustomerId);
        },
      });
    }
  });

  useEffect(() => {
    const setSessionStorageAccountId = async () => {
      const { id: individualId } = getAccountCollectionAndId();

      if (!individualId && user && !userLoading) {
        const { claims } = await user.getIdTokenResult({ forceRefresh: true });
        const { individual } = claims;
  
        window.sessionStorage.setItem("accountId", `individuals-${individual}`);
      }
    }

    setSessionStorageAccountId()
  }, [user, userLoading])

  const changePaymentFlowStep = () => {
    if (!uids || !user) {
      return setPaymentFlowStep("create-account");
    }

    if (!paymentMethodId) {
      return setPaymentFlowStep("payment-information");
    }
    return setPaymentFlowStep("");
  };

  const generateContent = () => {
    if (
      !Object.keys(individualData).length ||
      isPaymentMethodLoading ||
      isSubscriptionsLoading ||
      userLoading
    ) {
      return (
        <DefaultPaper style={{ width: "100%" }}>
          <Grid item container justifyContent="center">
            <CircularProgress />
          </Grid>
        </DefaultPaper>
      );
    }

    if (currentOffsetSubscriptionType && currentOffsetSubscriptionStatus === "active") {
      return (
        <PurchaseDialogLayout
          title="Thank you for subscribing!"
          subtitle={`You are subscribed to the ${currentOffsetSubscriptionTypeTitle} plan.`}
        >
          <Box p={2}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="body1">
                  If you would like to manage your subscription, login to your
                  MyAclymate account.
                </Typography>
              </Grid>
              <Grid item container justifyContent="center">
                <Button variant="contained" color="primary" href="/login">
                  Login
                </Button>
              </Grid>
            </Grid>
          </Box>
        </PurchaseDialogLayout>
      );
    }

    if (paymentFlowStep === "create-account") {
      return (
        <SubscriptionAccountCreation
          individualData={individualData}
          setIndividualData={setIndividualData}
          setPaymentFlowStep={setPaymentFlowStep}
        />
      );
    }

    if (paymentFlowStep === "payment-information") {
      return (
        <PurchaseFlowPaymentInfoBox
          individual={individualData}
          dialogSubtitle="We need your credit card information before you can subscribe"
          selectedOffsetBucket={selectedOffsetBucket}
          subscriptionTitle={title}
        />
      );
    }

    return (
      <SubscriptionPurchaseBox
        selectedOffsetBucket={selectedOffsetBucket}
        individualData={individualData}
        paymentMethodId={paymentMethodId}
        offsetsToDisplay={offsetsToDisplay}
        setDisplayProject={setDisplayProject}
        handleSubscribeOnClick={changePaymentFlowStep}
      />
    );
  };

  return generateContent();
};
export default MyAclymateSurveyOffsetSubscriptionPurchase;
