import React, { useState } from "react";

import {
  Grid,
  Paper,
  IconButton,
  Collapse,
  Alert,
  AlertTitle,
  Box,
  ButtonBase,
} from "@mui/material";

import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";

import { useLayoutHelpers } from "@aclymatepackages/themes";

const AlertBox = ({
  title,
  subtitle,
  action,
  severity = "warning",
  icon,
  isCollapsible,
  variant = "standard",
  open,
  setOpen,
}) => {
  const { isMobile } = useLayoutHelpers();

  return (
    <Collapse in={open}>
      <Paper>
        <Alert
          style={{ textAlign: "left" }}
          variant={variant}
          severity={severity}
          icon={
            <Grid item container alignItems="center">
              {icon || <WarningIcon fontSize="large" color="inherit" />}
            </Grid>
          }
          action={
            <Box
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2} alignItems="center" wrap="nowrap">
                {action && !isMobile && <Grid item>{action}</Grid>}
                {isCollapsible && (
                  <Grid item>
                    <IconButton onClick={() => setOpen(false)} size="large">
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Box>
          }
        >
          <AlertTitle>{title}</AlertTitle>
          {subtitle}
        </Alert>
      </Paper>
    </Collapse>
  );
};

const CollapsibleAlert = ({ idx, onClick, ...otherProps }) => {
  const { isMobile } = useLayoutHelpers();

  const [open, setOpen] = useState(true);

  const closedStyleObj = !open
    ? {
        padding: "0px",
      }
    : {};

  const alertProps = { ...otherProps, open, setOpen };

  return (
    <Grid item idx={`collapsible-alert-${idx}`} style={closedStyleObj}>
      {isMobile ? (
        <ButtonBase onClick={onClick}>
          <AlertBox {...alertProps} />
        </ButtonBase>
      ) : (
        <AlertBox {...alertProps} />
      )}
    </Grid>
  );
};
export default CollapsibleAlert;
