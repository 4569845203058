import React, { useState, useEffect, useContext } from "react";

import { Grid, Typography } from "@mui/material";

import { Select } from "@aclymatepackages/atoms";
import { utilitiesProviders } from "@aclymatepackages/lists";

import MultiPartFormLayout from "../flows/MultipartForm";

import PaginatedRowDisplay from "../csv-uploaders/PaginatedRowDisplay";

import CircularProgressWithLabel from "../../atoms/loading/CircularProgressWithLabel";
import TitleHelperIcon from "../../atoms/icons/TitleHelperIcon";

import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import { useCachedFirebaseCrud } from "../../../helpers/firebase";
import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";
import {
  serviceClassDescription,
  useCreateMeterTasks,
  useFetchMeters,
  useUtilityAuthStep,
} from "../../../helpers/components/utilitiesApi";
import { useOfficesWithCurrentChanges } from "../../../helpers/components/offices";

const useUtilitySelectionStep = ({ utilitiesProvidersList }) => {
  const [selectedUtilitiesProvider, setSelectedUtilitiesProvider] = useState();
  const utilitiesProviderOptions = utilitiesProvidersList.map(
    ({ name, utilityId }) => ({ label: name, value: utilityId })
  );

  return {
    utilitySelectionStep:
      utilitiesProvidersList.length > 1
        ? [
            {
              label: "Select Your Utility Provider",
              title: "Select Your Utility Provider",
              subtitle:
                "There are multiple utility providers near this office. Please select the provider that services this office.",
              titleIcon: (
                <TitleHelperIcon
                  tooltipText="Don't see your utility provider in the dropdown below? Click to learn how you can add your utilities."
                  href=""
                />
              ),
              input: (
                <Select
                  options={utilitiesProviderOptions}
                  label="Utility Provider"
                  value={selectedUtilitiesProvider?.value || ""}
                  editValue={(value) =>
                    setSelectedUtilitiesProvider(
                      utilitiesProviderOptions.find(
                        (provider) => provider.value === value
                      )
                    )
                  }
                />
              ),
              buttonDisabled: !selectedUtilitiesProvider,
            },
          ]
        : [],
    selectedUtilitiesProviderId:
      utilitiesProvidersList.length > 1
        ? selectedUtilitiesProvider?.value
        : utilitiesProviderOptions[0]?.value,
  };
};

const MeterPairingRow = ({
  serviceAddress,
  serviceClass,
  meterName,
  pairedOffice,
  officesOptions,
  offices,
  setPairedMetersList,
}) => {
  const { officeId } = pairedOffice || {};

  const [selectedOffice, setSelectedOffice] = useState(officeId || "");

  const ignoreMeterOption = {
    label: "This meter isn't tied to one of our offices",
    value: "ignore",
  };

  const handleSetPairedMetersList = (selectedOfficeId) =>
    setPairedMetersList((currentPairedMetersList) => {
      const { name = "" } =
        offices.find(({ id }) => id === selectedOfficeId) || {};
      const currentPairedMeter = currentPairedMetersList.find(
        ({ meterName: pairedMeterName }) => pairedMeterName === meterName
      );
      const otherPairedMeters = currentPairedMetersList.filter(
        ({ meterName: pairedMeterName }) => pairedMeterName !== meterName
      );
      const newPairedMeter = {
        ...currentPairedMeter,
        pairedOffice: { name, officeId: selectedOfficeId },
      };

      return [...otherPairedMeters, newPairedMeter].sort(
        (a, b) => a.meterIdx - b.meterIdx
      );
    });

  const handleSetSelectedOffice = (selectedOffice) => {
    handleSetPairedMetersList(selectedOffice);
    return setSelectedOffice(selectedOffice);
  };

  return (
    <Grid item container alignItems="center">
      <Grid item xs={5}>
        <Typography variant="body1">{meterName}</Typography>
        <Typography variant="body2">{serviceAddress}</Typography>
        <Typography variant="body2">
          {`Meter type: ${serviceClassDescription(serviceClass)}`}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Select
          size="small"
          label="Select an office"
          value={selectedOffice}
          editValue={handleSetSelectedOffice}
          options={[ignoreMeterOption, ...officesOptions]}
          smallFont
        />
      </Grid>
    </Grid>
  );
};

const useMeterPairingStep = ({ utilityId, setOpen }) => {
  const accountId = window.sessionStorage.getItem("accountId");
  const [offices, officesLoading] = useOfficesWithCurrentChanges();
  const [metersList, metersListLoading] = useFetchMeters({
    utilityId,
  });
  const { updateCollectionDoc } = useCachedFirebaseCrud();
  const createMeterTasks = useCreateMeterTasks();
  const { activateSnackbar } = useContext(PlatformLayoutContext);

  const [pairedMetersList, setPairedMetersList] = useState();

  const { states: coverageStates = [] } =
    utilitiesProviders.find(
      ({ utilityId: providerId }) => providerId === utilityId
    ) || {};

  useEffect(() => {
    if (!metersListLoading && !pairedMetersList) {
      setPairedMetersList(metersList);
    }
  }, [metersList, metersListLoading, pairedMetersList]);

  const officesOptions = offices
    .filter(
      ({
        linkedElectricUtilityId,
        linkedGasUtilityId,
        linkedCombinedUtilityId,
        utilities: { utilitiesInLease },
        address: { state },
        status,
      }) =>
        !linkedElectricUtilityId &&
        !linkedGasUtilityId &&
        !linkedCombinedUtilityId &&
        !utilitiesInLease &&
        status !== "closed" &&
        utilityId === "DEMO"
          ? true
          : coverageStates.includes(state)
    )
    .map(({ name, id }) => ({
      label: name,
      value: id,
    }));

  const isAllMetersPaired =
    pairedMetersList &&
    pairedMetersList
      .map(({ pairedOffice }) => !!pairedOffice)
      .reduce(
        (totalIsPaired, currentIsPaired) => totalIsPaired && currentIsPaired,
        true
      );

  const saveNewUtilitiesIntegration = async () => {
    const createUtilityKeys = (isOfficeCombinedUtility, isElectricMeter) => {
      if (isOfficeCombinedUtility) {
        return ["linkedCombinedUtility", "linkedCombinedUtilityId"];
      }

      if (isElectricMeter) {
        return ["linkedElectricUtility", "linkedElectricUtilityId"];
      }

      return ["linkedGasUtility", "linkedGasUtilityId"];
    };

    const pairedMetersListWithoutIgnoredMeters = pairedMetersList.filter(
      ({ pairedOffice: { officeId } }) => officeId !== "ignore"
    );
    pairedMetersListWithoutIgnoredMeters.forEach(
      ({ serviceClass, expirationDate, utilityId, pairedOffice }) => {
        const { officeId: pairedOfficeId } = pairedOffice;
        const isElectricMeter = [
          "res-electric",
          "comm-electric",
          "electric",
        ].includes(serviceClass);

        const linkedUtility = {
          name: utilitiesProviders.find(
            ({ utilityId: providerId }) => providerId === utilityId
          )?.name,
          utilityId,
          expirationDate,
          pairedMeters: pairedMetersListWithoutIgnoredMeters
            .filter(
              ({ pairedOffice: { officeId } }) => officeId === pairedOfficeId
            )
            .map(({ meterId, serviceAddress, serviceClass }) => ({
              meterId,
              serviceAddress,
              serviceClass,
            })),
        };
        const { utilities } = offices.find(({ id }) => id === pairedOfficeId);
        const { combinedUtility } = utilities;
        const [utilityObjKey, utilityIdKey] = createUtilityKeys(
          !!combinedUtility,
          isElectricMeter
        );

        return updateCollectionDoc("offices", pairedOfficeId, {
          [utilityIdKey]: utilityId,
          [utilityObjKey]: linkedUtility,
        });
      }
    );

    const meterIdsList = pairedMetersListWithoutIgnoredMeters.map(
      ({ meterId }) => meterId
    );

    await createMeterTasks(pairedMetersListWithoutIgnoredMeters);
    fetchOurApi({
      accountId,
      path: "/integrations/utilityApi/activate-meters",
      method: "POST",
      data: { metersList: meterIdsList },
      callback: (res) => res,
    });
    setOpen(false);

    return activateSnackbar({
      message:
        "Your utilities were successfully linked to your company offices.",
      alert: "success",
    });
  };

  return {
    label: "Pair Meters to Your Office(s)",
    title: "Pair Meters to Your Office(s)",
    subtitle:
      "Select the offices below to pair them with the following meters from your utility provider.",
    input:
      !pairedMetersList || officesLoading ? (
        <Grid container justifyContent="center">
          <Grid item>
            <CircularProgressWithLabel />
          </Grid>
        </Grid>
      ) : (
        <PaginatedRowDisplay
          rowList={pairedMetersList}
          RowComponent={MeterPairingRow}
          rowComponentProps={{
            officesOptions,
            setPairedMetersList,
            offices,
          }}
          rowKeyGenerator={(row) => `meter-pairing-row-${row.meterIdx}`}
        />
      ),
    buttonDisabled: !isAllMetersPaired,
    buttonText: "Submit",
    onAdvanceForm: saveNewUtilitiesIntegration,
    pairedMetersList,
  };
};

const LinkUtilitiesForm = ({ setOpen, utilitiesProvidersList }) => {
  const { utilitySelectionStep, selectedUtilitiesProviderId } =
    useUtilitySelectionStep({ utilitiesProvidersList });
  const utilityAuthStep = useUtilityAuthStep({
    utilityId: selectedUtilitiesProviderId,
  });
  const meterPairingStep = useMeterPairingStep({
    utilityId: selectedUtilitiesProviderId,
    setOpen,
  });

  const forms = [...utilitySelectionStep, ...utilityAuthStep, meterPairingStep];

  return (
    <MultiPartFormLayout
      type="utilitiesApi"
      onClose={() => setOpen(false)}
      forms={forms}
    />
  );
};
export default LinkUtilitiesForm;
