import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import {
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  Divider,
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import { Custom404 } from "@aclymatepackages/atoms";
import { hexToRgba } from "@aclymatepackages/converters";
import { mergePartnerTheme, mergeMainTheme } from "@aclymatepackages/themes";

import PageHeader from "./PageHeader";
import AccountCreationFlow from "./AccountCreationFlow";

import { useReferralCookie } from "../../helpers/components/accountAccess";
import { analyticsPage } from "../../helpers/analytics";
import { useLayoutHelpers } from "../../helpers/otherHelpers";

export const referralPartners = [
  {
    slug: "green-business-bureau",
    name: "Green Business Bureau",
    theme: {
      palette: {
        primary: { main: "#57ad18", light: "#9acd74" },
        secondary: { main: "#2c0253", light: "#806797" },
      },
    },
  },
  {
    slug: "good-business-colorado",
    name: "Good Business Colorado",
    theme: {
      palette: {
        primary: { main: "#091353", light: "#1226a5" },
        secondary: { main: "#FFAA40", light: "#ffd199" },
      },
    },
    backgroundImage: "/partner-logos/gbc-background.jpg",
  },
  {
    slug: "simple-switch",
    name: "Simple Switch",
    theme: {
      palette: {
        primary: { main: "#2a3890", light: "#5162cd" },
        secondary: { main: "#d49b65", light: "#fff6eb" },
      },
    },
  },
  {
    slug: "climeco",
    name: "Climeco",
    theme: {
      palette: { primary: { main: "#163a69" }, secondary: { main: "#4a9a63" } },
    },
  },
  {
    slug: "bld-west",
    name: "BLD West",
    title: "Welcome BLD West Members",
    subtitle: "Sign Up Below to Activate Your Limited-time 15% Discount",
    cta: "Discount Applied After Account Creation and Before Payment, No Credit Card Required",
    backgroundImage: "/partner-logos/bld-west-bg.jpeg",
  },
  {
    slug: "tonic",
    name: "Tonic",
    theme: {
      palette: { primary: { main: "#852066" }, secondary: { main: "#282f44" } },
    },
  },
];

const ReferralPartner = () => {
  const theme = useTheme();

  const { setReferralCookie } = useReferralCookie();
  const { standardBoxPadding, isMobile } = useLayoutHelpers();
  const { partnerSlug } = useParams();
  const partner = referralPartners.find(
    (partner) => partner.slug === partnerSlug
  );
  const {
    name,
    theme: partnerTheme,
    backgroundImage,
    title,
    subtitle,
    cta,
  } = partner || {};

  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (partner) {
      setReferralCookie(partnerSlug);
      analyticsPage(`Referral partner- ${name}`);
    }
  }, [partner, name, partnerSlug, setReferralCookie]);

  const pageTheme = partnerTheme || theme;

  return (
    <>
      {!partner ? (
        <Custom404 />
      ) : (
        <StyledEngineProvider injectFirst>
          <ThemeProvider
            theme={
              partnerTheme
                ? mergePartnerTheme(createTheme(partnerTheme))
                : mergeMainTheme
            }
          >
            <Grid
              container
              direction="column"
              style={{
                height: "100vh",
                boxSizing: "border-box",
                overflow: "hidden",
              }}
              wrap="nowrap"
            >
              <Grid item>
                <PageHeader />
              </Grid>
              <Grid
                item
                style={{
                  backgroundImage: backgroundImage
                    ? `url('/images${backgroundImage}')`
                    : "url('/images/website/beach.jpg')",
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  flexGrow: 1,
                  overflow: "hidden auto",
                }}
              >
                <Box
                  {...standardBoxPadding}
                  pt={8}
                  style={{
                    backgroundColor: hexToRgba(
                      pageTheme.palette.primary.main,
                      0.7
                    ),
                    minHeight: "100%",
                    boxSizing: "border-box",
                  }}
                  display="flex"
                  alignItems="center"
                >
                  <Container maxWidth="sm">
                    <Paper style={{ position: "relative" }}>
                      <Box
                        p={1}
                        display="flex"
                        alignItems="center"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: "50%",
                          transform: "translateX(-50px) translateY(-50px)",
                          width: "100px",
                          borderRadius: "50%",
                          border: "solid white thick",
                          height: "100px",
                          backgroundColor: "white",
                        }}
                      >
                        <img
                          src={`/images/partner-logos/${partnerSlug}.png`}
                          style={{
                            width: "100%",
                          }}
                          alt="partner-logo"
                        />
                      </Box>
                      <Box p={2} pt={9}>
                        <Typography
                          variant={isMobile ? "h5" : "h4"}
                          align="center"
                          gutterBottom
                        >
                          {title || "Carbon Footprint Calculator"}
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                          {subtitle || `Welcome ${name} Members`}
                        </Typography>
                        <Typography
                          variant="body2"
                          align="center"
                          color="textSecondary"
                        >
                          {cta ||
                            "Use the form below to get started with your Aclymate account"}
                        </Typography>
                      </Box>
                      <Divider />
                      <Box p={2}>
                        <Grid container spacing={2} direction="column">
                          <AccountCreationFlow
                            setError={setErrorMsg}
                            referralPartner={partnerSlug}
                          />
                          {errorMsg && (
                            <Grid item>
                              <Typography
                                color="error"
                                variant="body1"
                                align="center"
                              >
                                {errorMsg}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    </Paper>
                  </Container>
                </Box>
              </Grid>
            </Grid>
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </>
  );
};
export default ReferralPartner;
