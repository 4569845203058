import React, { useContext, useState } from "react";
import clsx from "clsx";

import {
  Box,
  Grid,
  Button,
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  Snackbar,
  IconButton,
  useMediaQuery,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Tabs,
  Tab,
  CircularProgress,
  Avatar,
  Alert,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBack from "@mui/icons-material/ArrowBack";

import { faLeaf } from "@fortawesome/free-solid-svg-icons";

import SlideLayout from "./SlideLayout";

import useStripeInput from "../hooks/stripeInput";
import StripeElements from "../modules/StripeElements";
import { makeColumnObj } from "../modules/tables/SortableTable";
import TopBarSupportIcon from "../atoms/buttons/TopBarSupportIcon";
import InvoicesTable from "../platform-pages/settings/InvoicesTable";
import BillingMethodCardDisplay from "../platform-pages/settings/BillingMethodCardDisplay";

import {
  PlatformLayoutContextProvider,
  PlatformLayoutContext,
} from "../../helpers/contexts/platformLayout";
import { useAuth, signOut, useAccountData } from "../../helpers/firebase";
import { analyticsTrack } from "../../helpers/analytics";
import { calculateIsCardExpiringSoon } from "../../helpers/components/settings";
import { useCustomerInvoices } from "../../helpers/hooks/stripe";
import {
  StripeCustomerContext,
  StripeCustomerContextProvider,
} from "../../helpers/contexts/stripeCustomer";

const drawerWidth = 240;

const useStyles = styled((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
  },
  flexGrow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: "white",
    backgroundColor: "black",
    zIndex: 999,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 999,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1),
    overflow: "hidden",
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
  content: {
    marginTop: theme.spacing(8),
    flexGrow: 1,
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  tabBar: {
    background: theme.palette.primary.main,
    color: "white",
    padding: theme.spacing(1, 4, 0),
  },
  tabButton: {
    color: "white",
    padding: theme.spacing(1, 4),
    "&:hover": {
      borderBottom: "solid 3px rgba(255, 255, 255, 0.8)",
    },
  },
  activeView: {
    borderBottom: "solid 3px white",
    fontWeight: "bold",
  },
}));

const AlertSnackbar = ({
  open,
  setOpen,
  onClose,
  alert,
  message,
  action,
  customButton,
  anchorOrigin = { vertical: "top", horizontal: "center" },
  autoHideDuration = 10000,
  ...otherProps
}) => {
  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }

    return setOpen(false);
  };

  const ActionButtons = () => (
    <>
      {customButton
        ? customButton
        : !!onClose && (
            <Button
              onClick={() => {
                handleClose();
                onClose();
              }}
              size="small"
              id="snackbarActionBtn"
            >
              {action || "Yes"}
            </Button>
          )}
      <IconButton onClick={handleClose} size="small" id="snackbarCloseIconBtn">
        <CloseIcon />
      </IconButton>
    </>
  );

  return (
    <>
      {!!alert ? (
        <Snackbar
          open={open}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          autoHideDuration={autoHideDuration}
          style={{ zIndex: 1501 }}
          {...otherProps}
        >
          <Alert
            severity={alert}
            onClose={handleClose}
            action={<ActionButtons />}
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          message={message}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          autoHideDuration={autoHideDuration}
          style={{ zIndex: 1501 }}
          {...otherProps}
          action={<ActionButtons />}
        />
      )}
    </>
  );
};

const useBillingMethodDisplay = ({ setIsSettingsOpen }) => {
  const { input, button } = useStripeInput(() => setIsSettingsOpen(false));
  const { paymentMethod, isPaymentMethodLoading } = useContext(
    StripeCustomerContext
  );

  const { card } = paymentMethod || {};
  const { expiration, expirationDate } = card || {};

  const [showNewBillingMethodForm, setShowNewBillingMethodForm] =
    useState(false);

  const generatePaymentCardHeader = () => {
    const isCardExpiringSoon = calculateIsCardExpiringSoon(
      expirationDate,
      expiration
    );

    if (isCardExpiringSoon) {
      return "Your credit card is expiring soon. Click on the credit card below to edit it.";
    }

    return "Your credit card is setup. Click on the credit card if you'd like to edit your billing information.";
  };

  const generateBillingMethodDisplay = () => {
    if (isPaymentMethodLoading) {
      return {
        input: (
          <Grid container item justifyContent="center">
            <CircularProgress />
          </Grid>
        ),
      };
    }

    if (card && !showNewBillingMethodForm) {
      return {
        input: (
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="body1" color="textPrimary" align="center">
                {generatePaymentCardHeader()}
              </Typography>
            </Grid>
            <BillingMethodCardDisplay
              onClick={() => setShowNewBillingMethodForm(true)}
            />
          </Grid>
        ),
      };
    }

    return {
      input,
      button: (
        <Grid
          container
          justifyContent={card ? "space-between" : "flex-end"}
          alignItems="center"
        >
          {card && (
            <Grid item>
              <IconButton
                onClick={() => setShowNewBillingMethodForm(false)}
                size="small"
                id="snackbarCloseIconBtn"
              >
                <ArrowBack />
              </IconButton>
            </Grid>
          )}
          <Grid item>{button}</Grid>
        </Grid>
      ),
    };
  };

  return generateBillingMethodDisplay();
};

const InvoicesDisplay = () => {
  const [invoices, invoicesLoading] = useCustomerInvoices();

  const componentLoading = invoicesLoading;

  const formatInvoices = () => {
    if (componentLoading) {
      return [];
    }

    const formattedInvoices = invoices.length
      ? invoices.map(({ type, ...otherProps }) => {
          return { icon: faLeaf, type, ...otherProps };
        })
      : [];

    return formattedInvoices;
  };

  const columns = [
    makeColumnObj("TYPE", "type", true),
    makeColumnObj("DATE", "date", true),
    makeColumnObj("NAME", "name", true),
    makeColumnObj("PRICE", "dollarValue", true),
    makeColumnObj("INVOICE"),
  ];

  const formattedInvoices = formatInvoices();

  return (
    <InvoicesTable formattedInvoices={formattedInvoices} columns={columns} />
  );
};

const SettingsSlideLayout = ({ setIsSettingsOpen }) => {
  const { input, button } = useBillingMethodDisplay({ setIsSettingsOpen });

  const [selectedViewIdx, setSelectedViewIdx] = useState(0);

  const views = [
    {
      label: "Payment Info",
      value: "paymentInfo",
      content: input,
      footer: button,
    },
    {
      label: "Invoices",
      value: "invoices",
      content: <InvoicesDisplay />,
    },
  ];

  const onTabClick = (value) => {
    const tabValueIndex = views.findIndex((tab) => tab.value === value);
    return setSelectedViewIdx(tabValueIndex);
  };

  const { value, content, footer } = views[selectedViewIdx] || {};

  return (
    <SlideLayout
      isSlideOpen
      setIsSlideOpen={() => setIsSettingsOpen(false)}
      header={
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar>
              <SettingsIcon />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h5" color="textPrimary">
              Settings
            </Typography>
          </Grid>
        </Grid>
      }
      tagInputs={
        <>
          <Tabs
            value={value}
            onChange={(_, value) => onTabClick(value)}
            variant="scrollable"
            scrollButtons="auto"
          >
            {views.map(({ label, value }, idx) => (
              <Tab
                label={<Typography color="textPrimary">{label}</Typography>}
                value={value}
                key={`object-details-view-tab-${idx}`}
              />
            ))}
          </Tabs>
          <Divider />
        </>
      }
      content={content}
      footer={footer}
    />
  );
};

const AccountMenu = () => {
  const [user] = useAuth();
  const { email } = user || {};

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const logout = () =>
    signOut().then(() => {
      analyticsTrack("User Sign Out", { email });
      return window.sessionStorage.clear();
    });

  return (
    <>
      {isSettingsOpen && (
        <StripeElements>
          <SettingsSlideLayout setIsSettingsOpen={setIsSettingsOpen} />
        </StripeElements>
      )}
      <IconButton
        color="inherit"
        onClick={(e) =>
          setMenuAnchorEl((currentAnchor) =>
            !!currentAnchor ? null : e.currentTarget
          )
        }
        size="large"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        getContentAnchorEl={null}
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            setMenuAnchorEl(null);
            return setIsSettingsOpen(true);
          }}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => logout()}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Log out" />
        </MenuItem>
      </Menu>
    </>
  );
};

const TopBar = () => {
  const [{ email, name }] = useAccountData();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={clsx(classes.appBar)} elevation={0}>
      <Toolbar>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="stretch"
          style={{ width: "100%", height: "64px" }}
        >
          <Box display="flex" alignItems="center">
            <Grid container spacing={1} alignItems="center" wrap="nowrap">
              {isSmall ? (
                <>
                  <Grid item container alignItems="center">
                    <img
                      src="/images/aclymate-icon.png"
                      alt="RWT logo"
                      style={{ width: "38px", padding: "4px" }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" noWrap id="NameCompanyHeading">
                      myAclymate
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item container alignItems="center">
                    <img
                      src="/images/aclymate-icon.png"
                      alt="RWT logo"
                      style={{ width: "48px", padding: "4px" }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" noWrap id="NameCompanyHeading">
                      {`myAclymate${
                        process.env.REACT_APP_ENVIRONMENT !== "production"
                          ? ` (${`${process.env.REACT_APP_ENVIRONMENT} mode`.toUpperCase()})`
                          : ""
                      }`}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          <Box display="flex" alignItems="center">
            <Grid container alignItems="center" spacing={1}>
              {email && name && (
                <Grid item>
                  <TopBarSupportIcon
                    companyName="myAclymate"
                    email={email}
                    userName={name}
                    showHelpCenter={false}
                  />
                </Grid>
              )}
              <Grid item>
                <AccountMenu />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const Layout = ({ children }) => {
  const classes = useStyles();

  const { snackbarOpen, setSnackbarOpen, snackbarProps } = useContext(
    PlatformLayoutContext
  );

  return (
    <>
      <AlertSnackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        {...snackbarProps}
      />
      <div className={classes.root}>
        <CssBaseline />
        <TopBar />
        <main className={classes.content}>{children}</main>
      </div>
    </>
  );
};

const MyAclymateLayout = ({ children, ...props }) => (
  <PlatformLayoutContextProvider {...props}>
    <StripeCustomerContextProvider>
      <Layout {...props}>{children}</Layout>
    </StripeCustomerContextProvider>
  </PlatformLayoutContextProvider>
);
export default MyAclymateLayout;
