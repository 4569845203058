import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const CircularProgressWithLabel = ({ value, size }) => (
  <Box position="relative" display="inline-flex">
    {!value && <CircularProgress color="primary" size={size} />}
    {!!value && (
      <>
        <CircularProgress
          variant="determinate"
          color="primary"
          value={value}
          size={size}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
          >{`${isNaN(value) ? 0 : Math.round(value)}%`}</Typography>
        </Box>
      </>
    )}
  </Box>
);
export default CircularProgressWithLabel;
