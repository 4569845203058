import React, { useState } from "react";

import { Box, StyledEngineProvider, ThemeProvider } from "@mui/material";

import { Backdrop } from "@aclymatepackages/atoms";
import mainTheme, {
  mergeDarkTheme,
  useLayoutHelpers,
} from "@aclymatepackages/themes";

import MyAclymateSurvey from "../../myaclymate/MyAclymateSurvey";

import OnboardingImageLayoutBackdrop from "../../layouts/OnboardingImageLayoutBackdrop";
import ImpactReportLayout from "../../myaclymate/ImpactReportLayout";

const OnboardingWelcome = ({ onNextStep }) => (
  <OnboardingImageLayoutBackdrop
    onNextStep={onNextStep}
    image="/images/platform/onboarding-svg.svg"
    title="Welcome to myAclymate"
    subtitle="We have just a few more steps we need you to complete to finish setting up your account."
    button="Let's get started"
  />
);

const OnboardingForm = ({
  email,
  individualId,
  formStep,
  setFormStep,
  setIndividualData,
  setTotalTonsCo2e,
  boxProps,
}) => (
  <Box
    p={2}
    style={{
      minHeight: "100%",
      width: "100%",
    }}
    {...boxProps}
  >
    <MyAclymateSurvey
      setIndividualData={setIndividualData}
      setTotalTonsCo2e={setTotalTonsCo2e}
      formStep={formStep}
      setFormStep={setFormStep}
      existingEmail={email}
      existingIndividualId={individualId}
    />
  </Box>
);

const OnboardingDataEntryBlock = ({ ...otherProps }) => {
  const { isMobile } = useLayoutHelpers();

  const [totalTonsCo2e, setTotalTonsCo2e] = useState(0);
  const [formStep, setFormStep] = useState(0);

  return (
    <Backdrop style={{ overflowY: "auto", overflowX: "hidden" }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={mainTheme}>
          {isMobile ? (
            <ImpactReportLayout
              totalTonsCo2e={totalTonsCo2e}
              formStep={formStep}
            >
              <OnboardingForm
                formStep={formStep}
                setFormStep={setFormStep}
                setTotalTonsCo2e={setTotalTonsCo2e}
                boxProps={{
                  position: "relative",
                }}
                {...otherProps}
              />
            </ImpactReportLayout>
          ) : (
            <OnboardingForm
              formStep={formStep}
              setFormStep={setFormStep}
              setTotalTonsCo2e={setTotalTonsCo2e}
              boxProps={{
                position: "absolute",
                top: 0,
              }}
              {...otherProps}
            />
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </Backdrop>
  );
};

const MyAclymateOnboarding = ({ ...otherProps }) => {
  const [activeOnboardingStep, setActiveOnboardingStep] = useState(0);

  const onNextStep = () =>
    setActiveOnboardingStep((currentStep) => currentStep + 1);

  const myAclymateOnboardingSteps = [
    <OnboardingWelcome onNextStep={onNextStep} />,
    <OnboardingDataEntryBlock {...otherProps} />,
  ];

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mergeDarkTheme}>
        {myAclymateOnboardingSteps[activeOnboardingStep]}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default MyAclymateOnboarding;
