import React from "react";

import {
  Grid,
  Typography,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";

import { mainTheme } from "@aclymatepackages/themes";

const FormErrorMessage = ({ formAdvanceErrorMessage }) => (
  <Grid item container justifyContent="center">
    <Grid item>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={mainTheme}>
          <Typography variant="subtitle1" color="error" align="center">
            {formAdvanceErrorMessage}
          </Typography>
        </ThemeProvider>
      </StyledEngineProvider>
    </Grid>
  </Grid>
);
export default FormErrorMessage;
