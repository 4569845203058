import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import he from "he";
import { Redirect } from "react-router-dom";

import {
  Box,
  Grid,
  Button,
  Chip,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Tooltip,
  List,
  FormControl,
  InputLabel,
  OutlinedInput,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import CancelIcon from "@mui/icons-material/Cancel";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import FaceIcon from "@mui/icons-material/Face";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { formatDate, letterSBoolean } from "@aclymatepackages/formatters";
import { nameToFirstName, nameToInitials } from "@aclymatepackages/converters";
import { LoadingButton, TextField } from "@aclymatepackages/atoms";
import { emailRegExpTest } from "@aclymatepackages/reg-exp";

import InputModal from "../../atoms/mui/InputModal";
import ErrorBoundary from "../../atoms/ErrorBoundary";
import Card from "../../atoms/mui/Card";
import ListItem from "../../atoms/mui/ListItem";
import ChooseAccountForm from "../../account-access/ChooseAccountForm";

import { getAccountCollectionAndId } from "../../../helpers/otherHelpers";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import {
  useAuth,
  useAccountData,
  firebaseArrayUnionObj,
  useCachedFirebaseCrud,
} from "../../../helpers/firebase";

const useSendNewUserInvite = () => {
  const [user] = useAuth();
  const { displayName: userName } = user || {};

  const [{ id: companyId, name: companyName }] = useAccountData();

  return async (email, inviteId) =>
    fetchOurApi({
      path: "/sendgrid/new-user-invite/",
      method: "POST",
      data: {
        email,
        inviterName: userName,
        companyId,
        inviteId,
        companyName,
      },
    });
};

const UserListItem = ({ user, currentUser, removeUser, resendUserInvite }) => {
  const {
    name: userName = "",
    email,
    invitation: thisInvitation,
    inviteDate,
    uid: thisUid,
  } = user;

  const [isRemovingUser, setIsRemovingUser] = useState(false);

  const rowIsCurrentUser = thisUid === currentUser?.uid;

  const removeUserButtonObj = {
    tooltip: rowIsCurrentUser
      ? "You can't remove yourself as a user"
      : "Remove User",
    onClick: () => setIsRemovingUser(true),
    icon: <DeleteIcon />,
    disabled: rowIsCurrentUser,
  };

  return (
    <ListItem
      primaryText={
        isRemovingUser
          ? "Are you sure you want to remove this user?"
          : thisInvitation
          ? email
          : he.decode(userName)
      }
      secondaryText={
        !isRemovingUser &&
        (thisInvitation ? `Invite sent on ${formatDate(inviteDate)}` : email)
      }
      avatar={
        isRemovingUser || !userName ? <FaceIcon /> : nameToInitials(userName)
      }
      actions={
        isRemovingUser
          ? [
              {
                tooltip: "Confirm",
                onClick: () => {
                  removeUser();
                  setIsRemovingUser(false);
                },
                icon: <CheckCircleIcon />,
              },
              {
                tooltip: "Cancel",
                onClick: () => setIsRemovingUser(false),
                icon: <CancelIcon />,
              },
            ]
          : thisInvitation
          ? [
              {
                tooltip: "Resend Invitation",
                icon: <EmailIcon />,
                onClick: () => resendUserInvite(),
              },
              removeUserButtonObj,
            ]
          : [removeUserButtonObj]
      }
    />
  );
};

const NewUserDialogBox = ({ setOpen, companyName, displayUsers }) => {
  const { updateAccountData } = useCachedFirebaseCrud();
  const sendNewUserInvite = useSendNewUserInvite();

  const [user] = useAuth();
  const { displayName: userName } = user || {};

  const [newUserEmails, setNewUserEmails] = useState([]);
  const [currentNewUser, setCurrentNewUser] = useState("");
  const [messageSubject, setMessageSubject] = useState(
    `Help ${userName} keep ${companyName} carbon neutral with Aclymate`
  );
  const [message, setMessage] = useState(
    `Hello,\nI'm using Aclymate to help keep ${companyName} carbon neutral. Aclymate is the free platform that makes it quick and easy for us to know our carbon footprint. Click the button below to join our account on Aclymate.\nSincerely,\n\r${userName} from ${companyName}`
  );
  const [newUsersError, setNewUsersError] = useState("");
  const [newUsersLoading, setNewUsersLoading] = useState(false);

  const onEmailKeyUp = ({ key, target: { value } }) => {
    const keyIsNextKey = [" ", "Enter", ","].includes(key);

    if (!value && key === "Backspace") {
      return setNewUserEmails((emails) =>
        emails.filter((_, idx) => idx !== emails.length - 1)
      );
    }

    if (keyIsNextKey) {
      setCurrentNewUser("");
    }

    const adjustedValue =
      key === "," || key === " " ? value.substring(0, value.length - 1) : value;
    if (adjustedValue && keyIsNextKey) {
      return setNewUserEmails((emails) => [...emails, adjustedValue]);
    }
    return null;
  };

  const onDeleteUserEmail = (email) =>
    setNewUserEmails((emails) => {
      const emailIdx = emails.findIndex((eml) => eml === email);
      return emails.filter((_, idx) => idx !== emailIdx);
    });

  const areAllEmailsValid = () => {
    if (!newUserEmails.length) {
      return false;
    }
    return newUserEmails.reduce(
      (acc, email) => acc && emailRegExpTest(email),
      true
    );
  };

  const inviteNewUsers = async () => {
    setNewUsersLoading(true);
    const usersWithExistingUser = await Promise.all(
      newUserEmails.map(async (email) => {
        const existingUser = await fetchOurApi({
          path: "/onboarding/check-email",
          method: "POST",
          data: { email },
          callback: ({ success }) => success,
        });
        return { email, existingUser };
      })
    );

    const existingUsersArray = usersWithExistingUser.filter(
      ({ existingUser }) => existingUser
    );

    if (existingUsersArray.length) {
      const existingUserEmails = existingUsersArray.map(({ email }) => email);
      setNewUsersLoading(false);
      return setNewUsersError(
        `The following user${letterSBoolean(
          existingUserEmails
        )} already exist as users in other accounts: ${existingUserEmails.join(
          ", "
        )}`
      );
    }

    const existingTableUsers = newUserEmails.filter((email) =>
      displayUsers.find(({ email: tableUserEmail }) => tableUserEmail === email)
    );
    if (existingTableUsers.length) {
      setNewUsersLoading(false);
      return setNewUsersError(
        `The following user${letterSBoolean(
          existingTableUsers
        )} already exist as users in this account: ${existingTableUsers.join(
          ", "
        )}`
      );
    }

    const newUsersWithInvites = newUserEmails.map((email) => {
      const invitation = String(Date.now() * Math.random()).replace(".", "");
      return { email, invitation, inviteDate: new Date() };
    });
    const inviteIds = newUsersWithInvites.map(({ invitation }) => invitation);

    updateAccountData({
      users: firebaseArrayUnionObj(newUsersWithInvites),
      invitations: firebaseArrayUnionObj(inviteIds),
    });

    newUsersWithInvites.forEach(({ email, invitation }) =>
      sendNewUserInvite(email, invitation)
    );

    return setOpen(false);
  };

  const NewUserEmailChip = ({ email }) => {
    const { palette } = useTheme();
    const isValidEmail = emailRegExpTest(email);
    const styleObj = isValidEmail
      ? { borderColor: palette.secondary.dark }
      : { backgroundColor: palette.error.main, color: "white" };

    return (
      <Chip
        variant={isValidEmail ? "outlined" : "default"}
        style={{
          margin: "2px",
          ...styleObj,
        }}
        label={email}
        onDelete={() => onDeleteUserEmail(email)}
        size="small"
      />
    );
  };

  return (
    <InputModal
      setOpen={setOpen}
      title="Add your team to Aclymate"
      subtitle="Use this form to invite your team to join you on Aclymate."
      actionButton={
        <Grid item>
          <LoadingButton
            label="Invite New Administrators"
            color="primary"
            onClick={inviteNewUsers}
            isLoading={newUsersLoading}
            disabled={!areAllEmailsValid() || !messageSubject || !message}
          />
        </Grid>
      }
    >
      <Grid item>
        <FormControl
          variant="outlined"
          fullWidth
          focused={!!newUserEmails.length}
          style={{ display: "inline-flex", flexWrap: "wrap", flex: 1 }}
          size="small"
        >
          <InputLabel>User Emails</InputLabel>
          <OutlinedInput
            label="User Emails"
            style={{
              display: "inline-flex",
              flexWrap: "wrap",
              flex: 1,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              float: "left",
              paddingTop: newUserEmails.length > 2 ? "8px" : 0,
            }}
            value={currentNewUser}
            onChange={(e) => setCurrentNewUser(e.target.value)}
            onKeyUp={onEmailKeyUp}
            startAdornment={newUserEmails.map((email, idx) => (
              <NewUserEmailChip email={email} key={`new-user-email-${idx}`} />
            ))}
            onBlur={() => {
              if (!!currentNewUser) {
                setNewUserEmails((emails) => [...emails, currentNewUser]);
              }
              setCurrentNewUser("");
            }}
          />
        </FormControl>
      </Grid>
      <Grid item sm={12}>
        <TextField
          label="Subject"
          value={messageSubject}
          setValue={setMessageSubject}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          label="Message"
          multiline
          rows={10}
          value={message}
          setValue={setMessage}
        />
      </Grid>
      {newUsersError && (
        <Grid item sm={12}>
          <Typography color="error">{newUsersError}</Typography>
        </Grid>
      )}
    </InputModal>
  );
};

const UserCardAction = ({
  displayUsers,
  companyDataLoading,
  pendingUsers,
  setNewUserOpen,
}) => {
  const sendNewUserInvite = useSendNewUserInvite();

  const { updateAccountData } = useCachedFirebaseCrud();

  const [cardMenuAnchorEl, setCardMenuAnchorEl] = useState(null);

  const resendAllUserInvites = () => {
    Promise.all(
      pendingUsers.map(
        async ({ email, invitation }) =>
          await sendNewUserInvite(email, invitation)
      )
    );
    const updatedUsers = displayUsers.map((user) =>
      user.invitation ? { ...user, inviteDate: new Date() } : user
    );
    return updateAccountData({ users: updatedUsers });
  };

  const menuOptions = [
    {
      label: "Add Administrators",
      onClick: () => setNewUserOpen(true),
      icon: <GroupAddIcon />,
    },
    {
      label: "Resend All Invites",
      onClick: () => resendAllUserInvites(),
      icon: <EmailIcon />,
    },
  ];

  if (displayUsers.length === 1 || companyDataLoading) {
    return null;
  }

  if (!pendingUsers.length) {
    return (
      <Tooltip title="Add administrators">
        <IconButton onClick={() => setNewUserOpen(true)} size="large">
          <GroupAddIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <div>
      <IconButton
        onClick={(e) => setCardMenuAnchorEl(e.currentTarget)}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={cardMenuAnchorEl}
        open={!!cardMenuAnchorEl}
        onClose={() => setCardMenuAnchorEl(null)}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        keepMounted
      >
        {menuOptions.map(({ label, onClick, icon }, idx) => (
          <MenuItem
            onClick={() => {
              setCardMenuAnchorEl(null);
              onClick();
            }}
            key={`user-menu-item-${idx}`}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={label} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const DashboardUsersCard = () => {
  const sendNewUserInvite = useSendNewUserInvite();

  const [currentUser, userLoading] = useAuth();
  const { displayName: currentUserName } = currentUser || {};

  const { updateAccountData } = useCachedFirebaseCrud();

  const [companyData, companyDataLoading] = useAccountData();
  const {
    name: companyName,
    uids,
    invitations,
    users: displayUsers = [],
  } = companyData;
  const [newUserOpen, setNewUserOpen] = useState(false);

  const pendingUsers = Array.isArray(displayUsers)
    ? displayUsers.filter(({ invitation }) => invitation)
    : [];

  const removeUser =
    ({ uid: thisUid, invitation: thisInvitation, email, name: userName }) =>
    () => {
      const updatedUsers = displayUsers.filter(({ uid, invitation }) =>
        uid ? uid !== thisUid : invitation !== thisInvitation
      );
      const updatedUids = thisUid
        ? uids.filter((uid) => uid !== thisUid)
        : uids;
      const updateInvitations = thisInvitation
        ? invitations.filter((invitation) => invitation !== thisInvitation)
        : invitations;

      fetchOurApi({
        path: "/sendgrid/delete-user",
        method: "POST",
        data: {
          email,
          companyName,
          firstName: userName && nameToFirstName(userName),
          adminName: currentUserName,
        },
      });

      if (thisUid) {
        fetchOurApi({
          path: "/users/delete",
          method: "POST",
          data: { uid: thisUid },
        });
      }

      return updateAccountData({
        users: updatedUsers,
        uids: updatedUids,
        invitations: updateInvitations,
      });
    };

  const resendUserInvite =
    ({ email, invitation: thisInvitation }) =>
    () => {
      sendNewUserInvite(email, thisInvitation);

      const updatedUsers = displayUsers.map((user) =>
        thisInvitation === user.invitation
          ? { ...user, inviteDate: new Date() }
          : user
      );

      return updateAccountData({ users: updatedUsers });
    };

  const usersSubtitle = () => {
    if (!displayUsers?.length) {
      return "";
    }

    if (displayUsers?.length === 1) {
      return `It's a little lonely here. Try adding some more administrators to your account.`;
    }
    const confirmedUsers = displayUsers.filter(({ uid }) => uid);
    const longPendingUsers = pendingUsers.filter(
      ({ inviteDate }) => dayjs(new Date()).diff(dayjs(inviteDate), "days") > 7
    );

    if (longPendingUsers.length) {
      return "Some of your pending administrators are taking a while to login. Maybe try resending their invitations?";
    }

    if (pendingUsers.length) {
      return `${confirmedUsers.length} confirmed administrator${letterSBoolean(
        confirmedUsers
      )}, ${pendingUsers.length} more ${
        pendingUsers.length === 1 ? "has" : "have"
      } been invited to your account.`;
    }
    return `${confirmedUsers.length} confirmed administrators for your account on Aclymate.`;
  };

  return (
    <>
      {newUserOpen && (
        <NewUserDialogBox
          open={newUserOpen}
          setOpen={setNewUserOpen}
          companyName={companyName}
          displayUsers={displayUsers}
        />
      )}
      <Card
        style={{ height: "100%" }}
        isLoading={companyDataLoading || userLoading}
        gridProps={{ sm: 6 }}
        cardType="layout"
        title="Account Administrators"
        subtitle={usersSubtitle()}
        icon={<AccountCircleIcon />}
        action={
          <UserCardAction
            displayUsers={displayUsers}
            companyDataLoading={companyDataLoading}
            pendingUsers={pendingUsers}
            setNewUserOpen={setNewUserOpen}
          />
        }
      >
        <Box style={{ overflowY: "auto", maxHeight: 272 }}>
          <Box display="flex" flexDirection="column" flexWrap="nowrap">
            <Box flexGrow={1}>
              <List>
                {displayUsers.map((user, idx) => (
                  <UserListItem
                    key={`user-row-${idx}`}
                    user={user}
                    currentUser={currentUser}
                    removeUser={removeUser(user, idx)}
                    resendUserInvite={resendUserInvite(user, idx)}
                  />
                ))}
              </List>
            </Box>
            <Box display="flex" justifyContent="center" p={2}>
              {!companyDataLoading && displayUsers.length === 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setNewUserOpen(true)}
                >
                  Add New Administrators
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Card>
    </>
  );
};

const SwapAccountsCard = () => {
  const [user, userLoading] = useAuth();

  const [showAccountSwapCard, setShowAccountSwapCard] = useState(null);
  const [redirect, setRedirect] = useState(false);

  const { email, uid } = user || {};
  const { collection } = getAccountCollectionAndId(); //TODO: We should be getting this from the URL param instead

  useEffect(() => {
    if (!userLoading && showAccountSwapCard === null) {
      fetchOurApi({
        path: "/onboarding/get-user-account-data",
        method: "POST",
        data: {
          email,
          uid,
        },
        callback: ({ companyData, individualData }) => {
          if (!!companyData && !!individualData) {
            return setShowAccountSwapCard({
              company: {
                accountId: companyData.id,
                accountType: "company",
              },
              individual: {
                accountId: individualData.id,
                accountType: "individual",
              },
            });
          }

          return setShowAccountSwapCard(false);
        },
      });
    }
  }, [userLoading, email, uid, showAccountSwapCard]);

  return redirect ? (
    <Redirect to={redirect} />
  ) : showAccountSwapCard ? (
    <Card
      gridProps={{ sm: 6 }}
      cardType="layout"
      title="Swap Accounts"
      icon={<SwapHorizIcon />}
      subtitle="Swap between your company and myAclymate account below."
    >
      <Box p={2}>
        <ChooseAccountForm
          chooseAccountFormOpen={showAccountSwapCard}
          setRedirect={setRedirect}
          hideCompanyButton={collection === "v2-companies"}
          hideMyAclymateButton={collection === "individuals"}
        />
      </Box>
    </Card>
  ) : (
    <></>
  );
};

const Users = () => (
  <ErrorBoundary>
    <Grid container spacing={2} alignItems="stretch">
      <DashboardUsersCard />
      <SwapAccountsCard />
    </Grid>
  </ErrorBoundary>
);
export default Users;
