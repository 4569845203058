import React, { useContext, useState } from "react";
import dayjs from "dayjs";
import { InlineWidget } from "react-calendly";

import {
  Box,
  Grid,
  ButtonBase,
  Typography,
  Divider,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  StyledEngineProvider,
  Paper,
  Badge,
  Alert,
  FormControlLabel,
  Switch,
  ThemeProvider,
  useTheme,
  Button,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import ErrorIcon from "@mui/icons-material/Error";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import HelpIcon from "@mui/icons-material/Help";
import PaidIcon from "@mui/icons-material/Paid";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faConciergeBell } from "@fortawesome/free-solid-svg-icons";

import {
  LoadingButton,
  ToggleButtons,
  Autocomplete,
} from "@aclymatepackages/atoms";
import {
  formatDecimal,
  ucFirstLetters,
  letterSBoolean,
} from "@aclymatepackages/formatters";
import { hexToRgba, nameToInitials } from "@aclymatepackages/converters";
import { mergeDarkTheme, mainTheme } from "@aclymatepackages/themes";
import { FREE_TRIAL_DAYS } from "@aclymatepackages/constants";
import { truncateText } from "@aclymatepackages/other-helpers";

import SlideLayout from "../layouts/SlideLayout";
import Link from "../atoms/mui/Link";
import CircularProgressWithLabel from "../atoms/loading/CircularProgressWithLabel";
import TopBarSupportIcon from "../atoms/buttons/TopBarSupportIcon";

import useEmissionsContext from "../../helpers/contexts/emissions";
import { PlatformLayoutContext } from "../../helpers/contexts/platformLayout";
import { settingsPages } from "../../helpers/components/settings";
import {
  useCurrentCarbonBalanceTons,
  useSubscriptionType,
  useRevenueYears,
} from "../../helpers/hooks/companyData";
import { fetchOurApi } from "../../helpers/utils/apiCalls";
import {
  useAuth,
  useAccountData,
  useCachedFirebaseCrud,
  useCollectionDataListener,
  firebaseFieldDeleteObj,
} from "../../helpers/firebase";
import { analyticsTrack } from "../../helpers/analytics";
import { useEmployeesData } from "../../helpers/components/employees";
import { useOfficesData } from "../../helpers/components/offices";
import { usePrimaryViewAlerts } from "../../helpers/components/primaryView";
import {
  getAccountCollectionAndId,
  unitTypes,
} from "../../helpers/otherHelpers";
import useAccountingData from "../../helpers/hooks/accountingData";
import { AdminDataContext } from "../../helpers/contexts/adminData";
import { useLogout } from "../../helpers/components/accountAccess";
import useDashboardDataContext from "../../helpers/contexts/dashboardData";

//eslint-disable-next-line
const baseTransactions = [
  {
    id: "0tRC1wSJGNqN9LVm0WMH",
    date: new Date("07/18/2020"),
    vendor: "Conoco Ridgway",
    value: 35.56,
    subcategory: "fuel",
    tonsCo2e: 0.13446,
    station: "Conoco, U.S. 550, Ridgway, CO, USA",
    status: "review",
    source: "quickbooks",
  },
  {
    id: "hW0fmjcaG7VXvBsKovcL",
    date: new Date("06/14/2020"),
    vendor: "Monthly commuter emissions",
    subcategory: "commuting-auto",
    tonsCo2e: 0.4567,
    status: "review",
    source: "aclymate",
  },
  {
    id: "JGIFhuPfnjDIQ8xMrgDW",
    date: new Date("07/26/2020"),
    vendor: "United Airlines Ticket X546583183128",
    value: 365.49,
    tonsCo2e: 0.576,
    subcategory: "flights",
    status: "review",
    source: "quickbooks",
  },
  {
    id: "wPGOLQlFGQbIcqhJq7zl",
    date: new Date("07/20/2020"),
    vendor: "Xcel Energy",
    value: 80.75,
    subcategory: "utilities",
    status: "review",
    source: "quickbooks",
  },
  {
    id: "Q2vMjYLAMFAdIVoX2XjG",
    date: new Date("08/18/2020"),
    vendor: "Monthly fleet mileage",
    value: null,
    subcategory: "mileage",
    status: "review",
    source: "aclymate",
  },
  {
    id: "9pqEUNZRQ6CeLMD2qOkC",
    date: new Date("07/25/2020"),
    vendor: "Frontier Baggage Fee XDFERE",
    value: 25,
    subcategory: "flights",
    status: "review",
    source: "quickbooks",
  },
  {
    id: "Qkw7vGhqnE9DOQV0avmg",
    date: new Date("07/09/2020"),
    vendor: "Frontier WER78809840",
    value: 325.46,
    subcategory: "flights",
    status: "review",
    source: "quickbooks",
  },
  {
    id: "ScYUHoZvtrRzoDWZdHLq",
    date: new Date("07/08/2020"),
    vendor: "Snarf's",
    value: 16.29,
    status: "review",
    source: "quickbooks",
  },
  {
    id: "3UIHIdu23ObL6mu0v571",
    date: new Date("07/17/2020"),
    vendor: "Denver Parking Meter",
    value: 2,
    status: "review",
    source: "quickbooks",
  },
  {
    id: "MQAL0wDzrf2LANiyzvDd",
    date: new Date("06/23/2019"),
    vendor: "Hertz -SFO",
    value: 25.67,
    subcategory: "rental-cars",
    status: "review",
    source: "quickbooks",
  },
  {
    id: "yAe2kNJNuYXwBlpzmljh",
    date: new Date("06/23/2019"),
    vendor: "Lyft Ride",
    value: 13.45,
    subcategory: "rides",
    status: "review",
    source: "quickbooks",
  },
  {
    id: "wPjOLQlFGQbIcqhJq7zl",
    date: new Date("07/10/2020"),
    vendor: "Electric Company",
    value: 80.75,
    subcategory: "electricity",
    status: "review",
    source: "quickbooks",
  },
  {
    id: "wPGOLQlFGkbIcqhJq7zl",
    date: new Date("07/09/2020"),
    vendor: "Gas Company",
    value: 80.75,
    subcategory: "gas",
    status: "review",
    source: "quickbooks",
  },
  {
    id: "wPGOLQasgkfbIcqhJq7zl",
    date: new Date("11/13/2020"),
    vendor: "Luber",
    value: 25.73,
    subcategory: "rides",
    status: "review",
    source: "quickbooks",
  },
];

const ColoredWelcomeText = ({ title, companyName }) => {
  const { palette } = useTheme();

  return (
    <Grid item>
      <Typography variant="h2" style={{ color: palette.text.primary }}>
        {truncateText(ucFirstLetters(title || companyName))}
      </Typography>
    </Grid>
  );
};

const SandboxModeSwitch = () => {
  const { palette } = useTheme();

  const { viewMode, setViewMode } = useContext(PlatformLayoutContext);
  const isSandboxMode = viewMode === "sandbox";

  return (
    <>
      <Grid item>
        <ThemeProvider theme={isSandboxMode ? mainTheme : mergeDarkTheme}>
          <Box px={2}>
            <FormControlLabel
              style={{ color: palette.text.primary }}
              label="Sandbox Mode"
              labelPlacement="start"
              control={
                <Switch
                  checked={isSandboxMode}
                  onChange={() =>
                    setViewMode((currentMode) => {
                      const isCurrentModeSandbox = currentMode === "sandbox";

                      analyticsTrack(
                        isCurrentModeSandbox ? "sandbox off" : "sandbox on"
                      );
                      const newViewMode = isCurrentModeSandbox
                        ? "company"
                        : "sandbox";
                      window.localStorage.setItem(
                        "aclymate-view-mode",
                        newViewMode
                      );
                      return newViewMode;
                    })
                  }
                />
              }
            />
          </Box>
        </ThemeProvider>
      </Grid>
    </>
  );
};

const BalanceAndLeaderStatusChip = () => {
  const theme = useTheme();
  const { convertCarbonUnits, displayUnitLabel } = useContext(
    PlatformLayoutContext
  );

  const [currentCarbonBalanceTons, currentCarbonBalanceTonsLoading] =
    useCurrentCarbonBalanceTons();

  const [hover, setHover] = useState(false);

  if (currentCarbonBalanceTonsLoading) {
    return <></>;
  }

  const buildDisplayProps = () => {
    if (currentCarbonBalanceTons < 0) {
      return {
        color: "error",
        image: <ErrorIcon color="inherit" style={{ fontSize: 32 }} />,
        tooltip:
          "Your company has emitted more carbon more than its offsets. Click to browse offset projects you can use to fix your footprint.",
      };
    }

    return {
      tooltip:
        "Congratulations! Your carbon footprint is currently fully offset. Click to view your certification page.",
      color: "secondary",
      image: (
        <Box
          style={{
            backgroundImage: "url('/images/aclymate-icon-white.png')",
            backgroundSize: "cover",
            width: "30px",
            height: "30px",
            borderRadius: "18px",
          }}
        />
      ),
    };
  };

  const { tooltip, color, image } = buildDisplayProps();

  return (
    <Grid item>
      <ThemeProvider theme={mainTheme}>
        <Tooltip title={tooltip}>
          <ButtonBase
            style={{
              borderRadius: "20px",
            }}
            href={
              currentCarbonBalanceTons < 0
                ? "/platform/company/purchase"
                : "/platform/company/badges"
            }
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Paper
              elevation={hover ? 3 : 0}
              style={{
                marginRight: theme.spacing(1),
                borderRadius: "20px",
              }}
            >
              <Box
                style={{
                  background: `linear-gradient(45deg, ${theme.palette[color].dark} 0%, ${theme.palette[color].light} 100%)`,
                  borderRadius: "20px",
                  border: "white 1px solid",
                  padding: "1px",
                  color: "white",
                }}
                display="flex"
                alignItems="center"
              >
                {image}
                <Box
                  style={{
                    padding: theme.spacing(0.25, 1),
                  }}
                >
                  <Typography variant="subtitle1" style={{ color: "white" }}>
                    {`${
                      !!currentCarbonBalanceTons
                        ? formatDecimal(
                            convertCarbonUnits(currentCarbonBalanceTons)
                          )
                        : 0
                    } ${displayUnitLabel}`}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </ButtonBase>
        </Tooltip>
      </ThemeProvider>
    </Grid>
  );
};

const TaskProgressWithLabel = ({
  type,
  currentChunk,
  totalChunks,
  totalTasks,
  totalTasksProcessed,
  taskData,
}) => {
  const generateTooltipTitle = () => {
    if (type === "accounting") {
      return `Total ${ucFirstLetters(
        type
      )} transactions processed: ${totalTasksProcessed}/${totalTasks}`;
    }

    if (type === "utilitiesApi") {
      const { officeName, serviceClass } = taskData;

      return `Fetching utility emissions for ${officeName} (${serviceClass} meter)`;
    }

    if (type === "event-attendees-csv") {
      return "Processing event attendees CSV data";
    }
    if (type === "vendors") {
      return "Processing vendor data";
    }

    return "Unknown task type";
  };

  return (
    <Tooltip title={generateTooltipTitle()}>
      <Box p={1.5}>
        <CircularProgressWithLabel
          value={(currentChunk / totalChunks) * 100}
          size={32}
        />
      </Box>
    </Tooltip>
  );
};

const FreeTrialDaysPieChart = ({ opacity }) => {
  const { palette } = useTheme();

  const [{ status, trialDate }, subscriptionsDataLoading] =
    useSubscriptionType("saas");

  if (subscriptionsDataLoading || status !== "trialing") {
    return <></>;
  }

  const subscriptionDaysRemaining = Math.abs(
    dayjs().diff(dayjs(trialDate).add(1, "day"), "days")
  );

  const subscriptionPercentage =
    (subscriptionDaysRemaining / FREE_TRIAL_DAYS) * 100;

  const pieColorizer = () => {
    if (subscriptionDaysRemaining < 10) {
      return palette.error.main;
    }
    if (subscriptionDaysRemaining < 20) {
      return palette.vehicles.main;
    }
    return palette.secondary.main;
  };

  return (
    <Tooltip
      title={`You have ${subscriptionDaysRemaining} day${letterSBoolean(
        subscriptionDaysRemaining
      )} left in your free trial of our premium analytics plan.`}
    >
      <span>
        <Link href="/platform/company/settings/products">
          <IconButton size="large">
            <div
              style={{
                position: "relative",
                height: "35px",
                width: "35px",
                background: `conic-gradient(${pieColorizer()} 0, ${pieColorizer()} ${subscriptionPercentage}%, transparent ${subscriptionPercentage}%, transparent 100%`,
                borderRadius: "50%",
              }}
            >
              <Box
                style={{
                  position: "absolute",
                  inset: "5px",
                  backgroundColor:
                    opacity > 0.5 ? palette.text.primary : "white",
                  borderRadius: "50%",
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  variant="body1"
                  color={opacity > 0.5 ? "white" : "textPrimary"}
                >
                  {subscriptionDaysRemaining}
                </Typography>
              </Box>
            </div>
          </IconButton>
        </Link>
      </span>
    </Tooltip>
  );
};

const TopBarSupportSlider = () => {
  const [user] = useAuth();
  const { displayName, email } = user || [];
  const [{ name }] = useAccountData();

  return (
    <TopBarSupportIcon
      email={email}
      userName={displayName}
      companyName={name}
    />
  );
};

const ConciergeServiceWindow = () => {
  const { palette } = useTheme();

  const [{ type }, subscriptionDataLoading] = useSubscriptionType("saas");

  const [conciergeWindowOpen, setConciergeWindowOpen] = useState(false);

  if (subscriptionDataLoading || type !== "concierge") {
    return <></>;
  }

  return (
    <>
      {conciergeWindowOpen && (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
            <SlideLayout
              isSlideOpen
              setIsSlideOpen={setConciergeWindowOpen}
              header={
                <Typography variant="h5" color="textPrimary">
                  Meet with your Climate Navigator
                </Typography>
              }
              content={
                <InlineWidget url="https://calendly.com/travis-kruse/meet-with-aclymate" />
              }
            />
          </ThemeProvider>
        </StyledEngineProvider>
      )}
      <Grid item>
        <IconButton onClick={() => setConciergeWindowOpen(true)} size="large">
          <FontAwesomeIcon
            icon={faConciergeBell}
            style={{ color: palette.text.primary }}
          />
        </IconButton>
      </Grid>
    </>
  );
};

const DisplayUnitsInterface = () => {
  const { displayUnit, setDisplayUnit } = useContext(PlatformLayoutContext);

  return (
    <Box p={1}>
      <Box display="flex" justifyContent="center">
        <ToggleButtons
          value={displayUnit}
          buttons={unitTypes.map(({ short }) => ({
            name: short,
            value: short,
          }))}
          onChange={(value) => {
            window.localStorage.setItem("aclymate-display-unit", value);
            setDisplayUnit(value);
          }}
        />
      </Box>
      <Typography variant="caption" align="center" display="block">
        Display Units
      </Typography>
    </Box>
  );
};

const AccountAvatarMenu = ({ opacity }) => {
  const { palette } = useTheme();

  const [user] = useAuth();
  const { displayName } = user || {};

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const logout = useLogout();

  return (
    <>
      <IconButton
        onClick={(e) =>
          setMenuAnchorEl((currentAnchor) =>
            !!currentAnchor ? null : e.currentTarget
          )
        }
        size="large"
      >
        <Avatar
          style={{
            height: "35px",
            width: "35px",
            color: opacity > 0.5 ? "white" : palette.text.primary,
            backgroundColor: opacity > 0.5 ? palette.text.primary : "white",
          }}
        >
          {nameToInitials(displayName)}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        getContentAnchorEl={null}
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <DisplayUnitsInterface />
        <Divider />
        {settingsPages.map(({ label, href, icon }, idx) => (
          <Link
            key={`settings-menu-link-${idx}`}
            href={`/platform/company/settings/${href}`}
            onClick={() => setMenuAnchorEl(null)}
          >
            <MenuItem>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                primary={label}
                style={{ color: palette.text.primary }}
              />
            </MenuItem>
          </Link>
        ))}
        <Divider />
        <MenuItem onClick={() => logout()}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Log out" />
        </MenuItem>
      </Menu>
    </>
  );
};

const AlertRowLayout = ({ href, color, avatarColor, icon, text }) => {
  const theme = useTheme();

  return (
    <Link href={href}>
      <Alert
        style={{
          border: "thin white solid",
          backgroundColor: color,
          display: "flex",
          alignItems: "center",
        }}
        icon={
          <Avatar
            style={{
              width: theme.spacing(4),
              height: theme.spacing(4),
              backgroundColor: avatarColor,
            }}
          >
            {icon}
          </Avatar>
        }
      >
        <Typography variant="body2">{text}</Typography>
      </Alert>
    </Link>
  );
};

const ViewAlertRow = ({ alerts, dashboardView, color, icon }) => {
  const theme = useTheme();

  return (
    <>
      {!!alerts.length && (
        <AlertRowLayout
          href={`/platform/company/dashboard/${dashboardView}`}
          color={color}
          avatarColor={theme.palette[dashboardView].main}
          icon={icon}
          text={`You have ${alerts.length} alert${letterSBoolean(alerts)} in 
          ${ucFirstLetters(dashboardView)} view`}
        />
      )}
    </>
  );
};

const HighLevelAlertSection = ({
  taggedEmployeesTransactions,
  taggedOfficeTransactions,
}) => {
  const { palette } = useTheme();

  const [{ openEvents: unClosedYears = [], interval }, accountingDataLoading] =
    useAccountingData();

  const [highAlertMenu, setHighAlertMenu] = useState(null);

  const { employeeNotifications, employeesLoading } = useEmployeesData({
    transactions: taggedEmployeesTransactions,
  });

  const { officeAlerts, officesLoading } = useOfficesData({
    transactions: taggedOfficeTransactions,
  });

  const { vendorAlerts, dashboardDataLoading } = useDashboardDataContext();

  const transactionAlerts = usePrimaryViewAlerts();

  const [revenueYears, revenueYearsLoading] = useRevenueYears();

  const yearsWithoutRevenue = revenueYears.filter(({ revenue }) => !revenue);

  const totalAlertCount =
    employeeNotifications.length +
    officeAlerts.length +
    vendorAlerts.length +
    transactionAlerts.length +
    (unClosedYears.length ? 1 : 0) +
    (yearsWithoutRevenue.length ? 1 : 0);

  const allAlertsLoading =
    accountingDataLoading ||
    employeesLoading ||
    officesLoading ||
    dashboardDataLoading ||
    revenueYearsLoading;

  const handleAlertMenuClick = (event) => {
    setHighAlertMenu((currentAnchor) =>
      currentAnchor ? null : event.currentTarget
    );
  };

  const handleAlertMenuClose = () => {
    setHighAlertMenu(null);
  };

  const alertsOptions = [
    {
      alerts: transactionAlerts,
      dashboardView: "primary",
      color: hexToRgba(palette.primary.main, 0.1),
      icon: <PlaylistAddCheckIcon />,
    },
    {
      alerts: officeAlerts,
      dashboardView: "offices",
      color: hexToRgba(palette.offices.main, 0.1),
      icon: <BusinessIcon />,
    },
    {
      alerts: employeeNotifications,
      dashboardView: "employees",
      color: hexToRgba(palette.employees.main, 0.1),
      icon: <PeopleIcon />,
    },
    {
      alerts: vendorAlerts,
      dashboardView: "vendors",
      color: hexToRgba(palette.vendors.main, 0.1),
      icon: <PaidIcon />,
    },
  ];

  return (
    <ThemeProvider theme={mainTheme}>
      <Tooltip
        title={
          totalAlertCount
            ? `You currently have ${totalAlertCount} alerts`
            : "You don't have any active alerts"
        }
      >
        <span>
          <IconButton
            disabled={!totalAlertCount}
            onClick={handleAlertMenuClick}
            size="large"
          >
            <Badge
              badgeContent={!allAlertsLoading && totalAlertCount}
              color="error"
              overlap="rectangular"
            >
              {totalAlertCount ? (
                <NotificationsIcon
                  fontSize="large"
                  style={{ color: palette.text.primary }}
                />
              ) : (
                <NotificationsNoneIcon
                  fontSize="large"
                  style={{ color: palette.text.primary }}
                />
              )}
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        anchorEl={highAlertMenu}
        open={!!highAlertMenu}
        getContentAnchorEl={null}
        onClose={handleAlertMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Box p={1}>
          {!!unClosedYears.length && (
            <AlertRowLayout
              href="/platform/company/settings/accounting"
              color="white"
              avatarColor={palette.secondary.main}
              icon={<MenuBookIcon />}
              text={`You need to close your accounting for ${
                unClosedYears.length
              } ${interval}${letterSBoolean(unClosedYears)}`}
            />
          )}
          {alertsOptions.map((alert, idx) => (
            <ViewAlertRow key={`alert-row-${idx}`} {...alert} />
          ))}
          {!!yearsWithoutRevenue.length && (
            <AlertRowLayout
              href="/platform/company/settings/company"
              color="white"
              avatarColor={palette.secondary.main}
              icon={<AttachMoneyIcon />}
              text={`You have ${
                yearsWithoutRevenue.length
              } year${letterSBoolean(
                yearsWithoutRevenue
              )} that you need to set your revenue for.`}
            />
          )}
        </Box>
      </Menu>
    </ThemeProvider>
  );
};

const DemoResetIcon = () => {
  const { palette } = useTheme();

  const { updateAccountData } = useCachedFirebaseCrud();

  //This function is only used for demos/dev
  const resetDemo = async () => {
    const { id: companyId } = getAccountCollectionAndId();

    fetchOurApi({
      path: "/transactions/reset-account",
      method: "POST",
      data: { companyId },
    });

    updateAccountData({
      certification: {},
      storedCsvs: [],
      accounting: firebaseFieldDeleteObj(),
    });

    //This should be turned on if you're testing transaction functionality and want to enable to default transactions.
    // baseTransactions.forEach((trans) => {
    //   return db
    //     .doc(`v2-companies/${companyId}/transactions/${trans.id}`)
    //     .set(trans);
    // });
  };

  return (
    <>
      {process.env.REACT_APP_ENVIRONMENT !== "production" && (
        <Grid item>
          <Tooltip title="Reset Account">
            <span>
              <IconButton onClick={() => resetDemo()}>
                <SettingsBackupRestoreIcon
                  fontSize="large"
                  style={{ color: palette.text.primary }}
                />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      )}
    </>
  );
};

const AdminSelectCompany = () => {
  const {
    selectedCompany,
    setSelectedCompany,
    selectableCompanies,
    fetchAndSetAdminData,
    isAdminDataLoading,
  } = useContext(AdminDataContext);
  const { viewMode, setViewMode } = useContext(PlatformLayoutContext) || {};

  const { id: selectedCompanyId } = selectedCompany || {};

  return (
    <Box p={2}>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        {!selectableCompanies.length ? (
          <Grid item>
            <CircularProgressWithLabel />
          </Grid>
        ) : (
          <>
            <Grid item container spacing={1} justifyContent="center">
              <Grid item xs={12}>
                <Autocomplete
                  label="Select Company"
                  availableOptions={selectableCompanies}
                  value={selectedCompany}
                  setValue={setSelectedCompany}
                />
              </Grid>
              {!!selectedCompanyId && (
                <Grid item>
                  <Typography variant="body2" style={{ color: "white" }}>
                    {`Selected company ID: ${selectedCompanyId}`}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <LoadingButton
                  color="primary"
                  label="Fetch Data"
                  disabled={!selectedCompany || !selectedCompanyId}
                  onClick={() => fetchAndSetAdminData()}
                  isLoading={isAdminDataLoading}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={viewMode !== "admin"}
                  onClick={() => {
                    setSelectedCompany({});

                    return setViewMode("company");
                  }}
                >
                  Clear Data
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

const AdminSelectCompanyMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton color="primary" onClick={handleClick}>
        <AdminPanelSettingsIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem>
          <Box sx={{ p: 2, bgcolor: "background.paper", borderRadius: 1 }}>
            <AdminSelectCompany />
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
};

const TopBar = ({ title, topBarRef, opacity = 1 }) => {
  const theme = useTheme();
  const { isUserAclymateAdmin } = useContext(AdminDataContext);

  const { viewMode } = useContext(PlatformLayoutContext);
  const {
    taggedEmployeesTransactions,
    taggedOfficeTransactions,
    transactions,
  } = useEmissionsContext();

  const [{ name: companyName }] = useAccountData();

  const [runningTasks] = useCollectionDataListener("tasks", [
    ["status", "==", "running"],
  ]);

  const isSandboxMode = viewMode === "sandbox";
  const isAdminMode = viewMode === "admin";

  return (
    <>
      <Box
        style={{
          padding: theme.spacing(1, 2),
          backgroundColor: hexToRgba(
            theme.palette.backgroundGray.main,
            opacity ?? 1
          ),
          width: "100%",
        }}
        ref={topBarRef}
      >
        <ThemeProvider theme={opacity > 0.5 ? mainTheme : mergeDarkTheme}>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <ColoredWelcomeText title={title} companyName={companyName} />
            <Grid item>
              <Grid container alignItems="center">
                {isUserAclymateAdmin && <AdminSelectCompanyMenu />}
                <SandboxModeSwitch />
                <ThemeProvider theme={mainTheme}>
                  <BalanceAndLeaderStatusChip />
                </ThemeProvider>
                <DemoResetIcon />
                {runningTasks.map((runningTask, idx) => (
                  <Grid item key={`running-task-icon-${idx}`}>
                    <TaskProgressWithLabel {...runningTask} />
                  </Grid>
                ))}
                <HighLevelAlertSection
                  taggedEmployeesTransactions={taggedEmployeesTransactions}
                  taggedOfficeTransactions={taggedOfficeTransactions}
                  transactions={transactions}
                />
                <TopBarSupportSlider />
                <ConciergeServiceWindow />
                <Grid item>
                  <ThemeProvider theme={mainTheme}>
                    <FreeTrialDaysPieChart opacity={opacity} />
                  </ThemeProvider>
                </Grid>
                <Grid item>
                  <ThemeProvider theme={mainTheme}>
                    <AccountAvatarMenu opacity={opacity} />
                  </ThemeProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </Box>
      {(isSandboxMode || isAdminMode) && (
        <Box
          style={{
            position: "relative",
            height: "2px",
            backgroundColor: isAdminMode
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
            width: "calc(100% - 15px)",
            zIndex: 999,
          }}
        >
          <Box
            style={{
              position: "absolute",
              left: "50%",
              bottom: "-16px",
              backgroundColor: isAdminMode
                ? theme.palette.primary.main
                : theme.palette.secondary.main,
              borderRadius: theme.spacing(1),
              transform: "translateX(-55px)",
            }}
            p={1}
          >
            <ThemeProvider theme={mergeDarkTheme}>
              <Grid spacing={2} container wrap="nowrap" alignItems="stretch">
                <Grid item>
                  <Typography variant="subtitle1" color="textPrimary">
                    {isAdminMode ? "Admin Mode" : "Sandbox Mode"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    title={`You're currently in ${
                      isAdminMode ? "admin" : "sandbox"
                    } mode. Any changes you make here are temporary and will not be stored.`}
                  >
                    <span>
                      <HelpIcon style={{ color: "white" }} />
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </ThemeProvider>
          </Box>
        </Box>
      )}
    </>
  );
};
export default TopBar;
