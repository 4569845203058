import React from "react";

import PdlCompanySelect from "../PdlCompanySelect";

const VendorPdlSelect = ({
  editVendor,
  label = "Please enter the name of your vendor",
  action,
}) => {
  const editCompanyName = async (name) => {
    if (action) {
      return await action({ name });
    }

    editVendor("emissionCategory")("spend-based");
    return editVendor("name")(name);
  };

  const onCompanySelected = async (selectedCompany) => {
    const { dbId, pdlId, name } = selectedCompany || {};

    if (action) {
      return action(selectedCompany);
    }

    editVendor("name")(name);

    if (dbId) {
      return editVendor("dbId")(dbId);
    }

    editVendor("emissionCategory")("spend-based");
    return editVendor("pdlId")(pdlId);
  };

  return (
    <PdlCompanySelect
      label={label}
      onCompanyNotFound={editCompanyName}
      onCompanySelected={onCompanySelected}
    />
  );
};
export default VendorPdlSelect;
