import React, { useState, useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import dayjs from "dayjs";

import {
  Box,
  Tooltip,
  Grid,
  Typography,
  ButtonBase,
  IconButton,
  Popper,
  StyledEngineProvider,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRibbon } from "@fortawesome/free-solid-svg-icons";

import {
  TextField,
  DefaultPaper,
  LoadingButton,
} from "@aclymatepackages/atoms";
import { ucFirstLetters, formatDollars } from "@aclymatepackages/formatters";
import {
  useLayoutHelpers,
  mergeDarkTheme,
  mainTheme,
  isColorDarkTheme,
} from "@aclymatepackages/themes";
import { editRowData } from "@aclymatepackages/array-immutability-helpers";

import DollarInput from "../../atoms/DollarInput";
import ErrorBoundary from "../../atoms/ErrorBoundary";
import Card from "../../atoms/mui/Card";
import LogoUploadDropzone from "../../modules/LogoUploadDropzone";
import CompanyNameRow from "../../modules/settings/CompanyNameRow";
import CompanyAddressRow from "../../modules/settings/CompanyAddressRow";
import CompanyIndustryRow from "../../modules/settings/CompanyIndustryRow";
import CompanyEmployeeCountRow from "../../modules/settings/CompanyEmployeeCountRow";
import CompanyDataListItem from "../../modules/settings/CompanyDataListItem";
import EditStartDateButton from "../../atoms/accounting/EditStartDateButton";
import {
  useAccountData,
  useCachedFirebaseCrud,
} from "../../../helpers/firebase";
import useAccountingData from "../../../helpers/hooks/accountingData";
import { useRevenueYears } from "../../../helpers/hooks/companyData";

const BrandColorDisplay = ({ type, color }) => {
  const { theme } = useLayoutHelpers();
  const { updateAccountData } = useCachedFirebaseCrud();

  const [saveLoading, setSaveLoading] = useState(false);
  const [pickerColor, setPickerColor] = useState(
    color || theme.palette.backgroundGray.main
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const hexColorRegexpTest = RegExp("^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$").test(
    pickerColor
  );

  const onColorSave = async () => {
    setSaveLoading(true);
    await updateAccountData({ [`branding.${type}Color`]: pickerColor });
    setSaveLoading(false);
    return setAnchorEl(null);
  };

  return (
    <Grid item>
      <Popper open={!!anchorEl} anchorEl={anchorEl} placement="left-start">
        <DefaultPaper>
          <Grid container spacing={1} direction="column">
            <Grid
              item
              container
              justifyContent="space-between"
              spacing={2}
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant="subtitle2"
                  align="center"
                >{`Select your ${type} color`}</Typography>
              </Grid>
              <Grid item>
                <IconButton size="small" onClick={() => setAnchorEl(null)}>
                  <CancelIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item>
              <HexColorPicker color={pickerColor} onChange={setPickerColor} />
            </Grid>
            <Grid item>
              <TextField
                value={pickerColor}
                setValue={setPickerColor}
                error={!hexColorRegexpTest}
              />
            </Grid>
            <Grid item container justifyContent="center">
              <Grid item>
                <LoadingButton
                  label="Save Color"
                  isLoading={saveLoading}
                  color="primary"
                  disabled={!hexColorRegexpTest}
                  onClick={onColorSave}
                />
              </Grid>
            </Grid>
          </Grid>
        </DefaultPaper>
      </Popper>
      <ButtonBase
        style={{ width: "100%" }}
        onClick={({ currentTarget }) => setAnchorEl(currentTarget)}
      >
        <DefaultPaper
          style={{
            backgroundColor: color || theme.palette.backgroundGray.main,
            width: "100%",
          }}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider
              theme={isColorDarkTheme(color) ? mergeDarkTheme : mainTheme}
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <div>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="textPrimary"
                  >{`${ucFirstLetters(type)}${
                    color ? `: ${color}` : ""
                  }`}</Typography>
                  <Typography
                    variant="body2"
                    align="center"
                    color="textSecondary"
                  >
                    {color
                      ? "Click to edit"
                      : `Click to select your ${type} color`}
                  </Typography>
                </div>
              </Box>
            </ThemeProvider>
          </StyledEngineProvider>
        </DefaultPaper>
      </ButtonBase>
    </Grid>
  );
};

const BrandingCard = () => {
  const [{ branding }, companyDataLoading] = useAccountData();
  const { primaryColor, secondaryColor } = branding || {};

  return (
    <Card
      cardType="layout"
      gridProps={{ sm: 6 }}
      title="Branding"
      icon={<FontAwesomeIcon icon={faRibbon} />}
      subtitle="Customize how you want to display your company's presence on our website"
      isLoading={companyDataLoading}
      content={
        <Grid container spacing={2} alignItems="stretch">
          <Grid item sm={5}>
            <LogoUploadDropzone />
          </Grid>
          <Grid item sm={7}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h6">Brand Colors</Typography>
              </Grid>
              <BrandColorDisplay type="primary" color={primaryColor} />
              <BrandColorDisplay type="secondary" color={secondaryColor} />
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

const StartDateRow = () => {
  const [{ startDate }] = useAccountData();
  const [{ baselineDate }] = useAccountingData();

  return (
    <CompanyDataListItem
      editingComponent={<EditStartDateButton />}
      primaryText={dayjs(startDate).year()}
      secondaryText="Accounting Start Year"
      editDisabled={!!baselineDate}
    />
  );
};

const RevenueListItem = ({ year, revenue, isEditing, editRevenueYear }) => {
  const { palette } = useTheme();

  return (
    <Grid item container spacing={2} alignItems="center">
      <Grid item>
        {!revenue ? (
          <ClearIcon style={{ color: palette.error.main }} />
        ) : (
          <CheckCircleIcon style={{ color: palette.secondary.main }} />
        )}
      </Grid>
      <Grid item>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ width: "48px" }}
        >
          {year}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        {isEditing ? (
          <DollarInput
            label="Revenue"
            value={revenue}
            setValue={(value) => editRevenueYear("revenue", value)}
          />
        ) : (
          <Typography variant="h6" color={!revenue ? "error" : "auto"}>
            {revenue
              ? formatDollars(revenue)
              : `Please enter your ${year} revenue`}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const CompanyRevenueCard = () => {
  const { updateAccountData } = useCachedFirebaseCrud();
  const [revenueYears, revenueYearsLoading] = useRevenueYears();

  const [isEditing, setIsEditing] = useState(false);
  const [editRevenueYears, setEditRevenueYears] = useState([]);

  useEffect(() => {
    if (!revenueYearsLoading && !editRevenueYears.length) {
      setEditRevenueYears(revenueYears);
    }
  }, [editRevenueYears, revenueYears, revenueYearsLoading]);

  const onCancel = () => {
    setEditRevenueYears(revenueYears);
    return setIsEditing(false);
  };

  const onSave = () => {
    updateAccountData({ revenueYears: editRevenueYears });
    return setIsEditing(false);
  };

  return (
    <Card
      cardType="layout"
      gridProps={{ sm: 6 }}
      title="Revenue History"
      icon={<AttachMoneyIcon />}
      isLoading={revenueYearsLoading}
      action={
        <>
          {isEditing ? (
            <Grid container>
              <Grid item>
                <IconButton onClick={onCancel}>
                  <CancelIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={onSave}>
                  <SaveIcon />
                </IconButton>
              </Grid>
            </Grid>
          ) : (
            <IconButton>
              <EditIcon onClick={() => setIsEditing(true)} />
            </IconButton>
          )}
        </>
      }
      content={
        <Grid container direction="column" spacing={1}>
          {editRevenueYears.map((revenueYear, idx) => (
            <RevenueListItem
              key={`revenue-list-item-${idx}`}
              isEditing={isEditing}
              editRevenueYear={editRowData(idx, setEditRevenueYears)}
              {...revenueYear}
            />
          ))}
        </Grid>
      }
    />
  );
};

const DashboardCompanyData = () => {
  const [companyData, companyDataLoading] = useAccountData();
  const {
    primaryOffice,
    startDate,
    geography,
    isRemote,
    employeeCount,
    employeeCountConfirmed,
  } = companyData;

  const companyDataSubtitle = () => {
    if (!primaryOffice || !startDate) {
      return "Please finish setting up your company profile.";
    }
    return "Your company profile is all up to date. Click on any field to edit it.";
  };

  return (
    <ErrorBoundary>
      <Grid container spacing={2}>
        <Card
          cardType="layout"
          gridProps={{ sm: 6 }}
          title="Company Information"
          subtitle={companyDataSubtitle()}
          icon={<LocationCityIcon />}
          isLoading={companyDataLoading}
          contentType="list"
          action={
            isRemote &&
            !geography?.address?.description && (
              <div>
                <Tooltip title="You have missing company information. Add it below.">
                  <WarningIcon color="error" />
                </Tooltip>
              </div>
            )
          }
        >
          <CompanyNameRow companyData={companyData} />
          <CompanyAddressRow companyData={companyData} />
          <CompanyIndustryRow companyData={companyData} />
          {employeeCount && !employeeCountConfirmed && (
            <CompanyEmployeeCountRow companyData={companyData} />
          )}
          <StartDateRow />
        </Card>
        <BrandingCard />
        <CompanyRevenueCard />
      </Grid>
    </ErrorBoundary>
  );
};
export default DashboardCompanyData;
