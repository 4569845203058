import React from "react";

import { Grid, useTheme } from "@mui/material";

import { ToggleButtons } from "@aclymatepackages/atoms";

const DateIntervalToggles = ({ graphPeriod, setGraphPeriod, fullName }) => {
  const { palette } = useTheme();

  const intervalButtons = [
    {
      value: "month",
      name: fullName ? "Monthly" : "M",
      id: "month-option",
    },
    {
      value: "quarter",
      name: fullName ? "Quarterly" : "Q",
      id: "quarter-option",
    },
    {
      value: "year",
      name: fullName ? "Yearly" : "Y",
      id: "year-option",
    },
  ];

  return (
    <Grid item>
      <ToggleButtons
        size="small"
        value={graphPeriod}
        onChange={(value) => setGraphPeriod(value)}
        buttons={intervalButtons.map((button) => ({
          ...button,
          style: {
            backgroundColor:
              graphPeriod === button.value ? palette.success.main : "inherit",
          },
        }))}
      />
    </Grid>
  );
};
export default DateIntervalToggles;
