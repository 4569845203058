import React, { useState, useEffect } from "react";

import {
  Box,
  Grid,
  Container,
  Typography,
  Button,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faHome,
  faMoneyCheck,
  faPlane,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";

import { AnimatedLogo } from "@aclymatepackages/atoms";
import { mergeDarkTheme, useLayoutHelpers } from "@aclymatepackages/themes";
import {
  FootprintEquivalencies,
  ComparisonChart,
} from "@aclymatepackages/modules";
import { getYearlyTonsCo2eValues } from "@aclymatepackages/calcs/myAclymate";
import { tonsToLbs } from "@aclymatepackages/converters";
import {
  formatDate,
  formatDecimal,
  ucFirstLetters,
} from "@aclymatepackages/formatters";
import {
  US_AVERAGE_PER_CAPITA_HOME_TONS_CO2E_PER_YEAR,
  US_AVERAGE_VEHICLE_TRAVEL_TONS_CO2E_PER_YEAR,
  US_AVERAGE_FLIGHTS_TONS_CO2E_PER_YEAR,
  US_AVERAGE_DIET_TONS_CO2E_PER_YEAR,
  US_AVERAGE_EXPENDITURES_PER_CAPITA_TONS_CO2E_PER_YEAR,
} from "@aclymatepackages/constants";

import WorkCTAPage from "./views/WorkCTAPage";
import OffsetBucketSubscriptionPurchase from "./OffsetBucketSubscriptionPurchase";
import MyAclymateOnboarding from "./MyAclymateOnboarding";

import ReportContentCard from "../../myaclymate/ReportCardContent";
import WorkspaceCTABlock from "../../myaclymate/WorkspaceCTABlock";
import SubscriptionDetailsSlider from "../../myaclymate/SubscriptionDetailsSlider";
import OffsetBucketsBox from "../../myaclymate/OffsetBucketsBox";
import CarbonFootprintGraphic from "../../myaclymate/CarbonFootprintGraphic";

import {
  isObjectEmpty,
  getAccountCollectionAndId,
} from "../../../helpers/otherHelpers";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import { useAccountData } from "../../../helpers/firebase";
import { useOffsetBucketSubscription } from "../../../helpers/hooks/companyData";
import offsetBuckets from "../../../helpers/components/display-lists/offsetBuckets";
import { useAdminProjects } from "../../../helpers/components/projects";
import { useCustomerInvoices } from "../../../helpers/hooks/stripe";

const ImpactSummary = ({
  isDesktop,
  offsetsBucketStatus,
  offsetsBucketSubscription,
}) => {
  const { theme } = useLayoutHelpers();
  const [adminProjects] = useAdminProjects();
  const [invoices] = useCustomerInvoices();

  const [manageSubscriptionType, setManageSubscriptionType] = useState("");

  const { upcomingInvoice, type: offsetBucketSubscriptionType } =
    offsetsBucketSubscription || {};
  const { dueDate } = upcomingInvoice || {};
  const { title, offsetCategories, icon } =
    offsetBuckets[offsetBucketSubscriptionType];

  const projectsWithOffsetBucketCategories = adminProjects.filter(
    ({ categories = [] }) =>
      offsetCategories.some((categorySlug) =>
        categories.map(({ slug }) => slug).includes(categorySlug)
      )
  );
  const { images = [] } = projectsWithOffsetBucketCategories[0] || {};

  const offsetSubscriptionInvoices = invoices.filter(({ type }) =>
    ["naturalAndWorkingLands", "cleanTransition", "climateJustice"].includes(
      type
    )
  );
  const OFFSET_SUBSCRIPTION_COST_PER_TON = 30;
  const totalDollarValueFromOffsetSubscriptions =
    offsetSubscriptionInvoices.reduce(
      (totalValue, { dollarValue }) => totalValue + dollarValue,
      0
    );
  const offsetTotalToDateTons =
    totalDollarValueFromOffsetSubscriptions / OFFSET_SUBSCRIPTION_COST_PER_TON;

  const leftColumnSpacingObj = !isDesktop ? { xs: 7 } : {};
  const rightColumnSpacingObj = !isDesktop ? { xs: 5 } : {};

  const backgroundImageProps = isDesktop
    ? {
        backgroundImage: `linear-gradient(112deg, ${theme.palette.primary.main} 65%, rgba(0,0,0,0) 70%), url(${images[0]})`,
        backgroundSize: `100%, 40% 100%`,
        backgroundPosition: "left top, right bottom",
      }
    : {
        backgroundImage: `linear-gradient(180deg, ${theme.palette.primary.main} 65%, rgba(0,0,0,0) 70%), url(${images[0]})`,
        backgroundSize: `100%, 100% 40%`,
        backgroundPosition: "left top, left bottom",
      };

  return (
    <>
      {manageSubscriptionType && (
        <SubscriptionDetailsSlider
          selectedOffsetBucket={manageSubscriptionType}
          setSelectedOffsetBucket={setManageSubscriptionType}
          SubscriptionPurchaseFlow={OffsetBucketSubscriptionPurchase}
        />
      )}
      <Box
        p={4}
        style={{
          borderRadius: "5px",
          width: "100%",
          ...backgroundImageProps,
        }}
        display="flex"
        justifyContent="center"
      >
        <Grid container spacing={2} justifyContent="space-between">
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={mergeDarkTheme}>
              <Grid
                item
                container
                md={7}
                xs={12}
                direction="column"
                spacing={2}
              >
                <Grid item container spacing={2} direction="column">
                  <Grid item>
                    <Grid
                      item
                      container
                      spacing={2}
                      wrap="nowrap"
                      alignItems="center"
                    >
                      <Grid item>
                        <FontAwesomeIcon icon={icon} color="white" size="2x" />
                      </Grid>
                      <Grid item>
                        <Typography variant="h5" color="textPrimary">
                          {`Your ${title} Subscription`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    wrap="nowrap"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item {...leftColumnSpacingObj}>
                      <Typography variant="body1" color="textPrimary">
                        Offset Total to Date:
                      </Typography>
                    </Grid>
                    <Grid item {...rightColumnSpacingObj}>
                      <Typography variant="body1" color="textPrimary">
                        {`${formatDecimal(
                          tonsToLbs(offsetTotalToDateTons)
                        )} lbs`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    wrap="nowrap"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item {...leftColumnSpacingObj}>
                      <Typography variant="body1" color="textPrimary">
                        Current Subscription Status:
                      </Typography>
                    </Grid>
                    <Grid item {...rightColumnSpacingObj}>
                      <Typography variant="body1" color="textPrimary">
                        {ucFirstLetters(offsetsBucketStatus)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    wrap="nowrap"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item {...leftColumnSpacingObj}>
                      <Typography variant="body1" color="textPrimary">
                        Next Bill Date:
                      </Typography>
                    </Grid>
                    <Grid item {...rightColumnSpacingObj}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                      >{`${formatDate(dueDate)}`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container justifyContent="center">
                  <Box p={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        setManageSubscriptionType(offsetBucketSubscriptionType)
                      }
                    >
                      Manage your subscription
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </ThemeProvider>
          </StyledEngineProvider>
        </Grid>
      </Box>
    </>
  );
};

const MyAclymateDashboard = ({
  emissionsData,
  setSelectedView,
  invitedCompanyName,
  individualData,
}) => {
  const { isMedium } = useLayoutHelpers();
  const [offsetsBucketSubscription] = useOffsetBucketSubscription();
  const { status: offsetsBucketStatus } = offsetsBucketSubscription;

  const isDesktop = !isMedium;
  const { name, numPeople } = individualData;

  const yearlyTonsCo2eObj = getYearlyTonsCo2eValues(emissionsData);
  const {
    yearlyTotalTonsCo2e,
    yearlyHomeTonsCo2e,
    yearlyTravelTonsCo2e,
    yearlyFlightsTonsCo2e,
    yearlyDietTonsCo2e,
    yearlySpendingTonsCo2e,
  } = yearlyTonsCo2eObj;

  const categories = [
    {
      type: "home",
      color: "offices",
      icon: faHome,
      title: "Home Emissions",
    },
    {
      type: "travel",
      color: "vehicles",
      icon: faCar,
      title: "Travel Emissions",
    },
    {
      type: "flights",
      color: "travel",
      icon: faPlane,
      title: "Flights Emissions",
    },
    {
      type: "diet",
      color: "employees",
      icon: faUtensils,
      title: "Diet Emissions",
    },
    {
      type: "expenses",
      color: "rides",
      icon: faMoneyCheck,
      title: "Expenses Emissions",
    },
  ];
  const maxCategories = categories.map((category) => ({
    ...category,
    categoryMaxTons: Math.max(
      ...[
        yearlyHomeTonsCo2e,
        yearlyTravelTonsCo2e,
        yearlyFlightsTonsCo2e,
        yearlyDietTonsCo2e,
        yearlySpendingTonsCo2e,
      ]
    ),
  }));

  return (
    <Grid container direction="column" spacing={2}>
      {isDesktop && (
        <Grid item>
          <Typography variant="h4">{`Welcome, ${name || "User"}!`}</Typography>
        </Grid>
      )}
      <Grid item>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item md={5} xs={12}>
            <CarbonFootprintGraphic
              isDesktop={isDesktop}
              yearlyTonsCo2eObj={yearlyTonsCo2eObj}
            />
          </Grid>
          <Grid item md={7} xs={12}>
            <Grid container direction="column" spacing={2}>
              <Grid item container>
                <ReportContentCard
                  title={`Your ${
                    numPeople > 1 ? "Household " : ""
                  }Emissions Equivalents`}
                >
                  <FootprintEquivalencies
                    footprintTons={yearlyTotalTonsCo2e}
                    exclusionCategories={[
                      "tree-sequestration",
                      "carbon-budget",
                    ]}
                  />
                </ReportContentCard>
              </Grid>
              <Grid item>
                <ReportContentCard>
                  <Grid container spacing={2} direction="column">
                    <Grid item>
                      <ComparisonChart
                        categories={maxCategories}
                        individualData={{
                          homeTons: yearlyHomeTonsCo2e,
                          travelTons: yearlyTravelTonsCo2e,
                          flightsTons: yearlyFlightsTonsCo2e,
                          dietTons: yearlyDietTonsCo2e,
                          expensesTons: yearlySpendingTonsCo2e,
                        }}
                        comparisonData={{
                          homeTons:
                            US_AVERAGE_PER_CAPITA_HOME_TONS_CO2E_PER_YEAR,
                          travelTons:
                            US_AVERAGE_VEHICLE_TRAVEL_TONS_CO2E_PER_YEAR,
                          flightsTons: US_AVERAGE_FLIGHTS_TONS_CO2E_PER_YEAR,
                          dietTons: US_AVERAGE_DIET_TONS_CO2E_PER_YEAR,
                          expensesTons:
                            US_AVERAGE_EXPENDITURES_PER_CAPITA_TONS_CO2E_PER_YEAR,
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        align="right"
                      >
                        Units are in lbs of CO<sub>2</sub>
                      </Typography>
                    </Grid>
                  </Grid>
                </ReportContentCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {offsetsBucketStatus === "active" ? (
        <Grid item container>
          <ImpactSummary
            isDesktop={isDesktop}
            yearlyTotalTonsCo2e={yearlyTotalTonsCo2e}
            offsetsBucketStatus={offsetsBucketStatus}
            offsetsBucketSubscription={offsetsBucketSubscription}
          />
        </Grid>
      ) : (
        <Grid item container>
          <OffsetBucketsBox
            isDesktop={isDesktop}
            emissionsData={emissionsData}
            SubscriptionPurchaseFlow={OffsetBucketSubscriptionPurchase}
          />
        </Grid>
      )}
      <Grid item>
        <WorkspaceCTABlock
          isDesktop={isDesktop}
          {...yearlyTonsCo2eObj}
          learnMoreOnClick={() => setSelectedView("work")}
          invitedCompanyName={invitedCompanyName}
        />
      </Grid>
      {/* We're commenting this out until we figure out what blog collection we want to pull from */}
      {/* <Grid item>
        <BlogBox />
      </Grid> */}
    </Grid>
  );
};

const MyAclymateDashboardLayout = () => {
  const { id: individualId } = getAccountCollectionAndId();
  const { standardBoxPadding } = useLayoutHelpers();
  const [individual, individualLoading] = useAccountData();

  const [emissionsData, setEmissionsData] = useState({});
  const [selectedView, setSelectedView] = useState("primary");
  const [individualData, setIndividualData] = useState({});

  const { email, isCompletedMyAclymateOnboarding, links = [] } = individualData;

  const { name: invitedCompanyName } =
    links.find(
      ({ type, status }) => type === "company" && status === "active"
    ) || {};

  useEffect(() => {
    if (isObjectEmpty(individualData) && !individualLoading) {
      setIndividualData(individual);
    }
  }, [individual, individualData, individualLoading]);

  useEffect(() => {
    if (Object.keys(individualData).length) {
      fetchOurApi({
        path: "/calcs/individuals/fetch-individual-carbon",
        method: "POST",
        data: { individual: individualData },
        callback: (res) => setEmissionsData(res),
      });
    }
  }, [individualData]);

  const myAclymateDashboardViews = {
    primary: (
      <MyAclymateDashboard
        emissionsData={emissionsData}
        setSelectedView={setSelectedView}
        invitedCompanyName={invitedCompanyName}
        individualData={individualData}
      />
    ),
    work: (
      <WorkCTAPage
        setSelectedView={setSelectedView}
        isCompanyInvited={!!invitedCompanyName}
      />
    ),
  };

  return !Object.keys(emissionsData).length ? (
    <Box
      style={{ height: "100vh", width: "100%" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <AnimatedLogo />
    </Box>
  ) : (
    <Box
      {...standardBoxPadding}
      style={{
        height: "100%",
        width: "100%",
        backgroundImage:
          "url(/images/platform/my-aclymate-dashboard-background.jpg)",
        backgroundSize: "cover",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Container maxWidth="lg">
        {!isCompletedMyAclymateOnboarding && (
          <MyAclymateOnboarding
            setIndividualData={setIndividualData}
            email={email}
            individualId={individualId}
          />
        )}
        {myAclymateDashboardViews[selectedView]}
      </Container>
    </Box>
  );
};
export default MyAclymateDashboardLayout;
