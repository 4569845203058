import React from "react";

import { Box, Grid, Typography, Avatar, ButtonBase } from "@mui/material";

const BadgeStepTab = ({
  number,
  name,
  color,
  isSelected,
  onClick,
  gridItemXs,
}) => {
  const avatarTextColor = isSelected ? color : "white";
  const numberBackgroundColor = isSelected ? "white" : color;

  return (
    <Grid item xs={gridItemXs || 4}>
      <ButtonBase style={{ width: "100%" }} onClick={onClick}>
        <Box
          p={2}
          style={{ backgroundColor: numberBackgroundColor, width: "100%" }}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            {number && (
              <Grid item>
                <Avatar
                  style={{
                    backgroundColor: avatarTextColor,
                    color: numberBackgroundColor,
                  }}
                >
                  {number}
                </Avatar>
              </Grid>
            )}
            <Grid item>
              <Typography variant="h3" style={{ color: avatarTextColor }}>
                {name}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </ButtonBase>
    </Grid>
  );
};

export default BadgeStepTab;
