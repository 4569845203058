import React from "react";

import { Grid, Button, Typography, InputAdornment } from "@mui/material";

import { Autocomplete } from "@aclymatepackages/atoms";

import {
  addDataRow,
  editRowData,
  removeDataRow,
} from "@aclymatepackages/array-immutability-helpers";
import { TextField } from "@aclymatepackages/atoms";
import { states } from "@aclymatepackages/lists";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { ucFirstLetters } from "@aclymatepackages/formatters";

import RemovableRow from "../../modules/RemovableRow";

import { isAttendeeStateBreakdownPercentageError } from "../../../helpers/components/events";

const InterfaceRow = ({ state, percentage, editRow }) => {
  const editRowField = (field) => (value) => editRow(field, value);

  const stateOptions = states.map(({ name }) => ({
    value: name,
    name: ucFirstLetters(name),
  }));

  return (
    <>
      <Grid item xs={6}>
        <Autocomplete
          value={stateOptions.find(({ value }) => value === state) || null}
          setValue={({ value }) => editRowField("state")(value)}
          availableOptions={stateOptions}
          label="State"
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={percentage}
          setValue={editRowField("percentage")}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          label="Percentage"
          error={percentage && !numbersRegExpTest(percentage)}
        />
      </Grid>
    </>
  );
};

const StatePercentagesInterface = ({ stateBreakdown, setStateBreakdown }) => {
  const percentageError =
    isAttendeeStateBreakdownPercentageError(stateBreakdown);

  return (
    <Grid container direction="column" spacing={2}>
      {stateBreakdown.map((row, idx) => (
        <RemovableRow
          key={`state-row-${idx}`}
          rowCount={stateBreakdown.length}
          removeRow={removeDataRow(idx, setStateBreakdown)}
        >
          <InterfaceRow
            {...row}
            editRow={editRowData(idx, setStateBreakdown)}
          />
        </RemovableRow>
      ))}
      {percentageError && (
        <Grid item>
          <Typography variant="body1" color="error" align="center">
            States can't add up to more than 100%
          </Typography>
        </Grid>
      )}
      <Grid item container justifyContent="center" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => addDataRow(setStateBreakdown, {})}
            size="small"
          >
            Add State
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default StatePercentagesInterface;
