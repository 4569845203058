import React, { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import dayjs from "dayjs";

import { Button, Grid, IconButton, Typography, useTheme } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { useLayoutHelpers } from "@aclymatepackages/themes";
import MultiPartForm, {
  useSharedFormLoading,
} from "@aclymatepackages/multi-part-form";
import { emailRegExpTest } from "@aclymatepackages/reg-exp";
import { PdlCompanySelect } from "@aclymatepackages/modules";
import { FREE_TRIAL_DAYS } from "@aclymatepackages/constants";
import { incorrectNameCheck } from "@aclymatepackages/other-helpers";

import PasswordInput from "../../../account-access/PasswordInput";
import ReportContentCard from "../../../myaclymate/ReportCardContent";

import {
  db,
  prepareFirebaseObj,
  useAccountData,
  useCachedFirebaseCrud,
} from "../../../../helpers/firebase";
import { generateRandomPin } from "../../../../helpers/otherHelpers";
import {
  fetchOurApi,
  setUserCustomClaims,
} from "../../../../helpers/utils/apiCalls";
import { analyticsTrack } from "../../../../helpers/analytics";

const BusinessInviteForm = ({ setInviteFormOpen }) => {
  const accountId = window.sessionStorage.getItem("accountId");
  const { palette } = useTheme();
  const { theme } = useLayoutHelpers();
  const { updateAccountData } = useCachedFirebaseCrud();
  const [
    {
      name: userName,
      email: myAclymateEmail,
      currentLinkedIds = [],
      links = [],
      commuteSchedules,
    },
  ] = useAccountData();
  const { setFormLoading } = useSharedFormLoading();

  const [company, setCompany] = useState({});
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyAccountPassword, setCompanyAccountPassword] = useState("");
  const [isCompanyExist, setIsCompanyExist] = useState(false);

  const { name } = company;

  const checkCompanyAndSet = async (company) => {
    const { name: companyName } = company;
    const { existingCompany } = await fetchOurApi({
      path: "/onboarding/check-company-exist",
      method: "POST",
      data: { type: "companyName", name: companyName },
      callback: (res) => res,
    });

    if (existingCompany) {
      return setIsCompanyExist(true);
    }
    return setCompany(company);
  };

  const inviteFormSubmit = async () => {
    setFormLoading(true);

    const { employeeCount, address, defaultAirport, ...otherProps } = company;

    const {
      uid,
      displayName,
      email: userEmail,
    } = await fetchOurApi({
      path: "/onboarding/new-auth-user",
      method: "POST",
      data: {
        email: companyEmail,
        password: companyAccountPassword,
        displayName: userName,
      },
      callback: (res) => res,
    });

    const stripeCustomerId = await fetchOurApi({
      path: "/onboarding/create-stripe-customer",
      method: "POST",
      data: {
        name: userName,
        metadata: { representative: userName },
        email: companyEmail,
      },
      callback: ({ id }) => id,
    });

    const { slug } = await fetchOurApi({
      path: "/onboarding/new-document-slug",
      method: "POST",
      data: { collection: "v2-companies", name },
      callback: (res) => res,
    });
    const employeeCountObj = employeeCount
      ? { employeeCount: Number(employeeCount) }
      : {};
    const geographyObj =
      address && defaultAirport
        ? { geography: { address, defaultAirport } }
        : {};

    const newBusinessAccountData = {
      dateCreated: new Date(),
      employeeSurveyPin: generateRandomPin(),
      slug,
      trialDate: dayjs().add(FREE_TRIAL_DAYS, "days").toDate(),
      uids: [uid],
      users: [{ uid, name: displayName, email: userEmail }],
      stripeCustomerId,
      ...otherProps,
      ...employeeCountObj,
      ...geographyObj,
    };

    const newCompanyId = await addDoc(
      collection(db, "v2-companies"),
      prepareFirebaseObj(newBusinessAccountData)
    ).then((docRef) => docRef.id);

    await setUserCustomClaims({
      uid,
      accountId: newCompanyId,
      accountCreationType: "company",
    });

    const [{ commuteEndpoints }] = commuteSchedules;

    await updateAccountData({
      currentLinkedIds: [...currentLinkedIds, newCompanyId],
      links: [
        ...links,
        {
          employeeStatus: commuteEndpoints.find(
            ({ isMyAclymateDefaultEndpoint }) => isMyAclymateDefaultEndpoint
          )
            ? "incomplete"
            : "confirmed",
          id: newCompanyId,
          name,
          status: "active",
          type: "company",
          startDate: new Date(),
        },
      ],
    });

    fetchOurApi({
      accountId,
      path: "/sendgrid/myaclymate-business-invite",
      method: "POST",
      data: {
        email: companyEmail,
      },
    });

    analyticsTrack("myAclymate Work Invite", {
      email: myAclymateEmail,
      companyName: name,
    });

    setInviteFormOpen(false);
    return setFormLoading(false);
  };

  const existingErrorOrnonExistingCompany = isCompanyExist
    ? [
        {
          input: (
            <Typography variant="h6" color={palette.error.main} align="center">
              An account already exists for this company.
            </Typography>
          ),
        },
      ]
    : [
        {
          label: "Enter your work email address",
          value: companyEmail,
          editData: setCompanyEmail,
          error:
            companyEmail &&
            (!emailRegExpTest(companyEmail) ||
              companyEmail === myAclymateEmail),
          helperText: `Make sure that this email address is different than your myAclymate account email (${myAclymateEmail})`,
        },
        {
          input: (
            <PasswordInput
              password={companyAccountPassword}
              setPassword={setCompanyAccountPassword}
              helperText
            />
          ),
          value: companyAccountPassword.length >= 12,
          submitButtonText: "Submit",
          onNextStep: inviteFormSubmit,
        },
      ];

  //TODO: This is definitely broken since we changed it to the PDL Company Select.
  const inviteFormRows = [
    {
      NextStepInput: PdlCompanySelect,
      nextStepInputProps: {
        company,
        inputSelectPropName: "editCompany",
        onInputSelect: checkCompanyAndSet,
        size: "small",
        label: "What's your company's name?",
        error: name && incorrectNameCheck(name, 60),
        errorMsg: "This company name is not valid",
      },
      error: name && incorrectNameCheck(name, 60),
      value: name,
    },
    ...existingErrorOrnonExistingCompany,
  ];

  return (
    <Grid container direction="column">
      <MultiPartForm
        title="Create your business"
        subtitle="Tell us your business name, work email, and a password. We will create an account for your business that you can use or invite others too."
        forms={[
          {
            name: "businessInvite",
            label: "Business Invite",
          },
        ]}
        rows={inviteFormRows.map((row) => ({
          ...row,
          form: "businessInvite",
        }))}
        onClose={() => setInviteFormOpen(false)}
        primaryIcon={EmailIcon}
        color={theme.palette.primary.main}
      />
    </Grid>
  );
};

const WorkCTAPage = ({ setSelectedView, isCompanyInvited }) => {
  const [inviteFormOpen, setInviteFormOpen] = useState(false);

  const comparisonPoints = [
    {
      text: "US small businesses emit enough carbon every year equivalent to being the world's third largest country!",
      image: "business-mission-bro",
    },
    {
      text: "57% of consumers are climate-conscious buyers and have higher-than-average disposable income.",
      image: "shopping-bag-bro",
    },
    {
      text: "75% of millenials would take a pay cut to work for a sustainable company.",
      image: "cash-payment-bro",
    },
  ];
  const processPoints = [
    "Click or tap the button below to fill out a brief form",
    "You will receive an email that will help you make the case to your boss and login to your company's Aclymate account",
    "They will value you and your support of sustainability and looking out for the best interest of the company",
    "Once you create an account, you can invite members of your company and they will cover your work-related offsets and best of all, you can feel good about making an even bigger difference through your company!",
  ];

  return (
    <>
      {inviteFormOpen && (
        <BusinessInviteForm setInviteFormOpen={setInviteFormOpen} />
      )}
      <ReportContentCard>
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid item container spacing={2} alignItems="center" wrap="nowrap">
            <Grid item>
              <IconButton
                size="small"
                onClick={() => setSelectedView("primary")}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="1x" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h6">
                Thank you for taking a big step towards helping the climate. You
                can make an even bigger impact by getting your company on board
                too.
              </Typography>
            </Grid>
          </Grid>
          {comparisonPoints.map(({ text, image }) => (
            <Grid
              item
              container
              xs={12}
              md={5}
              spacing={2}
              justifyContent="center"
            >
              <Grid item>
                <img
                  alt={"business-mountain"}
                  src={`/images/platform/${image}.svg`}
                  style={{ width: "100%", height: "100%" }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h6" align="center">
                  {text}
                </Typography>
              </Grid>
            </Grid>
          ))}
          <Grid
            item
            container
            xs={12}
            md={5}
            spacing={2}
            direction="column"
            justifyContent="center"
          >
            <Grid item>
              <Typography variant="h6" align="center">
                How does this work?
              </Typography>
            </Grid>
            <Grid item>
              <ul>
                {processPoints.map((point, idx) => (
                  <li key={`business-point-${idx}`}>
                    <Typography variant="body1">{point}</Typography>
                  </li>
                ))}
              </ul>
            </Grid>
            <Grid item container justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => setInviteFormOpen(true)}
                disabled={isCompanyInvited}
              >
                Invite your company
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ReportContentCard>
    </>
  );
};
export default WorkCTAPage;
