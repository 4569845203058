import React, { useState } from "react";

import { Grid, Button, Typography } from "@mui/material";

import AddOfficesForm from "../inputs/offices/AddOfficesForm";

import { useAccountData, useCachedFirebaseCrud } from "../../helpers/firebase";
import { useCompletedOnboardingSteps } from "../../helpers/hooks/companyData";
import { analyticsTrack } from "../../helpers/analytics";

const MissingSettingsLayout = ({
  image,
  title,
  subtitle,
  button,
  onClick,
  alternateButton,
}) => (
  <Grid item container alignItems="center">
    <Grid item xs={6} container justifyContent="center">
      <Grid item style={{ width: "100%", maxWidth: "500px" }}>
        <img src={image} alt="" width="100%" />
      </Grid>
    </Grid>
    <Grid item container xs={6} justifyContent="center">
      <Grid item container xs={10} direction="column" spacing={4}>
        <Grid item>
          <Typography variant="h6" align="center">
            {title}
          </Typography>
          <Typography variant="subtitle1" align="center">
            {subtitle}
          </Typography>
        </Grid>
        {button && (
          <Grid item container justifyContent="center" spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                size="large"
                color="primary"
                onClick={() => onClick()}
              >
                {button}
              </Button>
            </Grid>
            {alternateButton && <Grid item>{alternateButton}</Grid>}
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
);

export const MissingOffices = () => {
  const accountId = window.sessionStorage.getItem("accountId");

  const [{ isRemote, name: companyName }] = useAccountData();
  const { updateAccountData } = useCachedFirebaseCrud();

  const [formOpen, setFormOpen] = useState(false);

  const image = `/images/tags-onboarding-images/${
    isRemote ? "remote-company" : "company-offices"
  }.svg`;
  const title = isRemote
    ? "Looks like your company works entirely remotely."
    : "Looks like you haven't added have any workspaces yet.";
  const subtitle = isRemote
    ? `Working remotely is a great way to reduce your company's carbon footprint. If you ever do get an office for your business, you can add it with the "New Company Offices" button.`
    : `Workspaces are places where multiple employees work together (and typically somewhere you own or have a lease). Click the "New Workspaces" button to add new workspaces for your company.`;

  const convertToIsRemote = () => {
    analyticsTrack("company is remote", { accountId, companyName });
    return updateAccountData({ isRemote: true });
  };

  return (
    <>
      {formOpen && <AddOfficesForm setOpen={setFormOpen} />}
      <MissingSettingsLayout
        image={image}
        title={title}
        subtitle={subtitle}
        button="New Workspaces"
        onClick={() => setFormOpen(true)}
        alternateButton={
          isRemote === undefined && (
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => convertToIsRemote()}
            >
              We don't have any offices
            </Button>
          )
        }
      />
    </>
  );
};

export const MissingVendors = ({ setShowNewVendorInput }) => {
  return (
    <MissingSettingsLayout
      image="/images/tags-onboarding-images/vendors.svg"
      title="Looks like you haven't added any vendors yet"
      subtitle="Add vendors to start tracking emisions from your purchases."
      button="Add Vendors"
      onClick={() => setShowNewVendorInput(true)}
    />
  );
};

export const MissingEmployees = ({ setAddEmployees }) => {
  const [{ officesComplete }] = useCompletedOnboardingSteps();

  const subtitle = `${
    officesComplete
      ? ` To get started you can either invite your employees to fill out our
              employee survey and enter their commuting information, or you can
              add them manually yourself.`
      : ` Before you can set up your employees, first finish setting up your company workspaces, so you can tell us where your team works.`
  }`;

  const buttonProps = officesComplete
    ? { button: "add new employees", onClick: () => setAddEmployees(true) }
    : {};

  return (
    <>
      <MissingSettingsLayout
        title="Looks like you haven't added any employees yet"
        subtitle={subtitle}
        image="/images/tags-onboarding-images/confirmed-employees.svg"
        {...buttonProps}
      />
    </>
  );
};
