import React, { useState, useEffect } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { Grid } from "@mui/material";

import { getAccountCollectionAndId } from "../../helpers/otherHelpers";
import { useAccountData } from "../../helpers/firebase";
import { fetchOurApi } from "../../helpers/utils/apiCalls";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE);

const StripeElements = ({ loadingComponent, children }) => {
  const [{ stripeCustomerId: companyObjStripeCustomerId, billing = {} }] =
    useAccountData();

  const [stripeClientSecret, setStripeClientSecret] = useState("");

  useEffect(() => {
    const getStripeClientSecret = async () => {
      const { collection, id } = getAccountCollectionAndId();
      const { stripeCustomerId: billingObjStripeCustomerId } = billing;

      const { clientSecret } = await fetchOurApi({
        path: `/stripe/create-setup-intent`,
        method: "POST",
        data: {
          accountId: id,
          accountType: collection,
          customerId: billingObjStripeCustomerId || companyObjStripeCustomerId,
        },
        callback: (response) => response,
      });

      setStripeClientSecret(clientSecret);
    };

    if (!stripeClientSecret && companyObjStripeCustomerId && billing) {
      getStripeClientSecret();
    }
  }, [stripeClientSecret, companyObjStripeCustomerId, billing]);

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#27aae1",
      colorBackground: "fafafa",
    },
  };

  return (
    <>
      {!stripeClientSecret ? (
        loadingComponent ? (
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>{loadingComponent}</Grid>
          </Grid>
        ) : (
          <></>
        )
      ) : (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: stripeClientSecret, appearance }}
        >
          {children}
        </Elements>
      )}
    </>
  );
};
export default StripeElements;
