import React, { useState, useEffect, useRef } from "react";

import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, idx) => [el, idx]);
  stabilizedThis.sort((a = {}, b = {}) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const makeColumnObj = (name, column = null, sortable = false) => ({
  name,
  column,
  sortable,
});

const SortableTable = ({
  rows,
  rowComponent,
  columns,
  size,
  currentPage,
  setCurrentPage,
  style = {},
}) => {
  const { innerHeight } = window;
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("date");
  const [editRowSelected, setEditRowSelected] = useState(false);
  const [page, setPage] = useState(currentPage || 0);

  const rowLengthRef = useRef(rows.length);

  useEffect(() => {
    if (rows.length !== rowLengthRef) {
      setPage(0);
      rowLengthRef.current = rows.length;
    }
  }, [rows.length]);

  const usablePage = currentPage || page;
  const usableSetPage = setCurrentPage || setPage;

  const sortedPaginatedRows = stableSort(
    rows,
    getComparator(order, orderBy)
  ).slice(usablePage * 50, usablePage * 50 + 50);

  const handlePageChange = (_, newPage) => usableSetPage(newPage);

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const EnhancedTableHead = () => {
    const createSortHandler = (property) => (event) => {
      handleRequestSort(event, property);
    };

    const TableHeadCell = ({ column, name, sortable, orderBy, order }) => (
      <TableCell
        sortDirection={orderBy === column ? order : false}
        id={`${column}_HeadCell`}
      >
        {sortable ? (
          <TableSortLabel
            active={orderBy === column}
            direction={orderBy === column ? order : "asc"}
            onClick={createSortHandler(column)}
          >
            {name}
          </TableSortLabel>
        ) : (
          name
        )}
      </TableCell>
    );

    return (
      <TableHead>
        <TableRow>
          {columns.map(({ column, name, sortable }, idx) => (
            <TableHeadCell
              key={`table-head-cell-${idx}`}
              column={column}
              name={name}
              sortable={sortable}
              orderBy={orderBy}
              order={order}
            />
          ))}
        </TableRow>
      </TableHead>
    );
  };

  return (
    <>
      <TableContainer
        style={{ maxHeight: innerHeight - 250, ...style, zIndex: 0 }}
      >
        <Table stickyHeader size={size || "small"}>
          <EnhancedTableHead />
          <TableBody style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}>
            {sortedPaginatedRows.map((row, idx) =>
              rowComponent(row, idx, editRowSelected, setEditRowSelected)
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length > 50 && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[50]}
          align="left"
          count={rows.length}
          page={usablePage}
          rowsPerPage={50}
          onPageChange={handlePageChange}
          style={{ backgroundColor: "white" }}
        />
      )}
    </>
  );
};
export default SortableTable;
