import React, { useState } from "react";

import { ThemeProvider, Button } from "@mui/material";

import { mergeDarkTheme } from "@aclymatepackages/themes";

import OffsetPurchaseFlow from "../../platform-pages/purchase/OffsetPurchaseFlow";

import CollapsibleAlert from "../../atoms/notifications/CollapsibleAlert";
import OffsetDetailsPurchaseSlider from "./OffsetDetailsPurchaseSlider";

const CNaughtPurchaseAction = () => {
  const [showPurchaseSlider, setShowPurchaseSlider] = useState(false);

  return (
    <>
      {showPurchaseSlider && (
        <OffsetDetailsPurchaseSlider
          isCNaughtPurchase
          onSlideClose={() => setShowPurchaseSlider(false)}
          ProjectPurchaseFlow={OffsetPurchaseFlow}
        />
      )}
      <CollapsibleAlert
        variant="filled"
        title="Would you like to have our partners at CNaught provide you a curated portfolio?"
        subtitle="We have partnered with a team that you can trust to automatically invest in high-quality offsets."
        severity="info"
        onClick={() => setShowPurchaseSlider(true)}
        action={
          <ThemeProvider theme={mergeDarkTheme}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setShowPurchaseSlider(true)}
            >
              Learn More
            </Button>
          </ThemeProvider>
        }
        icon={
          <img
            src="/images/partner-logos/cnaught.svg"
            alt="cnaught logo"
            width="50px"
          />
        }
      />
    </>
  );
};
export default CNaughtPurchaseAction;
