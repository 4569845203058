import React from "react";

import { Grid, Typography } from "@mui/material";

import { DefaultPaper } from "@aclymatepackages/atoms";

import CopyToClipboardIcon from "./buttons/CopyToClipboardIcon";

const CopyButtonCard = ({ title, text, secondaryAction, color }) => {
  return (
    <DefaultPaper style={{ height: "100%" }}>
      <Grid item container direction="column">
        <Grid item container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body1">{title} : </Typography>
          </Grid>
          <Grid item>
            <CopyToClipboardIcon text={text} color={color} />
          </Grid>
        </Grid>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="caption" color="textSecondary">
              {text}
            </Typography>
          </Grid>
          {!!secondaryAction && <Grid item>{secondaryAction}</Grid>}
        </Grid>
      </Grid>
    </DefaultPaper>
  );
};
export default CopyButtonCard;
