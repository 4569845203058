import React from "react";

import { Select } from "@aclymatepackages/atoms";

import { unitTypes } from "../../helpers/otherHelpers";

const UnitsSelect = ({
  value = "",
  setValue,
  size = "small",
  label = "Unit",
}) => {
  return (
    <Select
      label={label}
      options={unitTypes.map(({ name, short }) => ({
        label: name,
        value: short,
      }))}
      value={value}
      editValue={setValue}
      size={size}
    />
  );
};
export default UnitsSelect;
