import React, { useState, useEffect } from "react";

import {
  Grid,
  Button,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import {
  lbsToTons,
  lbsToGrams,
  tonsToLbs,
  milesToMeters,
  gramsToLbs,
} from "@aclymatepackages/converters";
import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { Select, TextField } from "@aclymatepackages/atoms";

import PlacesAutocomplete from "../autocomplete/PlacesAutocomplete";

import UnitsSelect from "../UnitsSelect";

import {
  fetchOurApi,
  fetchDirectionsMileage,
} from "../../../helpers/utils/apiCalls";
import { setAddress } from "../../../helpers/utils/geography";
import { buildInitialTransactionInputValueFromSchema } from "../../../helpers/components/inputs";

const distanceUnitOptions = [
  { label: "Miles", value: "mi" },
  { label: "Kilometers", value: "km" },
];

const transportationOptions = [
  { title: "Truck", value: "road" },
  { title: "Ship", value: "sea" },
  { title: "Air", value: "air" },
  { title: "Train", value: "rail" },
];

const ShippingInput = ({ emissionData, editEmissionData }) => {
  const {
    weightUnit,
    distanceUnit,
    transportationMethod,
    distanceTraveled,
    shippingWeight,
    startPoint,
    endPoint,
  } = emissionData;

  const [distanceInputOption, setDistanceInputOption] = useState(
    distanceTraveled && !startPoint && !endPoint ? "distance" : "start-end"
  );
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    const getAndSetMileage = async () => {
      const totalMileage = await fetchDirectionsMileage(startPoint, endPoint);
      if (typeof totalMileage === "number") {
        setErrorMsg("");
        editEmissionData("distanceTraveled")(totalMileage);
        return totalMileage;
      }
      return setErrorMsg(
        "Unable to calculate distance between these two points"
      );
    };

    if (distanceInputOption === "start-end" && startPoint && endPoint) {
      getAndSetMileage();
    }
  }, [startPoint, endPoint, distanceInputOption, editEmissionData]);

  useEffect(() => {
    const calcShippingEmissions = async () => {
      const shippingWeightInGrams =
        weightUnit === "lbs"
          ? lbsToGrams(shippingWeight)
          : weightUnit === "tons"
          ? lbsToGrams(tonsToLbs(shippingWeight))
          : shippingWeight * 1000;

      const shippingDistanceInMeters =
        distanceUnit === "mi"
          ? milesToMeters(distanceTraveled)
          : distanceTraveled * 1000;

      return await fetchOurApi({
        path: `/patch/calc-shipping-emissions`,
        method: "POST",
        data: {
          distanceM: shippingDistanceInMeters,
          transportationMethod,
          packageMassG: shippingWeightInGrams,
        },
        callback: (res) =>
          editEmissionData("tonsCo2e")(
            Number(lbsToTons(gramsToLbs(res.data.mass_g)))
          ),
      });
    };

    if (
      transportationMethod &&
      shippingWeight &&
      distanceTraveled &&
      distanceTraveled &&
      shippingWeight
    ) {
      calcShippingEmissions();
    }
  }, [
    distanceTraveled,
    distanceUnit,
    transportationMethod,
    shippingWeight,
    weightUnit,
    editEmissionData,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <FormLabel>What is the total shipping weight?</FormLabel>
        </Grid>
        <Grid item xs={8}>
          <TextField
            setValue={editEmissionData("shippingWeight")}
            value={shippingWeight}
            label="Shipping Weight"
            error={
              (shippingWeight && !numbersRegExpTest(shippingWeight)) ||
              shippingWeight < 0
            }
          />
        </Grid>
        <Grid item xs={4}>
          <UnitsSelect
            value={weightUnit}
            setValue={editEmissionData("weightUnit")}
          />
        </Grid>
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12}>
          <FormLabel>
            {distanceInputOption === "start-end"
              ? "Where did your shipment travel?"
              : "How far did your shipment travel?"}
          </FormLabel>
        </Grid>
        {distanceInputOption === "distance" ? (
          <>
            <Grid item container direction="column" xs={8} spacing={1}>
              <Grid item>
                <TextField
                  setValue={editEmissionData("distanceTraveled")}
                  value={distanceTraveled}
                  label="Distance traveled"
                  error={
                    !numbersRegExpTest(distanceTraveled) || distanceTraveled < 0
                  }
                />
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  onClick={() => {
                    setDistanceInputOption("start-end");
                    editEmissionData("distanceTraveled")(null);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Use start and end points instead
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Select
                options={distanceUnitOptions}
                value={distanceUnit}
                editValue={editEmissionData("distanceUnit")}
                size="small"
              />
            </Grid>
          </>
        ) : (
          <Grid item container spacing={1}>
            <Grid item xs={6} container direction="column" spacing={1}>
              <Grid item>
                <PlacesAutocomplete
                  size="small"
                  place={startPoint}
                  editPlace={setAddress((place) =>
                    editEmissionData("startPoint")(place)
                  )}
                  label="Starting Point"
                />
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  onClick={() => {
                    editEmissionData("startPoint")(null);
                    editEmissionData("endPoint")(null);
                    editEmissionData("distanceTraveled")(0);
                    setDistanceInputOption("distance");
                  }}
                  variant="contained"
                  color="primary"
                >
                  Use distance instead
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <PlacesAutocomplete
                size="small"
                place={endPoint}
                editPlace={setAddress((place) =>
                  editEmissionData("endPoint")(place)
                )}
                label="Ending Point"
              />
            </Grid>
            {errorMsg && (
              <Grid item sm={12}>
                <Typography variant="caption" color="error">
                  {errorMsg}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item>
        <FormLabel>What is the primary shipping method?</FormLabel>
        <RadioGroup row>
          {transportationOptions.map((option, idx) => (
            <FormControlLabel
              value={option.value}
              checked={transportationMethod === option.value}
              control={
                <Radio
                  onChange={(e) =>
                    editEmissionData("transportationMethod")(e.target.value)
                  }
                />
              }
              label={option.title}
              key={idx}
            />
          ))}
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

/*SCHEMA
{
  weightUnit: string,
  distanceUnit: string,
  transportationMethod: string,
  distanceTraveled: string,
  shippingWeight: number,
  startPoint: addressSchema,
  endPoint: addressSchema
}
*/
const useShippingInput = ({ transaction, onSave }) => {
  const inputSchema = [
    { field: "weightUnit", defaultValue: "lbs" },
    { field: "distanceUnit" },
    { field: "transportationMethod" },
    { field: "distanceTraveled" },
    { field: "shippingWeight" },
    { field: "startPoint" },
    { field: "endPoint" },
  ];

  const [emissionData, setEmissionData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );

  const editEmissionData = (field) => (value) =>
    editObjectData(setEmissionData, field, value);

  const { transportationMethod, distanceTraveled, shippingWeight, tonsCo2e } =
    emissionData;

  const onTransactionSave = () =>
    onSave({
      ...emissionData,
      tonsCo2e,
    });

  return {
    inputBlock: (
      <ShippingInput
        emissionData={emissionData}
        editEmissionData={editEmissionData}
      />
    ),
    onTransactionSave,
    saveEnabled: shippingWeight && distanceTraveled && transportationMethod,
    tonsCo2e,
  };
};
export default useShippingInput;
