import React, { useState } from "react";

import {
  Grid,
  Typography,
  Button,
  Checkbox,
  InputAdornment,
  IconButton,
  useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LaunchIcon from "@mui/icons-material/Launch";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import { TextField } from "@aclymatepackages/atoms";

import DownloadBadges from "./DownloadBadges";
import Link from "../../atoms/mui/Link";

import {
  useAccountData,
  useCachedFirebaseCrud,
} from "../../../helpers/firebase";
import { useHighestEarnedTier } from "../../../helpers/components/badges";

const PagePromote = () => {
  const { palette } = useTheme();

  const [highestEarnedTier] = useHighestEarnedTier();
  const { promoLink } = highestEarnedTier;

  const [{ badges, slug }] = useAccountData();
  const { attestationsComplete } = badges || {};
  const { updateAccountData } = useCachedFirebaseCrud();
  const [attestationList, setAttestationList] = useState({
    "attestation-1": false,
    "attestation-2": false,
    "attestation-3": false,
  });

  const checklistItems = [
    {
      type: "attestation-1",
      title:
        "I attest that I have accounted for as many emissions to the best of my knowledge",
    },
    {
      type: "attestation-2",
      title:
        "I agree to link my certification logo to my Aclymate Certificate page",
    },
    {
      type: "attestation-3",
      title:
        "I understand that I must maintain the emissions accounting, reductions and offsets required to maintain my certification",
    },
  ];

  const saveAttestation = () =>
    updateAccountData({
      "badges.attestationsComplete": true,
    });

  const envParamsString =
    process.env.REACT_APP_ENVIRONMENT !== "production"
      ? `?env=${process.env.REACT_APP_ENVIRONMENT}`
      : "";
  const pageUrl = `https://netzero.aclymate.com/co/${slug}${envParamsString}`;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h2">Certification Page Generator</Typography>
        <Typography variant="body1">
          Below are folders containing the most-up-to date certifications your
          organization has earned along your climate journey, along with
          templates to promote your accomplishments on social media, email
          marketing and press release. Complete the steps below to download your
          certification logo. Note: If you are adding this to your website make
          sure to link it to your Aclymate Certificate page using the link below
          so you can share your accomplishments with anyone who visits your
          website!
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            {checklistItems.map(({ type, title }) => (
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  {attestationsComplete ? (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: palette.primary.main }}
                    />
                  ) : (
                    <Checkbox
                      size="small"
                      onChange={(event) =>
                        setAttestationList((prevList) => ({
                          ...prevList,
                          [type]: event.target.checked,
                        }))
                      }
                    />
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="body2">{title}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        {!attestationsComplete && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={saveAttestation}
              disabled={Object.values(attestationList).includes(false)}
            >
              Accept & Generate Certification Page
            </Button>
          </Grid>
        )}
        <Grid item style={{ width: "600px" }}>
          <TextField
            variant="outlined"
            label="Your certification page URL"
            disabled={!attestationsComplete}
            size="small"
            value={
              attestationsComplete
                ? pageUrl
                : "Your page url will show up here once it's ready."
            }
            setValue={() => {}}
            InputProps={{
              endAdornment: attestationsComplete && (
                <InputAdornment>
                  <Grid container wrap="nowrap">
                    <Grid item>
                      <IconButton
                        onClick={() => navigator.clipboard.writeText(pageUrl)}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <Link href={pageUrl}>
                        <IconButton>
                          <LaunchIcon />
                        </IconButton>
                      </Link>
                    </Grid>
                  </Grid>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {attestationsComplete && (
        <>
          <Grid
            container
            item
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="h3">Download your badges</Typography>
            </Grid>
            <Grid item>
              <Link href={promoLink}>
                <Button variant="contained" color="primary">
                  Promotional Materials
                </Button>
              </Link>
            </Grid>
          </Grid>
          <Grid item>
            <DownloadBadges {...highestEarnedTier} />
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default PagePromote;
