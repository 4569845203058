import React from "react";

import { Container, Grid, IconButton, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import { DefaultPaper, Backdrop } from "@aclymatepackages/atoms";

const InputModal = ({ setOpen, title, subtitle, actionButton, children }) => {
  return (
    <Backdrop open onClose={() => setOpen(false)}>
      <Container maxWidth="sm">
        <DefaultPaper>
          <Grid container spacing={2} direction="column">
            <Grid
              item
              container
              spacing={2}
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Grid item>
                <Typography variant="h4" color="textPrimary" gutterBottom>
                  {title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {subtitle}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => setOpen(false)}>
                  <CancelIcon />
                </IconButton>
              </Grid>
            </Grid>
            {children}
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
              wrap="nowrap"
            >
              {actionButton}
            </Grid>
          </Grid>
        </DefaultPaper>
      </Container>
    </Backdrop>
  );
};
export default InputModal;
