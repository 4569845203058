import React, { useContext } from "react";

import { Box, Grid, Typography, CircularProgress } from "@mui/material";

import { DefaultPaper } from "@aclymatepackages/atoms";
import { formatDate, ucFirstLetters } from "@aclymatepackages/formatters";

import PurchaseDialogLayout from "../../layouts/PurchaseDialogLayout";
import PurchaseFlowPaymentInfoBox from "../purchase/PurchaseFlowPaymentInfoBox";
import SignupFormLoadingActionButtonLayout from "../settings/SignupFormLoadingActionButtonLayout";

import {
  useAccountData,
  useAuth,
  useCachedFirebaseCrud,
} from "../../../helpers/firebase";
import { useOffsetBucketSubscription } from "../../../helpers/hooks/companyData";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import { analyticsTrack } from "../../../helpers/analytics";
import { useCreateStripeSubscription } from "../../../helpers/hooks/stripe";
import offsetBuckets from "../../../helpers/components/display-lists/offsetBuckets";
import { StripeCustomerContext } from "../../../helpers/contexts/stripeCustomer";

const offsetBucketsStripePriceIds = {
  naturalAndWorkingLands:
    process.env.REACT_APP_STRIPE_NATURAL_AND_WORKING_LANDS_MONTHLY_PRICE,
  cleanTransition: process.env.REACT_APP_STRIPE_CLEAN_TRANSITION_MONTHLY_PRICE,
  climateJustice: process.env.REACT_APP_STRIPE_CLIMATE_JUSTICE_MONTHLY_PRICE,
};

const MyAclymateExpiredSubscriptionButton = ({
  subscriptionType,
  stripeId,
  interval,
}) => {
  const { stripeSubmit, componentLoading, billing } =
    useCreateStripeSubscription();
  const { updateAccountData } = useCachedFirebaseCrud();

  const removeExpiredSubscription = async () => {
    const { subscriptions = [] } = billing;
    const activeSubscriptions = subscriptions.filter(
      ({ status }) => status !== "canceled"
    );
    const updatedBillingObj = {
      ...billing,
      subscriptions: activeSubscriptions,
    };

    await updateAccountData({
      billing: updatedBillingObj,
    });
    return stripeSubmit({
      subscriptionType,
      stripePriceId: stripeId,
      interval,
      updatedBillingObj,
    });
  };

  return (
    <SignupFormLoadingActionButtonLayout
      snackbarProps={{
        message: `You've turned automatic billing for your ${subscriptionType} subscription back on.`,
        alert: "success",
      }}
      componentLoading={componentLoading}
      label="Subscribe"
      onClick={removeExpiredSubscription}
      caption={`By clicking this button you agree to immediately create a subscription for our ${subscriptionType} plan. You will be automatically billed on the interval that you've selected until you cancel. Your payment method will immediately be billed the amount shown above.`}
    />
  );
};

const MyAclymateRestartSubscriptionButton = ({ subscriptionType }) => {
  const [user, userLoading] = useAuth();
  const [
    { billing, subscriptions: companyObjSubscriptions = [] },
    companyDataLoading,
  ] = useAccountData();
  const { updateAccountData } = useCachedFirebaseCrud();

  const { subscriptions = [] } = billing || {};

  const componentLoading = userLoading || companyDataLoading;

  const { subscriptionId, billingCycle } =
    (subscriptions.length &&
      subscriptions.find(({ type }) => type === subscriptionType)) ||
    {};

  const restartSubscription = async () => {
    await fetchOurApi({
      user,
      method: "POST",
      path: "/stripe/restart-subscription",
      data: { subscriptionId },
    });

    return await updateAccountData({
      subscriptions: [
        ...companyObjSubscriptions,
        `${subscriptionType}-${billingCycle}`,
      ],
    });
  };

  return (
    <SignupFormLoadingActionButtonLayout
      componentLoading={componentLoading}
      label="Restart Subscription"
      onClick={restartSubscription}
      caption={`By clicking this button you agree to immediately restart your subscription for our ${subscriptionType} plan. You will be automatically billed on the interval that you selected when you originally created the ${subscriptionType} subscription until you cancel.`}
    />
  );
};

const MyAclymateCancelSubscriptionButton = ({ subscriptionType }) => {
  const [user, userLoading] = useAuth();
  const [{ billing, subscriptions: subscriptionsArray }, companyDataLoading] =
    useAccountData();
  const { subscriptions = [] } = billing || {};

  const { updateAccountData } = useCachedFirebaseCrud();

  const componentLoading = userLoading || companyDataLoading;

  const { subscriptionId, billingCycle, upcomingInvoice } =
    (subscriptions.length &&
      subscriptions.find(({ type }) => type === subscriptionType)) ||
    {};
  const { dueDate } = upcomingInvoice || {};

  const cancelSubscriptionCall = async () =>
    await fetchOurApi({
      user,
      method: "POST",
      path: "/stripe/cancel-subscription",
      data: { subscriptionId },
    });

  const onCancelSubscription = async () => {
    await cancelSubscriptionCall();

    const updatedSubscriptions = subscriptions.map((subscription) => {
      if (subscription.subscriptionId === subscriptionId) {
        return { ...subscription, status: "canceled" };
      }

      return subscription;
    });
    const updatedSubscriptionsArray = subscriptionsArray.filter(
      (element) => element !== `${subscriptionType}-${billingCycle}`
    );

    const updatedBillingObj = {
      ...billing,
      subscriptions: updatedSubscriptions,
    };

    return await updateAccountData({
      billing: updatedBillingObj,
      subscriptions: updatedSubscriptionsArray,
    });
  };

  return (
    <SignupFormLoadingActionButtonLayout
      snackbarProps={{
        message: `You've canceled your ${subscriptionType} subscription.`,
        alert: "success",
      }}
      componentLoading={componentLoading}
      label="Cancel Subscription"
      onClick={onCancelSubscription}
      caption={`By clicking this button you agree to cancel your subscription for our ${subscriptionType} plan. You can continue using the features of this plan until ${formatDate(
        dueDate
      )}, at which point your subscription won't automatically renew.`}
      disabled={componentLoading}
    />
  );
};

const MyAclymateSubscriptionButton = ({
  subscriptionType,
  stripeId,
  interval,
  value,
  name,
  onPostSubscriptionSubmit,
}) => {
  const { stripeSubmit, componentLoading } = useCreateStripeSubscription();

  const onSubscribeClick = async () => {
    await stripeSubmit({
      subscriptionType,
      stripePriceId: stripeId,
      interval,
      value,
      name,
    });

    if (onPostSubscriptionSubmit) {
      return onPostSubscriptionSubmit();
    }

    return null;
  };

  return (
    <SignupFormLoadingActionButtonLayout
      snackbarProps={{
        message: `You've subscribed to our ${name} plan.`,
        alert: "success",
      }}
      componentLoading={componentLoading}
      label="Subscribe"
      onClick={onSubscribeClick}
      caption={`By clicking this button you agree to immediately create a subscription for our ${name} plan. You will be automatically billed on the interval that you've selected until you cancel. Your payment method will immediately be billed the amount shown above.`}
    />
  );
};

const MyAclymateSubscriptionActionBlock = ({
  status,
  subscriptionType,
  title,
  stripeId,
  interval,
  subscriptionObj,
  value,
  onPostSubscriptionSubmit,
}) => {
  if (status === "active" || status === "incomplete") {
    return (
      <MyAclymateCancelSubscriptionButton subscriptionType={subscriptionType} />
    );
  }

  if (status === "canceled") {
    return (
      <MyAclymateRestartSubscriptionButton
        subscriptionType={subscriptionType}
      />
    );
  }

  if (status === "expired" && subscriptionObj) {
    return (
      <MyAclymateExpiredSubscriptionButton
        subscriptionType={subscriptionType}
        stripeId={stripeId}
        interval={interval}
      />
    );
  }

  return (
    <MyAclymateSubscriptionButton
      subscriptionType={subscriptionType}
      stripeId={stripeId}
      interval={interval}
      value={value}
      name={`${ucFirstLetters(interval)} ${title}`}
      onPostSubscriptionSubmit={onPostSubscriptionSubmit}
    />
  );
};

const OffsetBucketSubscriptionPurchase = ({ selectedOffsetBucket }) => {
  const [{ email }, companyDataLoading] = useAccountData();
  const { paymentMethod, isPaymentMethodLoading } = useContext(
    StripeCustomerContext
  );
  const { paymentMethodId } = paymentMethod || {};

  const [offsetsBucketSubscription] = useOffsetBucketSubscription();
  const { status: offsetsBucketStatus } = offsetsBucketSubscription;

  const { title, tagline, descriptor } = offsetBuckets[selectedOffsetBucket];
  const stripePriceId = offsetBucketsStripePriceIds[selectedOffsetBucket];

  const onPostSubscriptionSubmit = () =>
    analyticsTrack("myAclymate Offset Subscription Purchase (In-App)", {
      email,
      title,
    });

  return companyDataLoading || isPaymentMethodLoading ? (
    <DefaultPaper style={{ width: "100%" }}>
      <Grid item container justifyContent="center">
        <CircularProgress />
      </Grid>
    </DefaultPaper>
  ) : !paymentMethodId ? (
    <PurchaseFlowPaymentInfoBox dialogSubtitle="We need your credit card information before you can subscribe" />
  ) : (
    <PurchaseDialogLayout title={title} subtitle={tagline}>
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="body1">{descriptor}</Typography>
          </Grid>
          <Grid item>
            <MyAclymateSubscriptionActionBlock
              status={offsetsBucketStatus}
              subscriptionType={selectedOffsetBucket}
              stripeId={stripePriceId}
              interval="month"
              title={title}
              subscriptionObj={offsetsBucketSubscription}
              onPostSubscriptionSubmit={onPostSubscriptionSubmit}
            />
          </Grid>
        </Grid>
      </Box>
    </PurchaseDialogLayout>
  );
};
export default OffsetBucketSubscriptionPurchase;
