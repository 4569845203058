import React, { useState } from "react";
import CompanyDataListItem from "./CompanyDataListItem";
import { useCachedFirebaseCrud } from "../../../helpers/firebase";
import { IndustryAutocomplete } from "@aclymatepackages/modules";

const CompanyIndustryRow = ({ companyData: { industry }, isEditable }) => {
  const { updateAccountData } = useCachedFirebaseCrud();

  const industryObject =
    typeof industry === "string" ? { label: industry } : industry;
  const [modifyIndustry, setModifyIndustry] = useState(industryObject);

  return (
    <CompanyDataListItem
      editingComponent={
        <IndustryAutocomplete
          industry={modifyIndustry}
          setIndustry={setModifyIndustry}
          style={{ width: "250px" }}
          label="Choose a new industry"
          variant="standard"
        />
      }
      saveDisabled={!modifyIndustry}
      onCancel={() => setModifyIndustry(industryObject)}
      onSave={() => updateAccountData({ industry: modifyIndustry })}
      primaryText={industryObject?.label}
      secondaryText="Company Industry"
      isEditable={isEditable}
    />
  );
};
export default CompanyIndustryRow;
