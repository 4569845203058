import { processAccountingData } from "@aclymatepackages/accounting";

import { useCachedDisplayData, useAccountData } from "../firebase";

const useAccountingData = () => {
  const [accountingYears, accountingEventsLoading] =
    useCachedDisplayData("accounting");
  const [offsets, offsetsLoading] = useCachedDisplayData("offsets");
  const [{ badges }, accountDataLoading] = useAccountData();
  const { pledgeDate, pledgeReductionPercentage } = badges || {};

  const dataLoading =
    accountingEventsLoading || offsetsLoading || accountDataLoading;

  const accountingData = dataLoading
    ? {}
    : processAccountingData({
        pledgeDate,
        pledgeReductionPercentage,
        accountingYears,
        offsets,
      });

  return [accountingData, dataLoading];
};
export default useAccountingData;
