import React, { useState } from "react";

import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import Link from "../atoms/mui/Link";

const PasswordInputHelperText = ({ password }) => (
  <Grid container direction="column" spacing={2}>
    {password.length < 12 && (
      <Grid item>
        <Typography variant="caption">
          Your password needs to be at least 12 characters long.
        </Typography>
      </Grid>
    )}
    <Grid item>
      <Typography variant="caption">
        We will never sell or share your data and will only use it to calculate
        your carbon emissions as described in our{" "}
        <Link href="https://aclymate.com/privacy-policy">Privacy Policy</Link>{" "}
        and{" "}
        <Link href="https://aclymate.com/terms-and-conditions">
          Terms of Use
        </Link>
      </Typography>
    </Grid>
  </Grid>
);

const PasswordInput = ({ password, setPassword, helperText, disabled }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      id="password-input"
      label="Password"
      variant="outlined"
      type={showPassword ? "text" : "password"}
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      helperText={helperText && <PasswordInputHelperText password={password} />}
      fullWidth
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword((showPassword) => !showPassword)}
              size="large"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      disabled={disabled}
    />
  );
};
export default PasswordInput;
