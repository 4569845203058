import React, { useState } from "react";

import { Box, Grid, Typography, Button, IconButton } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { TextField, AnimatedLogo } from "@aclymatepackages/atoms";
import { emailRegExpTest } from "@aclymatepackages/reg-exp";
import {
  editRowData,
  addDataRow,
  removeDataRow,
} from "@aclymatepackages/array-immutability-helpers";

import RemovableRow from "../../modules/RemovableRow";
import PurchaseDialogLayout from "../../layouts/PurchaseDialogLayout";

import { useAuth, useAccountData } from "../../../helpers/firebase";
import { analyticsTrack } from "../../../helpers/analytics";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";

const EditableEmailRow = ({ email, editEmail }) => (
  <>
    <Grid item sm={10}>
      <TextField
        label="Email Address"
        value={email}
        setValue={(value) => editEmail("email", value)}
      />
    </Grid>
  </>
);

const EmailsRow = ({
  estimateRecipientCount,
  estimateRecipient: { email },
  editEstimateRecipient,
  removeEstimateRecipient,
  idx,
}) => (
  <Grid container justifyContent="center" spacing={2}>
    {estimateRecipientCount > 1 ? (
      <RemovableRow
        rowCount={estimateRecipientCount}
        removeRow={removeEstimateRecipient}
        idx={idx}
        justifyContent="center"
      >
        <EditableEmailRow email={email} editEmail={editEstimateRecipient} />
      </RemovableRow>
    ) : (
      <EditableEmailRow email={email} editEmail={editEstimateRecipient} />
    )}
  </Grid>
);

const EstimateCreation = ({
  setPurchaseStep,
  setEstimateData,
  project,
  lbsToPurchase,
  purchaseDollars,
  isCNaughtPurchase,
}) => {
  const accountId = window.sessionStorage.getItem("accountId");
  const [user] = useAuth();
  const [{ stripeCustomerId: companyObjStripeCustomerId, billing }] =
    useAccountData();
  const { email: userEmail } = user || {};

  const [createEstimateLoading, setCreateEstimateLoading] = useState(false);
  const [estimateRecipients, setEstimateRecipients] = useState([{}]);

  const areEstimateRecipientsValid = () => {
    const emailsCorrectlyFilled = estimateRecipients.reduce(
      (acc, { email }) => acc && email && emailRegExpTest(email),
      true
    );

    if (!emailsCorrectlyFilled) {
      return false;
    }

    return true;
  };

  const createEstimate = () => {
    setCreateEstimateLoading(true);

    const { stripeCustomerId: billingObjStripeCustomerId } = billing || {};

    const createEstimateCallback = async (data) => {
      analyticsTrack("Estimate Created", {
        pounds: lbsToPurchase,
        project: project.slug,
        price: purchaseDollars,
      });

      setPurchaseStep("success");
      return setEstimateData(data);
    };

    return fetchOurApi({
      path: `/estimates/create-estimate`,
      method: "POST",
      data: {
        userEmail,
        estimateRecipients,
        accountId,
        lbsToPurchase: Number(lbsToPurchase),
        projectData: project,
        // certificationLevel,
        // certificationDate,
        customerId: companyObjStripeCustomerId || billingObjStripeCustomerId,
        isCNaughtPurchase,
      },
      callback: createEstimateCallback,
    });
  };

  return (
    <PurchaseDialogLayout
      title="Generate an estimate of the offset purchase"
      subtitle="Creating an offset estimate locks the price of these offsets for 30 days so that your team can review them internally."
    >
      <Box p={2} position="relative">
        <Grid item container direction="column" spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="h6" align="center">
              Add people who can view, approve, or pay for the estimate
            </Typography>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            {estimateRecipients.map((estimateRecipient, idx) => (
              <EmailsRow
                key={`estimate-recipient-row-${idx}`}
                estimateRecipient={estimateRecipient}
                estimateRecipientCount={estimateRecipients.length}
                editEstimateRecipient={editRowData(idx, setEstimateRecipients)}
                removeEstimateRecipient={removeDataRow(
                  idx,
                  setEstimateRecipients
                )}
                idx={idx}
              />
            ))}
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => addDataRow(setEstimateRecipients, {})}
            >
              Add another email
            </Button>
          </Grid>
          <Grid item container spacing={2} justifyContent="space-between">
            <Grid item>
              <IconButton onClick={() => setPurchaseStep("")} size="large">
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={createEstimate}
                disabled={!areEstimateRecipientsValid()}
              >
                Send Estimate
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {createEstimateLoading && (
          <Box
            position="absolute"
            style={{ inset: 0, backgroundColor: "rgba(255, 255, 255, 0.85)" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <AnimatedLogo />
          </Box>
        )}
      </Box>
    </PurchaseDialogLayout>
  );
};
export default EstimateCreation;
