import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useParams } from "react-router";
import ReactGA from "react-ga4";
import { useGrowthBook } from "@growthbook/growthbook-react";
import * as Sentry from "@sentry/react";

import { Box } from "@mui/material";

import { AnimatedLogo } from "@aclymatepackages/atoms";

import ErrorBoundary from "./atoms/ErrorBoundary";

import CompanyLayout from "./layouts/CompanyLayout";
import MyAclymateLayout from "./layouts/MyAclymateLayout";

import VendorDashboard from "./vendors/VendorDashboard";
import OnboardingChecklist from "./platform-pages/onboarding";
import Dashboard from "./platform-pages/dashboard";
import Settings from "./platform-pages/settings";
import Purchase from "./platform-pages/purchase";
import MyAclymateDashboard from "./platform-pages/myAclymateDashboard";
import Badges from "./platform-pages/badges";
import Reporting from "./platform-pages/reporting";
import Accounting from "./platform-pages/accounting";
import Events from "./platform-pages/events";

import ExpiredAccountFootprintSummary from "./modules/ExpiredSubscriptionFootprintSummary";

import { useAuth, signOut } from "../helpers/firebase";
import { analyticsIdentify } from "../helpers/analytics";
import { useSubscriptionType } from "../helpers/hooks/companyData";
import { getAccountCollectionAndId } from "../helpers/otherHelpers";
import { StripeCustomerContextProvider } from "../helpers/contexts/stripeCustomer";
import { EmissionsContextProvider } from "../helpers/contexts/emissions";

const LoadingScreen = () => (
  <Box
    style={{ height: "100vh", width: "100%" }}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <AnimatedLogo />
  </Box>
);

const SubscriptionSwitch = () => {
  const { page, view } = useParams();
  const [{ status }, subscriptionLoading] = useSubscriptionType("saas");

  const companyPageComponents = (page) => {
    switch (page) {
      case "onboarding":
        return <OnboardingChecklist />;
      case "dashboard":
        return <Dashboard />;
      case "settings":
        return <Settings view={view} />;
      case "purchase":
        return <Purchase />;
      case "badges":
        return <Badges />;
      case "reporting":
        return <Reporting />;
      case "accounting":
        return <Accounting />;
      case "events":
        return <Events />;
      default:
        return <Dashboard />;
    }
  };

  if (subscriptionLoading) {
    return <LoadingScreen />;
  }

  if (status === "vendor") {
    return (
      <StripeCustomerContextProvider>
        <EmissionsContextProvider>
          <VendorDashboard />
        </EmissionsContextProvider>
      </StripeCustomerContextProvider>
    );
  }

  if (status === "expired") {
    return <ExpiredAccountFootprintSummary />;
  }

  return (
    <CompanyLayout page={page} view={view}>
      <ErrorBoundary>{companyPageComponents(page)}</ErrorBoundary>
    </CompanyLayout>
  );
};

const PlatformIndex = () => {
  const { accountType, page, view } = useParams();
  const growthbook = useGrowthBook();

  const [user, userLoading] = useAuth();

  const [redirect, setRedirect] = useState(false);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  useEffect(() => {
    const authenticateUser = async () => {
      if (!user) {
        return setRedirect("/");
      }

      const { claims } = await user.getIdTokenResult({ forceRefresh: true });

      const { company, individual } = claims;
      const { collection, id: accountId } = getAccountCollectionAndId();

      if (
        (!company && !individual) ||
        (collection === "v2-companies" &&
          (company !== accountId || accountType !== "company")) ||
        (collection === "individuals" &&
          (individual !== accountId || accountType !== "my"))
      ) {
        setRedirect("/");
        return await signOut();
      }

      const { email, displayName, uid } = user;
      analyticsIdentify(user);

      //TODO: disable this in localhost
      if (process.env.NODE_ENV !== "development") {
        Sentry.setUser({ email, id: uid });
      }

      growthbook.setAttributes({
        company: collection === "v2-companies",
        individual: collection === "individuals",
        id: accountId,
        name: displayName,
        email,
      });

      if (process.env.REACT_APP_ENVIRONMENT === "production") {
        ReactGA.initialize([{ trackingId: "G-T865T22VKG" }]);
      }

      const { id: companyId } = getAccountCollectionAndId();
      if (!companyId) {
        const accountIdPrefix =
          accountType === "individual" ? "individuals" : "v2-companies";
        const fullAccountId = `${accountIdPrefix}-${company}`;
        window.sessionStorage.setItem("accountId", fullAccountId);
      }

      return setIsUserAuthenticated(true);
    };

    if (!userLoading) {
      authenticateUser();
    }
  }, [user, userLoading, growthbook, accountType]);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (!user) {
    return <></>;
  }

  if (!isUserAuthenticated) {
    return <LoadingScreen />;
  }

  if (accountType === "my") {
    return (
      <MyAclymateLayout page={page} view={view}>
        <MyAclymateDashboard />
      </MyAclymateLayout>
    );
  }

  return (
    <ErrorBoundary>
      <StripeCustomerContextProvider>
        <SubscriptionSwitch />
      </StripeCustomerContextProvider>
    </ErrorBoundary>
  );
};

export default PlatformIndex;
