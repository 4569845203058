import React, { useState } from "react";

import {
  List,
  Box,
  Grid,
  Button,
  Typography,
  Tooltip,
  Container,
  Avatar,
  useTheme,
} from "@mui/material";
import PowerIcon from "@mui/icons-material/Power";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCalendar,
  faCheckSquare,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";

import { AnimatedLogo } from "@aclymatepackages/atoms";
import { YesNoQuestion } from "@aclymatepackages/modules";
import { utilitiesProviders } from "@aclymatepackages/lists";

import Products from "../settings/Products";

import AddOfficesForm from "../../inputs/offices/AddOfficesForm";
import AddEmployeesInputs from "../../inputs/employees/AddEmployeesInputs";
import VendorInputForm from "../../inputs/vendors/VendorInputForm";
import CreditCardSlider from "../../inputs/CreditCardSlider";

import Card from "../../atoms/mui/Card";
import GrayPaper from "../../atoms/GrayPaper";
import AccordionBox from "../../atoms/mui/AccordionBox";
import EditStartDateButton from "../../atoms/accounting/EditStartDateButton";

import StripeElements from "../../modules/StripeElements";
import CompanyNameRow from "../../modules/settings/CompanyNameRow";
import CompanyAddressRow from "../../modules/settings/CompanyAddressRow";
import CompanyIndustryRow from "../../modules/settings/CompanyIndustryRow";
import CompanyEmployeeCountRow from "../../modules/settings/CompanyEmployeeCountRow";
import QuickbooksCard from "../../modules/settings/QuickbooksCard";

import {
  useAccountData,
  useCachedFirebaseCrud,
  useCachedDisplayData,
} from "../../../helpers/firebase";
import {
  useSubscriptionType,
  useStarterTierSubscriptionFlags,
} from "../../../helpers/hooks/companyData";
import { analyticsTrack } from "../../../helpers/analytics";

const useIsOfficesVendorsEmployeesComplete = () => {
  const [employees, employeesLoading] = useCachedDisplayData("employees");
  const [offices, officesLoading] = useCachedDisplayData("offices");
  const [vendors, vendorsLoading] = useCachedDisplayData("vendors");
  const [{ employeeSurveyLinkSent }, accountDataLoading] = useAccountData();

  const isComplete =
    (!!employees.length || !!employeeSurveyLinkSent) &&
    !!offices.length &&
    !!vendors.length;

  return {
    employees,
    offices,
    vendors,
    isComplete,
    isLoading:
      employeesLoading ||
      officesLoading ||
      vendorsLoading ||
      accountDataLoading,
  };
};

const useBasicCompanyInformationForm = () => {
  const { updateAccountData } = useCachedFirebaseCrud();
  const [companyData, accountDataLoading] = useAccountData();
  const { isRemote, peopleDataLabsCompanyId, companyDataConfirmed } =
    companyData;

  if (!peopleDataLabsCompanyId) {
    return [[], accountDataLoading];
  }

  const content = (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <List>
          <CompanyNameRow companyData={companyData} />
          <CompanyAddressRow companyData={companyData} />
          <CompanyIndustryRow companyData={companyData} />
          <CompanyEmployeeCountRow companyData={companyData} />
        </List>
      </Grid>
      <Grid item>
        <Box pl={2}>
          <YesNoQuestion
            question="Does your company only work remotely?"
            value={isRemote}
            setValue={(value) => updateAccountData({ isRemote: value })}
            lightBackground
          />
        </Box>
      </Grid>
      <Grid item container justifyContent="center">
        <Grid item>
          <Tooltip
            title={
              isRemote === undefined &&
              "Please select if you company operetes remotely or not"
            }
          >
            <span>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  analyticsTrack("Company Info Confirmed");
                  return updateAccountData({
                    companyDataConfirmed: true,
                    employeeCountConfirmed: true,
                  });
                }}
                disabled={isRemote === undefined || companyDataConfirmed}
              >
                {companyDataConfirmed ? "Confirmed" : "Confirm All"}
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  );

  return [
    [
      {
        content,
        contentTitle: "Basic Company Information",
        avatarIcon: faInfo,
        avatarColor: "travel",
        isFormCompleted: companyDataConfirmed,
      },
    ],
    accountDataLoading,
  ];
};

const useStartDateForm = () => {
  const [
    { startDate, peopleDataLabsCompanyId, companyDataConfirmed },
    accountDataLoading,
  ] = useAccountData();

  const steps = [
    {
      action: <EditStartDateButton />,
      primaryText: "Choose a year that you want to start your accounting from",
      isActive: !startDate && (peopleDataLabsCompanyId || companyDataConfirmed),
      isCompleted: !!startDate,
    },
  ];

  return [
    {
      steps,
      contentTitle: "Accounting Start Date",
      avatarIcon: faCalendar,
      avatarColor: "travel",
      isFormCompleted: !!startDate,
    },
    accountDataLoading,
  ];
};

const useOfficesEmployeesVendorsForm = (setSelectedInput) => {
  const { updateAccountData } = useCachedFirebaseCrud();
  const { employees, vendors, offices, isComplete, isLoading } =
    useIsOfficesVendorsEmployeesComplete();
  const [
    { isRemote, startDate, employeeSurveyPin, employeeSurveyLinkSent },
    accountDataLoading,
  ] = useAccountData();

  const hasCompanyOffice = !isRemote
    ? [
        {
          title: "office",
          primaryText: "Add offices",
          isActive: startDate,
          isCompleted: !!offices.length,
          action: (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSelectedInput("offices")}
              size="small"
            >
              Set Up Offices
            </Button>
          ),
        },
      ]
    : [];

  const steps = [
    ...hasCompanyOffice,
    {
      title: "vendor",
      primaryText: "Add Vendors",
      isActive: !!offices.length,
      isCompleted: !!vendors.length,
      action: (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectedInput("vendors")}
          size="small"
        >
          Set Up Vendors
        </Button>
      ),
    },
    {
      title: "employee",
      primaryText: "Add employees",
      isActive: !!vendors.length,
      isCompleted: !!employees.length || employeeSurveyLinkSent,
      action: !!employeeSurveyPin ? (
        <Button
          variant="contained"
          size="small"
          onClick={() => updateAccountData({ employeeSurveyLinkSent: true })}
        >
          I've invited my employees
        </Button>
      ) : (
        <Button
          variant="contained"
          size="small"
          onClick={() => setSelectedInput("employees")}
        >
          Set up Employees
        </Button>
      ),
    },
  ];

  return [
    {
      steps,
      contentTitle: "Detailed Company Information",
      avatarIcon: faBuilding,
      avatarColor: "travel",
      isFormCompleted: isComplete,
    },
    isLoading || accountDataLoading,
  ];
};

const ChecklistStep = ({
  action,
  primaryText,
  isActive,
  isCompleted,
  ...otherProps
}) => {
  const { palette } = useTheme();

  return (
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="center"
      disablePadding
      action={action}
      {...otherProps}
      style={{
        opacity: isActive && !isCompleted ? 1 : 0.9,
      }}
    >
      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            {isCompleted ? (
              <FontAwesomeIcon
                icon={faCheckSquare}
                color={palette.secondary.main}
                size="lg"
              />
            ) : (
              <FontAwesomeIcon icon={faSquare} size="lg" />
            )}
          </Grid>
          <Grid item>
            <Typography variant="body1">{primaryText}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>{action}</Grid>
    </Grid>
  );
};

const SubscriptionActionBlock = () => (
  <GrayPaper>
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography variant="h3">
          Unlock these integrations when you subscribe for Aclymate
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Automate your workflows with our integrations when you sign up for our
          our professional plan or higher.{" "}
        </Typography>
      </Grid>
      <Grid item container spacing={2}>
        <QuickbooksCard />
        <Card
          gridProps={{ sm: 6 }}
          cardType="layout"
          title="Utilities"
          subtitle="We can connect your utilities for faster setup."
          icon={<PowerIcon />}
          contentType="layout"
          content={
            <Grid container spacing={2} direction="column">
              <Grid item container spacing={2} justifyContent="center">
                {[...utilitiesProviders]
                  .slice(0, 10)
                  .filter(({ utilityId }) => utilityId !== "DEMO")
                  .map(({ logo, name, utilityId }) => (
                    <Grid item key={utilityId}>
                      <Tooltip title={name}>
                        <Avatar
                          src={`/images/utilities-logos/${logo}`}
                          imgProps={{
                            style: { objectFit: "contain" },
                          }}
                          style={{ width: 48, height: 48 }}
                        />
                      </Tooltip>
                    </Grid>
                  ))}
              </Grid>
              <Grid item>
                <Typography variant="h6" align="center" color="textSecondary">
                  Plus hundreds more
                </Typography>
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item>
        <Products />
      </Grid>
    </Grid>
  </GrayPaper>
);

const OnboardingChecklist = () => {
  const [selectedInput, setSelectedInput] = useState("");

  const [{ status: subscriptionStatus }, subscriptionLoading] =
    useSubscriptionType("saas");
  const { isStarterTier } = useStarterTierSubscriptionFlags();
  const [basicCompanyInfoForm, basicFormLoading] =
    useBasicCompanyInformationForm();
  const [startDateForm, startDateFormLoading] = useStartDateForm();
  const [officeEmployeesVendorsForm, officesEmployeeVendorsFormLoading] =
    useOfficesEmployeesVendorsForm(setSelectedInput);

  const dataLoading =
    subscriptionLoading ||
    basicFormLoading ||
    startDateFormLoading ||
    officesEmployeeVendorsFormLoading;

  const checklistForms = [
    ...basicCompanyInfoForm,
    startDateForm,
    officeEmployeesVendorsForm,
  ];

  const inputForms = {
    offices: <AddOfficesForm setOpen={setSelectedInput} />,
    vendors: <VendorInputForm setOpen={setSelectedInput} />,
    employees: <AddEmployeesInputs onClose={setSelectedInput} />,
    billing: (
      <StripeElements>
        <CreditCardSlider setSliderOpen={() => setSelectedInput("")} />
      </StripeElements>
    ),
  };

  const activeStep = checklistForms.reduce(
    (acc, { isFormCompleted }, idx) => (isFormCompleted ? idx + 1 : acc),
    0
  );

  return (
    <>
      {selectedInput && inputForms[selectedInput]}
      {dataLoading ? (
        <Box
          style={{ height: "90vh" }}
          p={4}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <AnimatedLogo />
        </Box>
      ) : (
        <Box p={2}>
          <Container maxWidth="lg">
            <Grid container spacing={2} direction="column">
              <Grid item>
                <Typography variant="h5" color="textPrimary">
                  Follow our quick and easy checklist to get started on your
                  climate journey.
                </Typography>
              </Grid>
              {checklistForms.map(
                (
                  {
                    contentTitle,
                    avatarColor,
                    avatarIcon,
                    isFormCompleted,
                    content,
                    steps,
                  },
                  index
                ) => (
                  <Grid item key={`checklist-step-${index}`}>
                    <AccordionBox
                      contentTitle={contentTitle}
                      avatarColor={avatarColor}
                      avatarIcon={avatarIcon}
                      isExpanded={!isFormCompleted && index === activeStep}
                      isChecked={isFormCompleted}
                    >
                      {content || (
                        <Grid container spacing={1} direction="column">
                          {steps.map((step, idx) => (
                            <ChecklistStep
                              key={`checklist-step-${idx}`}
                              {...step}
                            />
                          ))}
                        </Grid>
                      )}
                    </AccordionBox>
                  </Grid>
                )
              )}
              {!(subscriptionStatus === "active" && !isStarterTier) && (
                <Grid item>
                  <SubscriptionActionBlock />
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};
export default OnboardingChecklist;
