import { createContext, useContext } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { splitScopeEmissions } from "@aclymatepackages/chart-helpers";
import { buildCompanyEmissionsArrays } from "@aclymatepackages/calcs/recurring";

import { emissionStatuses } from "../components/primaryView";
import { PlatformLayoutContext } from "./platformLayout";
import { SandboxDataContext } from "./sandboxData";
import { AdminDataContext } from "./adminData";

import { useCachedDisplayData, useAccountData } from "../firebase";

import useAccountingData from "../hooks/accountingData";
import { useDisplayEvents } from "../components/events";

dayjs.extend(isSameOrAfter);

const EmissionsContext = createContext();

const assignEmissionStatuses = (emissions, mostRecentAccountingDate) => {
  const buildEmissionStatus = ({ id, date, status, subcategory }) => {
    if (dayjs(date).isAfter(dayjs())) {
      return "cleared";
    }

    if (
      mostRecentAccountingDate &&
      dayjs(date).isBefore(dayjs(mostRecentAccountingDate))
    ) {
      return "locked";
    }

    if (id && status !== "confirmed" && subcategory !== "spend-based") {
      return "unconfirmed";
    }

    return "confirmed";
  };

  return emissions.map((emission) => {
    const currentStatus = buildEmissionStatus(emission);
    const { severity } = emissionStatuses[currentStatus] || {};

    return { ...emission, currentStatus, severity };
  });
};

/*
//Update transactions so that we query transactions before the start date
//All transactions in this object should be formatted like in aclymatepackages/calcs/recurring.js:1232
const transactionObject = {
unconfirmedTransactions,
utilitiesTransactions,
manualEntryTransactions,
combinedUtilitiesManulTransactions,
utilityApiTransactions,
combinedUtilityApiTransactions,
gasUtilityApiTransactions,
electricityUtilityApiTransactions,
nonEventTransactions,
each office transactions,
each employee transactions,
each vendor transactions
each event transactions
}
*/

export const EmissionsContextProvider = ({ children }) => {
  const { viewMode = "company" } = useContext(PlatformLayoutContext) || {};
  const { sandboxData } = useContext(SandboxDataContext) || {};
  const { adminData } = useContext(AdminDataContext) || {};
  const { companyData: sandboxCompanyData } = sandboxData || {};
  const { companyData: adminCompanyData } = adminData || {};
  const { startDate: sandboxStartDate } = sandboxCompanyData || {};
  const { startDate: adminStartDate } = adminCompanyData || {};

  const [accountData, accountDataLoading] = useAccountData();
  const [{ mostRecentAccountingDate }, accountingDataLoading] =
    useAccountingData();
  const { startDate: companyStartDate } = accountData;

  const [dbEmissions, dbEmissionsLoading] = useCachedDisplayData("emissions");
  const [employees, employeesLoading] = useCachedDisplayData("employees");
  const [offices, officesLoading] = useCachedDisplayData("offices");
  const [dbTransactions, transactionsLoading] =
    useCachedDisplayData("transactions");
  const [events, eventsLoading] = useDisplayEvents();

  const externalDataLoading =
    employeesLoading ||
    officesLoading ||
    accountDataLoading ||
    transactionsLoading ||
    eventsLoading ||
    accountingDataLoading ||
    dbEmissionsLoading;

  const emissionStartDates = {
    admin: adminStartDate,
    sandbox: sandboxStartDate,
    company: companyStartDate,
  };

  const emissionsStartDate = emissionStartDates[viewMode];

  const buildEmissionsArraysFromExternalData = () => {
    if (externalDataLoading) {
      return {};
    }

    const { allEmissions, ...otherEmissionsArrays } =
      buildCompanyEmissionsArrays({
        companyData: {
          ...accountData,
          startDate: emissionsStartDate,
        },
        dbEmissions,
        employees,
        offices,
        transactions: dbTransactions,
        events,
      });

    const emissionsStatuses = assignEmissionStatuses(
      allEmissions,
      mostRecentAccountingDate
    );

    const splitEmissions = splitScopeEmissions(emissionsStatuses);

    const dbEmissionsAsTransactions = dbEmissions.length
      ? dbEmissions.map(({ name, startDate, scope, tonsCo2e }) => ({
          date: startDate,
          source: "manual-emission",
          vendor: name,
          name,
          currentStatus: "confirmed",
          tonsCo2e,
          subcategory:
            scope === 1 ? "gas" : scope === 2 ? "electricity" : "spend-based",
          scope,
        }))
      : [];

    return {
      ...otherEmissionsArrays,
      allEmissions: [...splitEmissions, ...dbEmissionsAsTransactions],
    };
  };

  const {
    emissionsMonths = [],
    monthlyCommuteEmissionsTons = [],
    monthlyUtilitiesEmissionsTons = [],
    totalRecurringEmissionsTons = [],
    averageMonthlyRecurringEmissionsTons = [],
    separateUtilitiesOfficesDefaultEmissions = [],
    transactions = [],
    allEmissions = [],
  } = buildEmissionsArraysFromExternalData();

  const taggedOfficeTransactions = [
    ...transactions,
    ...separateUtilitiesOfficesDefaultEmissions,
  ].filter((transaction) => {
    const { office } = transaction || {};
    const { id: officeId } = office || {};

    return officeId;
  });

  const taggedEmployeesTransactions = transactions.filter(
    ({ taggedEmployeesIds }) => taggedEmployeesIds
  );

  return (
    <EmissionsContext.Provider
      value={{
        emissionsMonths,
        monthlyCommuteEmissionsTons,
        monthlyUtilitiesEmissionsTons,
        totalRecurringEmissionsTons,
        averageMonthlyRecurringEmissionsTons,
        recurringEmissionsLoading: externalDataLoading,
        separateUtilitiesOfficesDefaultEmissions,
        transactions,
        allEmissions,
        taggedOfficeTransactions,
        taggedEmployeesTransactions,
      }}
    >
      {children}
    </EmissionsContext.Provider>
  );
};

const useEmissionsContext = () => useContext(EmissionsContext);
export default useEmissionsContext;
