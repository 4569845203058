import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router";

import {
  Box,
  Grid,
  Typography,
  Container,
  CircularProgress,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";

import {
  faCar,
  faHome,
  faMoneyCheck,
  faPlane,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";

import { DefaultPaper } from "@aclymatepackages/atoms";
import { useLayoutHelpers } from "@aclymatepackages/themes";
import {
  FootprintEquivalencies,
  ComparisonChart,
} from "@aclymatepackages/modules";
import { getYearlyTonsCo2eValues } from "@aclymatepackages/calcs/myAclymate";
import {
  US_AVERAGE_PER_CAPITA_HOME_TONS_CO2E_PER_YEAR,
  US_AVERAGE_VEHICLE_TRAVEL_TONS_CO2E_PER_YEAR,
  US_AVERAGE_FLIGHTS_TONS_CO2E_PER_YEAR,
  US_AVERAGE_DIET_TONS_CO2E_PER_YEAR,
  US_AVERAGE_EXPENDITURES_PER_CAPITA_TONS_CO2E_PER_YEAR,
} from "@aclymatepackages/constants";

import OffsetBucketsBox from "./OffsetBucketsBox";
import CarbonFootprintGraphic from "./CarbonFootprintGraphic";
import ImpactReportLayout from "./ImpactReportLayout";
import MyAclymateSurvey from "./MyAclymateSurvey";
import MyAclymateSurveyOffsetSubscriptionPurchase from "./MyAclymateSurveyOffsetSubscriptionPurchase";
import ReportContentCard from "./ReportCardContent";
import WorkspaceCTABlock from "./WorkspaceCTABlock";
import SlideLayoutCreateAccountCTA from "./SlideLayoutCreateAccountCTA";

import { isObjectEmpty } from "../../helpers/otherHelpers";
import { fetchOurApi } from "../../helpers/utils/apiCalls";
import { StripeCustomerContextProvider } from "../../helpers/contexts/stripeCustomer";

const generateMyAclymateAccountCreationLink = (
  individualId,
  doesAclymateAccountAlreadyExist
) => {
  const baseUrl = `https://${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`;

  if (doesAclymateAccountAlreadyExist) {
    return `${baseUrl}/login?accountType=my&accountId=${individualId}`;
  }

  return individualId
    ? `${baseUrl}/account-creation/my?individualId=${individualId}`
    : `${baseUrl}/account-creation/my`;
};

const ImpactReport = ({ individual }) => {
  const { isMedium, isMobile } = useLayoutHelpers();

  const [emissionsData, setEmissionsData] = useState({});

  const isEmissionsDataEmpty = isObjectEmpty(emissionsData);

  const yearlyTonsCo2eObj = getYearlyTonsCo2eValues(emissionsData);
  const {
    yearlyTotalTonsCo2e,
    yearlyHomeTonsCo2e,
    yearlyTravelTonsCo2e,
    yearlyFlightsTonsCo2e,
    yearlyDietTonsCo2e,
    yearlySpendingTonsCo2e,
  } = yearlyTonsCo2eObj;

  const {
    id: individualId,
    numPeople,
    doesAclymateAccountAlreadyExist,
  } = individual;

  const individualAccountCreationLink = generateMyAclymateAccountCreationLink(
    individualId,
    doesAclymateAccountAlreadyExist
  );

  useEffect(() => {
    if (individual && isEmissionsDataEmpty) {
      fetchOurApi({
        path: "/calcs/individuals/fetch-individual-carbon",
        method: "POST",
        data: { individual },
        callback: (res) => setEmissionsData(res),
      });
    }
  }, [individual, emissionsData, isEmissionsDataEmpty]);

  const categories = [
    {
      type: "home",
      color: "offices",
      icon: faHome,
      title: "Home Emissions",
    },
    {
      type: "travel",
      color: "vehicles",
      icon: faCar,
      title: "Travel Emissions",
    },
    {
      type: "flights",
      color: "flights",
      icon: faPlane,
      title: "Flights Emissions",
    },
    {
      type: "diet",
      color: "secondary",
      icon: faUtensils,
      title: "Diet Emissions",
    },
    {
      type: "expenses",
      color: "primary",
      icon: faMoneyCheck,
      title: "Expenses Emissions",
    },
  ];
  const maxCategories = categories.map((category) => ({
    ...category,
    categoryMaxTons: Math.max(
      ...[
        yearlyHomeTonsCo2e,
        yearlyTravelTonsCo2e,
        yearlyFlightsTonsCo2e,
        yearlyDietTonsCo2e,
        yearlySpendingTonsCo2e,
      ]
    ),
  }));

  return (
    <Container maxWidth="lg">
      {isEmissionsDataEmpty ? (
        <DefaultPaper style={{ width: "100%" }}>
          <Grid container item justifyContent="center">
            <CircularProgress />
          </Grid>
        </DefaultPaper>
      ) : (
        <Grid container direction="column" spacing={2}>
          <Grid item container spacing={2} alignItems="stretch">
            <Grid item md={5} xs={12}>
              <CarbonFootprintGraphic
                isDesktop={!isMobile}
                yearlyTonsCo2eObj={yearlyTonsCo2eObj}
                SlideLayoutLeftContent={() => (
                  <SlideLayoutCreateAccountCTA
                    createAccountLink={individualAccountCreationLink}
                    doesAclymateAccountAlreadyExist={
                      doesAclymateAccountAlreadyExist
                    }
                  />
                )}
                numPeople={numPeople}
              />
            </Grid>
            <Grid item md={7} xs={12}>
              <Grid container direction="column" spacing={2}>
                <Grid item container>
                  <ReportContentCard
                    title={`Your ${
                      numPeople > 1 ? "Household " : ""
                    }Emissions Equivalents`}
                  >
                    <FootprintEquivalencies
                      footprintTons={yearlyTotalTonsCo2e}
                      exclusionCategories={[
                        "tree-sequestration",
                        "carbon-budget",
                      ]}
                    />
                  </ReportContentCard>
                </Grid>
                <Grid item>
                  <ReportContentCard>
                    <Grid container spacing={2} direction="column">
                      <Grid item>
                        <ComparisonChart
                          categories={maxCategories}
                          individualData={{
                            homeTons: yearlyHomeTonsCo2e,
                            travelTons: yearlyTravelTonsCo2e,
                            flightsTons: yearlyFlightsTonsCo2e,
                            dietTons: yearlyDietTonsCo2e,
                            expensesTons: yearlySpendingTonsCo2e,
                          }}
                          comparisonData={{
                            homeTons:
                              US_AVERAGE_PER_CAPITA_HOME_TONS_CO2E_PER_YEAR,
                            travelTons:
                              US_AVERAGE_VEHICLE_TRAVEL_TONS_CO2E_PER_YEAR,
                            flightsTons: US_AVERAGE_FLIGHTS_TONS_CO2E_PER_YEAR,
                            dietTons: US_AVERAGE_DIET_TONS_CO2E_PER_YEAR,
                            expensesTons:
                              US_AVERAGE_EXPENDITURES_PER_CAPITA_TONS_CO2E_PER_YEAR,
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          color="textSecondary"
                          align="right"
                        >
                          Units are in lbs of CO<sub>2</sub>
                        </Typography>
                      </Grid>
                    </Grid>
                  </ReportContentCard>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container>
            <OffsetBucketsBox
              isDesktop={!isMobile}
              emissionsData={emissionsData}
              SubscriptionPurchaseFlow={(otherProps) => (
                <StripeCustomerContextProvider>
                  <MyAclymateSurveyOffsetSubscriptionPurchase
                    individualId={individualId}
                    {...otherProps}
                  />
                </StripeCustomerContextProvider>
              )}
              numPeople={numPeople}
            />
          </Grid>
          <Grid item>
            <WorkspaceCTABlock
              isDesktop={!isMedium}
              {...yearlyTonsCo2eObj}
              individualAccountCreationLink={individualAccountCreationLink}
              doesAclymateAccountAlreadyExist={doesAclymateAccountAlreadyExist}
            />
          </Grid>
          {/* We're commenting this out until we figure out what blog we want to pull from */}
          {/* <Grid item>
              <BlogBox />
            </Grid> */}
        </Grid>
      )}
    </Container>
  );
};

const ImpactReportLanding = ({
  individualDataLoading,
  setIndividualData,
  setTotalTonsCo2e,
  formStep,
  setFormStep,
}) => {
  const { isMobile } = useLayoutHelpers();

  const boxHeight = isMobile ? { height: "90%" } : {};

  return (
    <Box
      style={{
        ...boxHeight,
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
      }}
      boxSizing="inherit"
      display="flex"
      alignItems="center"
      position="relative"
    >
      {individualDataLoading ? (
        <Grid item container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <MyAclymateSurvey
          setIndividualData={setIndividualData}
          setTotalTonsCo2e={setTotalTonsCo2e}
          formStep={formStep}
          setFormStep={setFormStep}
        />
      )}
    </Box>
  );
};

const MyAclymatePage = () => {
  const { theme } = useLayoutHelpers();

  const { refresh } = useParams();
  const [cookies] = useCookies(["aclymate-in-slug"]);
  const slugCookie = cookies["aclymate-in-slug"];

  const [individualData, setIndividualData] = useState({});
  const [individualDataLoading, setIndividualDataLoading] = useState(true);
  const [totalTonsCo2e, setTotalTonsCo2e] = useState(0);
  const [formStep, setFormStep] = useState(0);

  const { isCompletedMyAclymateOnboarding } = individualData;

  useEffect(() => {
    const fetchIndividualData = async () => {
      if (slugCookie && !refresh) {
        const { email, ...otherIndividualData } = await fetchOurApi({
          path: `/individuals/${slugCookie}`,
          method: "GET",
          callback: (res) => res,
        });

        const { success: doesAclymateAccountAlreadyExist } = await fetchOurApi({
          path: "/onboarding/check-email",
          method: "POST",
          data: { email },
          callback: (res) => res,
        });

        setIndividualData({
          email,
          doesAclymateAccountAlreadyExist,
          ...otherIndividualData,
        });
        return setIndividualDataLoading(false);
      }

      return setIndividualDataLoading(false);
    };

    if (individualDataLoading) {
      fetchIndividualData();
    }
  }, [slugCookie, individualDataLoading, refresh]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ImpactReportLayout
          individual={individualData}
          totalTonsCo2e={totalTonsCo2e}
          formStep={formStep}
        >
          {isCompletedMyAclymateOnboarding ? (
            <ImpactReport individual={individualData} />
          ) : (
            <ImpactReportLanding
              individualDataLoading={individualDataLoading}
              setIndividualData={setIndividualData}
              setTotalTonsCo2e={setTotalTonsCo2e}
              formStep={formStep}
              setFormStep={setFormStep}
            />
          )}
        </ImpactReportLayout>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default MyAclymatePage;
