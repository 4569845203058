import React, { useState } from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import {
  Grid,
  Button,
  List,
  CircularProgress,
  Avatar,
  Tooltip,
} from "@mui/material";

import PowerIcon from "@mui/icons-material/Power";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { utilitiesProviders } from "@aclymatepackages/lists";
import { arrayToString } from "@aclymatepackages/converters";

import ErrorBoundary from "../../atoms/ErrorBoundary";
import Card from "../../atoms/mui/Card";
import ListItem from "../../atoms/mui/ListItem";
import Link from "../../atoms/mui/Link";
import CollapsibleAlert from "../../atoms/notifications/CollapsibleAlert";
import LinkUtilitiesForm from "../../inputs/offices/LinkUtilitiesForm";
import QuickbooksCard from "../../modules/settings/QuickbooksCard";

import { useOfficesWithCurrentChanges } from "../../../helpers/components/offices";
import {
  firebaseFieldDeleteObj,
  useCachedFirebaseCrud,
  useCollectionDataListener,
} from "../../../helpers/firebase";
import {
  useStarterTierSubscriptionFlags,
  useSubscriptionType,
} from "../../../helpers/hooks/companyData";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";

const UtilityItem = ({
  offices,
  name,
  utilityId,
  authorizationId,
  id: utilityIntegrationDocId,
}) => {
  const accountId = window.sessionStorage.getItem("accountId");
  const { updateAccountData, updateCollectionDoc, deleteCachedCollectionDoc } =
    useCachedFirebaseCrud();

  const [isDeletingUtilityIntegration, setIsDeletingUtilityIntegration] =
    useState(false);
  const [
    isDeletingUtilityIntegrationInProgress,
    setIsDeletingUtilityIntegrationInProgress,
  ] = useState(false);

  const { logo: avatarImg } = utilitiesProviders.find(
    ({ utilityId: providerId }) => providerId === utilityId
  );
  const pairedOffices = offices.filter(
    ({
      linkedElectricUtilityId,
      linkedGasUtilityId,
      linkedCombinedUtilityId,
    }) =>
      linkedElectricUtilityId === utilityId ||
      linkedGasUtilityId === utilityId ||
      linkedCombinedUtilityId === utilityId
  );
  const pairedOfficesNames = pairedOffices.map(({ name }) => name);

  const generateSecondaryText = () => {
    if (isDeletingUtilityIntegration) {
      return "All linked meters will be removed and authorization for this utility will be revoked.";
    }

    if (pairedOfficesNames.length) {
      return `Paired meters with ${arrayToString(pairedOfficesNames)}`;
    }

    return "No offices have been paired with yet";
  };

  const deleteUtilityIntegrationConnection = async () => {
    setIsDeletingUtilityIntegrationInProgress(true);

    await Promise.all(
      pairedOffices.map(async (office) => {
        const { id: officeId } = office;
        const linkedUtilityKey = [
          "linkedElectricUtility",
          "linkedGasUtility",
          "linkedCombinedUtility",
        ].find((key) => !!office[key]);

        return await updateCollectionDoc("offices", officeId, {
          [linkedUtilityKey]: firebaseFieldDeleteObj(),
          [`${linkedUtilityKey}Id`]: firebaseFieldDeleteObj(),
        });
      })
    );

    await fetchOurApi({
      accountId,
      path: "/integrations/utilityApi/revoke-authorization",
      method: "POST",
      data: { authorizationId },
    });

    await updateAccountData({
      utilityApiAuthorizationIds: firebaseFieldDeleteObj(),
    });

    return await deleteCachedCollectionDoc(
      "integrations",
      utilityIntegrationDocId
    );
  };

  const generateActions = () => {
    if (isDeletingUtilityIntegrationInProgress) {
      return [
        {
          tooltip: "Deletion in progress...",
          icon: <CircularProgress />,
        },
      ];
    }

    if (isDeletingUtilityIntegration) {
      return [
        {
          tooltip: "Confirm deletion",
          onClick: () => deleteUtilityIntegrationConnection(),
          icon: <CheckCircleIcon />,
        },
        {
          tooltip: "Cancel deletion",
          onClick: () => setIsDeletingUtilityIntegration(false),
          icon: <CancelIcon />,
        },
      ];
    }

    return [
      {
        tooltip: "Delete connection",
        onClick: () => setIsDeletingUtilityIntegration(true),
        icon: <LinkOffIcon />,
      },
    ];
  };

  return (
    <ListItem
      primaryText={
        isDeletingUtilityIntegration
          ? "Are you sure you would like to delete your utility integration?"
          : name
      }
      secondaryText={generateSecondaryText()}
      avatarImg={avatarImg}
      actions={generateActions()}
    />
  );
};

const UtilitiesApiCard = () => {
  const [utilityIntegrations, utilityIntegrationsLoading] =
    useCollectionDataListener("integrations", [["type", "==", "utilitiesApi"]]);
  const [offices, officesLoading] = useOfficesWithCurrentChanges();
  const [{ isPaid }] = useSubscriptionType();
  const { isStarterTier } = useStarterTierSubscriptionFlags();
  const utilityApiFeatures = useFeatureIsOn("utilityapi-features");

  const [utilitiesFormOpen, setUtilitiesFormOpen] = useState(false);
  const [selectedUtility, setSelectedUtility] = useState({});

  return utilityApiFeatures ? (
    <>
      {utilitiesFormOpen && (
        <LinkUtilitiesForm
          setOpen={setUtilitiesFormOpen}
          utilitiesProvidersList={[selectedUtility]}
        />
      )}
      <Card
        isLoading={utilityIntegrationsLoading || officesLoading}
        gridProps={{ sm: 6 }}
        cardType="layout"
        title="Utilities"
        subtitle="We can connect your utilities for faster setup."
        icon={<PowerIcon />}
        style={{ paddingBottom: "1rem" }}
        contentType="layout"
      >
        <Grid container spacing={2} direction="column">
          <Grid item>
            <List>
              {utilityIntegrations.map((utility) => (
                <UtilityItem offices={offices} {...utility} />
              ))}
            </List>
          </Grid>
          <Grid item>
            <Grid container spacing={2} justifyContent="center">
              {utilitiesProviders
                .filter(({ utilityId }) => utilityId !== "DEMO")
                .map(({ logo, name, utilityId, ...otherProps }) => (
                  <Grid item key={utilityId}>
                    <Tooltip title={name}>
                      <span>
                        <Button
                          onClick={() => {
                            setUtilitiesFormOpen(true);
                            return setSelectedUtility({
                              name,
                              logo,
                              utilityId,
                              ...otherProps,
                            });
                          }}
                          disabled={!isPaid || isStarterTier}
                        >
                          <Avatar
                            src={`/images/utilities-logos/${logo}`}
                            imgProps={{
                              style: { objectFit: "contain" },
                            }}
                            style={{ width: 48, height: 48 }}
                          />
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  ) : (
    <></>
  );
};

const Integrations = () => {
  const [{ isPaid }] = useSubscriptionType("saas");
  const { isStarterTier } = useStarterTierSubscriptionFlags();

  return (
    <ErrorBoundary>
      <Grid container spacing={2} alignItems="stretch">
        {(!isPaid || isStarterTier) && (
          <Grid item xs={12}>
            <CollapsibleAlert
              title="These are accounting integrations features"
              subtitle="If you would like to have access to integrations, upgrade your plan"
              action={
                <Link href="/platform/company/settings/products">
                  <Button color="primary" variant="contained">
                    Upgrade my plan
                  </Button>
                </Link>
              }
            />
          </Grid>
        )}
        <QuickbooksCard />
        <UtilitiesApiCard />
      </Grid>
    </ErrorBoundary>
  );
};
export default Integrations;
