import React, { useState, createContext } from "react";

export const AccountAccessContext = createContext();

export const AccountAccessContextProvider = ({ children }) => {
  const [accountAccessError, setAccountAccessError] = useState("");

  return (
    <AccountAccessContext.Provider
      value={{ accountAccessError, setAccountAccessError }}
    >
      {children}
    </AccountAccessContext.Provider>
  );
};
