import React, { useState, useEffect } from "react";

import {
  Grid,
  Typography,
  Chip,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { DefaultSelectableChip, TextField } from "@aclymatepackages/atoms";
import { truncateText } from "@aclymatepackages/other-helpers";

import { fetchOurApi } from "../../../helpers/utils/apiCalls";

const OpenAiVendorCategorization = ({
  naicsCodes = [],
  editVendor,
  naicsCode,
  naicsTitle,
  disabled,
  overrideNaicsTitle,
  questionText = "What does this company do?",
}) => {
  const [naicsCodesOptionsLoading, setNaicsCodesOptionsLoading] =
    useState(false);
  const [naicsCodesOptions, setNaicsCodesOptions] = useState(naicsCodes);
  const [companyDescription, setCompanyDescription] = useState("");
  const [originalNaicsTitle, setOriginalNaicsTitle] = useState(naicsTitle);

  useEffect(() => {
    if (naicsCodes?.length) {
      setNaicsCodesOptions(naicsCodes);
    }
  }, [naicsCodes]);

  const onDescriptionNaicsFetch = async () => {
    setNaicsCodesOptionsLoading(true);
    await fetchOurApi({
      path: "/open-ai/fetch-naics-codes-from-description",
      data: { description: companyDescription },
      method: "POST",
      callback: ({ naicsCodes }) => setNaicsCodesOptions(naicsCodes),
    });
    return setNaicsCodesOptionsLoading(false);
  };

  const onEnterPress = ({ key }) => {
    if (key === "Enter" && companyDescription) {
      return onDescriptionNaicsFetch();
    }
  };

  return (
    <>
      {!naicsCodes.length && !originalNaicsTitle && !overrideNaicsTitle && (
        <Grid item>
          <TextField
            label={questionText}
            onKeyUp={onEnterPress}
            value={companyDescription}
            setValue={setCompanyDescription}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {naicsCodesOptionsLoading ? (
                    <CircularProgress />
                  ) : (
                    <IconButton
                      onClick={onDescriptionNaicsFetch}
                      size="large"
                      disabled={!companyDescription}
                    >
                      <SearchIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      )}
      {(originalNaicsTitle || overrideNaicsTitle) && (
        <Grid item alignSelf="center">
          <Chip
            label={truncateText(overrideNaicsTitle || originalNaicsTitle, 50)}
            onDelete={!disabled && (() => setOriginalNaicsTitle(null))}
          />
        </Grid>
      )}
      {!!naicsCodesOptions?.length && (
        <Grid item container spacing={2} direction="container">
          <Grid item>
            <Typography variant="h6" align="center">
              Select the industry for this vendor
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" spacing={2}>
            {naicsCodesOptions.map(
              ({ code, description, tonsCo2ePerDollar }, idx) => (
                <Grid item key={`naics-chip-${idx}`}>
                  <DefaultSelectableChip
                    label={truncateText(description, 50)}
                    onClick={() => {
                      editVendor("naicsTitle")(description);
                      editVendor("naicsCode")(code);
                      return editVendor("tonsCo2ePerDollar")(tonsCo2ePerDollar);
                    }}
                    isSelected={naicsCode === code}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default OpenAiVendorCategorization;
