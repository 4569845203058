import React, { useState } from "react";

import {
  Box,
  Typography,
  Grid,
  Divider,
  Alert,
  Button,
  useTheme,
} from "@mui/material";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { LoadingButton, Checkbox, TextField } from "@aclymatepackages/atoms";
import { emailRegExpTest } from "@aclymatepackages/reg-exp";
import { incorrectNameCheck } from "@aclymatepackages/other-helpers";

import PasswordInput from "./PasswordInput";
import OAuthButtons from "./OAuthButtons";

import { excludedPersonalEmailProviders } from "../../helpers/components/accountAccess";

const isEmailPersonal = (email) =>
  excludedPersonalEmailProviders.some((excludedDomain) =>
    email.includes(excludedDomain)
  );

const AccountCreationInput = ({
  isNewCompanyAdmin,
  setErrorMsg,
  userLoading,
  initialUserData,
  isCompanyAccountCreation,
  completePasswordEmailAccountCreation,
  completeOauthAccountCreation,
}) => {
  const theme = useTheme();

  const [inputData, setInputData] = useState(initialUserData || {});
  const [honeyPot, setHoneyPot] = useState(false);

  const { email: existingIndividualEmail = "", name: existingIndividualName } =
    initialUserData || {};
  const {
    email = existingIndividualEmail,
    displayName = existingIndividualName,
    password = "",
  } = inputData || {};

  const editInputData = (field) => (value) =>
    editObjectData(setInputData, field, value);

  const isPersonalEmailError =
    isCompanyAccountCreation && isEmailPersonal(email);

  const onCompletePasswordEmailSignup = async () => {
    if (honeyPot) {
      return console.log("no bots allowed");
    }
    return await completePasswordEmailAccountCreation(inputData);
  };

  return (
    <>
      <OAuthButtons
        callback={completeOauthAccountCreation}
        setErrorMsg={setErrorMsg}
        isNewAccount
      />
      <Grid item>
        <Box position="relative">
          <div
            style={{
              position: "absolute",
              left: "calc(50% - 13px)",
              top: "-15px",
              padding: `calc(${theme.spacing(1)} / 2)`,
              backgroundColor: "white",
            }}
          >
            <Typography variant="subtitle2" color="textSecondary">
              OR
            </Typography>
          </div>
          <Divider />
        </Box>
      </Grid>
      <Grid item container direction="column" spacing={2}>
        {!existingIndividualName && (
          <Grid item>
            <TextField
              id="user-name-input"
              label="Your Name"
              value={displayName}
              setValue={editInputData("displayName")}
              error={displayName && incorrectNameCheck(displayName)}
              helperText={
                displayName &&
                incorrectNameCheck(displayName) &&
                "You need to have a name with less than 50 characters and it must contain at least one non-numeric character."
              }
              disabled={userLoading}
            />
          </Grid>
        )}
        {!existingIndividualEmail && (
          <Grid item>
            <TextField
              label="Email Address"
              value={email}
              setValue={editInputData("email")}
              id="email-update"
              disabled={userLoading}
            />
          </Grid>
        )}
        {isPersonalEmailError && (
          <Grid item>
            <Alert
              severity="error"
              action={
                <Button
                  color="inherit"
                  target="_blank"
                  href="https://calendly.com/climatemike/website-inquiry-aclymate"
                  size="small"
                >
                  Contact Us
                </Button>
              }
            >
              Please use a work email to sign up for Aclymate
            </Alert>
          </Grid>
        )}
        <Grid item>
          <PasswordInput
            password={password}
            setPassword={editInputData("password")}
            helperText
            disabled={userLoading}
          />
        </Grid>
      </Grid>
      <div
        ref={(el) => el && el.style.setProperty("display", "none", "important")} //This is a weird hack because we always want this component to be hidden since it's a honey pot
      >
        <Checkbox
          name="is-remote"
          tabIndex={-1}
          value={honeyPot}
          editValue={setHoneyPot}
          label="Is this business fully remote"
          autocomplete={false}
        />
      </div>
      <Grid container item spacing={2} justifyContent="center">
        <Grid item>
          <LoadingButton
            id="new-user-submit"
            color="secondary"
            label={isNewCompanyAdmin ? "Join Account" : "Create Account"}
            disabled={
              isPersonalEmailError ||
              !emailRegExpTest(email) ||
              !displayName ||
              password.length < 12 ||
              (displayName && incorrectNameCheck(displayName))
            }
            onClick={onCompletePasswordEmailSignup}
            isLoading={userLoading}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default AccountCreationInput;
