import React, { useState, useEffect, useRef } from "react";

import { Grid } from "@mui/material";

import findSubcategoryObj from "@aclymatepackages/subcategories";

import EmployeesInput from "./EmployeesInput";
import OfficeInput from "./OfficeInput";
import VendorsInput from "./VendorsInput";
import EventsInput from "./EventsInput";

import { fetchOurApi } from "../../../helpers/utils/apiCalls";

const TransactionsTagsInputs = ({ transaction, editTransaction }) => {
  const {
    id: parentId,
    subcategory: transactionSubcategory,
    employees,
    office,
    event,
    knownVendor,
  } = transaction;

  const { tags = [] } = findSubcategoryObj(transactionSubcategory);

  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [activePopper, setActivePopper] = useState("");
  const [totalWidth, setTotalWidth] = useState(0);
  const [inputBlocks, setInputBlocks] = useState(tags);
  const [transactionId, setTransactionId] = useState(parentId);

  const inputRef = useRef();
  const inputRefCurrent = inputRef.current;

  useEffect(() => {
    if (inputRefCurrent) {
      setTotalWidth(inputRef.current.offsetWidth - 24);
    }
  }, [inputRefCurrent]);

  useEffect(() => {
    if (
      JSON.stringify(tags) !== JSON.stringify(inputBlocks) ||
      parentId !== transactionId
    ) {
      setPopperAnchorEl(null);
      setActivePopper("");
      setInputBlocks(tags);
      setTransactionId(parentId);
    }
  }, [inputBlocks, tags, parentId, transactionId]);

  const setWidth = (type) => {
    if (activePopper && activePopper !== type) {
      return 3;
    }
    if (!activePopper) {
      return 12 / inputBlocks.length;
    }
    return 12 - (inputBlocks.length - 1) * 3;
  };

  const propsObj = {
    activePopper,
    setActivePopper,
    popperAnchorEl,
    setPopperAnchorEl,
    totalWidth,
    transactionSubcategory,
  };

  const inputPositions = (idx) => {
    if (idx === 0) {
      return "left";
    }
    if (idx === inputBlocks.length - 1) {
      return "right";
    }
    return "center";
  };

  const removeEmployee = ({ id }) => {
    const filteredEmployees = employees.filter(
      (employee) => employee.id !== id
    );
    return editTransaction("employees")(filteredEmployees);
  };

  const onSelectVendor = async (vendor) => {
    const { revenueYears, id } = vendor || {};

    if (!revenueYears) {
      return editTransaction("knownVendor")(vendor);
    }

    const mostRecentRevenueYear = revenueYears.reduce(
      (acc, revenueYear) => (revenueYear.year > acc.year ? revenueYear : acc),
      { year: 0 }
    );
    const { year, revenue } = mostRecentRevenueYear;

    const { yearlyTons } = await fetchOurApi({
      path: "/vendors/fetch-vendor-revenue-year-emissions",
      method: "POST",
      data: { vendorId: id, revenueYear: year },
      callback: (res) => res,
    });

    const tonsCo2ePerDollar = yearlyTons / revenue;
    return editTransaction("knownVendor")({ ...vendor, tonsCo2ePerDollar });
  };

  const inputOptions = (position) => ({
    employees: (
      <EmployeesInput
        employees={employees}
        setEmployees={editTransaction("employees")}
        removeEmployee={removeEmployee}
        position={position}
        inputLength={inputBlocks.length}
        {...propsObj}
      />
    ),
    vendors: (
      <VendorsInput
        vendor={knownVendor}
        setVendor={onSelectVendor}
        position={position}
        inputLength={inputBlocks.length}
        {...propsObj}
      />
    ),
    offices: (
      <OfficeInput
        office={office}
        setOffice={editTransaction("office")}
        position={position}
        inputLength={inputBlocks.length}
        {...propsObj}
      />
    ),
    events: (
      <EventsInput
        event={event}
        setEvent={editTransaction("event")}
        position={position}
        inputLength={inputBlocks.length}
        {...propsObj}
      />
    ),
  });

  if (!tags?.length) {
    return <></>;
  }

  return (
    <>
      <Grid item container spacing={1} ref={inputRef} justifyContent="center">
        {tags.map((input, idx) => (
          <Grid item sm={setWidth(input)} key={`tag-input-${idx}`}>
            {inputOptions(inputPositions(idx))[input]}
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default TransactionsTagsInputs;
