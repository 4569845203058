import React, { useState } from "react";

import { Grid, Button, Typography, Grow } from "@mui/material";

import { numbersRegExpTest } from "@aclymatepackages/reg-exp";
import { TextField } from "@aclymatepackages/atoms";

import { fetchTotalMileageCarbon } from "../../../helpers/utils/apiCalls";
import { setTransactionInitialValue } from "../../../helpers/components/inputs";

const RentalCarInputBlock = ({
  onRejectTransaction,
  showMileageInput,
  setShowMileageInput,
  fuelFullWarning,
  setFuelFullWarning,
  milesDriven,
  onMileageDrivenChange,
}) => {
  const onFuelPurchasedClick = () => {
    if (onRejectTransaction) {
      return onRejectTransaction();
    }
    return setFuelFullWarning(
      "You should enter a fuel transaction instead of a rental car"
    );
  };

  return (
    <Grid
      item
      container
      spacing={2}
      justifyContent="center"
      direction="column"
      alignItems="center"
      style={{ height: "100%" }}
      wrap="nowrap"
    >
      {!showMileageInput ? (
        <Grid item container justifyContent="center">
          <Grid item>
            <Typography variant="h6">
              Did you purchase fuel for this vehicle?
            </Typography>
          </Grid>
          <Grid item container spacing={2} justifyContent="center">
            <Grid item>
              <Button
                onClick={() => {
                  setShowMileageInput(true);
                  setFuelFullWarning(false);
                }}
              >
                No
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={onFuelPurchasedClick}
              >
                Yes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grow in={showMileageInput}>
          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h6">Enter Estimated Mileage</Typography>
            </Grid>
            <Grid item>
              <TextField
                label="Estimated miles driven"
                fullWidth
                variant="outlined"
                value={milesDriven}
                setValue={onMileageDrivenChange}
                large
                helperText={
                  milesDriven &&
                  !numbersRegExpTest(milesDriven) &&
                  "This must be a number"
                }
                id="rental-car-miles-driven-input"
              />
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={onFuelPurchasedClick}
              >
                Fuel was purchased for this vehicle
              </Button>
            </Grid>
          </Grid>
        </Grow>
      )}
      {!!fuelFullWarning && (
        <Grid item>
          <Typography
            variant="subtitle2"
            align="center"
            id="rental-car-fuel-full-warning"
          >
            {fuelFullWarning}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

const useRentalCarInput = ({
  transaction = {},
  onSave,
  onRejectTransaction,
  setCalcLoading,
}) => {
  const [rentalCarTonsCo2e, setRentalCarTonsCo2e] = useState(
    setTransactionInitialValue(transaction, "tonsCo2e")
  );
  const [milesDriven, setMilesDriven] = useState(
    setTransactionInitialValue(transaction, "milesDriven")
  );
  const [fuelFullWarning, setFuelFullWarning] = useState("");
  const [showMileageInput, setShowMileageInput] = useState(!!milesDriven);

  const onMileageDrivenChange = async (value) => {
    setMilesDriven(value);
    if (value) {
      setCalcLoading(true);
      await fetchTotalMileageCarbon(value, setRentalCarTonsCo2e);
      return setCalcLoading(false);
    }
    return setRentalCarTonsCo2e(0);
  };

  const inputBlock = (
    <RentalCarInputBlock
      onRejectTransaction={onRejectTransaction}
      showMileageInput={showMileageInput}
      setShowMileageInput={setShowMileageInput}
      fuelFullWarning={fuelFullWarning}
      setFuelFullWarning={setFuelFullWarning}
      milesDriven={milesDriven}
      onMileageDrivenChange={onMileageDrivenChange}
    />
  );

  const onTransactionSave = () =>
    onSave({
      tonsCo2e: rentalCarTonsCo2e,
      milesDriven,
    });

  const saveEnabled =
    (!!milesDriven && numbersRegExpTest(milesDriven)) || !!rentalCarTonsCo2e;

  return {
    inputBlock,
    onTransactionSave,
    saveEnabled,
    tonsCo2e: rentalCarTonsCo2e,
  };
};
export default useRentalCarInput;
