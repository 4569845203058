import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  ButtonBase,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { faArrowSquareDown } from "@fortawesome/pro-solid-svg-icons";

import { useLayoutHelpers } from "@aclymatepackages/themes";

const ImageUploadDropzone = ({
  imageName = "image",
  onImageUpload,
  setImageFile,
  previewImageStyle = {},
}) => {
  const { theme } = useLayoutHelpers();

  const [dataSaving, setDataSaving] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const handleImage = useCallback(
    async (image) => {
      if (onImageUpload) {
        setDataSaving(true);
        await onImageUpload(image);
        return setDataSaving(false);
      }

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => setPreviewImage(reader.result);
      return setImageFile(image);
    },
    [onImageUpload, setImageFile]
  );

  const onDrop = useCallback(
    async ([acceptedFile]) => {
      handleImage(acceptedFile);
    },
    [handleImage]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const inputProps = dataSaving ? {} : getInputProps();

  const onImageInput = ({ target }) => {
    const [image] = target?.files || [];
    if (!image) {
      return;
    }

    return handleImage(image);
  };

  return (
    <Box
      id="image-upload-container"
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: theme.palette.backgroundGray.light,
        position: "static",
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {previewImage ? (
        <Box
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${previewImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            position: "static",
            ...previewImageStyle,
          }}
        />
      ) : (
        <>
          <input
            id="file-uploader"
            type="file"
            onChange={onImageInput}
            style={{ display: "none" }}
            {...inputProps}
          />
          <div {...getRootProps()}>
            <ButtonBase
              {...getRootProps()}
              htmlFor="file-uploader"
              sx={{
                pt: 2,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                <Grid
                  container
                  direction="column"
                  rowGap={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  {dataSaving ? (
                    <Grid item>
                      <CircularProgress />
                    </Grid>
                  ) : (
                    <>
                      <Grid item>
                        <Typography
                          variant="caption"
                          align="center"
                          color="textSecondary"
                        >
                          {isDragActive
                            ? `Drop the ${imageName} here`
                            : `Upload ${imageName}`}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <FontAwesomeIcon
                          icon={isDragActive ? faArrowSquareDown : faImage}
                          size="2x"
                          style={{
                            color: theme.palette.backgroundGray.dark,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
            </ButtonBase>
          </div>
        </>
      )}
    </Box>
  );
};
export default ImageUploadDropzone;
