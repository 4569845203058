import React, { useContext } from "react";

import { Grid, Typography } from "@mui/material";

import { AnimatedLogo } from "@aclymatepackages/atoms";

import SubscriptionSignUpForm from "./SubscriptionSignUpForm";
import ProductDetailsLeftContent from "./ProductDetailsLeftContent";

import SlideLayout from "../../layouts/SlideLayout";
import PaymentInput from "../../modules/PaymentInput";

import { useCustomerCoupon } from "../../../helpers/hooks/stripe";
import { StripeCustomerContext } from "../../../helpers/contexts/stripeCustomer";

const ProductSignUpForm = ({
  subtitle,
  prices,
  product,
  status,
  image,
  title,
  bullets,
  icon,
  onClose,
  redirectUrl,
}) => {
  const [subscriptionTypeCoupon, couponLoading] = useCustomerCoupon(product);
  const { paymentMethod, isPaymentMethodLoading } = useContext(
    StripeCustomerContext
  );

  const displayContent = () => {
    if (isPaymentMethodLoading || couponLoading) {
      return (
        <Grid container justifyContent="center">
          <Grid item>
            <AnimatedLogo />
          </Grid>
        </Grid>
      );
    }

    const { card, us_bank_account } = paymentMethod || {};
    const { percentOff } = subscriptionTypeCoupon || {};

    if (!(card || us_bank_account) && percentOff !== 100) {
      return (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h6">
              Please input your payment method
            </Typography>
          </Grid>
          <Grid item>
            <PaymentInput />
          </Grid>
        </Grid>
      );
    }

    return (
      <SubscriptionSignUpForm
        title={title}
        status={status}
        subscriptionType={product}
        subtitle={subtitle}
        prices={prices}
        image={image}
        product={product}
        redirectUrl={redirectUrl}
      />
    );
  };

  return (
    <SlideLayout
      isSlideOpen
      setIsSlideOpen={onClose}
      content={displayContent()}
      leftContent={
        <ProductDetailsLeftContent
          bullets={bullets}
          title={title}
          icon={icon}
        />
      }
    />
  );
};
export default ProductSignUpForm;
