import React from "react";
import dayjs from "dayjs";

import {
  Avatar,
  IconButton,
  Tooltip,
  StyledEngineProvider,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnalytics } from "@fortawesome/pro-solid-svg-icons";

import { mergeDarkTheme } from "@aclymatepackages/themes";

import Link from "../mui/Link";

import { useSubscriptionType } from "../../../helpers/hooks/companyData";

const AnalyticsFeatureIcon = () => {
  const { palette } = useTheme();

  const [{ trialDate }] = useSubscriptionType("saas");

  const findAvatarColor = () => {
    const trialDaysLeft = dayjs(trialDate).diff(dayjs(), "days") + 1;

    if (trialDaysLeft >= 12) {
      return palette.primary.main;
    }

    if (trialDaysLeft >= 6) {
      return palette.vehicles.main;
    }

    return palette.error.main;
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mergeDarkTheme}>
        <Tooltip title="This features requires a paid Aclymate subscription. Click to learn more.">
          <span>
            <Link href="/platform/company/settings/products">
              <IconButton size="small">
                <Avatar
                  style={{
                    height: "32px",
                    width: "32px",
                    backgroundColor: findAvatarColor(),
                  }}
                >
                  <FontAwesomeIcon icon={faAnalytics} color="white" size="sm" />
                </Avatar>
              </IconButton>
            </Link>
          </span>
        </Tooltip>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default AnalyticsFeatureIcon;
