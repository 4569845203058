import React, { useContext } from "react";

import { EmissionsChart as EmissionsChartModule } from "@aclymatepackages/modules";

import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";

const EmissionsChart = (props) => {
  const { convertCarbonUnits, displayUnitLabel } = useContext(
    PlatformLayoutContext
  );

  return (
    <EmissionsChartModule
      {...props}
      convertCarbonUnits={convertCarbonUnits}
      displayUnitLabel={displayUnitLabel}
    />
  );
};
export default EmissionsChart;
