import React, { useState } from "react";
import { useParams } from "react-router";

import { Grid, Button, Typography } from "@mui/material";

import Link from "../atoms/mui/Link";

import AccountAccessLayout from "../layouts/AccountAccessLayout";
import PasswordInput from "./PasswordInput";
import { fetchOurApi } from "../../helpers/utils/apiCalls";

const PasswordReset = () => {
  const { uid } = useParams();
  const [password, setPassword] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);

  const onPasswordReset = () =>
    fetchOurApi({
      path: "/onboarding/update-password",
      method: "POST",
      data: { uid, password },
      callback: () => setPasswordChanged(true),
    });

  return (
    <AccountAccessLayout
      formTitle="Reset your password"
      formSubtitle="We recommend a memorable passphrase. Try combining multiple words that are meaningful to you like marketingpicklestaplerhorse."
      layoutType="login"
    >
      {passwordChanged ? (
        <Grid item>
          <Typography variant="subtitle1">
            Your password has been reset. You can now use use your new password
            to{" "}
            <Link href="/" color="inherit" underline="always">
              login in to Aclymate.
            </Link>
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid item>
            <PasswordInput
              password={password}
              setPassword={setPassword}
              helperText
            />
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={password.length < 12}
                onClick={() => onPasswordReset()}
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </AccountAccessLayout>
  );
};
export default PasswordReset;
