//eslint-disable-next-line
/*global google*/

import React, { useState } from "react";

import {
  Box,
  Grid,
  Typography,
  IconButton,
  Popover,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";

import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";

import { DefaultPaper } from "@aclymatepackages/atoms";
import mainTheme, { useLayoutHelpers } from "@aclymatepackages/themes";

const PageSectionPaper = ({
  title,
  subtitle,
  tooltip,
  annotation,
  children,
  theme = mainTheme,
  backgroundColor,
}) => {
  const { isMobile } = useLayoutHelpers();

  const [popperAnchorEl, setPopperAnchorEl] = useState(null);

  return (
    <>
      {!!popperAnchorEl && (
        <Popover
          open={!!popperAnchorEl}
          anchorEl={popperAnchorEl}
          onClose={() => setPopperAnchorEl(null)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box p={2} style={{ maxWidth: isMobile ? "300px" : "450px" }}>
            {tooltip}
          </Box>
        </Popover>
      )}
      <Grid item>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <DefaultPaper
              style={{
                background: backgroundColor || "rgba(255, 255, 255, 0.9)",
              }}
            >
              <Grid container direction="column" spacing={2}>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  spacing={2}
                  wrap="nowrap"
                >
                  <Grid item>
                    <Typography variant={isMobile ? "h4" : "h3"}>
                      {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {subtitle}
                    </Typography>
                  </Grid>
                  {tooltip && (
                    <Grid item>
                      {popperAnchorEl ? (
                        <IconButton
                          onClick={() => setPopperAnchorEl(null)}
                          size="large"
                        >
                          <CancelIcon fontSize="large" />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={({ currentTarget }) =>
                            setPopperAnchorEl(currentTarget)
                          }
                          size="large"
                        >
                          <HelpIcon fontSize="large" />
                        </IconButton>
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid item>{children}</Grid>
                {annotation && (
                  <Grid item container justifyContent="flex-end">
                    <Grid item>
                      <Typography
                        variant="body2"
                        display="block"
                        color="textSecondary"
                        align="right"
                      >
                        All units are in pounds of CO<sub>2</sub>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </DefaultPaper>
          </ThemeProvider>
        </StyledEngineProvider>
      </Grid>
    </>
  );
};
export default PageSectionPaper;
