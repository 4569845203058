import React from "react";

import { Grid, Typography, Button, ThemeProvider } from "@mui/material";

import { mainTheme } from "@aclymatepackages/themes";

const OnboardingImageLayout = ({
  onNextStep,
  image,
  title,
  subtitle,
  button,
  backdropProps = {},
  action,
}) => (
  <Grid container direction="column" spacing={4} alignItems="center">
    <Grid item>
      <img src={image} width={350} alt="onboarding graphic" />
    </Grid>
    <Grid item>
      <Typography variant="h2" align="center" color="textPrimary">
        {title}
      </Typography>
      <Typography variant="subtitle1" align="center" color="textSecondary">
        {subtitle}
      </Typography>
    </Grid>
    <Grid item>
      <ThemeProvider theme={mainTheme}>
        {action || (
          <Button variant="contained" color="secondary" onClick={onNextStep}>
            {button}
          </Button>
        )}
      </ThemeProvider>
    </Grid>
  </Grid>
);
export default OnboardingImageLayout;
