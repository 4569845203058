import React from "react";
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";

import {
  Grid,
  Button,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";

import { mergeMainTheme } from "@aclymatepackages/themes";

const OAuthButtons = ({ callback, isNewAccount, setErrorMsg }) => {
  const googleAuthProvider = new GoogleAuthProvider();
  const microsoftAuthProvider = new OAuthProvider("microsoft.com");

  const onAuthClick = (provider) => {
    const auth = getAuth();
    return signInWithPopup(auth, provider)
      .then(({ user }) => callback(user))
      .catch(({ message }) => setErrorMsg(message));
  };

  return (
    <Grid item>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={mergeMainTheme}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-around"
            spacing={1}
          >
            {[
              {
                icon: faGoogle,
                provider: googleAuthProvider,
                label: "google",
                color: "primary",
              },
              {
                icon: faMicrosoft,
                provider: microsoftAuthProvider,
                label: "Microsoft",
                color: "secondary",
              },
            ].map(({ icon, provider, label, color }, idx) => (
              <Grid item key={`oauth-button-${idx}`}>
                <Button
                  startIcon={<FontAwesomeIcon icon={icon} size="sm" />}
                  variant="contained"
                  color={color}
                  onClick={() => onAuthClick(provider)}
                >
                  {`Sign ${isNewAccount ? "up" : "in"} with ${label}`}
                </Button>
              </Grid>
            ))}
          </Grid>
        </ThemeProvider>
      </StyledEngineProvider>
    </Grid>
  );
};
export default OAuthButtons;
