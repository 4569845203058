import React, { useState } from "react";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

import {
  addDataRow,
  editRowData,
} from "@aclymatepackages/array-immutability-helpers";

import AddNewFormQuestion from "../forms/AddNewFormQuestion";
import MultiPartFormLayout from "../flows/MultipartForm";

import { useCachedDisplayData } from "../../../helpers/firebase";
import {
  useNewOfficesSubmit,
  useBuildNewOfficeInputFormRows,
} from "../../../helpers/components/offices";
import {
  useStarterTierSubscriptionFlags,
  useSubscriptionType,
} from "../../../helpers/hooks/companyData";

import { useAccountData } from "../../../helpers/firebase";

const AddOfficesForm = ({
  setOpen,
  onFormSubmit,
  onboarding,
  isSliderLeftContent,
}) => {
  const [offices] = useCachedDisplayData("offices");
  const [{ startDate: companyStartDate, name: companyName }] = useAccountData();
  const onNewOfficesSubmit = useNewOfficesSubmit();
  const buildNewOfficeInputFormRows = useBuildNewOfficeInputFormRows();
  const [{ isPaid }] = useSubscriptionType("saas");
  const utilityApiFeatures = useFeatureIsOn("utilityapi-features");
  const { isStarterTier } = useStarterTierSubscriptionFlags();

  const [newOffices, setNewOffices] = useState([{}]);
  const addNewOfficeRow = () =>
    addDataRow(setNewOffices, {
      name: `Office #${offices.length + newOffices.length + 1}`,
    });

  const onSubmit = () => {
    if (setOpen) {
      setOpen(false);
    }

    if (onFormSubmit) {
      onFormSubmit();
    }

    return onNewOfficesSubmit(newOffices);
  };

  const FormBottomInput = ({ showNextForm }) => (
    <AddNewFormQuestion
      showNextForm={showNextForm}
      onAddNewForm={() => addNewOfficeRow()}
      onFormSubmit={onSubmit}
      label="office"
    />
  );

  const onboardingPropsObj = !onboarding
    ? { FormBottomInput }
    : { submitButtonText: "Submit Office", onAdvanceForm: onSubmit };

  return (
    <MultiPartFormLayout
      type="offices"
      forms={newOffices.map((office, idx) => ({
        label: office.name,
        title: office.name,
        rows: buildNewOfficeInputFormRows({
          office,
          editOffice: editRowData(idx, setNewOffices),
          index: idx,
          companyStartDate,
          companyName,
          isUtilityApiAccessible:
            isPaid && !isStarterTier && utilityApiFeatures,
        }),
        ...onboardingPropsObj,
      }))}
      onClose={!isSliderLeftContent && (() => setOpen(false))}
      submitLoadingText="Please wait while we load your new offices..."
      style={isSliderLeftContent && { right: "40%" }}
    />
  );
};
export default AddOfficesForm;
