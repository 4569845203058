import React, { useState } from "react";

import { LoadingButton } from "@aclymatepackages/atoms";

import { fetchOurApi } from "../../helpers/utils/apiCalls";

const ResendVerificationEmailButton = ({ email }) => {
  const [resendEmailLoading, setResendEmailLoading] = useState(false);

  const refreshVerificationToken = async () => {
    setResendEmailLoading(true);
    await fetchOurApi({
      path: "/onboarding/refresh-verification-token",
      method: "POST",
      data: { email },
    });
    return setResendEmailLoading(false);
  };

  return (
    <LoadingButton
      isLoading={resendEmailLoading}
      label="Resend Verification Email"
      color="secondary"
      onClick={refreshVerificationToken}
    />
  );
};
export default ResendVerificationEmailButton;
