import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";

import { faLeaf } from "@fortawesome/pro-duotone-svg-icons";

import { gcdFromCoordinates } from "@aclymatepackages/calcs/travel";
import { totalLbsOffsetCost } from "@aclymatepackages/other-helpers";

import { getAccountCollectionAndId } from "../otherHelpers";
import { offsetCategories, offsetTypeImages } from "./display-lists/projects";
import { fetchAdminApi } from "../utils/apiCalls";

export const convertCostToPricePerTon = (totalThousandLbsCost) =>
  totalThousandLbsCost * (2204.6 / 1000);

export const sortFilterOptionsAlphabetically = (options) => {
  return [...options].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (b.name < a.name) {
      return 1;
    }
    return 0;
  });
};

export const formatProject = (project) => {
  const {
    typeSlug = "",
    country = "",
    state = "",
    coordinates,
    ...otherProjectProps
  } = project;

  const projectCategories = offsetCategories.filter(({ offsetTypes }) =>
    offsetTypes.includes(typeSlug?.toLowerCase())
  );

  const formattedCategories = projectCategories.length
    ? projectCategories.map(({ icon, name, slug }) => ({
        icon,
        name,
        slug,
      }))
    : [{ slug: "default", icon: faLeaf, name: "Uncategorized" }];

  const [{ slug }] = formattedCategories;

  const totalThousandLbsCost = totalLbsOffsetCost(1000, project);
  const location = country === "United States" ? state : country;

  return {
    ...otherProjectProps,
    marker: `https://aclymate.app/images/project-categories/svg/${slug}.png`,
    totalThousandLbsCost,
    categories: formattedCategories,
    country,
    state,
    location,
    typeSlug,
    latLng: coordinates,
    coordinates,
  };
};

const calculateRankWeight = (rank, ranksArray) =>
  !!rank ? ranksArray.length - (rank - 1) : 0;

const formatProjectsForScoring = (project) => {
  const { typeSlug, country, state } = project;

  const projectCategories = offsetCategories.filter(({ offsetTypes }) =>
    offsetTypes.includes(typeSlug.toLowerCase())
  );
  const categorySlugsList = projectCategories
    .map(({ offsetTypes }) => offsetTypes)
    .flatMap((offsetTypes) => offsetTypes);
  const location = country === "United States" ? state : country;

  return {
    ...project,
    categorySlugs: categorySlugsList,
    totalThousandLbsCost: totalLbsOffsetCost(1000, project),
    location,
  };
};

const createLocationScore = (
  { locations, rankWeight },
  { location = "", coordinates = {} }
) => {
  const { x, y } = coordinates;
  const LONGEST_DISTANCE_BETWEEN_TWO_PLACES_ON_EARTH_KM = 20004;

  if (
    locations.some(
      ({ factor: locationName }) => locationName === location.toLowerCase()
    )
  ) {
    const [{ rank }] = locations.filter(
      ({ factor: locationName }) => locationName === location.toLowerCase()
    );

    return calculateRankWeight(rank, locations) * rankWeight;
  }

  const distancesFromOffsetProject = locations.map((location) => {
    const { latitude, longitude } = location || {};

    return {
      ...location,
      distanceFromOffset: gcdFromCoordinates(
        { latitude, longitude },
        { latitude: x, longitude: y }
      ),
    };
  });

  const smallestDistanceFromOffset = Math.min(
    ...distancesFromOffsetProject.map(
      ({ distanceFromOffset }) => distanceFromOffset
    )
  );

  const [{ rank: closestLocationRank }] = distancesFromOffsetProject.filter(
    ({ distanceFromOffset }) =>
      distanceFromOffset === smallestDistanceFromOffset
  );

  return (
    (1 -
      smallestDistanceFromOffset /
        LONGEST_DISTANCE_BETWEEN_TWO_PLACES_ON_EARTH_KM) *
    calculateRankWeight(closestLocationRank, locations) *
    rankWeight
  );
};

const createPriceScore = (
  { idealPrice, maxPrice, rankWeight },
  { totalThousandLbsCost }
) => {
  if (totalThousandLbsCost < idealPrice) {
    return rankWeight;
  }

  if (totalThousandLbsCost >= idealPrice && totalThousandLbsCost <= maxPrice) {
    const result =
      (1 - (maxPrice - totalThousandLbsCost) / (maxPrice - idealPrice)) *
      rankWeight;

    return result;
  }

  return 0;
};

const createCategoryScore = (
  { subcategories, rankWeight },
  { categorySlugs }
) => {
  const subcategoriesWithSlugsAndRankWeight = subcategories.map(
    ({ factor: subcategoryName, rank }) => ({
      subcategorySlugs: offsetCategories.find(
        ({ name }) => name === subcategoryName
      ).offsetTypes,
      rankWeight: calculateRankWeight(rank, subcategories),
    })
  );
  const categoriesInBothScores = categorySlugs.map((categorySlug) => {
    const subcategory = subcategoriesWithSlugsAndRankWeight.filter(
      ({ subcategorySlugs }) => subcategorySlugs.includes(categorySlug)
    ) || [{}];
    const [{ rankWeight = 0 }] = subcategory.length ? subcategory : [{}];

    return rankWeight;
  });
  const totalCategoriesScore = categoriesInBothScores.reduce(
    (total, current) => total + current,
    0
  );
  const highestPossibleScore = categorySlugs.length || 1;

  return (totalCategoriesScore / highestPossibleScore) * rankWeight;
};

const createMechanismScore = ({ mechanisms, rankWeight }, { mechanism }) =>
  mechanisms.includes(mechanism.toLowerCase()) ? rankWeight : 0;

const createUnSdgsScore = (
  { unSdgs: preferenceUnSdgs, rankWeight },
  { unSdgs: offsetUnSdgs }
) => {
  const sdgsInBothScores = offsetUnSdgs.map((offsetUnSdg) => {
    const preferenceUnSdg = preferenceUnSdgs.filter(
      ({ factor: preferenceUnSdg }) =>
        Number(preferenceUnSdg.charAt(0)) === offsetUnSdg
    );
    const [{ rank = 0 }] = preferenceUnSdg.length ? preferenceUnSdg : [{}];

    return calculateRankWeight(rank, preferenceUnSdgs);
  });
  const totalSdgsScores = sdgsInBothScores.reduce(
    (total, current) => total + current,
    0
  );
  const highestPossibleScore = offsetUnSdgs.length || 1;

  return (totalSdgsScores / highestPossibleScore) * rankWeight;
};

const createVerificationStatusScore = ({ rankWeight }, { registryUrl }) =>
  registryUrl ? rankWeight : 0;

export const createRankedOffsetList = ({ offsetPreferences, projects }) => {
  if (!offsetPreferences || !offsetPreferences?.length) {
    return projects;
  }

  const formattedProjects = projects.map((project) =>
    formatProjectsForScoring(project)
  );

  const offsetPreferencesWithRankWeights = offsetPreferences.map(
    (preference) => ({
      ...preference,
      rankWeight: calculateRankWeight(preference.rank, offsetPreferences),
    })
  );

  const scoringFunctions = {
    location: createLocationScore,
    pricing: createPriceScore,
    category: createCategoryScore,
    mechanism: createMechanismScore,
    unSdgs: createUnSdgsScore,
    verificationStatus: createVerificationStatusScore,
  };

  const getPreferenceScore = (preference, offsetProject) => {
    const { factor, rankWeight } = preference;
    const scoringFunction = scoringFunctions[factor];

    if (!rankWeight) {
      return 0;
    }

    return { [factor]: scoringFunction(preference, offsetProject) };
  };

  const scoredOffsets = formattedProjects.map((offsetProject) => {
    const preferencesScores = offsetPreferencesWithRankWeights.map(
      (preference) => {
        return getPreferenceScore(preference, offsetProject);
      }
    );
    const totalScore = preferencesScores.reduce((sum, preference) => {
      const [score = 0] = Object.values(preference);

      return sum + score;
    }, 0);

    return {
      ...offsetProject,
      preferencesScores,
      totalScore,
    };
  });

  const sortedScoredOffsets = scoredOffsets.sort(
    (a, b) => b.totalScore - a.totalScore
  );

  return sortedScoredOffsets;
};

const fetchAdminProjects = async () => {
  const { id: companyId } = getAccountCollectionAndId();

  const isProduction = process.env.REACT_APP_ENVIRONMENT === "production";

  const projectsCallback = (projects) => {
    const filteredProjects = projects.filter((project) => {
      const { production, offsetProvider } = project;
      if (offsetProvider !== "patch") {
        return true;
      }

      return isProduction === production;
    });

    const projectsWithImages = filteredProjects.map((project) => {
      const { images = [], type } = project;

      if (images.length) {
        return project;
      }

      const randomImgIdx = Math.floor(Math.random() * Math.floor(3)) + 1;
      const randomImage = `https://aclymate.app/images/project-categories/${
        offsetTypeImages.includes(type)
          ? `${type}${randomImgIdx}`
          : `default${randomImgIdx}`
      }.jpg`;

      return { ...project, images: [randomImage] };
    });

    return projectsWithImages.map((project) => formatProject(project));
  };

  const environmentString =
    !isProduction && companyId ? `/${process.env.REACT_APP_ENVIRONMENT}` : "";

  return await fetchAdminApi({
    url: `/offsets/fetch-all/${companyId || ""}${environmentString}`,
    data: { method: "GET" },
    callback: projectsCallback,
  });
};

export const useAdminProjects = () => {
  const { cache } = useSWRConfig();
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);

  useEffect(() => {
    const fetchProjects = async () => {
      const cachedProjects = cache.get("projects");
      if (cachedProjects?.length) {
        setProjectsLoading(false);
        return setProjects(cachedProjects);
      }

      const formattedProjects = await fetchAdminProjects();

      cache.set("projects", formattedProjects);
      setProjects(formattedProjects);
      return setProjectsLoading(false);
    };

    if (!projects?.length) {
      fetchProjects();
    }
  }, [projects?.length, cache]);

  return [projects, projectsLoading];
};
