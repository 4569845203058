import React, { useState } from "react";

import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import {
  Grid,
  Tooltip,
  Badge,
  Button,
  IconButton,
  useTheme,
} from "@mui/material";
import DateRange from "@mui/icons-material/DateRange";

import { Popper, ToggleButtons } from "@aclymatepackages/atoms";

import DatePicker from "../../atoms/mui/DatePicker";

dayjs.extend(isSameOrBefore);

const getDateFromInterval = (dateInterval) => {
  const now = dayjs();

  switch (dateInterval) {
    case "last-month":
      return now.subtract(1, "month").startOf("month");

    case "last-quarter":
      const quarterMonth = Math.floor((now.month() - 1) / 3) * 3;
      return now.subtract(quarterMonth, "month").startOf("month");

    case "year-to-date":
      return now.startOf("year");

    case "last-6-month":
      return now.subtract(5, "month").startOf("month");

    case "last-year":
      return now.subtract(1, "year");

    default:
      return null;
  }
};

const DateRangeFilter = ({
  filterStartDate,
  setFilterStartDate,
  filterEndDate,
  setFilterEndDate,
  initialIntervalSelected = "",
}) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [intervalSelected, setIntervalSelected] = useState(
    initialIntervalSelected
  );

  const dateIntervalButtons = [
    {
      value: "last-month",
      name: "Last Month",
      id: "last-month-option",
    },
    {
      value: "last-quarter",
      name: "Last Quarter",
      id: "last-quarter-option",
    },
    {
      value: "year-to-date",
      name: "Year-to-Date",
      id: "year-to-date-option",
    },
    {
      value: "last-6-month",
      name: "Last 6 Months",
      id: "last-6-month-option",
    },
    {
      value: "last-year",
      name: "Last Year",
      id: "last-year-option",
    },
    {
      value: "custom",
      name: "Custom",
      id: "custom",
    },
  ];

  const resetDateRange = () => {
    setFilterStartDate(null);
    setFilterEndDate(null);
    return setAnchorEl(null);
  };

  const handleDateInterval = (interval) => {
    setIntervalSelected(interval);
    if (interval === "custom") {
      setFilterStartDate(null);
      return setFilterEndDate(null);
    }

    const startDate = getDateFromInterval(interval);

    return setFilterStartDate(startDate);
  };

  const isCustomDate = intervalSelected === "custom";

  const datePickerError =
    filterStartDate &&
    filterEndDate &&
    filterEndDate?.isSameOrBefore(filterStartDate);

  return (
    <>
      <Grid item>
        <Tooltip title="Add a date range">
          <span>
            <IconButton
              onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
            >
              {intervalSelected || filterEndDate || filterStartDate ? (
                <Badge variant="dot" color="secondary">
                  <DateRange />
                </Badge>
              ) : (
                <DateRange />
              )}
            </IconButton>
          </span>
        </Tooltip>
      </Grid>
      <Popper
        isOpen={!!anchorEl}
        anchorEl={anchorEl}
        placement="bottom-end"
        title={isCustomDate ? "Add a Date Range" : "Select the date range"}
        onClose={() => setAnchorEl(null)}
        content={
          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <ToggleButtons
                size="small"
                value={intervalSelected}
                onChange={handleDateInterval}
                buttons={dateIntervalButtons.map((button) => ({
                  ...button,
                  style: {
                    backgroundColor:
                      intervalSelected === button.value
                        ? palette.success.main
                        : "inherit",
                  },
                }))}
              />
            </Grid>
            {isCustomDate && (
              <Grid item container justifyContent="space-around" spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    onOpen={() => setFilterStartDate(null)}
                    label={"Start Date"}
                    date={filterStartDate}
                    editDate={(date) => {
                      const startDate = dayjs(date).startOf("day");
                      setFilterStartDate(startDate);
                    }}
                    size="small"
                    slotProps={{
                      textField: { error: datePickerError, fullWidth: true },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    onOpen={() => setFilterEndDate(null)}
                    label={"End Date"}
                    date={filterEndDate}
                    editDate={(date) => {
                      const endDate = dayjs(date).endOf("day");
                      setFilterEndDate(endDate);
                    }}
                    size="small"
                    slotProps={{
                      textField: { error: datePickerError, fullWidth: true },
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" onClick={() => resetDateRange()}>
                  Reset Date Filter
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};
export default DateRangeFilter;
