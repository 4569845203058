import React from "react";

import {
  Avatar,
  Grid,
  Typography,
  StyledEngineProvider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { mergeDarkTheme, mainTheme } from "@aclymatepackages/themes";

const ProductDetailsLeftContent = ({ icon, title, bullets = [] }) => {
  const theme = useTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mergeDarkTheme}>
        <Grid container spacing={2} direction="column">
          <Grid container item justifyContent="center">
            <Grid item>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={mainTheme}>
                  <Avatar
                    style={{
                      height: "120px",
                      width: "120px",
                      color: "white",
                      backgroundColor: theme.palette.secondary.main,
                    }}
                  >
                    <FontAwesomeIcon icon={icon} size="3x" />
                  </Avatar>
                </ThemeProvider>
              </StyledEngineProvider>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h3" color="textPrimary" align="center">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <List>
              {[...bullets].map((benefit, idx) => (
                <ListItem key={`product-benefit-${idx}`} primaryText={benefit}>
                  <ListItemIcon style={{ color: theme.palette.employees.main }}>
                    <CheckCircleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={benefit}
                    primaryTypographyProps={{ color: "textPrimary" }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ProductDetailsLeftContent;
