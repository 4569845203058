import React, { useState } from "react";
import { Box } from "@mui/material";
import ListItem from "../../atoms/mui/ListItem";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";

const CompanyDataListItem = ({
  editingComponent,
  saveDisabled,
  onEditClick,
  onCancel = () => {},
  onSave = () => {},
  secondaryText,
  editTooltip,
  editDisabled,
  ...otherProps
}) => {
  const [isEditing, setIsEditing] = useState(false);

  return (
    <ListItem
      listContent={isEditing && <Box py={1}>{editingComponent}</Box>}
      secondaryText={secondaryText}
      {...otherProps}
      actions={
        isEditing
          ? [
              {
                tooltip: "Cancel Changes",
                icon: <CancelIcon />,
                onClick: () => {
                  onCancel();
                  return setIsEditing(false);
                },
              },
              {
                tooltip: "Save Changes",
                icon: <SaveIcon />,
                disabled: saveDisabled,
                onClick: () => {
                  setIsEditing(false);
                  return onSave();
                },
              },
            ]
          : [
              {
                tooltip: editTooltip || `Edit ${secondaryText}`,
                icon: <EditIcon />,
                onClick: () =>
                  onEditClick ? onEditClick() : setIsEditing(true),
                disabled: editDisabled,
              },
            ]
      }
    />
  );
};
export default CompanyDataListItem;
