import React, { useContext } from "react";

import { EmissionsCustomTooltip as EmissionsCustomTooltipModule } from "@aclymatepackages/modules";

import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";

const EmissionsCustomTooltip = (props) => {
  const { displayUnitLabel } = useContext(PlatformLayoutContext);

  return (
    <EmissionsCustomTooltipModule
      {...props}
      displayUnitLabel={displayUnitLabel}
    />
  );
};
export default EmissionsCustomTooltip;
