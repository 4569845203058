import React, { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";

import {
  Box,
  Grid,
  Button,
  IconButton,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import {
  addDataRow,
  editRowData,
  removeDataRow,
} from "@aclymatepackages/array-immutability-helpers";
import { Select, ToggleButtons } from "@aclymatepackages/atoms";
import { formatDecimal } from "@aclymatepackages/formatters";

import AirportAutocomplete from "../autocomplete/AirportAutocomplete";
import DatePicker from "../../atoms/mui/DatePicker";

import { isObjectEmpty } from "../../../helpers/otherHelpers";
import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";
import { fetchOurApi } from "../../../helpers/utils/apiCalls";
import useAccountingData from "../../../helpers/hooks/accountingData";

const allClasses = ["Economy", "Premium", "Business", "First"];

const classOptions = (distance, defaultClass = null) => {
  if (!distance && defaultClass) {
    return allClasses;
  }
  if (distance) {
    return distance >= 3700
      ? allClasses
      : distance < 3700 && distance >= 500
      ? ["Economy", "First"]
      : ["Economy"];
  }
  return ["Economy"];
};

const DestinationRow = ({
  flightIdx,
  idx,
  destination,
  destinationsCount,
  editAirport,
  insertDestination,
  removeDestination,
}) => {
  const InsertIcon = () => (
    <IconButton
      style={{
        position: "absolute",
        left: "-36px",
        bottom: "-24px",
      }}
      onClick={() => insertDestination()}
      id={`flight${flightIdx}-insert-leg-btn-${idx}`}
      size="large"
    >
      <AddCircleIcon />
    </IconButton>
  );

  if (idx === 0 || idx === destinationsCount - 1) {
    return (
      <Grid
        item
        style={{ position: "relative" }}
        key={`destination-row-${idx}`}
      >
        <AirportAutocomplete
          label={idx === 0 ? "Start Point" : "End Point"}
          airport={destination || ""}
          editValue={editAirport}
          isInternational
          id={`flight${flightIdx}-destination-input-${idx}`}
        />
        {idx === 0 && <InsertIcon />}
      </Grid>
    );
  }

  return (
    <Grid
      item
      container
      style={{ position: "relative" }}
      key={`destination-row-${idx}`}
      alignItems="center"
    >
      <Grid item sm={10}>
        <AirportAutocomplete
          label={`Layover ${idx}`}
          airport={destination || ""}
          editValue={editAirport}
          isInternational
          id={`flight${idx}-destination-input-${idx}`}
        />
      </Grid>
      <Grid item sm={2}>
        <IconButton
          onClick={() => removeDestination()}
          id={`flight${flightIdx}-remove-leg-btn-${idx}`}
          size="large"
        >
          <CancelIcon />
        </IconButton>
      </Grid>
      <InsertIcon />
    </Grid>
  );
};

const LegRow = ({
  leg,
  defaultClass,
  setDefaultClass,
  setPassClass,
  idx,
  flightIdx,
}) => {
  const { distance, passClass, tonsCo2e } = leg;

  const { convertCarbonUnits, displayUnitLabel } = useContext(
    PlatformLayoutContext
  );

  const editPassClass = (passClass) => {
    setPassClass(passClass);
    setDefaultClass(passClass);
  };

  return (
    <Grid item>
      <Select
        size="small"
        label="Passenger Class"
        value={passClass}
        editValue={editPassClass}
        disabled={!distance}
        options={classOptions(distance, defaultClass).map(
          (option, optionIdx) => ({
            label: option,
            value: option.toLowerCase(),
            elementId: `flight${flightIdx}-passenger-class-select-${idx}-option-${optionIdx}`,
          })
        )}
        helperText={
          tonsCo2e &&
          `${formatDecimal(
            convertCarbonUnits(tonsCo2e)
          )} ${displayUnitLabel} CO2`
        }
        id={`flight${flightIdx}-passenger-class-select-${idx}`}
      />
    </Grid>
  );
};

const FlightBlock = ({
  defaultClass,
  setDefaultClass,
  flights,
  flightIdx,
  flight,
  editFlight,
  editAirport,
  updateConnectedLegs,
  tripType,
  flightsCount,
  removeFlight,
}) => {
  const theme = useTheme();

  const { destinations, legs, date } = flight || {};

  const [{ earliestNewEmissionDate }] = useAccountingData();

  const insertDestination = (destinationIdx) => async () => {
    const arrayInsert = (array, index, element) => [
      ...array.slice(0, index),
      element,
      ...array.slice(index),
    ];
    const newLegs = arrayInsert(legs, destinationIdx + 1, {
      passClass: defaultClass,
    });

    const newDestinations = arrayInsert(destinations, destinationIdx + 1, {});

    await updateConnectedLegs(destinationIdx + 1, newDestinations, newLegs);
    return editFlight("destinations", newDestinations);
  };

  const removeDestination = (destinationIdx) => () => {
    const newDestinations = destinations.filter(
      (_, idx) => idx !== destinationIdx
    );
    const newLegs = legs.filter((_, idx) => idx !== destinationIdx);
    updateConnectedLegs(destinationIdx, newDestinations, newLegs);
    return editFlight("destinations", newDestinations);
  };

  const editDate = (date) => {
    editFlight("date", new Date(date));
  };

  const setPassClass = (legIdx) => async (passClass) => {
    const newLegs = await Promise.all(
      legs.map(async (leg, idx) => {
        if (legIdx === idx) {
          const { distance } = leg;
          const newCarbonTons = await fetchOurApi({
            path: "/calcs/travel/fetch-flights-carbon",
            method: "POST",
            data: {
              distance,
              passClass,
            },
            callback: ({ flightCarbonTons }) => flightCarbonTons,
          });
          return { ...leg, tonsCo2e: newCarbonTons, passClass };
        }
        return leg;
      })
    );
    return editFlight("legs", newLegs);
  };

  const minDate = () => {
    if (earliestNewEmissionDate) {
      return earliestNewEmissionDate;
    }

    if (tripType === "round-trip" && flightIdx === 1 && flights[0]?.date) {
      return flights[0].date;
    }
    if (tripType === "multi-city" && flights[flightIdx - 1]?.date) {
      return flights[flightIdx - 1].date;
    }
    return dayjs().subtract(10, "year");
  };

  const maxDate = () => {
    if (tripType === "round-trip" && flightIdx === 0 && flights[1]?.date) {
      return flights[1].date;
    }
    if (tripType === "multi-city" && flights[flightIdx + 1]?.date) {
      return flights[flightIdx + 1].date;
    }
    return dayjs().add(10, "year");
  };

  return (
    <Box
      style={{
        padding: theme.spacing(2),
        position: "relative",
        overflowX: "hidden",
        overflowY: "auto",
        width: "100%",
        height: "220px",
      }}
    >
      <Grid container direction="column" spacing={3} alignItems="center">
        <Grid item sm={8}>
          <DatePicker
            label="Date of Travel"
            date={date || null}
            editDate={editDate}
            size="small"
            minDate={minDate() || undefined}
            minDateMessage="This flight must be after the last one."
            maxDate={maxDate() || undefined}
            maxDateMessage="This flight must be before the last one."
            id={`flight${flightIdx}-date-input`}
          />
        </Grid>
        <Grid item container justifyContent="center" alignContent="center">
          <Grid
            item
            sm={11}
            container
            spacing={1}
            alignItems="stretch"
            justifyContent="center"
          >
            <Grid
              item
              container
              direction="column"
              sm={7}
              spacing={2}
              justifyContent="space-between"
            >
              {destinations &&
                destinations.map((destination, idx) => (
                  <DestinationRow
                    key={`destination-row-${idx}`}
                    flightIdx={flightIdx}
                    idx={idx}
                    destination={destination}
                    destinationsCount={destinations.length}
                    editAirport={editAirport(idx)}
                    insertDestination={insertDestination(idx)}
                    removeDestination={removeDestination(idx)}
                  />
                ))}
            </Grid>
            <Grid
              item
              container
              direction="column"
              spacing={2}
              justifyContent="center"
              sm={5}
            >
              {legs &&
                legs.map((leg, idx) => (
                  <LegRow
                    key={`flight-leg-row-${idx}`}
                    leg={leg}
                    defaultClass={defaultClass}
                    setDefaultClass={setDefaultClass}
                    setPassClass={setPassClass(idx)}
                    idx={idx}
                    flightIdx={flightIdx}
                  />
                ))}
            </Grid>
            {tripType === "multi-city" && flightsCount > 2 && (
              <Grid item container justifyContent="center">
                <Grid item>
                  <Button
                    onClick={() => removeFlight()}
                    id={`remove-flight${flightIdx}-btn`}
                  >
                    Remove Flight
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const basePassClassObj = { passClass: "economy" };
const buildFlightObj = (flightData) =>
  flightData || {
    destinations: [{}, {}],
    legs: [basePassClassObj],
  };

const makeFlightArrays = () => {
  const firstFlight = buildFlightObj({
    destinations: [{}, {}],
    legs: [basePassClassObj],
  });

  const roundTripFlights = [
    firstFlight,
    buildFlightObj({
      destinations: [{}, {}],
      legs: [basePassClassObj],
    }),
  ];

  const oneWayFlight = [firstFlight];
  const multiCityFlights = [firstFlight, buildFlightObj(), buildFlightObj()];
  return { roundTripFlights, oneWayFlight, multiCityFlights };
};

const totalFlightCarbonTons = (flights) =>
  flights
    .map((flight) => flight.legs.map((leg) => leg.tonsCo2e))
    .flat()
    .reduce((acc, curr) => (curr ? acc + curr : !!acc && false), 0);

//TODO: There's a bug in how the name of trip are saved when they're input manually
//Need to save it with a name that reflects the destination
//Or at the very least, the date of the flight, and not just the date it was entered
const FlightsInput = ({
  transaction: { status },
  tripType,
  setTripType,
  flights,
  setFlights,
  activeFlightIdx,
  setActiveFlightIdx,
}) => {
  const [defaultClass, setDefaultClass] = useState("economy");

  const editFlights = (flightIdx) => (field) => (value) =>
    editRowData(flightIdx, setFlights)(field, value);

  const changeTripType = (newTripType) => {
    setTripType(newTripType);
    setActiveFlightIdx(0);
    switch (newTripType) {
      case "one-way":
        setFlights(makeFlightArrays().oneWayFlight);
        break;
      case "multi-city":
        setFlights(makeFlightArrays().multiCityFlights);
        break;
      default:
        setFlights(makeFlightArrays().roundTripFlights);
    }
  };

  const fetchGcdAndCarbon = async (to, from, passClass = "economy") => {
    if (!isObjectEmpty(to) && !isObjectEmpty(from)) {
      return await fetchOurApi({
        path: "/calcs/travel/fetch-gcd-and-carbon",
        method: "POST",
        data: {
          to,
          from,
          passClass,
        },
        callback: (res) => res,
      });
    }
    return {};
  };

  const findNewPassClass = (gcd, passClass) => {
    const availablePassClasses = classOptions(gcd).map((passClass) =>
      passClass.toLowerCase()
    );

    if (availablePassClasses.length === 1) {
      return availablePassClasses[0];
    }

    if (defaultClass && availablePassClasses.includes(defaultClass)) {
      return defaultClass;
    }

    return passClass;
  };

  const updateConnectedLegs =
    (flightIdx) =>
    async (destinationIdx, destinations, passedLegs = null) => {
      const { legs } = flights[flightIdx];
      const usableLegs = passedLegs || legs;

      if (destinationIdx === 0 && !isObjectEmpty(destinations[1])) {
        const [from, to] = destinations;
        const { passClass } = usableLegs[0];
        const { gcd, flightCarbonTons } = await fetchGcdAndCarbon(
          from,
          to,
          passClass
        );

        const newLegs = usableLegs.map((leg, idx) =>
          !idx
            ? {
                ...leg,
                distance: gcd,
                tonsCo2e: flightCarbonTons,
                passClass: findNewPassClass(gcd, passClass),
              }
            : leg
        );

        return editFlights(flightIdx)("legs")(newLegs);
      }

      if (
        destinationIdx === destinations.length - 1 &&
        !isObjectEmpty(destinations[destinationIdx - 1])
      ) {
        const legIdx = usableLegs.length - 1;
        const to = destinations[destinations.length - 1];
        const from = destinations[destinations.length - 2];
        const { passClass } = usableLegs[legIdx];
        const { gcd, flightCarbonTons } = await fetchGcdAndCarbon(
          from,
          to,
          passClass
        );

        const newLegs = usableLegs.map((leg, idx) =>
          idx === legIdx
            ? {
                ...leg,
                distance: gcd,
                tonsCo2e: flightCarbonTons,
                passClass: findNewPassClass(gcd, passClass),
              }
            : leg
        );

        return editFlights(flightIdx)("legs")(newLegs);
      }

      const previousDestination = destinations[destinationIdx - 1];
      const thisDestination = destinations[destinationIdx];
      const nextDestination = destinations[destinationIdx + 1];

      const buildLeg = async (from, to) => {
        const { gcd, flightCarbonTons } = await fetchGcdAndCarbon(
          from,
          to,
          defaultClass
        );
        return {
          from,
          to,
          distance: gcd,
          tonsCo2e: flightCarbonTons,
          passClass: findNewPassClass(gcd, defaultClass),
        };
      };

      const newLegs = await Promise.all(
        usableLegs.map(async (leg, idx) => {
          if (idx === destinationIdx - 1) {
            return await buildLeg(previousDestination, thisDestination);
          }
          if (idx === destinationIdx) {
            return await buildLeg(thisDestination, nextDestination);
          }
          return leg;
        })
      );

      return editFlights(flightIdx)("legs")(newLegs);
    };

  const editDestinations = (flightIdx, destinationIdx, airport) => {
    const { destinations } = flights[flightIdx];

    const newDestinations = destinations.map((destination, idx) =>
      idx === destinationIdx ? airport : destination
    );
    editFlights(flightIdx)("destinations")(newDestinations);
  };

  const editDestinationAirport =
    (flightIdx) => (destinationIdx) => async (airport) => {
      const { destinations } = flights[flightIdx];
      const newDestinations = destinations.map((destination, idx) =>
        idx === destinationIdx ? airport : destination
      );

      editDestinations(flightIdx, destinationIdx, airport);
      updateConnectedLegs(flightIdx)(destinationIdx, newDestinations);

      if (
        tripType === "round-trip" &&
        flightIdx === 0 &&
        destinationIdx === 0
      ) {
        const { destinations: secondLegDestinations } = flights[1];
        editDestinations(1, secondLegDestinations.length - 1, airport);
      }

      if (
        tripType === "round-trip" &&
        flightIdx === 1 &&
        destinationIdx === destinations.length - 1
      ) {
        editDestinations(0, 0, airport);
      }

      if (
        flightIdx < flights.length - 1 &&
        destinationIdx === destinations.length - 1
      ) {
        editDestinations(flightIdx + 1, 0, airport);
        updateConnectedLegs(flightIdx + 1)(0, newDestinations);
      }

      if (flightIdx !== 0 && destinationIdx === 0) {
        const prevFlightDestinationsCount =
          flights[flightIdx - 1].destinations.length;
        editDestinations(
          flightIdx - 1,
          prevFlightDestinationsCount - 1,
          airport
        );
        updateConnectedLegs(flightIdx - 1)(
          prevFlightDestinationsCount - 1,
          newDestinations
        );
      }
    };

  return (
    <Grid item container direction="column" spacing={2} wrap="nowrap">
      <Grid container item justifyContent="center">
        <Grid item>
          <ToggleButtons
            size="small"
            value={tripType}
            onChange={changeTripType}
            buttons={[
              {
                value: "round-trip",
                name: "Round Trip",
                id: "round-trip-option",
              },
              { value: "one-way", name: "One Way", id: "one-way-option" },
              {
                value: "multi-city",
                name: "Multi-City",
                id: "multi-city-option",
              },
            ]}
          />
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Tabs
          value={activeFlightIdx}
          onChange={(_, value) => setActiveFlightIdx(value)}
          variant={tripType === "multi-city" ? "scrollable" : "fullWidth"}
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="inherit"
        >
          {flights.map((_, idx) => (
            <Tab
              key={`flight-tab-${idx}`}
              value={idx}
              label={
                tripType === "one-way"
                  ? "One Way Trip"
                  : tripType === "round-trip"
                  ? idx === 1
                    ? "Return Trip"
                    : "Outgoing Trip"
                  : `Trip ${idx + 1}`
              }
              id={`flight-tab-${idx}`}
            />
          ))}
        </Tabs>
        <FlightBlock
          key={`flight-row-${activeFlightIdx}`}
          defaultClass={defaultClass}
          setDefaultClass={setDefaultClass}
          transactionStatus={status}
          flights={flights}
          flightIdx={activeFlightIdx}
          flight={flights[activeFlightIdx]}
          editFlight={editRowData(activeFlightIdx, setFlights)}
          editAirport={editDestinationAirport(activeFlightIdx)}
          updateConnectedLegs={updateConnectedLegs(activeFlightIdx)}
          tripType={tripType}
          flightsCount={flights.length}
          removeFlight={removeDataRow(activeFlightIdx, setFlights)}
        />
      </Grid>
      {tripType === "multi-city" && (
        <Grid item container sm={12} justifyContent="center">
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => addDataRow(setFlights, buildFlightObj())}
              id="add-flight-btn"
            >
              Add Flight
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const useFlightsInput = ({ transaction = {}, onSave }) => {
  /* **** DATA STRUCTURE ****
      trip: {
        name: string,
        employee: string (id),
        value: number,
        flights: [
          {
            date: string,
            destinations: [
              {name: string, airport: {airportObj}}
            ],
            legs: [
              {to: string, from: string, distance: number, passClass: string}
            ]
          }
        ]
      }
    */
  const { flights: existingFlights, tonsCo2e } = transaction;

  const setInitialTripType = () => {
    if (!existingFlights) {
      return "round-trip";
    }

    if (existingFlights.length === 1) {
      return "one-way";
    }

    if (existingFlights.length === 2) {
      return "round-trip";
    }

    return "multi-city";
  };

  const [tripType, setTripType] = useState(setInitialTripType());
  const [flights, setFlights] = useState(
    !!existingFlights ? existingFlights : makeFlightArrays().roundTripFlights
  );
  const [activeFlightIdx, setActiveFlightIdx] = useState(0);
  const [flightTonsCo2e, setFlightTonsCo2e] = useState(
    existingFlights ? tonsCo2e : 0
  );

  useEffect(() => {
    setFlightTonsCo2e(totalFlightCarbonTons(flights));
  }, [flights]);

  useEffect(() => {
    if (existingFlights) {
      return setFlights(existingFlights);
    }
  }, [existingFlights]);

  const areFlightsAcceptable = () => {
    if (!flights.length) {
      return false;
    }

    return flights.reduce((acc, { legs, date }) => {
      if (!date) {
        return acc && false;
      }
      const areAllLegsCompleted = legs.reduce(
        (legAcc, { passClass, defaultClass, distance }) =>
          (!!passClass || !!defaultClass) && !!distance && legAcc,
        true
      );
      if (!areAllLegsCompleted) {
        return acc && false;
      }
      return acc && true;
    }, true);
  };

  const flightInputProps = {
    transaction,
    tripType,
    setTripType,
    flights,
    setFlights,
    activeFlightIdx,
    setActiveFlightIdx,
  };

  const onTransactionSave = () => {
    const emissionDate = flights[flights.length - 1].date;

    return onSave({
      tonsCo2e: flightTonsCo2e,
      flights,
      date: emissionDate,
    });
  };

  return {
    inputBlock: <FlightsInput {...flightInputProps} />,
    onTransactionSave,
    saveEnabled: areFlightsAcceptable(),
    tonsCo2e: flightTonsCo2e,
  };
};
export default useFlightsInput;
