import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FilterChips from "../modules/filter/FilterChips";

const useStatusFilterChips = (objectsWithStatus, statusObj) => {
  const [selectedChips, setSelectedChips] = useState(["all"]);

  const buildChipsArray = () => {
    const uniqueStatuses = [
      ...new Set(objectsWithStatus.map(({ status }) => status)),
    ];

    return uniqueStatuses.map((status) => {
      const { icon, ...otherProps } = statusObj[status];

      return {
        icon: <FontAwesomeIcon style={{ color: "inherit" }} icon={icon} />,
        ...otherProps,
      };
    });
  };

  const filterByChips = ({ status }) => {
    if (selectedChips.includes("all")) {
      return true;
    }

    return selectedChips.includes(status);
  };

  return {
    filterChips: (
      <FilterChips
        chipsArray={buildChipsArray()}
        selectedChips={selectedChips}
        setSelectedChips={setSelectedChips}
      />
    ),
    filterByChips,
  };
};
export default useStatusFilterChips;
