import React from "react";

import {
  Grid,
  Typography,
  LinearProgress,
  Grow,
  Alert,
  AlertTitle,
} from "@mui/material";

import { ToggleButtons } from "@aclymatepackages/atoms";

import { useCachedDisplayData } from "../../../helpers/firebase";

const RentalOrCompanyVehicleButtons = ({ purchaseType, setPurchaseType }) => {
  const [companyVehicles, vehiclesLoading] = useCachedDisplayData("vehicles", [
    ["type", "==", "company"],
  ]);

  return (
    <>
      {vehiclesLoading ? (
        <LinearProgress />
      ) : (
        !!companyVehicles.length && (
          <>
            <Grid item container spacing={2} wrap="nowrap">
              <Grid item>
                <Typography variant="body2">
                  Which type of vehicle was this fuel purchased for?
                </Typography>
              </Grid>
              <Grid item>
                <ToggleButtons
                  size="small"
                  selected={false}
                  value={purchaseType}
                  onChange={setPurchaseType}
                  buttons={[
                    {
                      value: "rental-vehicle",
                      name: "Rental",
                      id: "rental-vehicle-option",
                    },
                    {
                      value: "company-vehicle",
                      name: "Company",
                      id: "company-vehicle-option",
                    },
                  ]}
                  style={{ margin: "auto" }}
                />
              </Grid>
            </Grid>
            {purchaseType === "company-vehicle" && (
              <Grow
                in={purchaseType === "company-vehicle"}
                mountOnEnter
                unmountOnExit
              >
                <Grid item>
                  <Alert severity="warning">
                    <AlertTitle>
                      Is this fuel purchase for a tracked drive?
                    </AlertTitle>
                    Make sure you don't double count emissions that were already
                    calculated from tracked drives.
                  </Alert>
                </Grid>
              </Grow>
            )}
          </>
        )
      )}
    </>
  );
};
export default RentalOrCompanyVehicleButtons;
