import React, { useContext } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import {
  Box,
  Grid,
  Button,
  Typography,
  Avatar,
  Paper,
  useTheme,
} from "@mui/material";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import WarningIcon from "@mui/icons-material/Warning";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCheck,
  faReceipt,
  faLeaf,
} from "@fortawesome/free-solid-svg-icons";

import { formatDate, formatDollars } from "@aclymatepackages/formatters";

import CreditCardSlider from "../../inputs/CreditCardSlider";
import BillingMethodCardDisplay from "./BillingMethodCardDisplay";
import StripeElements from "../../modules/StripeElements";

import ErrorBoundary from "../../atoms/ErrorBoundary";
import Card from "../../atoms/mui/Card";
import Link from "../../atoms/mui/Link";

import InvoicesTable from "./InvoicesTable";
import { makeColumnObj } from "../../modules/tables/SortableTable";

import {
  calculateIsCardExpiringSoon,
  useSubscriptionProducts,
} from "../../../helpers/components/settings";
import { useCustomerInvoices } from "../../../helpers/hooks/stripe";
import { useSubscriptionType } from "../../../helpers/hooks/companyData";
import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";
import { StripeCustomerContext } from "../../../helpers/contexts/stripeCustomer";
import { useCachedDisplayData } from "../../../helpers/firebase";

const PaymentMethodCard = () => {
  const history = useHistory();
  const { action } = useParams();

  const { paymentMethod, isPaymentMethodLoading } = useContext(
    StripeCustomerContext
  );
  const { card, us_bank_account } = paymentMethod || {};
  const { expiration, expirationDate } = card || {};

  const isCardExpiringSoon = calculateIsCardExpiringSoon(
    expirationDate,
    expiration
  );

  const billingSubtitle = () => {
    if (!card && !us_bank_account) {
      return "Your credit card or US bank account needs to be setup.";
    }

    if (card && isCardExpiringSoon) {
      return "Your credit card is expiring soon. Click on the credit card below to edit it.";
    }

    if (card) {
      return "Your credit card is setup. Click on the credit card if you'd like to edit your billing information.";
    }

    return "Your US bank account is setup. Click on the bank card if you'd like to edit your billing information.";
  };

  return (
    <>
      {action === "payment-method" && (
        <StripeElements>
          <CreditCardSlider
            setSliderOpen={() =>
              history.push("/platform/company/settings/billing")
            }
          />
        </StripeElements>
      )}
      <Card
        cardType="layout"
        gridProps={{ sm: 6 }}
        isLoading={isPaymentMethodLoading}
        icon={<CreditCardIcon />}
        title="Billing"
        subtitle={billingSubtitle()}
        action={card && isCardExpiringSoon && <WarningIcon color="error" />}
        content={
          <Grid container justifyContent="center">
            <BillingMethodCardDisplay
              onClick={() =>
                history.push(
                  "/platform/company/settings/billing/payment-method"
                )
              }
              noCardComponent={
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() =>
                      history.push(
                        "/platform/company/settings/billing/payment-method"
                      )
                    }
                  >
                    Add your payment information
                  </Button>
                </Grid>
              }
            />
          </Grid>
        }
      />
    </>
  );
};

const SubscriptionDisplayCard = ({
  type: existingType,
  interval: existingInterval,
  upcomingInvoice,
  status,
}) => {
  const { palette } = useTheme();
  const [subscriptionProducts] = useSubscriptionProducts();
  const { title, prices, icon } = subscriptionProducts.find(
    (product) => product.product === existingType
  );
  const { value: price } = prices.find(
    (price) => price.interval === existingInterval
  );

  const { dollarAmountDue, dueDate } = upcomingInvoice || {};

  const buildSubtitle = () => {
    if (status === "incomplete") {
      return "The payment for this subscription is still processing.";
    }

    if (status === "canceled") {
      return `This subscription is canceled and won't renew on ${formatDate(
        dueDate
      )}`;
    }

    return `Next payment of ${formatDollars(
      dollarAmountDue
    )} due on ${formatDate(dueDate)}`;
  };

  return (
    <Grid item>
      <Paper>
        <Box p={1} style={{ backgroundColor: palette.backgroundGray.light }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Avatar>
                <FontAwesomeIcon icon={icon} />
              </Avatar>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle2"
                color="textPrimary"
              >{`${title}- ${formatDollars(
                price
              )} ${existingInterval}ly`}</Typography>
              <Typography variant="caption" color="textSecondary">
                {buildSubtitle()}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
};

const SubscriptionsCard = () => {
  const [subscription, subscriptionLoading] = useSubscriptionType("saas");
  const { status, type, isPaid, cancelAtPeriodEnd, trialDate } = subscription;

  const buildSubtitle = () => {
    if (subscriptionLoading) {
      return "Loading...";
    }

    if (status === "active" || (status === "canceled" && cancelAtPeriodEnd)) {
      return `Your ${type} subscription is currently active`;
    }

    if (!status || status === "canceled") {
      return "You have no active subscriptions. Check out our subscription products by clicking on the button below.";
    }

    if (status === "trialing") {
      return `You're currently on a trial subscription that expires on  ${formatDate(
        trialDate
      )}`;
    }

    return `There's currently an issue with your ${type} subscription`;
  };

  const isTrialing = status === "trialing";

  return (
    <Card
      cardType="layout"
      gridProps={{ sm: 6 }}
      style={{ height: "100%" }}
      isLoading={subscriptionLoading}
      icon={<FontAwesomeIcon icon={faCalendarCheck} />}
      title="Subscriptions"
      subtitle={buildSubtitle()}
    >
      <Box style={{ height: "100%" }} display="flex" flexDirection="column">
        {isPaid && (
          <Box flexGrow={1} display="flex" alignItems="center">
            <Grid container justifyContent="center" spacing={1} wrap="wrap">
              <SubscriptionDisplayCard {...subscription} />
            </Grid>
          </Box>
        )}
        <Box
          flexGrow={!isTrialing ? 0 : 1}
          p={2}
          display="flex"
          alignItems="center"
        >
          <Grid container justifyContent="center">
            <Grid item>
              <Link href={`/platform/company/settings/products`}>
                <Button variant="contained" color="primary">
                  Browse Subscription Products
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
};

const InvoicesCard = () => {
  const [subscriptionProducts, subscriptionProductsLoading] =
    useSubscriptionProducts();
  const [invoices, invoicesLoading] = useCustomerInvoices();
  const [offsets, offsetsLoading] = useCachedDisplayData("offsets");
  const { viewMode } = useContext(PlatformLayoutContext);

  const componentLoading =
    invoicesLoading || subscriptionProductsLoading || offsetsLoading;

  const formatInvoices = () => {
    const offsetsWithoutInvoices = offsets.filter(
      ({ hostedInvoiceUrl }) => !hostedInvoiceUrl
    );

    if (
      componentLoading ||
      viewMode === "sandbox" ||
      (!invoices.length && !offsetsWithoutInvoices.length)
    ) {
      return [];
    }

    const formattedInvoices = invoices.map(({ type, ...otherProps }) => {
      const { icon } = subscriptionProducts.find(
        (product) => product.product === type
      ) || { icon: faLeaf };
      return { icon, type, ...otherProps };
    });
    const formattedOffsetsWithoutInvoices = offsetsWithoutInvoices.map(
      ({ date, name, totalOffsetCost }) => ({
        type: "offset",
        icon: faLeaf,
        date,
        name,
        dollarValue: totalOffsetCost,
      })
    );

    return [...formattedInvoices, ...formattedOffsetsWithoutInvoices];
  };

  const columns = [
    makeColumnObj("TYPE", "type", true),
    makeColumnObj("DATE", "date", true),
    makeColumnObj("NAME", "name", true),
    makeColumnObj("PRICE", "dollarValue", true),
    makeColumnObj("INVOICE"),
  ];

  const formattedInvoices = formatInvoices();

  return (
    <Card
      cardType="layout"
      gridProps={{ sm: 12 }}
      isLoading={invoicesLoading}
      title="Invoices"
      icon={<FontAwesomeIcon icon={faReceipt} />}
    >
      <InvoicesTable formattedInvoices={formattedInvoices} columns={columns} />
    </Card>
  );
};

const Billing = () => {
  return (
    <ErrorBoundary>
      <Grid container spacing={2} alignItems="stretch">
        <PaymentMethodCard />
        <SubscriptionsCard />
        <InvoicesCard />
      </Grid>
    </ErrorBoundary>
  );
};
export default Billing;
