import React from "react";

import { Box, IconButton, Grid, Typography, Paper } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { useLayoutHelpers } from "@aclymatepackages/themes";

import ErrorBoundary from "../atoms/ErrorBoundary";
import Backdrop from "../atoms/mui/Backdrop";

const ContentBox = ({
  contentPosition,
  handleArrowClick,
  header,
  leftArrowDisabled,
  rightArrowDisabled,
  setIsSlideOpen,
  title,
  tagInputs,
  content,
  footer,
  footerColor,
  style = {},
}) => {
  const { isMobile } = useLayoutHelpers();

  return (
    <Box
      style={{
        height: "100%",
        background: "white",
        overflow: "hidden",
        ...style,
      }}
      display="flex"
      flexDirection="column"
    >
      <Box flexGrow={0} p={2}>
        <Grid container alignItems="flex-start" justifyContent="space-between">
          <Grid item container wrap="nowrap" alignItems="center">
            <Grid item xs={handleArrowClick ? 8 : 10}>
              {header}
            </Grid>
            <Grid
              item
              xs={handleArrowClick ? 4 : 2}
              container
              justifyContent="flex-end"
              wrap="nowrap"
            >
              {handleArrowClick && (
                <Grid item>
                  <Grid container wrap="nowrap" style={{ marginLeft: "-16px" }}>
                    <Grid item>
                      <IconButton
                        onClick={() => handleArrowClick("left")}
                        disabled={leftArrowDisabled}
                      >
                        <NavigateBeforeIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => handleArrowClick("right")}
                        disabled={rightArrowDisabled}
                      >
                        <NavigateNextIcon fontSize="large" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {setIsSlideOpen && (
                <Grid item style={{ paddingTop: "8px" }}>
                  <IconButton onClick={() => setIsSlideOpen(false)} edge="end">
                    <CloseIcon fontSize="large" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        flexGrow={1}
        style={{ overflowY: "auto", overflowX: "hidden" }}
        py={2}
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
      >
        <ErrorBoundary>
          <Box flexGrow={0} px={2}>
            {title && <Typography variant="h6">{title}</Typography>}
          </Box>
          <Box flexGrow={0}>{tagInputs}</Box>
          <Box
            flexGrow={1}
            p={isMobile ? 1 : 2}
            display="flex"
            justifyContent={contentPosition || "center"}
            flexDirection="column"
          >
            {content}
          </Box>
        </ErrorBoundary>
      </Box>
      {footer && (
        <Box flexGrow={0} p={2} style={{ backgroundColor: footerColor }}>
          {footer}
        </Box>
      )}
    </Box>
  );
};

const SlideLayout = ({
  setIsSlideOpen,
  isSlideOpen = false,
  leftContent,
  ...otherProps
}) => {
  const { isMedium, theme } = useLayoutHelpers();

  return (
    <>
      {isMedium ? (
        <Backdrop
          open={isSlideOpen}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: `${theme.spacing(2)}px`,
          }}
        >
          <Paper
            style={{
              overflow: "hidden",
              width: "100%",
              maxHeight: "100%",
            }}
          >
            <ContentBox {...otherProps} setIsSlideOpen={setIsSlideOpen} />
          </Paper>
        </Backdrop>
      ) : (
        <>
          <Backdrop open={isSlideOpen} onClick={() => setIsSlideOpen(false)} />
          {leftContent && (
            <Box
              p={3}
              position="fixed"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              style={{
                zIndex: 1200,
                top: 0,
                left: 0,
                bottom: 0,
                width: "60%",
                boxSizing: "border-box",
              }}
            >
              {leftContent}
            </Box>
          )}
          <ContentBox
            style={{
              right: 0,
              top: 0,
              bottom: 0,
              width: "40%",
              height: "100vh",
              position: "fixed",
              zIndex: 1200,
            }}
            setIsSlideOpen={setIsSlideOpen}
            {...otherProps}
          />
        </>
      )}
    </>
  );
};
export default SlideLayout;
