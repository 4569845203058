import React from "react";

import { Select } from "@aclymatepackages/atoms";
import { ucFirstLetters } from "@aclymatepackages/formatters";

const FuelTypesSelect = ({
  fuelType,
  editVehicle,
  availableFuelTypes,
  id,
  smallFont,
  variant,
  noLabel,
}) => (
  <Select
    id={id}
    size="small"
    label={noLabel || "Fuel Type"}
    value={fuelType || ""}
    editValue={editVehicle}
    options={availableFuelTypes.map((option) => ({
      label: ucFirstLetters(option),
      value: option,
    }))}
    smallFont={smallFont}
    variant={variant}
  />
);
export default FuelTypesSelect;
