import React, { useState, useContext } from "react";

import { useHistory } from "react-router-dom";

import { Button, Grid, Typography } from "@mui/material";

import { LoadingButton } from "@aclymatepackages/atoms";

import Link from "../../atoms/mui/Link";

import { PlatformLayoutContext } from "../../../helpers/contexts/platformLayout";
import { getAccountCollectionAndId } from "../../../helpers/otherHelpers";

const SignupFormLoadingActionButtonLayout = ({
  componentLoading,
  onClick,
  label,
  caption,
  href,
  snackbarProps,
  disabled,
}) => {
  const history = useHistory();
  const { activateSnackbar } = useContext(PlatformLayoutContext);

  const [saveLoading, setSaveLoading] = useState(false);

  const { collection } = getAccountCollectionAndId();

  const onButtonClick = () => {
    setSaveLoading(true);
    onClick();

    if (snackbarProps) {
      activateSnackbar(snackbarProps);
    }

    if (collection === "individuals") {
      return null;
    }

    return history.push("/platform/company/settings/products");
  };

  return (
    <Grid container spacing={1} direction="column" alignItems="center">
      <Grid item>
        {href ? (
          <Link href={href}>
            <Button variant="contained" color="primary">
              {label}
            </Button>
          </Link>
        ) : (
          <LoadingButton
            isLoading={saveLoading}
            label={label}
            color="secondary"
            disabled={componentLoading || disabled}
            onClick={onButtonClick}
          />
        )}
      </Grid>
      <Grid item>
        <Typography
          color="textSecondary"
          align="center"
          variant="caption"
          component="div"
        >
          {caption}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default SignupFormLoadingActionButtonLayout;
