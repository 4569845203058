import React from "react";

import { Box, Grid, Button, Typography, LinearProgress } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoePrints } from "@fortawesome/free-solid-svg-icons";

import { useLayoutHelpers } from "@aclymatepackages/themes";
import { formatDecimal } from "@aclymatepackages/formatters";

import TopBarSupportIcon from "../atoms/buttons/TopBarSupportIcon";

import PageHeader from "./PageHeader";

import { isObjectEmpty } from "../../helpers/otherHelpers";

const generateMyAclymateAccountCreationLink = (
  individualId,
  doesAclymateAccountAlreadyExist
) => {
  const baseUrl = `https://${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`;

  if (doesAclymateAccountAlreadyExist) {
    return `${baseUrl}/login?accountType=my&accountId=${individualId}`;
  }

  return individualId
    ? `${baseUrl}/account-creation/my?individualId=${individualId}`
    : `${baseUrl}/account-creation/my`;
};

const ImpactReportLayout = ({
  individual,
  totalTonsCo2e,
  formStep,
  children,
}) => {
  const TOTAL_INDIVIDUAL_SURVEY_STEPS = 5;

  const { isMobile, isMedium, theme, standardBoxPadding } = useLayoutHelpers();

  const {
    id: individualId,
    email,
    name,
    doesAclymateAccountAlreadyExist,
  } = individual || {};

  const individualAccountCreationLink = generateMyAclymateAccountCreationLink(
    individualId,
    doesAclymateAccountAlreadyExist
  );
  const accountCreationButtonProps = isMobile
    ? {
        onClick: () => window.open(individualAccountCreationLink, "_system"),
      }
    : { href: individualAccountCreationLink };

  const actionComponent = individualId
    ? {
        actionComponent: (
          <Grid item container spacing={2} wrap="nowrap" alignItems="center">
            <Grid item>
              <TopBarSupportIcon
                companyName="myAclymate Impact Report"
                email={email}
                userName={name}
                showHelpCenter={false}
                isLightBackground
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                {...accountCreationButtonProps}
              >
                {doesAclymateAccountAlreadyExist ? "Login" : "Get Started"}
              </Button>
            </Grid>
          </Grid>
        ),
      }
    : isMedium && !!totalTonsCo2e
    ? {
        actionComponent: (
          <Grid container spacing={1} wrap="nowrap" alignItems="center">
            <Grid item>
              <FontAwesomeIcon
                icon={faShoePrints}
                size="2x"
                style={{
                  color: theme.palette.secondary.main,
                  rotate: "270deg",
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {formatDecimal(totalTonsCo2e)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">Tons CO2e</Typography>
            </Grid>
          </Grid>
        ),
      }
    : {};

  return (
    <Box
      sx={{
        height: "100vh",
        boxSizing: "border-box",
        overflow: "hidden",
        backgroundColor: isObjectEmpty(individual)
          ? "white"
          : theme.palette.secondary.main,
      }}
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
    >
      <PageHeader {...actionComponent} />
      {!!isMedium && !!formStep && !individualId && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            variant="determinate"
            value={
              ((formStep > 5 ? 5 : formStep) / TOTAL_INDIVIDUAL_SURVEY_STEPS) *
              100
            }
            color="secondary"
          />
        </Box>
      )}
      <Box
        flexGrow={1}
        sx={{
          overflowY: "auto",
          overflowX: "hidden",
        }}
        {...standardBoxPadding}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ImpactReportLayout;
