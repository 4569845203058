import React, { useState, useEffect, useRef } from "react";

import { Box } from "@mui/material";

import { nameToFirstName } from "@aclymatepackages/converters";

import LoadingCardLayoutBox from "../layouts/LoadingCardLayoutBox";
import TopBar from "../modules/TopBar";

import { useAuth } from "../../helpers/firebase.js";

const HEADER_HEIGHT = 270;

const ScrollingImageHeader = ({
  topBarHeight,
  topbarOpacity,
  headerImage,
  headerHeight,
  children,
}) => {
  return (
    <>
      {topbarOpacity < 0.25 && (
        <Box
          p={2}
          style={{
            position: "absolute",
            top: `${topBarHeight}px`,
            width: "100%",
            zIndex: 3,
          }}
        >
          {children}
        </Box>
      )}
      <Box
        position="absolute"
        style={{
          top: 0,
          left: 0,
          width: "100%",
          backgroundImage: `url('/images/${headerImage}.jpg')`,
          backgroundSize: "cover",
          backgroundPosition: "25% 45%",
          zIndex: 0,
        }}
      >
        <Box
          style={{
            background:
              "linear-gradient(15deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)",
            minHeight: `${headerHeight || HEADER_HEIGHT}px`,
          }}
          display="flex"
          flexDirection="column"
        />
      </Box>
    </>
  );
};

const StickyContentBox = ({
  noShadow,
  topBarHeight,
  scrollPercentage,
  children,
}) => {
  const blockShadowStyle =
    scrollPercentage >= 1 && !noShadow
      ? { boxShadow: "0px 2px 10px 0px #000000" }
      : {};

  return (
    <Box
      display="flex"
      position="sticky"
      style={{
        top: `${topBarHeight - 1}px`,
        ...blockShadowStyle,
      }}
      zIndex={100}
      alignItems="stretch"
    >
      {children}
    </Box>
  );
};

const ScrollingImageHeaderLayout = ({
  headerContent,
  headerImage,
  headerHeight,
  stickyContent,
  backgroundColor,
  isLoading,
  stickyBarHeight,
  noShadow,
  layoutBoxStyle,
  children,
}) => {
  const [user] = useAuth();
  const { displayName = "" } = user || {};
  const [topBarHeight, setTopBarHeight] = useState(0);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const hasImageAndContent = !!headerImage && !!headerContent;

  const topBarRef = useRef();

  useEffect(() => {
    if (topBarRef.current) {
      setTopBarHeight(topBarRef.current.offsetHeight);
    }
  }, [topBarRef]);

  const actualHeaderHeight = headerHeight || HEADER_HEIGHT;

  const onDashboardScroll = ({ target }) => {
    const { scrollTop: scrollPosition } = target;

    return setScrollPercentage(
      scrollPosition / (actualHeaderHeight - topBarHeight)
    );
  };

  return (
    <Box
      style={{ height: "100vh", overflow: "hidden" }}
      id="dashboard-page-box"
      position="relative"
    >
      <Box
        position="absolute"
        style={{ zIndex: 101, left: 0, right: 0, top: 0 }}
      >
        <TopBar
          topBarRef={topBarRef}
          title={
            scrollPercentage < 1 && `Welcome ${nameToFirstName(displayName)}`
          }
          opacity={hasImageAndContent ? scrollPercentage : 1}
        />
      </Box>

      {hasImageAndContent && (
        <ScrollingImageHeader
          topBarHeight={topBarHeight}
          topbarOpacity={scrollPercentage}
          headerImage={headerImage}
          headerHeight={headerHeight}
        >
          {headerContent}
        </ScrollingImageHeader>
      )}
      <Box
        id="dashboard-page-box-content"
        position="relative"
        style={{
          overflowY: "auto",
          height: "100%",
        }}
        onScroll={onDashboardScroll}
      >
        <Box
          style={{
            height: hasImageAndContent
              ? `${actualHeaderHeight}px`
              : `${topBarHeight}px`,
          }}
        />
        {stickyContent && (
          <StickyContentBox
            noShadow={noShadow}
            topBarHeight={topBarHeight}
            scrollPercentage={scrollPercentage}
          >
            {stickyContent}
          </StickyContentBox>
        )}
        <Box
          style={{
            background: "white",
          }}
        >
          <LoadingCardLayoutBox
            isLoading={isLoading}
            backgroundColor={backgroundColor}
            style={
              layoutBoxStyle || {
                minHeight: `calc(100vh - ${
                  actualHeaderHeight + stickyBarHeight
                }px)`,
              }
            }
          >
            {children}
          </LoadingCardLayoutBox>
        </Box>
      </Box>
    </Box>
  );
};
export default ScrollingImageHeaderLayout;
