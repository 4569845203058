import { ucFirstLetters } from "@aclymatepackages/formatters";

import { fetchOurApi } from "../utils/apiCalls";

export const createFactorText = (factor) => {
  if (factor === "unSdgs") {
    return "UN SDGs";
  }

  if (factor === "verificationStatus") {
    return "Verification Status";
  }

  return ucFirstLetters(factor);
};

export const formatOffsetPreferencesForScoring = async (offsetPreferences) =>
  await Promise.all(
    offsetPreferences.map(async (preference) => {
      const { factor, rank } = preference;

      if (factor === "location" && rank !== 0) {
        const { locations } = preference;

        const locationsWithCoordinates = await Promise.all(
          locations.map(async (location) => {
            const { factor: place } = location;
            const dataString = JSON.stringify({ place });
            const { predictions } = await fetchOurApi({
              path: `/google-maps/autocomplete/${dataString}`,
              method: "GET",
              callback: (res) => res,
            });
            const [{ place_id }] = predictions;

            const { result } = await fetchOurApi({
              path: `/google-maps/place-details/${place_id}`,
              method: "GET",
              callback: (res) => res,
            });
            const { lat: latitude, lng: longitude } = result.geometry.location;

            return {
              ...location,
              latitude,
              longitude,
            };
          })
        );

        return {
          ...preference,
          locations: locationsWithCoordinates,
        };
      }

      return preference;
    })
  );
