import React, { useState } from "react";

import { Grid, Button } from "@mui/material";

import { editObjectData } from "@aclymatepackages/array-immutability-helpers";
import { TextField, Checkbox } from "@aclymatepackages/atoms";
import { DEFAULT_TONS_CO2E_PER_MILE } from "@aclymatepackages/constants";

import RentalOrCompanyVehicleButtons from "../../atoms/buttons/RentalOrCompanyVehicleButtons";
import PlacesAutocomplete from "../autocomplete/PlacesAutocomplete";

import { setAddress } from "../../../helpers/utils/geography";
import { fetchDirectionsMileage } from "../../../helpers/utils/apiCalls";
import { buildInitialTransactionInputValueFromSchema } from "../../../helpers/components/inputs";

const MileageInput = ({
  purchaseType,
  editInputData,
  from,
  to,
  mileage,
  roundTrip,
}) => {
  const [showMileageInput, setShowMileageInput] = useState(true);

  const editToFrom = (field) => async (newValue) => {
    editInputData(field)(newValue);

    const oldValue = field === "to" ? from : to;

    const mileage =
      oldValue && newValue
        ? await fetchDirectionsMileage(oldValue, newValue)
        : 0;
    return editInputData("mileage")(mileage);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <RentalOrCompanyVehicleButtons
        purchaseType={purchaseType}
        setPurchaseType={editInputData("purchaseType")}
      />
      {showMileageInput ? (
        <Grid item sm={12}>
          <TextField
            label="Miles Traveled"
            value={mileage}
            setValue={editInputData("mileage")}
            size="small"
          />
        </Grid>
      ) : (
        <>
          <Grid item sm={12}>
            <PlacesAutocomplete
              label="From Address"
              place={from ?? null}
              editPlace={setAddress(editToFrom("from"))}
              size="small"
            />
          </Grid>
          <Grid item sm={12}>
            <PlacesAutocomplete
              label="To Address"
              place={to ?? null}
              editPlace={setAddress(editToFrom("to"))}
              size="small"
            />
          </Grid>
        </>
      )}
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => setShowMileageInput((currentInput) => !currentInput)}
          >
            {!showMileageInput ? "Enter Mileage Instead" : "Enter Destinations"}
          </Button>
        </Grid>
        {!showMileageInput && (
          <Grid item>
            <Checkbox
              label="Round Trip?"
              value={roundTrip}
              editValue={editInputData("roundTrip")}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export const useMileageInput = ({ transaction, onSave }) => {
  const { vehicle } = transaction;
  const { tonsCo2ePerMile = DEFAULT_TONS_CO2E_PER_MILE } = vehicle || {};

  const inputSchema = [
    { field: "purchaseType", defaultValue: "company-vehicle" },
    { field: "roundTrip", defaultValue: false },
    { field: "to" },
    { field: "from" },
  ];

  const [inputData, setInputData] = useState(
    buildInitialTransactionInputValueFromSchema(transaction, inputSchema)
  );

  const editInputData = (field) => (value) =>
    editObjectData(setInputData, field, value);

  const { mileage, roundTrip } = inputData;

  const calculateTonsCo2e = () => {
    if (!mileage) {
      return 0;
    }

    if (roundTrip) {
      return mileage * tonsCo2ePerMile * 2;
    }

    return mileage * tonsCo2ePerMile;
  };

  const tonsCo2e = calculateTonsCo2e();
  const onTransactionSave = () => onSave({ ...inputData, tonsCo2e });

  return {
    inputBlock: <MileageInput {...inputData} editInputData={editInputData} />,
    saveEnabled: !!mileage,
    onTransactionSave,
    tonsCo2e,
  };
};
export default useMileageInput;
