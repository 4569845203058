import React from "react";

import { InputAdornment } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import NumberInput from "./NumberInput";

const DollarInput = (props) => {
  return (
    <NumberInput
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoneyIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};
export default DollarInput;
