import React from "react";

import { findThisTagIconColor } from "../../../helpers/components/tags";

const TagIcon = ({ type, color = "inherit", ...otherProps }) => {
  const { Icon } = findThisTagIconColor(type);

  if (!Icon) {
    return <></>;
  }

  return (
    <div>
      <Icon color={color} {...otherProps} />
    </div>
  );
};
export default TagIcon;
