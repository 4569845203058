import React from "react";

import { Tooltip, IconButton } from "@mui/material";

import FileDownloadIcon from "@mui/icons-material/FileDownload";

const DownloadButton = ({
  tooltip,
  buildFile,
  fileName,
  children = <FileDownloadIcon />,
}) => {
  const onDownload = async () => {
    const file = await buildFile();

    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
    return element.remove();
  };

  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton onClick={onDownload}>{children}</IconButton>
      </span>
    </Tooltip>
  );
};
export default DownloadButton;
