import React, { useState, useContext } from "react";

import { Paper, Grid, ButtonBase } from "@mui/material";

import ProjectSummaryDisplay from "./ProjectSummaryDisplay";

import Link from "../../atoms/mui/Link";

import { ProjectsContext } from "../../../helpers/contexts/projects";

const OffsetProjectCardButton = ({ project, onSelectProject, disableHref, children }) => {
  const { slug } = project;

  const { displayPageUrl, onProjectClick } = useContext(ProjectsContext) || {};

  const [hover, setHover] = useState(false);

  const hrefObj = !disableHref ? { href: `${displayPageUrl}/${slug}` } : {}

  return (
    <Link
      {...hrefObj}
      onClick={() => {
        if (onSelectProject) {
          return onSelectProject(project)
        }

        if (onProjectClick) {
          return onProjectClick();
        }
      }}
    >
      <ButtonBase
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{ width: "100%", height: "100%", textAlign: "left" }}
      >
        <Paper
          elevation={hover ? 4 : 1}
          style={{ width: "100%", height: "100%", overflow: "hidden" }}
        >
          {children}
        </Paper>
      </ButtonBase>
    </Link>
  );
};

const OffsetProjectCard = ({ project, isFullScreen, noIcons, onSelectProject, disableHref }) => (
  <Grid item lg={isFullScreen ? 4 : 6} sm={isFullScreen ? 4 : 12} xs={12}>
    <OffsetProjectCardButton project={project} onSelectProject={onSelectProject} disableHref={disableHref} >
      <ProjectSummaryDisplay project={project} noIcons={noIcons} />
    </OffsetProjectCardButton>
  </Grid>
);
export default OffsetProjectCard;
