import React from "react";

import { Typography, ButtonBase } from "@mui/material";

const ActionLinkButton = ({ onClick, disabled, children }) => {
  return (
    <ButtonBase
      onClick={onClick}
      disabled={disabled}
      style={{ textAlign: "left" }}
    >
      <Typography
        variant="body1"
        style={{ textDecoration: disabled ? "none" : "underline" }}
      >
        {children}
      </Typography>
    </ButtonBase>
  );
};
export default ActionLinkButton;
