import React from "react";

import { IconButton, useTheme } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const socialMediaIconsArray = [
  { href: "https://www.facebook.com/Aclymate", icon: faFacebook },
  { href: "https://www.linkedin.com/company/aclymate/", icon: faLinkedinIn },
  { href: "https://www.instagram.com/aclymate/?hl=en", icon: faInstagram },
  { href: "https://twitter.com/AclymateCarbon", icon: faTwitter },
  { href: "https://www.tiktok.com/@my.aclymate", icon: faTiktok },
  { href: "https://www.youtube.com/@Aclymate", icon: faYoutube },
];

const SocialMediaIcons = () => {
  const { palette } = useTheme();

  return (
    <>
      {socialMediaIconsArray.map(({ href, icon }, idx) => (
        <IconButton
          href={href}
          target="_blank"
          key={`social-media-icon-${idx}`}
          size="large"
        >
          <FontAwesomeIcon icon={icon} size="1x" color={palette.text.primary} />
        </IconButton>
      ))}
    </>
  );
};
export default SocialMediaIcons;
