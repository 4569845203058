import React from "react";

import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  useMediaQuery,
  Button,
  StyledEngineProvider,
  ThemeProvider,
  useTheme,
} from "@mui/material";

import { ucFirstLetters } from "@aclymatepackages/formatters";
import { mergeDarkTheme } from "@aclymatepackages/themes";

import Link from "../atoms/mui/Link";

const PageHeader = ({ actionComponent, links }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar
      style={{
        backgroundColor: "#000",
        color: "white",
      }}
      position="static"
    >
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={2} alignItems="center" wrap="nowrap">
              <Grid item>
                <Link href="/">
                  {isMobile ? (
                    <img
                      src="/images/aclymate-icon.png"
                      alt="RWT logo"
                      style={{ maxWidth: "200px" }}
                    />
                  ) : (
                    <img
                      src="/images/aclymate-full-logo-white-text.png"
                      alt="RWT logo"
                      style={{
                        width: "40px",
                        paddingTop: theme.spacing(1),
                      }}
                    />
                  )}
                </Link>
              </Grid>
              {links &&
                links.map(({ label, href }, idx) => (
                  <Grid item key={`header-link-${idx}`}>
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={mergeDarkTheme}>
                        <Link href={href}>
                          <Button size="small">{label}</Button>
                        </Link>
                      </ThemeProvider>
                    </StyledEngineProvider>
                  </Grid>
                ))}
            </Grid>
          </Grid>
          {process.env.REACT_APP_ENVIRONMENT !== "production" && (
            <Grid item>
              <Typography variant="subtitle1" align="center">
                {ucFirstLetters(`${process.env.REACT_APP_ENVIRONMENT} mode`)}
              </Typography>
            </Grid>
          )}
          <Grid item color="inherit">
            {actionComponent}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
export default PageHeader;
