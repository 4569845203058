//eslint-disable-next-line
/*global google*/
import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import TagManager from "react-gtm-module";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";

import theme from "@aclymatepackages/themes";

import "@fontsource/poppins";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

TagManager.initialize({ gtmId: "GTM-PNHV7VR" });

const initiateSentry = () => {
  if (process.env.NODE_ENV !== "development") {
    Sentry.init({
      dsn: "https://2ba6804ae40e48f38e6efb3f3b7e00f8@o1400981.ingest.sentry.io/6731175",
      environment: process.env.REACT_APP_ENVIRONMENT,
      integrations: [
        Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0, // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
};

initiateSentry();

const dndBackend = "ontouchstart" in window ? TouchBackend : HTML5Backend;

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.Fragment>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <DndProvider backend={dndBackend}>
            <App />
          </DndProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  </React.Fragment>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
