import React from "react";

import GasInputForm from "../offices/GasInputForm";

import { useGasInputProps } from "../../../helpers/components/inputs";

const useGasInput = ({ transaction, onSave }) => {
  const {
    gasCarbonTons,
    gasUnit,
    setGasUnit,
    gasUnitValue,
    setGasUnitValue,
    fuelType,
    setFuelType,
    saveEnabled,
    saveObject,
  } = useGasInputProps(transaction);

  const inputBlock = (
    <GasInputForm
      fuelType={fuelType}
      setFuelType={setFuelType}
      gasUnit={gasUnit}
      setGasUnit={setGasUnit}
      unitValue={gasUnitValue}
      setUnitValue={setGasUnitValue}
    />
  );

  const onTransactionSave = () =>
    onSave({ ...saveObject, tonsCo2e: gasCarbonTons });

  return {
    inputBlock,
    onTransactionSave,
    saveEnabled,
    tonsCo2e: gasCarbonTons,
  };
};
export default useGasInput;
