import { useState, useContext } from "react";
import dayjs from "dayjs";

import useCsvUploader from "./csvUploader";

import { PlatformLayoutContext } from "../../helpers/contexts/platformLayout";
import useEmissionsContext from "../../helpers/contexts/emissions";
import { useSharedFormLoading } from "../../helpers/components/inputs";
import { fetchOurApi } from "../../helpers/utils/apiCalls";
import { useAccountData, useCachedDisplayData } from "../../helpers/firebase";
import { analyticsTrack } from "../../helpers/analytics";
import { getAccountCollectionAndId } from "../../helpers/otherHelpers";

const fieldOptions = [
  { label: "Date", value: "date" },
  { label: "Vendor", value: "vendor" },
  { label: "Value", value: "value" },
  { label: "Category", value: "category" },
];

const requiredFields = ["Date", "Vendor", "Value", "Category"];

const knownFields = [
  { header: "date", field: "date" },
  { header: "description", field: "vendor" },
  { header: "vendor", field: "vendor" },
  { header: "memo", field: "vendor" },
  { header: "value", field: "value" },
  { header: "total", field: "value" },
  { header: "amount", field: "value" },
  { header: "category", field: "category" },
  { header: "description", field: "category" },
];

const fieldValidator = (headers) => {
  const fieldsBoolean = requiredFields.reduce(
    (booleanAcc, requiredField) =>
      booleanAcc &&
      headers.find(({ field }) => requiredField.toLowerCase() === field),
    true
  );

  return {
    success: fieldsBoolean,
    message:
      !fieldsBoolean &&
      `The fields Date, Vendor,${
        requiredFields.includes("Category") ? " Category," : ""
      } and Value are required for this CSV. Exclude Category to select your own categories for each transaction.`,
  };
};

const parseTransactionValue = (value) => {
  if (!value) {
    return null;
  }

  if (!isNaN(Number(value))) {
    return Number(value);
  }

  const valueNumbers = value.match(/[0-9.-]+/gm) || [];
  if (!valueNumbers.length) {
    return null;
  }

  const joinedNumber = valueNumbers.join("");

  const matchParentheses = value.match(/\([0-9,.]+\)/gm);

  return matchParentheses?.length
    ? Number(joinedNumber * -1)
    : Number(joinedNumber);
};

const useProcessTransactions = (setOpen) => {
  const { activateSnackbar } = useContext(PlatformLayoutContext);
  const { transactions: existingTransactions } = useEmissionsContext();

  const [{ startDate }] = useAccountData();
  const [existingVendors] = useCachedDisplayData("vendors");

  const { setFormLoading } = useSharedFormLoading();

  const processTransactionsData = async ({ data }) => {
    setFormLoading(true);

    const sortAndFormatTransactions = (transactions) => {
      const realTransactions = transactions.filter(
        ({ date, value }) => date && value
      );

      const sortTransactions = () => {
        //This mutable code is in here because we're starting to have performance implications of having to ready possibly 100K transactions from a CSV
        //We're wrapping them in a function to limit their scope and make it more readable
        let tooOldTransactions = [];
        let duplicateTransactions = [];
        let transactionsToProcess = [];

        realTransactions.forEach((transaction) => {
          const { date, value: rawValue, vendor } = transaction;
          const sharedProps = {
            ...transaction,
            rawValue,
            value: parseTransactionValue(rawValue),
          };

          if (startDate && dayjs(date).isBefore(startDate)) {
            return tooOldTransactions.push(sharedProps);
          }

          const isExistingTransaction = !!existingTransactions.find(
            (existingTransaction) =>
              dayjs(existingTransaction.date).isSame(dayjs(date, "day")) &&
              existingTransaction.value === rawValue &&
              existingTransaction.vendor === vendor
          );

          if (isExistingTransaction) {
            return duplicateTransactions.push(sharedProps);
          }

          return transactionsToProcess.push(sharedProps);
        });

        return {
          tooOldTransactions,
          duplicateTransactions,
          transactionsToProcess,
        };
      };

      return sortTransactions();
    };

    const findUniqueVendors = (transactions) => {
      const uniqueTransactionVendorsMap = [
        ...new Map(
          transactions.map(({ vendor, category }) => [vendor, category])
        ),
      ];

      const uniqueTransactionVendors = uniqueTransactionVendorsMap.map(
        ([name, category]) => ({
          name,
          category,
        })
      );

      return uniqueTransactionVendors.filter(
        ({ name }) =>
          !existingVendors.find(
            ({ name: existingVendorName }) => existingVendorName === name
          )
      );
    };

    const { tooOldTransactions, duplicateTransactions, transactionsToProcess } =
      sortAndFormatTransactions(data);

    const vendorsToProcess = findUniqueVendors(transactionsToProcess);

    const { id } = getAccountCollectionAndId();
    const { transactionsTaskId } = await fetchOurApi({
      path: "/transactions/write-transactions-task-data",
      method: "POST",
      data: {
        companyId: id,
        transactions: transactionsToProcess,
        source: "csv-uploader",
      },
      callback: (res) => res,
    });

    await fetchOurApi({
      path: "/vendors/write-vendors-task-data",
      method: "POST",
      data: { companyId: id, vendors: vendorsToProcess, transactionsTaskId },
    });

    const numberOfTransactions = transactionsToProcess.length;
    const numberOfVendors = vendorsToProcess.length;

    const buildSnackbarMessage = () => {
      const mainSentence = `We're processing ${numberOfTransactions} transactions and ${numberOfVendors} new vendors. You'll receive a notification when the processing is complete`;

      const duplicateTransactionsSentence = !!duplicateTransactions.length
        ? ` ${duplicateTransactions.length} transactions were excluded because they were duplicates of already loaded transactions`
        : "";

      const tooOldTransactionsSentence = !!tooOldTransactions.length
        ? ` ${tooOldTransactions.length} transactions were excluded because they happened before your start date.`
        : "";

      return `${mainSentence}${duplicateTransactionsSentence}${tooOldTransactionsSentence}`;
    };

    activateSnackbar({ message: buildSnackbarMessage(), alert: "success" });
    setFormLoading(false);
    setOpen(false);
    return analyticsTrack("Transactions Loaded", {
      numberOfTransactions,
      numberOfVendors,
    });
  };

  return processTransactionsData;
};

const useTransactionsUploader = ({ open, setOpen }) => {
  const [fileInfo, setFileInfo] = useState({});
  const [matchedHeaders, setMatchedHeaders] = useState([]);

  const processTransactionsData = useProcessTransactions(setOpen);

  const csvUploaderSteps = useCsvUploader({
    knownFields,
    fieldOptions,
    open,
    setOpen,
    fileInfo,
    setFileInfo,
    matchedHeaders,
    setMatchedHeaders,
    processData: processTransactionsData,
    fieldValidator,
    docType: "transactions",
    firstStepText:
      "Upload a CSV of your transactions and we'll automatically sort out the ones that you can offset.",
    requiredFields,
  });

  return csvUploaderSteps;
};
export default useTransactionsUploader;
