import React from "react";

import { Box, useTheme } from "@mui/material";

const VendorHeader = ({ action }) => {
  const theme = useTheme();

  return (
    <Box
      p={2}
      style={{ backgroundColor: theme.palette.backgroundGray.main }}
      flexGrow={0}
      position="relative"
      display="flex"
      justifyContent="center"
    >
      <img
        alt="aclymate logo"
        style={{ height: "50px" }}
        src="/images/aclymate-full-logo.png"
      />
      {action && (
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            boxSizing: "border-box",
          }}
        >
          {action}
        </Box>
      )}
    </Box>
  );
};
export default VendorHeader;
