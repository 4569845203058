import React, { useContext, useState } from "react";

import {
  Container,
  Grid,
  Button,
  Avatar,
  IconButton,
  Typography,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { mainTheme, mergeDarkTheme } from "@aclymatepackages/themes";

import RevealFormBlock from "./RevealFormBlock";
import FormErrorMessage from "./FormErrorMessage";

import { MultipartFormContext } from "../../../helpers/contexts/multiPartForm";

const FormInputBlock = ({
  input,
  noButton,
  isActiveForm,
  buttonDisabled,
  showNextForm,
  buttonText,
  onAdvanceForm,
  formAdvanceErrorMessage,
}) => {
  const onNextStep = () => {
    if (onAdvanceForm) {
      onAdvanceForm();
    }
    return showNextForm();
  };

  const isButtonDisabled = buttonDisabled || formAdvanceErrorMessage;

  return (
    <>
      <Grid item style={{ width: "100%" }}>
        {input}
      </Grid>
      {formAdvanceErrorMessage && (
        <FormErrorMessage formAdvanceErrorMessage={formAdvanceErrorMessage} />
      )}
      {isActiveForm && !noButton && (
        <Grid item container justifyContent="center">
          <Grid item>
            <StyledEngineProvider injectFirst>
              <ThemeProvider
                theme={!isButtonDisabled ? mainTheme : mergeDarkTheme}
              >
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon fontSize="large" />}
                  disabled={!!isButtonDisabled}
                  onClick={onNextStep}
                >
                  {buttonText || "Next Step"}
                </Button>
              </ThemeProvider>
            </StyledEngineProvider>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const FormContainer = ({
  form,
  label,
  title,
  titleIcon,
  subtitle,
  size = "sm",
  isActiveForm,
  rows,
  input,
  formAdvanceErrorMessage,
  titleAlign = "flex-start",
  FormBottomInput,
  showNextForm,
  ...otherProps
}) => {
  const { color } = useContext(MultipartFormContext);

  const [inactiveFormOpen, setInactiveFormOpen] = useState(false);

  return (
    <>
      {form || (
        <Grid item>
          <Container
            maxWidth={!inactiveFormOpen && !isActiveForm ? "sm" : size}
          >
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={mergeDarkTheme}>
                <Grid container direction="column" spacing={2}>
                  {!isActiveForm ? (
                    <Grid
                      item
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Grid item>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          wrap="nowrap"
                        >
                          <Grid item>
                            <Avatar style={{ backgroundColor: color }}>
                              <DoneIcon color="primary" />
                            </Avatar>
                          </Grid>
                          <Grid item>
                            <Typography variant="h3">{label}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() =>
                            setInactiveFormOpen(
                              (currentStatus) => !currentStatus
                            )
                          }
                          size="large"
                        >
                          {inactiveFormOpen ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item container justifyContent={titleAlign}>
                      <Grid
                        item
                        container
                        alignItems="center"
                        justifyContent={titleAlign}
                        spacing={2}
                      >
                        <Grid item>
                          <Typography variant="h4">{title}</Typography>
                        </Grid>
                        {titleIcon && (
                          <Grid item>
                            <Grid item container alignItems="center">
                              {titleIcon}
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      {subtitle && (
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            {subtitle}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {(isActiveForm || inactiveFormOpen) &&
                    (rows ? (
                      <RevealFormBlock
                        isActiveForm={isActiveForm}
                        rows={
                          FormBottomInput
                            ? [
                                ...rows,
                                <FormBottomInput showNextForm={showNextForm} />,
                              ]
                            : rows
                        }
                        FormBottomInput={FormBottomInput}
                        formAdvanceErrorMessage={formAdvanceErrorMessage}
                        showNextForm={showNextForm}
                        {...otherProps}
                      />
                    ) : (
                      <FormInputBlock
                        input={input}
                        isActiveForm={isActiveForm}
                        showNextForm={showNextForm}
                        formAdvanceErrorMessage={formAdvanceErrorMessage}
                        {...otherProps}
                      />
                    ))}
                </Grid>
              </ThemeProvider>
            </StyledEngineProvider>
          </Container>
        </Grid>
      )}
    </>
  );
};
export default FormContainer;
