import React, { useEffect, useState, useContext } from "react";

import { Grid } from "@mui/material";

import FormContainer from "./FormContainer";
import FormDivider from "./FormDivider";

import { MultipartFormContext } from "../../../helpers/contexts/multiPartForm";

const MultiPartFormReveal = ({
  forms,
  showAllSteps,
  formStep: parentFormStep,
  setFormStep: parentSetFormStep,
}) => {
  const [localFormStep, setLocalFormStep] = useState(
    showAllSteps ? forms.length + 1 : 1
  );
  const { goToBottom } = useContext(MultipartFormContext);

  const formStep = parentFormStep ?? localFormStep;
  const setFormStep = parentSetFormStep || setLocalFormStep;

  useEffect(() => {
    goToBottom();
  }, [formStep, goToBottom]);

  const formBlocks = forms.map((form, idx) => (
    <FormContainer
      key={`form-block-${idx}`}
      isActiveForm={idx === formStep - 1}
      showNextForm={() => setFormStep((currentStep) => currentStep + 1)}
      {...form}
    />
  ));

  return (
    <>
      {[...new Array(formStep)].map((_, idx) => (
        <React.Fragment key={`form-question-${idx}`}>
          <Grid item>{formBlocks[idx]}</Grid>
          {idx < formStep - 1 && <FormDivider />}
        </React.Fragment>
      ))}
    </>
  );
};
export default MultiPartFormReveal;
