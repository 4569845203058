import React, { useState } from "react";

import { Box, Grid, Typography, Button, useTheme } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import { MatchingCompanyChips, AnimatedLogo } from "@aclymatepackages/atoms";
import { industriesList, subcategories } from "@aclymatepackages/lists";
import { findVendorEmissionCategoryData } from "@aclymatepackages/calcs/vendors";

import DetailsActionAlert from "../../../../atoms/notifications/DetailsActionAlert";
import EmissionsCategorySelect from "../../../../inputs/vendors/EmissionsCategorySelect";
import SpendBasedVendorInputs from "../../../../inputs/vendors/SpendBasedVendorInputs";

import { fetchOurApi } from "../../../../../helpers/utils/apiCalls";

const EmissionCategoryGridInputs = ({
  emissionCategory,
  editVendor,
  disabled,
  ...otherProps
}) => {
  return (
    <>
      <Grid item>
        <EmissionsCategorySelect
          value={emissionCategory}
          setValue={(category) => {
            if (category !== "spend-based") {
              editVendor("naicsTitle")(null);
              editVendor("naicsCode")(null);
              editVendor("tonsCo2ePerDollar")(null);
            }

            return editVendor("emissionCategory")(category);
          }}
          disabled={disabled}
        />
      </Grid>
      {emissionCategory === "spend-based" && (
        <SpendBasedVendorInputs
          {...otherProps}
          disabled={disabled}
          editVendor={editVendor}
        />
      )}
    </>
  );
};

const MatchingCompaniesSelector = ({
  matchingCompanies,
  selectedCompany,
  setSelectedCompany,
  onSelectCompany,
  setNaicsCodesOptions,
}) => {
  const isMultipleMatches = matchingCompanies.length > 1;

  return (
    <Grid item>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="h6" align="center">
            We found {isMultipleMatches ? "these companies" : "this company"}{" "}
            that match{isMultipleMatches ? "" : "es"} this vendor. Please select
            and confirm if{" "}
            {isMultipleMatches ? "one of these companies" : "this company"} is
            your vendor.
          </Typography>
        </Grid>
        <Grid item>
          <MatchingCompanyChips
            matchingCompanies={matchingCompanies}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            onSelectCompany={onSelectCompany}
          />
        </Grid>
        <Grid item container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              endIcon={selectedCompany?.id === "none" && <CheckIcon />}
              variant="contained"
              color="secondary"
              onClick={() => {
                setNaicsCodesOptions([]);
                setSelectedCompany({ id: "none" });
                return onSelectCompany({ id: "none" });
              }}
            >
              {isMultipleMatches
                ? "None of these companies match"
                : "This isn't my vendor"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const VendorDetailsStates = ({
  editVendor,
  vendor,
  matchingCompanies = [],
  matchingCompaniesLoading,
  selectedCompany,
  setSelectedCompany,
  selectedVendor,
  setEditableVendor,
}) => {
  const {
    emissionCategory,
    naicsTitle,
    scopeThreeCategory,
    status,
    surveyStatus,
    naicsCode,
  } = vendor;

  const { palette } = useTheme();

  const [naicsCodesOptionsLoading, setNaicsCodesOptionsLoading] =
    useState(false);
  const [naicsCodesOptions, setNaicsCodesOptions] = useState([]);

  const emissionCategoryProps = {
    emissionCategory,
    naicsCode,
    naicsTitle,
    scopeThreeCategory,
    editVendor,
  };

  const { dbId } = selectedCompany || {};

  const onSelectCompany = async (selectedCompany) => {
    setEditableVendor(selectedVendor);
    const { dbId, id, name, industry } = selectedCompany || {};

    if (!selectedCompany || id === "none") {
      return setNaicsCodesOptions([]);
    }

    if (dbId) {
      const { naicsCode, scopeThreeCategory, emissionCategory } =
        findVendorEmissionCategoryData(selectedCompany);
      editVendor("emissionCategory")(emissionCategory);
      editVendor("naicsCode")(naicsCode);
      return editVendor("scopeThreeCategory")(scopeThreeCategory);
    }

    editVendor("name")(name);

    const { naics: industryNaicsCode, tonsCo2ePerDollar } =
      industriesList.find(
        ({ peopleDataLabsIndustryType }) =>
          peopleDataLabsIndustryType === industry
      ) || {};

    const naicsSubcategory = subcategories.find(
      ({ naics }) => naics?.code === industryNaicsCode
    );

    if (industryNaicsCode && naicsSubcategory) {
      const { subcategory: subcategoryName } = naicsSubcategory;
      editVendor("tonsCo2ePerDollar")(tonsCo2ePerDollar);
      return editVendor("emissionCategory")(subcategoryName);
    }

    editVendor("emissionCategory")("spend-based");
    setNaicsCodesOptionsLoading(true);
    const { description, naicsCodes } = await fetchOurApi({
      path: "/open-ai/fetch-company-description-and-naics",
      data: {
        name,
        industry,
      },
      method: "POST",
      callback: (res) => res,
    });
    setNaicsCodesOptionsLoading(false);
    editVendor("description")(description);

    return setNaicsCodesOptions(naicsCodes);
  };

  if (status !== "unmatched") {
    return (
      <>
        {(surveyStatus === "has-account" ||
          surveyStatus === "survey-completed") && (
          <DetailsActionAlert
            color={palette.success.main}
            text={
              surveyStatus === "survey-completed"
                ? "This vendor has completed their vendor survey so you can't recategorize them."
                : "This company has an Aclymate account, so you can't recategorize this vendor or ask them to take a survey."
            }
          />
        )}
        <EmissionCategoryGridInputs
          {...emissionCategoryProps}
          disabled={
            surveyStatus === "has-account" ||
            surveyStatus === "survey-completed"
          }
        />
      </>
    );
  }

  if (matchingCompaniesLoading) {
    return (
      <Box p={8} display="flex" justifyContent="center" alignItems="center">
        <AnimatedLogo />
      </Box>
    );
  }

  if (!matchingCompanies.length) {
    return (
      <>
        <DetailsActionAlert
          color={palette.error.main}
          text="We couldn't match this vendor to any of our known vendors. Please confirm the name of this vendor."
        />
        <EmissionCategoryGridInputs {...emissionCategoryProps} />
      </>
    );
  }

  return (
    <>
      <MatchingCompaniesSelector
        matchingCompanies={matchingCompanies}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
        onSelectCompany={onSelectCompany}
        setNaicsCodesOptions={setNaicsCodesOptions}
      />
      {naicsCodesOptionsLoading && (
        <Box p={2} display="flex" justifyContent="center" alignItems="center">
          <AnimatedLogo />
        </Box>
      )}
      {!!selectedCompany && !naicsCodesOptionsLoading && (
        <EmissionCategoryGridInputs
          disabled={!!dbId && !!emissionCategory}
          editVendor={editVendor}
          {...emissionCategoryProps}
          naicsCodes={naicsCodesOptions}
        />
      )}
    </>
  );
};

const EditVendorDetails = (props) => (
  <Grid container direction="column" spacing={2}>
    <VendorDetailsStates {...props} />
  </Grid>
);
export default EditVendorDetails;
