import React, { useState } from "react";

import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  CircularProgress,
  Avatar,
  useTheme,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen,
  faFloppyDisk,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

const AvatarIconButton = ({ tooltip, onClick, disabled, icon }) => {
  const { palette } = useTheme();

  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton size="small" onClick={onClick} disabled={disabled}>
          <Avatar
            style={{
              backgroundColor: palette.backgroundGray.dark,
              height: "24px",
              width: "24px",
            }}
          >
            <FontAwesomeIcon icon={icon} style={{ color: "white" }} size="xs" />
          </Avatar>
        </IconButton>
      </span>
    </Tooltip>
  );
};

const BlockContent = ({
  isEditing: parentIsEditing,
  setIsEditing: parentSetIsEditing,
  content,
  editingInterface,
  onSave = () => {},
  saveDisabled,
  elementName,
  disableEdit,
  isEditableByDefault = false,
  padding = 2,
  height = "100%",
  exportLoading,
  imageFile,
}) => {
  const { palette, shape } = useTheme();

  const [localIsEditing, setLocalIsEditing] = useState(isEditableByDefault);
  const [saveLoading, setSaveLoading] = useState(false);

  const isEditing = parentIsEditing || localIsEditing;
  const setIsEditing = parentSetIsEditing || setLocalIsEditing;

  const onSaveClick = async () => {
    setSaveLoading(true);
    await onSave();
    setSaveLoading(false);
    return setIsEditing(false);
  };

  return (
    <Box
      position="relative"
      p={padding}
      style={{
        height,
        width: "100%",
        borderRadius: shape.borderRadius,
        border: isEditing
          ? "none"
          : `${palette.backgroundGray.dark} thin solid`,
      }}
    >
      {isEditing ? editingInterface : content}
      {!exportLoading && (
        <Box position="absolute" right={8.5} top={-17} zIndex={10}>
          {isEditing ? (
            <Grid container justifyContent="flex-end" alignItems="center">
              {!isEditableByDefault && (
                <Grid item>
                  <AvatarIconButton
                    tooltip="Cancel Edits"
                    onClick={() => setIsEditing(false)}
                    icon={faXmark}
                  />
                </Grid>
              )}
              <Grid item>
                {saveLoading ? (
                  <CircularProgress size={22} color="inherit" />
                ) : imageFile ? (
                  <AvatarIconButton
                    tooltip="Save Edits"
                    onClick={onSaveClick}
                    disabled={saveDisabled}
                    icon={faFloppyDisk}
                  />
                ) : null}
              </Grid>
            </Grid>
          ) : (
            <AvatarIconButton
              tooltip={`Edit ${elementName || "content"}`}
              onClick={() => setIsEditing(true)}
              disabled={disableEdit}
              icon={faPen}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

const EditableContentBlock = ({ contentLoading, ...otherProps }) => {
  return (
    <>
      {contentLoading ? (
        <Box
          id="content-container"
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%", width: "100%" }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <BlockContent {...otherProps} />
      )}
    </>
  );
};
export default EditableContentBlock;
